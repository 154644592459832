import React from 'react'
import { Heading, Text } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImageProps } from 'gatsby-plugin-image'
import LogoList from './logo-list'

const FooterPartners: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.FooterPartnersQuery>(graphql`
    query FooterPartners {
      footerPartners: allContentfulFooterPartners {
        nodes {
          title
          body
          footerPartners {
            name
            url
            logo {
              gatsbyImageData(
                width: 120
                quality: 60
                layout: FIXED
                formats: [WEBP]
              )
            }
            page {
              slug
              topic {
                slug
                context {
                  slug
                }
              }
            }
          }
        }
      }
    }
  `)

  const header = data.footerPartners.nodes[0].title
  const body = data.footerPartners.nodes[0].body
  const partners = data.footerPartners.nodes[0].footerPartners

  return (
    <LogoList
      items={partners.map(partner => ({
        title: partner.name,
        logo: partner.logo as GatsbyImageProps,
        page: partner.page && partner.page[0],
        url: partner.url,
      }))}
      variant="tight"
    >
      {header && (
        <Heading as="h3" variant="med">
          {header}
        </Heading>
      )}
      {body && (
        <Text sx={{ mt: 9 }} variant="intro">
          {body}
        </Text>
      )}
    </LogoList>
  )
}

export default FooterPartners
