import React, { useEffect, useRef } from 'react'
import PageLink from './page-link'

interface Props {
  href: string
}

/**
 * A block link that anchors to another place on the same page.
 *
 * Arrow points up or down depending on placement.
 */
const AnchorLink: React.FC<Props> = ({ href, children }) => {
  const ref = useRef<HTMLAnchorElement>(null)

  useEffect(() => {
    const link = ref.current
    const linkTop = link?.getBoundingClientRect().top

    const isHash = href && href[0] === '#'
    const hash = isHash ? href.substring(1) : new URL(href).hash.substring(1)

    const target = document.getElementById(hash)
    const targetTop = target?.getBoundingClientRect().top

    if (link && linkTop && targetTop && targetTop < linkTop) {
      link.classList.add('flip')
    } else if (link) {
      link.classList.remove('flip')
    }
  })

  return (
    <PageLink innerRef={ref} to={href} variant="anchor">
      {children}
    </PageLink>
  )
}

export default AnchorLink
