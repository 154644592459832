import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Navlink from './nav-link'
import { formatPageLink } from '../utils/helpers'
import { Wrap } from 'raam'
import { ReactNodeArray } from 'react'

const FooterNav: React.FC = props => {
  const data = useStaticQuery<GatsbyTypes.UtilityNavigationQuery>(graphql`
    query UtilityNavigation {
      contentfulPageCollection(group: { eq: "Utility" }) {
        pages {
          ...PageLink
        }
      }
    }
  `)

  const pages = data.contentfulPageCollection?.pages

  const links: ReactNodeArray | undefined = pages?.map((page, i) => (
    <Navlink key={i} to={formatPageLink(page)}>
      {page?.title}
    </Navlink>
  ))

  return (
    <Wrap sx={{ justifyContent: 'center' }} gap={12} {...props}>
      {links}
    </Wrap>
  )
}

export default FooterNav
