import React from 'react'
import { Box, SxStyleProp, ThemeUIStyleObject } from 'theme-ui'
import { useStaticQuery, graphql } from 'gatsby'
import NavLink from './nav-link'
import { fluidScale } from '../utils/mixins'
import { formatPageLink } from '../utils/helpers'
import { ReactNodeArray, useState } from 'react'
import { Page } from '../utils/types'
import arrowhead from '../images/small-arrowhead.svg'

interface Config {
  largeButtons: boolean
}

interface Props {
  sx?: SxStyleProp
  config?: Config | boolean
  page?: Page
  parent?: GatsbyTypes.SupportPageQuery
  subPages?: Array<GatsbyTypes.SupportSubPageQuery>
}

/**
 * Secondary navigation component, presented as tabs under the main navigation
 */
const SubNav: React.FC<Props> = ({ sx, config, page, parent, subPages }) => {
  let pages = subPages
  const data = useStaticQuery<GatsbyTypes.SecondaryNavigationQuery>(graphql`
    query SecondaryNavigation {
      allContentfulPageCollection(filter: { group: { eq: "Secondary" } }) {
        edges {
          node {
            pages {
              ...PageLink
            }
            context {
              slug
            }
          }
        }
      }
    }
  `)

  if (data && !pages) {
    // select the subnavigation that matches the current URL path
    const group = data.allContentfulPageCollection.edges.find(
      edge => formatPageLink(page, 1) === `/${edge.node.context?.slug}`
    )

    pages = group?.node.pages
  }

  const [isOpen, setOpen] = useState(false)

  const open = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    setOpen(!isOpen)
  }

  const mobileTabbedMenu = parent && parent.mobileTabbedMenu

  const current: ThemeUIStyleObject = {
    fontWeight: 700,
    display: mobileTabbedMenu ? 'none' : ['block', 'none'],
    paddingX: '4%',
    paddingY: 11,
    backgroundColor: 'background',
    backgroundImage: `url(${arrowhead})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 1.5rem center',
    backgroundSize: '1rem',
    borderBottom: '0.1rem solid',
    borderColor: 'muted',
    '&:hover': {
      color: 'text',
    },
  }

  const all: ThemeUIStyleObject = {
    display: mobileTabbedMenu ? 'flex' : [isOpen ? 'block' : 'none', 'flex'],
    justifyContent: 'space-evenly',
    paddingY: [!mobileTabbedMenu ? 11 : 0, 0],
    backgroundColor: 'muted',
    borderTop: [!mobileTabbedMenu ? '0.1rem solid' : 'none', 'none'],
    borderColor: 'background',
  }

  const links: ReactNodeArray | undefined = pages?.map((page, i, pages) => {
    let link = {
      width: [
        !mobileTabbedMenu ? 'auto' : 100 / pages.length + '%',
        100 / pages.length + '%',
      ],
      paddingX: [!mobileTabbedMenu ? '4%' : 7, 7],
      paddingY: [
        !mobileTabbedMenu ? 6 : fluidScale(10, 0.8, 1),
        fluidScale(10, 0.8, 1),
      ],
      variant: [
        !mobileTabbedMenu ? 'links.nav' : 'links.tab',
        'links.tab',
      ] as unknown as string,
      '&:hover': {
        color: [!mobileTabbedMenu ? 'contrast' : 'text', 'text'],
      },
    }

    if (typeof config === 'object' && config.largeButtons) {
      link = {
        ...link,
        paddingY: [!mobileTabbedMenu ? 6 : 12, fluidScale(12, 0.8, 1)],
        '&:after': {
          width: '90% !important',
          left: '5% !important',
        },
      }
    }

    return (
      <NavLink
        key={i}
        sx={link as ThemeUIStyleObject}
        to={formatPageLink(page)}
      >
        {page?.title}
      </NavLink>
    )
  })

  return (
    <Box as="nav" sx={sx}>
      <NavLink sx={current} to={formatPageLink(page)} onClick={open}>
        {page?.title}
      </NavLink>
      <Box sx={all}>{links}</Box>
    </Box>
  )
}

export default SubNav
