import React from 'react'
import { ThemeUIStyleObject, useThemeUI, get, Link } from 'theme-ui'
import { Inline, Stack } from 'raam'
import { fluidScale } from '../utils/mixins'
import JoinLink from './join-link'

interface Props {
  vertical?: boolean
  isBusiness?: boolean
}

const HeaderButtons: React.FC<Props> = ({ vertical, isBusiness, ...other }) => {
  const { theme } = useThemeUI()
  const flexGap = vertical
    ? 9
    : fluidScale(13, 0.65, 1.9)(theme) + ' !important'

  const wrapper: ThemeUIStyleObject = {
    overflow: 'visible',
    marginX: vertical ? 0 : flexGap,
    marginY: vertical ? 15 : 0,
    paddingLeft: vertical ? 0 : 5,
  }

  const wrapperFallback: ThemeUIStyleObject = {
    'marginLeft ': 11,
    'marginRight ': 11,
    '& > div': { marginLeft: vertical ? 0 : 9 },
    '& > div:first-of-type': { marginLeft: 0 },
  }

  const buttonStyle: ThemeUIStyleObject = {
    paddingX: fluidScale(13, 0.7, 1.7),
    paddingY: fluidScale(11, 0.7, 1.3),
    minWidth: vertical
      ? '12rem'
      : fluidScale(get(theme, 'buttons.primary.minWidth'), 0.7, 8.3),
  }

  const buttonStyleFallback: ThemeUIStyleObject = {
    'paddingLeft ': 11,
    'paddingRight ': 11,
    'paddingTop ': 10,
    'paddingBottom ': 10,
    'minWidth ': '6rem',
  }

  const buttonVariant = vertical ? 'buttonAlt' : 'button'

  const List = vertical ? Stack : Inline

  return (
    <List sx={{ ...wrapperFallback, ...wrapper }} gap={flexGap} {...other}>
      {!vertical && (
        <Link
          sx={{ ...buttonStyleFallback, ...buttonStyle }}
          href={process.env.GATSBY_ONLINE_ACCOUNT_URL}
          variant="buttonSecondary"
        >
          Log in
        </Link>
      )}
      <JoinLink
        sx={{ ...buttonStyleFallback, ...buttonStyle }}
        variant={buttonVariant}
        isBusiness={isBusiness}
      >
        Get a quote
      </JoinLink>
    </List>
  )
}

export default HeaderButtons
