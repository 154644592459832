import React from 'react'
import { useState } from 'react'
import { Button, Container, Text, ThemeUIStyleObject, Image } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import Article from '../article'

import PageLink from '../page-link'
import windTurbineImage from '../../images/wind-turbine.png'

interface Props {
  ready: boolean
  active: boolean
  onOkayClicked?: () => void
  onSettingsClicked?: () => void
}

const CookieBanner: React.FC<Props> = Props => {
  const { ready, active, onOkayClicked, onSettingsClicked } = Props

  const data = useStaticQuery(graphql`
    query {
      contentfulPage(slug: { eq: "our-cookies" }) {
        sections {
          __typename
          ... on Node {
            ... on ContentfulArticle {
              title
              body {
                raw
                references {
                  ...ArticleReferences
                }
              }
              aside {
                raw
                references {
                  ...ArticleReferences
                }
              }
            }
          }
        }
      }
    }
  `)

  const article = data?.contentfulPage?.sections?.find(
    section => section?.__typename === 'ContentfulArticle'
  )

  const hasArticle = !!article

  const [showCookieDetails, setShowCookieDetails] = useState(false)

  const overlay: ThemeUIStyleObject = {
    maxWidth: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    zIndex: 5,
  }

  const outer: ThemeUIStyleObject = {
    border: '0.625rem',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['100%', '54%', '54%'],
    backgroundColor: 'muted',
    borderRadius: '0.625rem',
    borderBottom: 'single',
    borderBottomColor: 'contrast',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);',
    zIndex: 6,
  }

  const outerLarger = {
    border: '0.625rem',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['100%', '61%', '61%'],
    backgroundColor: 'muted',
    borderRadius: '0.625rem',
    borderBottom: 'single',
    borderBottomColor: 'contrast',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);',
    zIndex: 6,
  }

  const inner: ThemeUIStyleObject = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }

  const buttonContainer: ThemeUIStyleObject = {
    marginTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '40%',
    fontSize: '100%',
  }

  const windTurbine: ThemeUIStyleObject = {
    zIndex: 7,
    position: 'relative',
  }

  const button: ThemeUIStyleObject = {
    minWidth: '9em',
    paddingY: '1.25rem',
    paddingX: '1.25rem',
    fontWeight: 'normal',
    marginTop: '-0.5rem',
    fontSize: '100%',
    marginBottom: '2rem',
  }

  const settingsButton: ThemeUIStyleObject = {
    borderColor: 'contrast',
    minWidth: '9em',
    paddingY: '1.25rem',
    paddingX: '1.25rem',
    fontWeight: 'normal',
    fontSize: '100%',
    marginTop: '-0.5rem',
    marginBottom: '2rem',
    marginRight: ['1rem', '1rem', 0],
  }

  const greenBand: ThemeUIStyleObject = {
    maxWidth: '100%',
    width: '100%',
    top: 0,
    height: '7.5rem',
    backgroundColor: '#9AD43A',
    position: 'absolute',
    zIndex: 6,
    borderRadius: '0.625rem 0.625rem 0 0',
  }

  const pText: ThemeUIStyleObject = {
    textAlign: 'center',
    fontSize: '1rem',
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: '0.938rem',
    zIndex: '7',
  }

  const scrollingModal: ThemeUIStyleObject = {
    overflowY: 'scroll',
    height: '18rem',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'rgba(255,255,255,0.6)',
    marginX: 0,
    marginY: '1rem',
    paddingTop: '4rem',
    border: 'activeBg',
    '&::-webkit-scrollbar': {
      width: '0.7rem', // Width of the vertical scrollbar
    },
    '&::-webkit-scrollbar-track': {
      background: 'rgba(0, 0, 0, 0.1)', // Color of the track
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(0, 0, 0, 0.5)', // Color of the thumb
      borderRadius: '6px', // Border radius of the thumb
    },
  }

  return ready && active ? (
    <Container sx={overlay}>
      <Container
        sx={showCookieDetails ? outerLarger : outer}
        variant="full"
        data-testid="cookie-banner"
      >
        <Container sx={greenBand}></Container>
        <Container sx={inner} variant="wide">
          <Image src={windTurbineImage} sx={windTurbine}></Image>
          <Text as="h2" variant="heading" sx={heading}>
            Ecotricity uses cookies
          </Text>

          {showCookieDetails && hasArticle ? (
            <Container sx={scrollingModal}>
              <Article
                title={article.title}
                body={article.body}
                aside={article.aside}
                variant="default"
              ></Article>
            </Container>
          ) : (
            <Text as="p" variant="intro" sx={pText}>
              Ecotricity use{' '}
              <PageLink
                to=""
                onClick={
                  hasArticle
                    ? () => setShowCookieDetails(true)
                    : () => setShowCookieDetails(false)
                }
              >
                cookies
              </PageLink>{' '}
              to improve the site performance and offer advertising tailored to
              your interests. By continuing to browse the site, you agree to the
              use of these cookies, or you can change your settings if you
              prefer.
            </Text>
          )}
          <Container sx={buttonContainer}>
            <Button
              sx={settingsButton}
              variant="secondary"
              onClick={onSettingsClicked}
            >
              Settings
            </Button>
            <Button sx={button} variant="alt" onClick={onOkayClicked}>
              Okay
            </Button>
          </Container>
        </Container>
      </Container>
    </Container>
  ) : null
}

export default CookieBanner
