import React from 'react'
import { Grid, Text } from 'theme-ui'

const green = '#9c3'
const grey = '#eee'
const darkGrey = '#aaa'
const black = '#151515'

const stepStyle = {
  display: 'inline-block',
  borderRadius: '50%',
  lineHeight: '2',
  textAlign: 'center',
  fontWeight: 'bold',
  // using em's instead of rem to get it all nice and round like
  width: '2em',
  fontSize: '24px',
  margin: '0 auto 0.6rem',
} as const

const Stepper = ({ step }: { step: number }): JSX.Element => (
  <Grid
    columns="1fr 1fr 1fr"
    sx={{ marginBottom: '2rem', textAlign: 'center' }}
  >
    <Text
      as="span"
      style={{ ...stepStyle, backgroundColor: green, color: black }}
    >
      1
    </Text>
    <Text
      as="span"
      style={{
        ...stepStyle,
        backgroundColor: step >= 2 ? green : grey,
        color: step >= 2 ? black : darkGrey,
      }}
    >
      2
    </Text>
    <Text
      as="span"
      style={{
        ...stepStyle,
        backgroundColor: step >= 3 ? green : grey,
        color: step >= 3 ? black : darkGrey,
      }}
    >
      3
    </Text>

    <Text variant="label" as="span">
      Fuel Type
    </Text>
    <Text
      variant="label"
      as="span"
      sx={{
        opacity: step >= 2 ? 1 : 0.3,
      }}
    >
      Bank Details
    </Text>
    <Text
      variant="label"
      as="span"
      sx={{
        opacity: step >= 3 ? 1 : 0.3,
      }}
    >
      Confirm
    </Text>
  </Grid>
)

export default Stepper
