import React from 'react'
import { Box } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'
import { RadioIcon } from './radio'

type CCCheckboxProps = {
  className: string
  sx: ThemeUIStyleObject
  variant: string
}

export const CcCheckbox = React.forwardRef(function CCCheckbox(
  { className, sx, variant = 'radio', ...props }: CCCheckboxProps,
  ref
) {
  return (
    <Box sx={{ minWidth: 'min-content' }}>
      <Box
        ref={ref}
        as="input"
        type="checkbox"
        {...props}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      />
      <Box
        as={RadioIcon}
        aria-hidden="true"
        __themeKey="forms"
        variant={variant}
        className={className}
        sx={sx}
        __css={{
          // todo: system props??
          mr: 2,
          borderRadius: 9999,
          color: 'gray',
          flexShrink: 0,
          'input:checked ~ &': {
            color: 'primary',
          },
          'input:focus ~ &': {
            bg: 'highlight',
          },
        }}
      />
    </Box>
  )
})
