import React from 'react'
import { Checkbox, Label } from 'theme-ui'
import { UseFormMethods } from 'react-hook-form'

const WrappedCheckbox = <Name extends string>({
  name,
  label,
  register,
}: {
  readonly label: string
  readonly name: Name
} & UseFormMethods<{ readonly [Key in Name]: string }>): JSX.Element => (
  <Label
    htmlFor={name}
    id={`label-${name}`}
    sx={{
      margin: '0 0 2rem',
      width: 'auto',
      display: 'grid',
      gridTemplateColumns: '3rem auto',
    }}
  >
    <Checkbox
      aria-describedby={`label-${name} error-${name}`}
      aria-invalid="false"
      id={name}
      name={name}
      ref={register({})}
      value="selected"
    />
    {label}
  </Label>
)

export default WrappedCheckbox
