import React, { useState, useEffect, Fragment } from 'react'
import { Button, Text, Heading } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { SymmetricValidatingInput } from './inputs/validating-input'
import DatePicker from './inputs/date-picker'
import {
  validFitId,
  validMeterRead,
} from './microtricity-meter-reading/validators'
import { MicroMeterReadForm } from './utils/form-types'
import { submitMeterReadForm } from './utils/submit'
import {
  ExportStatus,
  fetchInstallation,
  Installation,
} from './utils/fetch-installation'
import { validPostcode } from './utils/validators'
import Modal from '../components/modal'

type MicroMeterReadData = {
  readonly fitId: string
  readonly postcode: string
  readonly generationRead: string
  readonly exportRead: string
  readonly readingDate: string
}
const reformatMicrotricityMeterRead = ({
  fitId,
  postcode,
  generationRead,
  exportRead,
  readingDate,
}: MicroMeterReadData): MicroMeterReadForm => ({
  fitId,
  postcode,
  generationRead: parseInt(generationRead),
  ...(exportRead && { exportRead: parseInt(exportRead) }),
  readDate: readingDate,
})

export const hasExportMeter = (installation?: Installation): boolean => {
  return (
    installation?.exportStatus?.toLowerCase() ===
    ExportStatus.STANDARD.toLowerCase()
  )
}

const MicrotricityMeterReadingForm = (): JSX.Element => {
  const formFns = useForm<MicroMeterReadData>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { register, handleSubmit, watch, errors, unregister, reset } = formFns

  const [state, setState] = useState<{
    readonly submissionError: string | null
    readonly submissionSuccess: { paymentDate: string } | null
    readonly inFlight: boolean
    exportReadOn: boolean
    installationFetchedSuccess: boolean | null
  }>({
    submissionError: null,
    submissionSuccess: null,
    inFlight: false,
    exportReadOn: true,
    installationFetchedSuccess: null,
  })

  const [shouldShowSuccessModal, setShouldShowSuccessModal] = useState(false)

  const [currentDate, setCurrentDate] = useState<Date | undefined>(undefined)

  const { fitId, postcode } = watch(['fitId', 'postcode'])

  const prefixFitID = (id: string) => {
    if (id.substring(0, 3).toLowerCase() === 'fit') {
      return id
    } else {
      return 'FIT' + id
    }
  }

  async function fetchData(fitIdPrepended, postcode) {
    let installation: Installation | undefined = undefined
    try {
      installation = await fetchInstallation(fitIdPrepended, postcode)
    } catch (e) {
      // do nothing
    }

    if (installation === undefined) {
      unregister('exportRead')
      setState(previousState => ({
        ...previousState,
        inFlight: false,
        installationFetchedSuccess: false,
        exportReadOn: true,
      }))
    } else {
      if (!hasExportMeter(installation)) {
        unregister('exportRead')
      }
      setState(previousState => ({
        ...previousState,
        inFlight: false,
        installationFetchedSuccess: true,
        exportReadOn: hasExportMeter(installation),
      }))
    }
  }

  useEffect(() => {
    setCurrentDate(new Date())
    const hour = 60 * 60 * 1000
    const time = hour
    const interval = setInterval(() => setCurrentDate(new Date()), time)
    return () => {
      clearInterval(interval)
    }
  }, [])
  const maxDate = currentDate?.toISOString().split('T')[0]

  useEffect(() => {
    if (fitId && postcode && !errors.fitId && !errors.postcode) {
      const fitIdPrepended = prefixFitID(fitId)
      fetchData(fitIdPrepended, postcode)
    }
  }, [errors.fitId, errors.postcode, fitId, postcode, unregister])

  const exportValidation = (isRequired: boolean) => (label: string) => {
    const rules: {
      required?: string
      validate: { validExportRead: (str: string | undefined) => string | true }
    } = {
      validate: {
        validExportRead: validMeterRead(label),
      },
    }
    if (isRequired) {
      rules.required = `Please enter your ${label}`
    }
    return register(rules)
  }

  const calculatePaymentMonth = () => {
    const month = new Date().getMonth() + 1
    if (month <= 3) return 'May'
    if (month <= 6) return 'August'
    if (month <= 9) return 'November'
    return 'February'
  }

  return (
    <>
      <form
        noValidate
        onSubmit={handleSubmit(formData => {
          if (state.inFlight) {
            return
          }
          setState(state => ({
            ...state,
            submissionError: null,
            inFlight: true,
          }))
          submitMeterReadForm(reformatMicrotricityMeterRead(formData))
            .then(response => {
              if (response.status === 'success') {
                setState(state => ({
                  ...state,
                  inFlight: false,
                  submissionSuccess: { paymentDate: formData.readingDate },
                  submissionError: null,
                }))
                setShouldShowSuccessModal(true)

                reset()
              } else {
                setState(state => ({
                  ...state,
                  inFlight: false,
                  submissionError: response.data.message,
                  submissionSuccess: null,
                }))
                setShouldShowSuccessModal(false)
              }
            })
            .catch(() =>
              setState(state => ({
                ...state,
                submissionError: 'Submission failed!',
                inFlight: false,
              }))
            )
        })}
      >
        <SymmetricValidatingInput
          {...formFns}
          tooltip={FitIdTooltip}
          deepName={['fitId'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validFitId: validFitId,
              },
            })
          }
          label="FIT ID"
        />

        <div style={{ width: '100%' }}>
          <SymmetricValidatingInput
            {...formFns}
            tooltip={PostCodeTooltip}
            deepName={['postcode'] as const}
            handleRef={(label: string) =>
              register({
                required: `Please enter your ${label}`,
                validate: {
                  validPostcode: validPostcode,
                },
              })
            }
            label="Postcode"
          />
        </div>
        <div
          style={{
            width: '100%',
            color: 'rgb(255, 102, 51)',
            padding: '0.2rem',
            minHeight: '2.5rem',
          }}
        >
          {state.installationFetchedSuccess ||
          state.installationFetchedSuccess === null
            ? null
            : 'The FIT ID and Postcode you entered do not match your account, please double-check these details are correct'}
        </div>
        <DatePicker
          {...formFns}
          errorMessage="Please enter a date for these meter readings"
          name="readingDate"
          max={maxDate}
          required={true}
          label="Meter Read Date"
        />
        <SymmetricValidatingInput
          {...formFns}
          tooltip={GenerationReadTooltip}
          deepName={['generationRead'] as const}
          label="Generation Read"
          type="number"
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validGenerationRead: validMeterRead(label),
              },
            })
          }
        />
        {state.exportReadOn ? (
          <SymmetricValidatingInput
            {...formFns}
            tooltip={ExportReadTooltip}
            deepName={['exportRead'] as const}
            handleRef={exportValidation(
              state.installationFetchedSuccess === true
            )}
            label="Export Read"
            type="number"
          />
        ) : (
          ''
        )}
        <p>
          “If you are having issues with your Meter, please contact your
          Installer. If you have any further queries relating to your
          Microtricity account, please get in touch on 0345 555 7600 or emailing
          microtricity@ecotricity.co.uk”
        </p>
        <Button type="submit" variant="primary">
          {state.inFlight ? 'Submitting form...' : 'Submit form'}
        </Button>
        {state.submissionError === null ? (
          ''
        ) : (
          <Text as="p" variant="errorLabel">
            {state.submissionError}
          </Text>
        )}
        {state.submissionSuccess !== null ? '' : <> </>}
      </form>
      <Modal
        isOpen={shouldShowSuccessModal}
        onClose={() => {
          setShouldShowSuccessModal(false)
        }}
      >
        <Heading as="h4">Meter Reading Submitted</Heading>
        <Text sx={{ marginTop: '12px' }}>
          Thank you for submitting your Microtricity meter reading, you will
          receive payment for this in {calculatePaymentMonth()}.{' '}
        </Text>
      </Modal>
    </>
  )
}

export default MicrotricityMeterReadingForm

const FitIdTooltip = () => (
  <span>
    Your FIT ID starts with FIT followed by 8 digits and an extension reference
    at the end e.g. FIT00001234-1 – if you’re unsure about this please get in
    touch.
  </span>
)
const PostCodeTooltip = () => (
  <span>
    Please note this is the address of your renewable energy installation, which
    may differ from your correspondence address.
  </span>
)
const GenerationReadTooltip = () => (
  <span>This reading needs to be taken from your Generation Meter.</span>
)

const ExportReadTooltip = () => (
  <span>
    This reading needs to be taken from your Export Meter (this may be from your
    Smart Meter).
  </span>
)
