import React, { useState } from 'react'
import DataViz from './data-viz'
import { LiveGenData } from '../hooks/use-live-gen-data'
import { ThemeUIStyleObject, Text, Grid } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import NavLink from './nav-link'
/**
 * Card component containing live generation visualisations.
 */

const borderRadius = '28px'
interface TabbedCardsProps {
  items: Queries.ContentfulGeneralContent[]
  data: LiveGenData
}

const mobileCardContainer = (activeTab: number): ThemeUIStyleObject => ({
  display: ['block', 'block', 'none'],
  border: '1px solid',
  borderColor: 'muted',
  borderRadius,
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',
  maxWidth: '440px',
  margin: '0 auto',
  'eco-gen-mix-pie': {
    '--icon-width': '22px',
    '--font-size': '13px',
    '--font-size-primary': '16px',
  },
  background:
    activeTab === 0
      ? 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(155, 211, 58, 0.25))'
      : 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(1, 33, 105, 0.25))',
})

const heading: ThemeUIStyleObject = {
  marginTop: 10,
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '32px',
}

const card = (ours: boolean): ThemeUIStyleObject => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  background: ours
    ? 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(155, 211, 58, 0.25))'
    : 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(1, 33, 105, 0.25))',
  borderRadius,
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',
  maxWidth: ['440px', null, null, '500px'],
  margin: '0 auto',
})

const mobileCard = (ours: boolean, active: boolean): ThemeUIStyleObject => ({
  display: active ? 'flex' : 'none',
  flexDirection: 'column',
  background: ours
    ? 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(155, 211, 58, 0.25))'
    : 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(1, 33, 105, 0.25))',
})

const banner: ThemeUIStyleObject = {
  height: 32,
  display: ['none', 'block'],
  justifyContent: 'center',
  alignContent: 'top',
  objectFit: 'fill',
}

const tabButton = (isActive: boolean, index: number): ThemeUIStyleObject => ({
  borderRadius: index === 0 ? `${borderRadius} 0 0 0` : `0 ${borderRadius} 0 0`,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  borderColor: 'muted',
  borderLeft: index === 0 ? 'none' : 'muted',
  textAlign: 'center',
  hr: {
    width: '150px',
    display: isActive ? 'block' : 'none',
    margin: '8px auto',
    color: index === 0 ? '#9AD43A' : '#012169',
  },
  h3: {
    marginTop: isActive ? '8px' : '19px',
    fontSize: 2,
    fontWeight: isActive ? 'bold' : 'light',
  },
})
const tabBanner = (isActive: boolean): ThemeUIStyleObject => ({
  height: '11px',
  display: isActive ? 'block' : 'none',
})

const button = (ours: boolean): ThemeUIStyleObject => ({
  padding: 0,
  height: '50px',
  borderRadius,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: ours ? 'text' : 'rgba(1, 33, 105, 1)',
  backgroundColor: ours ? 'primary' : 'rgba(255, 255, 255, 1)',
  border: ours ? 'primaryBg' : '0.0625rem solid #012169',
  fontSize: '13px',
  '&:hover': {
    color: ours ? 'background' : 'rgba(255, 255, 255, 1)',
    backgroundColor: ours ? 'primary' : 'rgba(1, 33, 105, 1)',
    borderColor: ours ? '#95C93E' : '#012169',
  },
})

const LargeScreenCards: React.FC<TabbedCardsProps> = ({
  items,
  data,
}: TabbedCardsProps) => {
  return (
    <Grid
      columns={[1, null, 2]}
      sx={{ display: ['none', null, 'flex'], maxWidth: [null] }}
    >
      {items.map(({ title, assets, items, ctaUrl, ctaText }, i) => {
        const isOurCard = i === 0
        return (
          <div key={title} sx={card(isOurCard)}>
            <GatsbyImage
              sx={banner}
              image={assets[0]?.gatsbyImageData}
              alt={assets[0]?.description}
            />
            <Text sx={heading} as="h3" variant="med">
              {title}
            </Text>
            <div>
              {items.map(dataViz => (
                <DataViz
                  key={dataViz.title}
                  {...dataViz}
                  data={data}
                  liveIconColour={isOurCard ? '#9AD43A' : '#012169'}
                />
              ))}
            </div>
            <div sx={{ padding: '32px' }}>
              <NavLink to={ctaUrl} sx={button(isOurCard)} variant="button">
                {ctaText}
              </NavLink>
            </div>
          </div>
        )
      })}
    </Grid>
  )
}

const MobileCards: React.FC<TabbedCardsProps> = ({
  items,
  data,
}: TabbedCardsProps) => {
  const [activeTab, setActiveTab] = useState<number>(0)

  return (
    <div sx={mobileCardContainer(activeTab)}>
      <div sx={{ display: 'flex' }}>
        {items.map(({ title, assets }, index) => (
          <div
            role="button"
            key={title}
            sx={tabButton(activeTab === index, index)}
            onClick={() => setActiveTab(index)}
          >
            <GatsbyImage
              sx={tabBanner(activeTab === index)}
              image={assets[0]?.gatsbyImageData}
              alt={assets[0]?.description}
            />
            <Text as="h3" variant="default">
              {title}
            </Text>
            <hr />
          </div>
        ))}
      </div>
      {items.map(({ title, items, ctaText, ctaUrl }, i) => (
        <div key={title} sx={mobileCard(i === 0, i === activeTab)}>
          {items.map(dataViz => (
            <DataViz
              key={dataViz.title}
              {...dataViz}
              data={data}
              liveIconColour={i === 0 ? '#9AD43A' : '#012169'}
            />
          ))}
          <div sx={{ padding: '16px' }}>
            <NavLink to={ctaUrl} variant="button" sx={button(i === 0)}>
              {ctaText}
            </NavLink>
          </div>
        </div>
      ))}
    </div>
  )
}

const TabbedCards: React.FC<TabbedCardsProps> = ({
  items,
  data,
}: TabbedCardsProps) => {
  return (
    <>
      <LargeScreenCards items={items} data={data} />
      <MobileCards items={items} data={data} />
    </>
  )
}

export default TabbedCards
