import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import { fluidScale } from '../utils/mixins'
import { MastheadVariants, Page } from '../utils/types'
import Breadcrumb from './breadcrumb'
import { isFluid } from '../utils/helpers'

interface Props {
  page?: Page
  variant?: MastheadVariants
  parent?: GatsbyTypes.SupportPageQuery
}

const Masthead: React.FC<Props> = ({ page, variant, parent }) => {
  const topic = page?.topic || parent?.topic

  const banner = page?.banner || topic?.banner || topic?.context?.banner

  const isBreadcrumb = variant === 'breadcrumb'
  const isBusiness = variant === 'business'
  const isSupport = variant === 'support' || variant === 'supportIndex'
  const isSubPage = variant === 'subpage'
  const isIndexPage =
    variant === 'news' || variant === 'business' || variant === 'supportIndex'

  const container: ThemeUIStyleObject = {
    position: 'relative',
    minHeight:
      isSupport || isSubPage
        ? ['10rem', '12rem', '15rem']
        : isBreadcrumb
        ? 0
        : '10rem',
    maxHeight: '22rem',
    backgroundColor: isSupport ? 'muted' : 'primary',
    overflow: isBreadcrumb ? 'visible' : 'hidden',
  }

  const breadcrumb: ThemeUIStyleObject = {
    display: ['none', null, isIndexPage ? 'none' : 'block'],
    position: 'absolute',
    marginX: fluidScale(12, 0.8, 2),
    top: 9,
  }

  const image: ThemeUIStyleObject = {
    display: ['none', isBreadcrumb ? 'none' : 'block'],
  }

  const text: ThemeUIStyleObject = {
    display: isBreadcrumb ? 'none' : 'block',
    position: 'absolute',
    paddingBottom: isBusiness ? [0, null, '4rem'] : 0,
    top: '50%',
    left: '4%',
    width: isSupport || isSubPage ? '92%' : isIndexPage ? '60rem' : '40rem',
    maxWidth: '96%',
    textAlign: isSupport || isSubPage ? 'center' : 'left',
    transform: 'translateY(-50%)',
  }

  const headingSize: ThemeUIStyleObject = {
    fontSize: isIndexPage
      ? [isSupport || isSubPage ? 5 : 3, 5, 6, 7]
      : [isSupport || isSubPage ? 4 : 3, 4, 5],
    fontFamily: 'heading',
    fontWeight: 'heading',
    lineHeight: 'heading',
  }

  const subheadingSize: ThemeUIStyleObject = {
    fontSize: isIndexPage ? [0, 1, null, 2] : [0, 1],
    fontFamily: 'body',
    fontWeight: 'light',
    lineHeight: 'body',
    width: '70%',
  }

  return topic || isSubPage ? (
    <Box sx={container}>
      <Box>
        {banner && isFluid(banner) && (
          <GatsbyImage sx={image} image={banner.gatsbyImageData} />
        )}
      </Box>
      <Breadcrumb
        sx={breadcrumb}
        parent={parent}
        page={page}
        variant={variant}
      />
      <Box sx={text}>
        <Heading sx={headingSize}>
          {isSupport
            ? page?.heading || page?.title
            : isSubPage
            ? parent?.title || page?.title
            : topic?.heading || topic?.name}
        </Heading>
        {topic?.subheading && (
          <Text sx={subheadingSize}>{topic?.subheading}</Text>
        )}
      </Box>
    </Box>
  ) : null
}

export default Masthead
