import React from 'react'
import { Box, Heading, Grid, Label, Link, Text, Textarea } from 'theme-ui'
import { useForm } from 'react-hook-form'
import {
  ValidatingInput,
  SymmetricValidatingInput,
} from './inputs/validating-input'
import Checkbox from './inputs/checkbox'
import { validPhoneNumber, validAccountNumber } from './utils/validators'
import AddressPicker, { AddressPickerFormData } from './inputs/address-picker'
import { PriorityServiceForm } from './utils/form-types'
import DatePicker from './inputs/date-picker'
import Salutation from './inputs/salutation'
import WebForm from './webform'
import { heading, paragraph, fieldset } from './utils/styles'

const completed = (): JSX.Element => (
  <Box sx={fieldset}>
    <Heading sx={heading}>Thanks for Letting Us Know Your Requirements</Heading>
    <Text as="p" sx={paragraph}>
      {`We've received your form. Now we'll go ahead and contact your local energy
      distributors.`}
    </Text>
    <Text as="p" sx={paragraph}>
      {`As soon as your registration is confirmed, we'll write to you to let you
      know.`}
    </Text>
    <Text as="p" sx={paragraph}>
      {`If you'd like to discuss your account with one of your Customer Support
      Advisors, get in touch with us on `}
      <Link href="tel:03455557100">0345 555 7 100</Link> or email us{' '}
      <Link href="mailto:home@ecotricity.co.uk">home@ecotricity.co.uk</Link>.
    </Text>
    <Text as="p" sx={{ fontWeight: 'bold' }}>
      Thanks for being with us,
    </Text>
    <Text as="p" sx={{ fontWeight: 'bold' }}>
      The Ecotricity Team
    </Text>
  </Box>
)

type PriorityServiceFormData = {
  readonly salutation: string
  readonly firstName: string
  readonly lastName: string
  readonly accountNumber: string
  readonly textPhoneNumber: string
  readonly hearing: string
  readonly medicalEquipment: string
  readonly sight: string
  readonly pensionableAge: string
  readonly mobility: string
  readonly childrenUnderFive: string
  readonly other: string
  readonly usingRepresentative: string
  readonly passwordScheme: string
  readonly comments: string
  readonly shareDetails: string
  readonly childsBirthday: string
  readonly repFullName: string
  readonly repPhoneNumber: string
  readonly password: string
  readonly address: AddressPickerFormData
}

const reformatPriorityService = ({
  salutation,
  firstName,
  lastName,
  accountNumber,
  textPhoneNumber,
  address: { line1, line2, line3, town, county, postcode, udprn },
  hearing,
  medicalEquipment,
  sight,
  pensionableAge,
  mobility,
  childrenUnderFive,
  other,
  usingRepresentative,
  passwordScheme,
  comments,
  shareDetails,
  childsBirthday,
  repFullName,
  repPhoneNumber,
  password,
}: PriorityServiceFormData): PriorityServiceForm => ({
  webform: {
    type: 'priorityServices',
    formData: {
      salutation,
      firstNames: firstName,
      lastNames: lastName,
      accountNumber,
      address: {
        address1: line1,
        address2: line2,
        address3: line3,
        town,
        county,
        postcode,
        udprn,
      },
      requirements: {
        hearing: Boolean(hearing),
        medicalEquipment: Boolean(medicalEquipment),
        sight: Boolean(sight),
        pensionableAge: Boolean(pensionableAge),
        mobility: Boolean(mobility),
        childrenUnderFive: Boolean(childrenUnderFive),
        youngestChildDateOfBirth: childrenUnderFive
          ? childsBirthday
          : undefined,
        other: Boolean(other),
      },
      textPhoneNumber,
      usingRepresentative: Boolean(usingRepresentative),
      representative: {
        fullNames: repFullName,
        phoneNumber: repPhoneNumber,
      },
      passwordScheme: Boolean(passwordScheme),
      passwordSchemePassword: password,
      comments,
      shareDetails: Boolean(shareDetails),
    },
  },
})

const PriorityService = (): JSX.Element => {
  const formFns = useForm<
    PriorityServiceFormData & { address: AddressPickerFormData }
  >({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { register, watch } = formFns

  const childrenUnderFive = watch('childrenUnderFive')
  const passwordScheme = watch('passwordScheme')
  const usingRepresentative = watch('usingRepresentative')

  const validRepresentative =
    (fieldName: string) => (value: string | undefined) =>
      !usingRepresentative || Boolean(value?.length)
        ? true
        : `Please enter a representative ${fieldName}`

  const requiredField = (label: string) =>
    register({ required: `Please enter your ${label.toLowerCase()}` })

  return (
    <WebForm<PriorityServiceForm, PriorityServiceFormData>
      reformat={reformatPriorityService}
      completeText={completed()}
      {...formFns}
    >
      <Box sx={fieldset}>
        <Heading sx={heading}>Your details</Heading>
        <Salutation {...formFns} />
        <Grid columns={[1, '1fr 1fr']} gap="10">
          <SymmetricValidatingInput
            {...formFns}
            deepName={['firstName'] as const}
            handleRef={requiredField}
            label="First name"
          />
          <SymmetricValidatingInput
            {...formFns}
            deepName={['lastName'] as const}
            handleRef={requiredField}
            label="Last name"
          />
        </Grid>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['accountNumber'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validAccountNumber: validAccountNumber,
              },
            })
          }
          label="Account number"
        />
        <AddressPicker addressName="address" {...formFns} />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Your requirements</Heading>
        <Text as="p" variant="intro" sx={heading}>
          Please let us know if you or anyone in your household has special
          requirements by selecting from the box below. You can tell us more
          about these further down the page.
        </Text>
        <Checkbox {...formFns} label="Hearing" name="hearing" />
        <Checkbox
          {...formFns}
          label="Medical equipment"
          name="medicalEquipment"
        />
        <Checkbox {...formFns} label="Sight" name="sight" />
        <Checkbox {...formFns} label="Pensionable Age" name="pensionableAge" />
        <Checkbox {...formFns} label="Mobility" name="mobility" />
        <Checkbox
          {...formFns}
          label="Pregnant and/or children under 5"
          name="childrenUnderFive"
        />
        <Checkbox {...formFns} label="Other" name="other" />
        {childrenUnderFive && (
          <Box>
            <Label>Date of Birth of Youngest child</Label>
            <DatePicker
              {...formFns}
              errorMessage="Please enter a birth date for your youngest child"
              name="childsBirthday"
              required={true}
            />
          </Box>
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Do you have a text phone?</Heading>
        <ValidatingInput
          {...formFns}
          deepName={['textPhoneNumber'] as const}
          handleRef={register({
            validate: validPhoneNumber('Your number'),
          })}
          label="If you have a textphone - a phone with a screen and a keyboard to send and receive messages in text - please enter your number."
        />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Using a representative</Heading>
        <Text as="p" variant="intro" sx={heading}>
          Is there someone you would like to us to speak to on your behalf?
        </Text>
        <Checkbox
          {...formFns}
          label="Yes, I would like someone to represent me"
          name="usingRepresentative"
        />
        {usingRepresentative && (
          <Box>
            <ValidatingInput
              {...formFns}
              deepName={['repFullName'] as const}
              handleRef={register({
                validate: validRepresentative('name'),
              })}
              label="Representative's full name"
            />
            <SymmetricValidatingInput
              {...formFns}
              deepName={['repPhoneNumber'] as const}
              handleRef={label =>
                register({
                  validate: {
                    hasNumber: validRepresentative('number'),
                    isPhoneNumber: validPhoneNumber(label),
                  },
                })
              }
              label="Representative's phone number"
            />
          </Box>
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Password scheme</Heading>
        <Text as="p" variant="intro" sx={heading}>
          {`We offer a password scheme for extra security. We'll only give your
          password to members of staff and agents who need to know it. They'll
          quote it to you when they call at your home.`}
        </Text>
        <Checkbox
          {...formFns}
          label="I would like to use a password"
          name="passwordScheme"
        />

        {passwordScheme && (
          <ValidatingInput
            {...formFns}
            deepName={['password'] as const}
            handleRef={requiredField('password')}
            label="Please enter your password"
            type="password"
          />
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Tell us more</Heading>
        <Label htmlFor="moreDetails">
          Please let us know the details of your requirements in the box below.
        </Label>
        <Textarea id="comments" name="comments" ref={register()} />
        <Checkbox
          {...formFns}
          label="I'm happy for Ecotricity to share the details I've given with my electricity/gas network operator, meter reading agents and Ofgem."
          name="shareDetails"
        />
      </Box>
    </WebForm>
  )
}

export default PriorityService
