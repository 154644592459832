const transportMatrix = {
  //Mode of transport
  car: {
    //Car type
    motorcycle: {
      //Number of adults
      '1': {
        none: 0,
        '<2': 180,
        '2-4': 567,
        '4-6': 946,
        '6-8': 1324,
        '8-10': 1702,
        '10+': 2080,
      },
      '2': {
        none: 0,
        '<2': 95,
        '2-4': 284,
        '4-6': 473,
        '6-8': 662,
        '8-10': 851,
        '10+': 1040,
      },
      '3': {
        none: 0,
        '<2': 63,
        '2-4': 189,
        '4-6': 315,
        '6-8': 441,
        '8-10': 567,
        '10+': 693,
      },
      '4': {
        none: 0,
        '<2': 47,
        '2-4': 142,
        '4-6': 236,
        '6-8': 331,
        '8-10': 425,
        '10+': 520,
      },
      '>4': {
        none: 0,
        '<2': 38,
        '2-4': 113,
        '4-6': 189,
        '6-8': 265,
        '8-10': 340,
        '10+': 416,
      },
    },
    'car-petrol-1.4l': {
      '1': {
        none: 0,
        '<2': 280,
        '2-4': 840,
        '4-6': 1401,
        '6-8': 1961,
        '8-10': 2521,
        '10+': 3081,
      },
      '2': {
        none: 0,
        '<2': 140,
        '2-4': 420,
        '4-6': 700,
        '6-8': 980,
        '8-10': 1260,
        '10+': 1541,
      },
      '3': {
        none: 0,
        '<2': 93,
        '2-4': 280,
        '4-6': 467,
        '6-8': 654,
        '8-10': 840,
        '10+': 1027,
      },
      '4': {
        none: 0,
        '<2': 70,
        '2-4': 210,
        '4-6': 350,
        '6-8': 490,
        '8-10': 630,
        '10+': 770,
      },
      '>4': {
        none: 0,
        '<2': 56,
        '2-4': 168,
        '4-6': 280,
        '6-8': 392,
        '8-10': 504,
        '10+': 616,
      },
    },
    'car-petrol-1.4l-2.0l': {
      '1': {
        none: 0,
        '<2': 352,
        '2-4': 1056,
        '4-6': 1760,
        '6-8': 2464,
        '8-10': 3169,
        '10+': 3873,
      },
      '2': {
        none: 0,
        '<2': 176,
        '2-4': 528,
        '4-6': 880,
        '6-8': 1232,
        '8-10': 1584,
        '10+': 1936,
      },
      '3': {
        none: 0,
        '<2': 117,
        '2-4': 352,
        '4-6': 587,
        '6-8': 821,
        '8-10': 1056,
        '10+': 1291,
      },
      '4': {
        none: 0,
        '<2': 88,
        '2-4': 264,
        '4-6': 440,
        '6-8': 616,
        '8-10': 792,
        '10+': 968,
      },
      '>4': {
        none: 0,
        '<2': 70,
        '2-4': 211,
        '4-6': 352,
        '6-8': 493,
        '8-10': 634,
        '10+': 775,
      },
    },
    'car-petrol-2.0l+': {
      '1': {
        none: 0,
        '<2': 523,
        '2-4': 1569,
        '4-6': 2615,
        '6-8': 3661,
        '8-10': 4707,
        '10+': 5754,
      },
      '2': {
        none: 0,
        '<2': 262,
        '2-4': 785,
        '4-6': 1308,
        '6-8': 1831,
        '8-10': 2354,
        '10+': 2877,
      },
      '3': {
        none: 0,
        '<2': 174,
        '2-4': 523,
        '4-6': 872,
        '6-8': 1220,
        '8-10': 1569,
        '10+': 1918,
      },
      '4': {
        none: 0,
        '<2': 161,
        '2-4': 392,
        '4-6': 654,
        '6-8': 915,
        '8-10': 1177,
        '10+': 1438,
      },
      '>4': {
        none: 0,
        '<2': 105,
        '2-4': 314,
        '4-6': 523,
        '6-8': 732,
        '8-10': 941,
        '10+': 1151,
      },
    },
    'car-diesel-1.7l': {
      '1': {
        none: 0,
        '<2': 258,
        '2-4': 774,
        '4-6': 1289,
        '6-8': 1805,
        '8-10': 2321,
        '10+': 2836,
      },
      '2': {
        none: 0,
        '<2': 129,
        '2-4': 387,
        '4-6': 645,
        '6-8': 902,
        '8-10': 1160,
        '10+': 1418,
      },
      '3': {
        none: 0,
        '<2': 86,
        '2-4': 258,
        '4-6': 430,
        '6-8': 602,
        '8-10': 774,
        '10+': 945,
      },
      '4': {
        none: 0,
        '<2': 64,
        '2-4': 193,
        '4-6': 322,
        '6-8': 451,
        '8-10': 580,
        '10+': 709,
      },
      '>4': {
        none: 0,
        '<2': 52,
        '2-4': 155,
        '4-6': 258,
        '6-8': 361,
        '8-10': 464,
        '10+': 567,
      },
    },
    'car-diesel-1.7l-2.0l': {
      '1': {
        none: 0,
        '<2': 309,
        '2-4': 927,
        '4-6': 1546,
        '6-8': 2164,
        '8-10': 2782,
        '10+': 3401,
      },
      '2': {
        none: 0,
        '<2': 155,
        '2-4': 464,
        '4-6': 773,
        '6-8': 1082,
        '8-10': 1391,
        '10+': 1700,
      },
      '3': {
        none: 0,
        '<2': 103,
        '2-4': 309,
        '4-6': 515,
        '6-8': 721,
        '8-10': 927,
        '10+': 1134,
      },
      '4': {
        none: 0,
        '<2': 77,
        '2-4': 232,
        '4-6': 386,
        '6-8': 541,
        '8-10': 696,
        '10+': 850,
      },
      '>4': {
        none: 0,
        '<2': 62,
        '2-4': 185,
        '4-6': 309,
        '6-8': 433,
        '8-10': 556,
        '10+': 680,
      },
    },
    'car-diesel-2.0l+': {
      '1': {
        none: 0,
        '<2': 388,
        '2-4': 1165,
        '4-6': 1942,
        '6-8': 2718,
        '8-10': 3495,
        '10+': 4272,
      },
      '2': {
        none: 0,
        '<2': 194,
        '2-4': 583,
        '4-6': 971,
        '6-8': 1359,
        '8-10': 1748,
        '10+': 2136,
      },
      '3': {
        none: 0,
        '<2': 129,
        '2-4': 388,
        '4-6': 647,
        '6-8': 906,
        '8-10': 1165,
        '10+': 1424,
      },
      '4': {
        none: 0,
        '<2': 97,
        '2-4': 291,
        '4-6': 485,
        '6-8': 680,
        '8-10': 874,
        '10+': 1068,
      },
      '>4': {
        none: 0,
        '<2': 78,
        '2-4': 233,
        '4-6': 388,
        '6-8': 544,
        '8-10': 699,
        '10+': 854,
      },
    },
    'car-hybrid-small': {
      '1': {
        none: 0,
        '<2': 197,
        '2-4': 590,
        '4-6': 983,
        '6-8': 1377,
        '8-10': 1770,
        '10+': 2163,
      },
      '2': {
        none: 0,
        '<2': 98,
        '2-4': 295,
        '4-6': 492,
        '6-8': 688,
        '8-10': 885,
        '10+': 1082,
      },
      '3': {
        none: 0,
        '<2': 66,
        '2-4': 197,
        '4-6': 328,
        '6-8': 459,
        '8-10': 590,
        '10+': 721,
      },
      '4': {
        none: 0,
        '<2': 49,
        '2-4': 148,
        '4-6': 246,
        '6-8': 344,
        '8-10': 443,
        '10+': 541,
      },
      '>4': {
        none: 0,
        '<2': 39,
        '2-4': 118,
        '4-6': 197,
        '6-8': 275,
        '8-10': 354,
        '10+': 433,
      },
    },
    'car-hybrid-medium': {
      '1': {
        none: 0,
        '<2': 170,
        '2-4': 511,
        '4-6': 852,
        '6-8': 1193,
        '8-10': 1534,
        '10+': 1875,
      },
      '2': {
        none: 0,
        '<2': 103,
        '2-4': 308,
        '4-6': 513,
        '6-8': 719,
        '8-10': 924,
        '10+': 1129,
      },
      '3': {
        none: 0,
        '<2': 68,
        '2-4': 205,
        '4-6': 342,
        '6-8': 479,
        '8-10': 616,
        '10+': 753,
      },
      '4': {
        none: 0,
        '<2': 51,
        '2-4': 154,
        '4-6': 257,
        '6-8': 359,
        '8-10': 462,
        '10+': 565,
      },
      '>4': {
        none: 0,
        '<2': 41,
        '2-4': 123,
        '4-6': 205,
        '6-8': 287,
        '8-10': 370,
        '10+': 452,
      },
    },
    'car-hybrid-large': {
      '1': {
        none: 0,
        '<2': 197,
        '2-4': 590,
        '4-6': 983,
        '6-8': 1376,
        '8-10': 1770,
        '10+': 2163,
      },
      '2': {
        none: 0,
        '<2': 142,
        '2-4': 426,
        '4-6': 710,
        '6-8': 994,
        '8-10': 1278,
        '10+': 1562,
      },
      '3': {
        none: 0,
        '<2': 95,
        '2-4': 284,
        '4-6': 473,
        '6-8': 663,
        '8-10': 852,
        '10+': 1041,
      },
      '4': {
        none: 0,
        '<2': 71,
        '2-4': 213,
        '4-6': 355,
        '6-8': 497,
        '8-10': 639,
        '10+': 781,
      },
      '>4': {
        none: 0,
        '<2': 57,
        '2-4': 170,
        '4-6': 284,
        '6-8': 398,
        '8-10': 511,
        '10+': 625,
      },
    },
    'car-plug-in-hybrid-small': {
      '1': {
        none: 0,
        '<2': 104,
        '2-4': 313,
        '4-6': 522,
        '6-8': 730,
        '8-10': 939,
        '10+': 1148,
      },
      '2': {
        none: 0,
        '<2': 52,
        '2-4': 157,
        '4-6': 261,
        '6-8': 365,
        '8-10': 470,
        '10+': 574,
      },
      '3': {
        none: 0,
        '<2': 35,
        '2-4': 104,
        '4-6': 174,
        '6-8': 243,
        '8-10': 313,
        '10+': 383,
      },
      '4': {
        none: 0,
        '<2': 26,
        '2-4': 78,
        '4-6': 130,
        '6-8': 183,
        '8-10': 235,
        '10+': 287,
      },
      '>4': {
        none: 0,
        '<2': 21,
        '2-4': 63,
        '4-6': 104,
        '6-8': 146,
        '8-10': 188,
        '10+': 230,
      },
    },
    'car-plug-in-hybrid-medium': {
      '1': {
        none: 0,
        '<2': 170,
        '2-4': 511,
        '4-6': 852,
        '6-8': 1193,
        '8-10': 1534,
        '10+': 1875,
      },
      '2': {
        none: 0,
        '<2': 85,
        '2-4': 256,
        '4-6': 426,
        '6-8': 597,
        '8-10': 767,
        '10+': 938,
      },
      '3': {
        none: 0,
        '<2': 57,
        '2-4': 170,
        '4-6': 284,
        '6-8': 398,
        '8-10': 511,
        '10+': 625,
      },
      '4': {
        none: 0,
        '<2': 43,
        '2-4': 128,
        '4-6': 213,
        '6-8': 298,
        '8-10': 384,
        '10+': 469,
      },
      '>4': {
        none: 0,
        '<2': 34,
        '2-4': 102,
        '4-6': 170,
        '6-8': 239,
        '8-10': 354,
        '10+': 433,
      },
    },
    'car-plug-in-hybrid-large': {
      '1': {
        none: 0,
        '<2': 197,
        '2-4': 590,
        '4-6': 983,
        '6-8': 1376,
        '8-10': 1770,
        '10+': 2163,
      },
      '2': {
        none: 0,
        '<2': 98,
        '2-4': 295,
        '4-6': 492,
        '6-8': 688,
        '8-10': 885,
        '10+': 1081,
      },
      '3': {
        none: 0,
        '<2': 66,
        '2-4': 197,
        '4-6': 328,
        '6-8': 459,
        '8-10': 590,
        '10+': 721,
      },
      '4': {
        none: 0,
        '<2': 49,
        '2-4': 147,
        '4-6': 246,
        '6-8': 344,
        '8-10': 442,
        '10+': 541,
      },
      '>4': {
        none: 0,
        '<2': 39,
        '2-4': 118,
        '4-6': 197,
        '6-8': 275,
        '8-10': 354,
        '10+': 433,
      },
    },
    'car-battery-electric-vehicle-small': {
      '1': {
        none: 0,
        '<2': 86,
        '2-4': 257,
        '4-6': 428,
        '6-8': 599,
        '8-10': 770,
        '10+': 941,
      },
      '2': {
        none: 0,
        '<2': 43,
        '2-4': 128,
        '4-6': 214,
        '6-8': 299,
        '8-10': 385,
        '10+': 471,
      },
      '3': {
        none: 0,
        '<2': 29,
        '2-4': 86,
        '4-6': 143,
        '6-8': 200,
        '8-10': 257,
        '10+': 314,
      },
      '4': {
        none: 0,
        '<2': 21,
        '2-4': 64,
        '4-6': 107,
        '6-8': 150,
        '8-10': 192,
        '10+': 235,
      },
      '>4': {
        none: 0,
        '<2': 17,
        '2-4': 51,
        '4-6': 86,
        '6-8': 120,
        '8-10': 154,
        '10+': 188,
      },
    },
    'car-battery-electric-vehicle-medium': {
      '1': {
        none: 0,
        '<2': 98,
        '2-4': 295,
        '4-6': 492,
        '6-8': 689,
        '8-10': 886,
        '10+': 1083,
      },
      '2': {
        none: 0,
        '<2': 49,
        '2-4': 148,
        '4-6': 246,
        '6-8': 345,
        '8-10': 443,
        '10+': 542,
      },
      '3': {
        none: 0,
        '<2': 33,
        '2-4': 98,
        '4-6': 164,
        '6-8': 230,
        '8-10': 295,
        '10+': 361,
      },
      '4': {
        none: 0,
        '<2': 25,
        '2-4': 74,
        '4-6': 123,
        '6-8': 172,
        '8-10': 222,
        '10+': 271,
      },
      '>4': {
        none: 0,
        '<2': 20,
        '2-4': 59,
        '4-6': 98,
        '6-8': 138,
        '8-10': 177,
        '10+': 217,
      },
    },
    'car-battery-electric-vehicle-large': {
      '1': {
        none: 0,
        '<2': 114,
        '2-4': 341,
        '4-6': 568,
        '6-8': 796,
        '8-10': 1023,
        '10+': 1251,
      },
      '2': {
        none: 0,
        '<2': 57,
        '2-4': 171,
        '4-6': 284,
        '6-8': 398,
        '8-10': 512,
        '10+': 625,
      },
      '3': {
        none: 0,
        '<2': 38,
        '2-4': 114,
        '4-6': 189,
        '6-8': 265,
        '8-10': 341,
        '10+': 417,
      },
      '4': {
        none: 0,
        '<2': 28,
        '2-4': 85,
        '4-6': 142,
        '6-8': 199,
        '8-10': 256,
        '10+': 313,
      },
      '>4': {
        none: 0,
        '<2': 23,
        '2-4': 68,
        '4-6': 114,
        '6-8': 159,
        '8-10': 205,
        '10+': 250,
      },
    },
  },
  bus: {
    //Hours spent
    none: 0,
    '<2': 141,
    '2-4': 423,
    '4-6': 705,
    '6-8': 986,
    '8-10': 1268,
    '10+': 1550,
  },
  train: {
    //Hours spent
    none: 0,
    '<2': 127,
    '2-4': 382,
    '4-6': 636,
    '6-8': 890,
    '8-10': 1145,
    '10+': 1399,
  },
  'domestic-flight': {
    //Hours spent
    none: 0,
    '0-5': 487,
    '5-10': 1460,
    '10-20': 2920,
    '20-30': 4867,
    '30-40': 6814,
    '50+': 10707,
  },
  'short-haul-flight': {
    //Hours spent
    none: 0,
    economy: {
      //Hours spent
      none: 0,
      '0-5': 299,
      '5-10': 897,
      '10-20': 1794,
      '20-30': 2989,
      '30-40': 4185,
      '50+': 6577,
    },
    business: {
      //Hours spent
      none: 0,
      '0-5': 448,
      '5-10': 1345,
      '10-20': 2690,
      '20-30': 4484,
      '30-40': 6278,
      '50+': 9865,
    },
  },
  'long-haul-flight': {
    none: 0,
    economy: {
      none: 0,
      '0-5': 293,
      '5-10': 878,
      '10-20': 1756,
      '20-30': 2927,
      '30-40': 4098,
      '50+': 6440,
    },
    premiumEconomy: {
      none: 0,
      '0-5': 468,
      '5-10': 1405,
      '10-20': 2810,
      '20-30': 4683,
      '30-40': 6557,
      '50+': 10303,
    },
    business: {
      none: 0,
      '0-5': 849,
      '5-10': 2547,
      '10-20': 5093,
      '20-30': 8488,
      '30-40': 11884,
      '50+': 18675,
    },
    first: {
      none: 0,
      '0-5': 1171,
      '5-10': 2512,
      '10-20': 7025,
      '20-30': 11708,
      '30-40': 16391,
      '50+': 25758,
    },
  },
}

export default transportMatrix
