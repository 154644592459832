import React from 'react'
import { Text, Box, Flex } from 'theme-ui'

type Props = {
  data: Array<{
    label: string
    value: number
  }>
  tickIncrement: number
  max: number
  units?: string
}

const HorizontalBarChart: React.FC<Props> = ({
  data,
  tickIncrement,
  max,
  units,
}) => {
  const ticks = Array.from(
    { length: max / tickIncrement },
    (_, i) => i * tickIncrement
  )
  ticks.push(max)
  return (
    <Box
      sx={{
        position: 'relative',
        padding: '2px 0',
      }}
    >
      {ticks.map((tick, index) => (
        <Flex
          key={index}
          sx={{
            position: 'absolute',
            top: 0,
            left: `${(tick / max) * 100}%`,
            height: '100%',
            width: 1,
            border: '1px dashed black',
            opacity: 0.3,
          }}
        >
          <Text
            as="span"
            sx={{
              fontSize: 1,
              color: 'text',
              position: 'relative',
              top: '-22px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {tick}
          </Text>
        </Flex>
      ))}
      {data.map(item => (
        <Box
          key={item.label}
          sx={{
            textAlign: 'left',
            flex: 1,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              height: '34px',
              width: `${(item.value / max) * 100}%`,
              backgroundColor: 'primary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '0 8px',
              borderRadius: '10px',
              margin: '8px 0',
              transition: 'width 0.3s ease-in-out',
            }}
          >
            <Text
              as="span"
              sx={{
                fontSize: '14px',
                color: 'text',
              }}
            >
              {item.label}
            </Text>
            <Text
              as="span"
              sx={{
                fontWeight: 'bold',
                fontSize: '20px',
                fontFamily: 'heading',
                color: 'text',
              }}
            >
              {item.value}
              <Text
                as="span"
                sx={{
                  fontSize: '10px',
                  fontFamily: 'heading',
                  color: 'text',
                }}
              >
                {units}
              </Text>
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default HorizontalBarChart
