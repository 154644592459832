import { useEffect } from 'react'
import useLocalStorage from './use-local-storage'

export interface Webgains {
  partner?: string
  clickid?: string
  wgu?: string | null
  wgexpiry?: string | null
}

const useWebgains = (): Webgains | undefined => {
  const [webgains, setWebgains] = useLocalStorage<Webgains>(
    'webgains',
    undefined,
    86400
  )

  useEffect(() => {
    const params = new URL(document.location.href).searchParams
    const partner = params.get('partner')
    const clickid = params.get('clickid')
    const wgu = params.get('wgu')
    const wgexpiry = params.get('wgexpiry')

    if (clickid && partner === 'webgains') {
      const webgainsObject = {
        partner: partner,
        clickid: clickid,
        wgu: wgu,
        wgexpiry: wgexpiry,
      }
      setWebgains(webgainsObject)
    }
  }, [])

  return webgains
}

export default useWebgains
