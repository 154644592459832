import React from 'react'
import { Link, ThemeUIStyleObject } from 'theme-ui'
import filesize from 'filesize.js'
import pdf from '../images/doc-pdf.svg'

interface Props {
  href: string
  title: string
  size: number
  mimeType: 'application/pdf'
}

/**
 * Block link for downloading document assets.
 *
 * Calculates file size. Can support multiple MIME types and icons.
 */
const AssetLink: React.FC<Props> = ({ href, title, size, mimeType }) => {
  const type = {
    'application/pdf': {
      icon: pdf,
      name: 'PDF',
    },
  }[mimeType]

  const link: ThemeUIStyleObject = {
    display: 'flex',
    alignItems: 'center',
  }

  const icon: ThemeUIStyleObject = {
    width: '3.6em',
    marginRight: '1em',
  }

  const info: ThemeUIStyleObject = {
    fontWeight: 'normal',
  }

  return (
    <Link sx={link} variant="label" href={href}>
      {type && <img sx={icon} src={type.icon} alt="Dog-eared document icon" />}
      <span>
        {title}
        {type && (
          <span sx={info}>
            {' '}
            ({type.name}, {filesize(size)})
          </span>
        )}
      </span>
    </Link>
  )
}

export default AssetLink
