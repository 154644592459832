/**
 * These functions are used for grouping together related rich-text blocks
 * using the Stack component. By grouping these blocks together we can
 * more effectively control the spacing between these and other groups.
 *
 * By default, the getGroup function will group together headings, assets and
 * block links (a paragraph containing a single link, presented as a button).
 *
 * The getGroup and renderGroup functions can be overriden if a component
 * needs to define additional groups or stacking strategies.
 */

import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { ReactNode, Children } from 'react'
import { CommonNode as Node } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Block as BlockNode } from '@contentful/rich-text-types'
import { isInlineBlock, isParagraph } from './helpers'
import { Stack } from 'raam'

export type Group = {
  type: string
  prev: string | false
  children: ReactNode[]
}

export const getGroup = (node: Node): string => {
  switch (node.nodeType) {
    case BLOCKS.PARAGRAPH:
      if (isInlineBlock(node)) return 'link'
      if (isParagraph(node)) return 'default'
      return ''
    case BLOCKS.HEADING_3:
    case BLOCKS.HEADING_4:
    case BLOCKS.HEADING_5:
    case BLOCKS.HEADING_6:
      return 'heading'
    case BLOCKS.UL_LIST:
    case BLOCKS.OL_LIST:
      return 'list'
    case BLOCKS.EMBEDDED_ASSET:
      return 'asset'
    default:
      return 'default'
  }
}

export const getGroupStyle = (group: Group): ThemeUIStyleObject => {
  if (group.type === 'heading' && group.prev) {
    return { mt: 8 }
  }
  if (group.prev === 'heading') {
    return { mt: group.type === 'list' ? -8 : -11 }
  }
  return {}
}

export const renderGroup = (group: Group, i: number): ReactNode => (
  <Stack key={i} sx={getGroupStyle(group)} className="group" gap={11}>
    {group.children}
  </Stack>
)

export const renderGroups = (
  doc: BlockNode,
  children: ReactNode,
  group = getGroup,
  render = renderGroup
): ReactNode => {
  // remove nodes not rendered as react children
  const content =
    Children.map(children, (child, i) =>
      child ? doc.content[i] : null
    )?.filter(Boolean) || []

  // group nodex
  const groups = content.reduce((groups: Group[], node: Node, i: number) => {
    // identify the group that the current node belongs to
    const current = group(node)

    if (current) {
      // identify the group that the previous node belongs to
      const prev = i > 0 && group(content[i - 1])

      // grab the react node corresponding to the current Contentful node
      const child = Children.toArray(children)[i]

      // create a new group if necessary, else push child onto current group
      if (current !== prev) {
        groups[groups.length] = {
          type: current,
          prev: prev,
          children: [child],
        }
      } else {
        groups[groups.length - 1].children.push(child)
      }
    }

    return groups
  }, [])

  return groups.map((group: Group, i) => render(group, i))
}
