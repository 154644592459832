import React from 'react'
import Layout from '../../components/layout'
import Section from '../../components/section'
import { Flex, Heading, Link, Text } from '@theme-ui/components'
import { ThemeUIStyleObject } from 'theme-ui'
import HalfPageQuote from '../../components/half-page-quote'
import SEO from '../../components/seo'
import metadataLogo from '../../images/carbon-calculator-og-image.png'

type PageProps = {
  pageContext: {
    firstStepUrl: string
  }
  location: {
    origin: string
  }
}

const CarbonCalculatorIntroductionPage: React.FC<PageProps> = ({
  pageContext: { firstStepUrl },
  location: { origin },
}) => {
  const wrapperStyle: ThemeUIStyleObject = {
    flexWrap: 'wrap',
  }

  const superTitleSx: ThemeUIStyleObject = {
    fontWeight: 300,
    position: 'relative',
    fontFamily: 'ITC Lubalin Graph',
    '&::before': {
      content: '""',
      display: ['none', 'none', 'block'],
      position: 'absolute',
      backgroundColor: 'black',
      width: '8.17rem',
      height: '0.17rem',
      left: '-9rem',
      top: '3.75rem',
    },
  }

  const descriptionSx: ThemeUIStyleObject = {
    marginTop: '1.25rem',
    marginBottom: '2.5rem',
    fontfamily: 'Roboto',
    fontSize: '1.1rem',
  }

  const sectionSx: ThemeUIStyleObject = {
    paddingX: '12%',
    paddingTop: '8.33rem',
  }

  const halfSectionSx: ThemeUiStyleObject = {
    paddingLeft: '6%',
    paddingRight: '12%',
  }

  return (
    <Layout simple={true}>
      <SEO
        title="Carbon Footprint Calculator"
        description={`Ecotricity’s carbon footprint calculator shows you what your carbon footprint is, why your carbon footprint is important and advice on how to reduce it.`}
        image={`${origin}${metadataLogo}`}
      />
      <Flex sx={wrapperStyle}>
        <Section style={sectionSx} variant="halfPage">
          <Heading sx={superTitleSx} variant="xl">
            The Green Britain
          </Heading>
          <Heading variant="xl">Carbon Footprint Calculator</Heading>
          <Text sx={descriptionSx} as="p">
            {`Take our one-minute survey and we'll work out roughly how much
            carbon you produce annually. Knowing your footprint is the first
            step to making small changes in your everyday life to cut your
            personal emissions.`}
          </Text>
          <Link variant="button" href={firstStepUrl}>
            Find out your footprint
          </Link>
        </Section>
        <HalfPageQuote
          style={halfSectionSx}
          variant="contrastHalfPage"
          quoteTitle="A <b>Manifesto</b> for zero carbon living"
          quote="
                        <p>“Modern life is killing us. It should be obvious.</p>
                        <p>We need to tackle the three big issues: Energy, Transport and Food and change how we power ourselves, how we travel and what we eat.</p>
                        <p>These are the fundamental drivers of the climate crisis, human health crisis and wildlife extinction.”</p>
                    "
          quoteSummary="Taken from <b>Manifesto</b>, by Dale Vince"
        />
      </Flex>
    </Layout>
  )
}

export default CarbonCalculatorIntroductionPage
