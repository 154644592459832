import React, { ReactElement } from 'react'
import Variant from './variant'

interface Props {
  name: string
  children: ReactElement[]
}

const Experiment: React.FC<Props> = ({ name = '', children = [] }) => {
  return (
    <>
      {children.map(({ props = {} }, i) => (
        <Variant id={i} name={name} {...props} key={`variant-${name}-${i}`} />
      ))}
    </>
  )
}

export default Experiment
