import React from 'react'
import {
  Flex,
  Link,
  Box,
  Image,
  Text,
  Heading,
  ThemeUIStyleObject,
} from 'theme-ui'
import {
  BLOCKS,
  CustomRenderer,
  Document,
  isParagraph,
  renderRichText,
} from '../rich-text'

import loginBg from '../images/cta-login.png'
import appBg from '../images/cta-app.png'
import ecoLogo from '../images/cta-eco-logo.png'
import topUpLogo from '../images/cta-top-up-logo.png'
import googleBtn from '../images/cta-app-google.png'
import appleBtn from '../images/cta-app-apple.png'

interface Props {
  heading: Document
  title: string
}
const LoginAppCTA: React.FC<Props> = ({ heading, title }) => {
  const container: ThemeUIStyleObject = {
    flexDirection: ['column', 'column', 'row'],
    justifyContent: 'center',
    alignItems: 'center',
  }

  const sectionHeading: ThemeUIStyleObject = {
    maxWidth: '55rem',
    marginBottom: '4.8rem',
    textAlign: 'center',
    fontSize: '1.8rem',
    fontWeight: '400',
  }

  const cardHeading: ThemeUIStyleObject = {
    color: 'background',
    paddingRight: '2rem',
  }

  const loginCard: ThemeUIStyleObject = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    backgroundImage: `url(${loginBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    maxHeight: '12rem',
    maxWidth: '30rem',
    borderRadius: '1rem',
    paddingX: '3rem',
    paddingY: '5rem',
  }

  const appCard: ThemeUIStyleObject = {
    position: 'relative',
    backgroundImage: `url(${appBg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    height: '12rem',
    maxWidth: '30rem',
    borderRadius: '1rem',
    paddingX: '1.5rem',
    paddingY: '1.5rem',
  }

  const googleAppButton: ThemeUIStyleObject = {
    position: 'absolute',
    paddingX: '5.5rem',
    paddingY: '5rem',
    backgroundImage: `url(${googleBtn})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10rem',
  }

  const appleAppButton: ThemeUIStyleObject = {
    position: 'absolute',
    paddingX: '5.5rem',
    paddingY: '5rem',
    marginLeft: '11rem',
    backgroundImage: `url(${appleBtn})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '10rem',
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        isParagraph(node) && (
          <Heading sx={sectionHeading} as="h2">
            {children}
          </Heading>
        ),
    },
  }

  const isPayg = title === 'Help with Pay As You Go'

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      {renderRichText(heading, renderers)}
      <Flex sx={container}>
        <Box sx={isPayg ? { display: 'none' } : loginCard}>
          <Heading sx={cardHeading} as="h3" variant="xs">
            Log in to your online account
          </Heading>
          <Link href={process.env.GATSBY_ONLINE_ACCOUNT_URL} variant="button">
            Log in
          </Link>
        </Box>
        <Text
          sx={{
            display: isPayg ? 'none' : 'flex',
            margin: ['2rem', '2rem', '3rem'],
          }}
          variant="hero"
        >
          or
        </Text>
        <Box sx={appCard}>
          <Flex sx={{ alignItems: 'center', paddingRight: '4rem' }}>
            <Image
              src={isPayg ? topUpLogo : ecoLogo}
              sx={{ width: '6rem', marginRight: '1.5rem' }}
            />
            <Heading sx={cardHeading} as="h3" variant="xs">
              Download our <span sx={{ color: 'primary' }}>NEW</span> Ecotricity
              {isPayg ? ' Top-Up' : ''} App
            </Heading>
          </Flex>
          <Flex
            sx={{
              paddingLeft: ['0rem', '5.5rem', '5.5rem'],
              marginTop: '0.75rem',
            }}
          >
            <Link
              sx={googleAppButton}
              href={
                isPayg
                  ? 'https://play.google.com/store/apps/details?id=com.paypoint.ecotricity&gl=GB'
                  : '/android-app'
              }
              target="_blank"
            ></Link>
            <Link
              sx={appleAppButton}
              href={
                isPayg
                  ? 'https://apps.apple.com/gb/app/ecotricity-top-up-app/id6443797888'
                  : '/ios-app'
              }
              target="_blank"
            ></Link>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  )
}

export default LoginAppCTA
