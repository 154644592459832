import React from 'react'
import { Container, ThemeUIStyleObject } from 'theme-ui'
import { fluidScale, spanWidth } from '../utils/mixins'
import sprayEdgeNarrowWebp from '../images/spray-edge-2.webp'
import sprayEdgeNarrowPng from '../images/spray-edge-2.png'
import sprayEdgeCutWebp from '../images/spray-edge-3.webp'
import sprayEdgeCutPng from '../images/spray-edge-3.png'
import useWebpImage from '../hooks/use-webp-image'

interface Props {
  as?: React.ElementType
  style?: ThemeUIStyleObject
  pos?: number
  count?: number
  fill?:
    | 'homeHero'
    | 'spraySW'
    | 'sprayNESW'
    | 'cutNWSpraySE'
    | 'flippedCutNWSpraySE'
    | 'contrastHalfPage'
    | 'liveGenLink'
    | 'blank'
    | 'greenFadeFull'
    | 'greenFadeHalf'
    | 'liveGenError'
  variant?:
    | 'page'
    | 'loose'
    | 'header'
    | 'rule'
    | 'index'
    | 'card'
    | 'wide'
    | 'halfPage'
    | 'contrastHalfPage'
    | 'halfPageRight'
    | 'halfPageRightV2'
    | 'default'
    | 'liveGen'
}

/**
 * Main layout component for page sections.
 *
 * Contains logic that defines the layout for page sections, depending on the
 * variant type. Mainly controls max-width, fluid padding and section divisions.
 */
const Section: React.FC<Props> = ({
  as,
  pos,
  count,
  fill,
  children,
  variant = 'default',
  style,
}) => {
  const el = as || 'section'
  const id = pos === undefined ? undefined : `section-${pos + 1}`
  const last = count === undefined || pos === count - 1
  const wrapped =
    (fill && variant === 'index') ||
    (fill && variant === 'wide') ||
    variant === 'liveGen' ||
    variant === 'card'

  const sprayEdgeNarrow = useWebpImage(sprayEdgeNarrowWebp, sprayEdgeNarrowPng)
  const sprayEdgeCut = useWebpImage(sprayEdgeCutWebp, sprayEdgeCutPng)

  const halfPageStyle: ThemeUIStyleObject = {
    paddingBottom: '12.5rem',
    flexBasis: ['100%', '50%'],
    maxWidth: '100%',
  }

  const fills = {
    homeHero: {
      backgroundColor: 'muted',
      overflow: 'hidden',
      marginBottom: '-4%',
    },
    spraySW: {
      position: 'relative',
      backgroundColor: 'muted',
      overflow: 'hidden',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        left: 0,
        bottom: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 252 / 1055)',
        backgroundImage: `url(${sprayEdgeNarrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'scaleX(-1) translate(10%, 65%)',
      },
    },
    sprayNESW: {
      position: 'relative',
      backgroundColor: 'muted',
      overflow: 'hidden',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        left: 0,
        bottom: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 252 / 1055)',
        backgroundImage: `url(${sprayEdgeNarrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'scaleX(-1) translate(10%, 65%)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        height: 0,
        right: 0,
        top: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 252 / 1055)',
        backgroundImage: `url(${sprayEdgeNarrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'scaleY(-1) translate(10%, 65%)',
      },
    },
    cutNWSpraySE: {
      position: 'relative',
      backgroundColor: 'muted',
      overflow: 'hidden',

      '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        left: 0,
        top: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 324 / 1034)',
        backgroundImage: `url(${sprayEdgeCut})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 50%',
        transform: 'translate(-28%, -30%)',
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        height: 0,
        right: 0,
        bottom: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 252 / 1055)',
        backgroundImage: `url(${sprayEdgeNarrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'translate(10%, 65%)',
      },
    },
    flippedCutNWSpraySE: {
      position: 'relative',
      backgroundColor: 'muted',
      overflow: 'hidden',

      '&::after': {
        content: '""',
        position: 'absolute',
        height: 0,
        left: 0,
        bottom: 0,
        width: '45%',
        paddingBottom: 'calc(45% * 252 / 1055)',
        backgroundImage: `url(${sprayEdgeNarrow})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transform: 'scaleX(-1) translate(0%, 65%)',
      },
    },
    contrastHalfPage: {
      backgroundColor: 'contrast',
    },
    liveGenLink: {
      marginTop: 12,
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, #E7EAEA 100%)',
    },
    greenFadeFull: {
      background:
        'linear-gradient(to bottom, #FFFFFF 0%, #EAF1DD 10%, #EAF1DD 90%, #FFFFFF 100%)',
    },
    greenFadeHalf: {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, #EAF1DD 100%)',
    },
    liveGenError: {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0) -33.76%, #EAF1DD 75.92%, #FFFFFF 100%)',
    },
  }

  const space = {
    xs: fluidScale(15, 0.75, 5),
    sml: fluidScale(17, 0.7, 5),
    med: fluidScale(19, 0.45, 8),
    lrg: fluidScale(23, 0.3, 10),
    col: [0, null, spanWidth(1, 12, 11)],
  }

  const variants = {
    header: {
      marginTop: space.med,
    },
    page: {
      marginTop: space.xs,
      marginBottom: last ? space.med : space.sml,
    },
    rule: {
      paddingTop: !pos ? space.xs : space.med,
      paddingBottom: space.med,
      borderTop: !pos ? 'none' : 'muted',
      borderWidth: 'divider',
    },
    index: {
      'marginTop ': 23, // homepage fallback for legacy browsers
      'marginBottom ': 23, // homepage fallback for legacy browsers
      marginY: space.lrg,
      paddingY: fill ? 17 : null,
    },
    card: {
      marginTop: space.xs,
      marginBottom: space.med,
      paddingX: space.col,
      paddingBottom: '4rem',
    },
    loose: {
      marginTop: space.sml,
      marginBottom: last ? space.med : space.sml,
    },
    wide: {},
    halfPage: {
      ...halfPageStyle,
    },
    halfPageRight: {
      position: ['relative', 'initial'],
      paddingLeft: ['12%', '12%', '5%'],
      paddingRight: ['12%', '12%', '17%'],
      paddingTop: ['30%', '10rem'],
      boxShadow: [
        '0px -4px 4px rgba(0, 0, 0, 0.25)',
        '-4px 0px 4px rgba(0, 0, 0, 0.25)',
      ],
      ...halfPageStyle,
      ...style,
    },
    halfPageRightV2: {
      position: ['relative', 'initial'],
      paddingLeft: ['12%', '12%', '5%'],
      paddingRight: ['12%', '12%', '17%'],
      paddingTop: ['30%', '10rem'],
      boxShadow: [
        '-1px 8px 14px 0px rgba(0, 0, 0, 0.20)',
        '-1px 8px 14px 0px rgba(0, 0, 0, 0.20)',
      ],
      ...halfPageStyle,
      ...style,
    },
    default: {
      marginTop: space.med,
      marginBottom: last ? space.med : space.sml,
    },
    liveGen: {
      paddingY: fill ? 17 : null,
      marginBottom: fill ? null : '128px',
      h1: {
        fontSize: ['36px', '48px'],
        textAlign: 'center',
      },
    },
  }

  const sx: ThemeUIStyleObject = {
    ...(variant && variants[variant]),
    ...(fill && fills[fill]),
  }

  const containers = {
    outer: wrapped ? 'full' : 'container',
    inner:
      variant === 'wide'
        ? 'wide'
        : variant === 'liveGen'
        ? 'full'
        : 'container',
  }

  return (
    <Container
      as={el}
      id={id}
      sx={{ ...style, ...sx }}
      variant={containers.outer}
    >
      {wrapped ? (
        <Container variant={containers.inner}>{children}</Container>
      ) : (
        children
      )}
    </Container>
  )
}

export default Section
