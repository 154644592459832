import JSConfetti from 'js-confetti'

const useConfetti = (): JSConfetti => {
  if (!window)
    return {
      addConfetti: () => Promise.resolve(),
      clearCanvas: () => null,
      destroyCanvas: () => null,
    }
  if (!window.__confetti) {
    window.__confetti = new JSConfetti()
  }
  return window.__confetti
}

export default useConfetti
