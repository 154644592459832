import React from 'react'
import NavLink from './nav-link'
import { formatPageLink } from '../utils/helpers'
import { fluidScale } from '../utils/mixins'
import { Text, useThemeUI } from 'theme-ui'
import { Stack, Wrap } from 'raam'

interface Props {
  query:
    | GatsbyTypes.SitemapSupportNavigationQuery
    | GatsbyTypes.SitemapNavigationQuery
}

const Sitemap: React.FC<Props> = ({ query }) => {
  const collections = query.contentfulSitemap?.collections
  const stacks = collections?.map((collection, i) => {
    const links = collection?.pages?.map((page, j) => {
      const link = formatPageLink(page)
      return (
        <NavLink key={j} to={link} activeClassName="">
          {page?.title}
        </NavLink>
      )
    })

    return (
      <Stack
        key={i}
        as="ul"
        sx={{ maxWidth: '14rem', textAlign: ['center', 'left'] }}
      >
        <Text variant="label">{collection?.heading}</Text>
        {links}
      </Stack>
    )
  })

  const { theme } = useThemeUI()
  const flexGap = [15, null, fluidScale(17, 0.6, 3)(theme)]

  return (
    <Wrap
      sx={{
        alignItems: 'flex-start',
        justifyContent: 'center',
        textAlign: 'left',
        rowGap: 12,
      }}
      gap={flexGap}
    >
      {stacks}
    </Wrap>
  )
}

export default Sitemap
