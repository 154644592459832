import React from 'react'
import { Fragment, useEffect } from 'react'
import { Box, Button, Grid, Flex, Heading, Text, Label } from 'theme-ui'
import {
  ValidatingInput,
  MaskedValidatingInput,
  SymmetricValidatingInput,
} from './inputs/validating-input'
import Checkbox from './inputs/checkbox'
import Salutation from './inputs/salutation'
import AddressPicker from './inputs/address-picker'
import { StepOneForm, StepTwoForm } from './direct-debit'
import { useForm, UseFormMethods } from 'react-hook-form'
import { heading, fieldset } from './utils/styles'
import { validEmail } from './utils/validators'
import { cleanSortCode } from '../utils/helpers'

const URL = `https://${
  process.env.GATSBY_API_HOST as string
}/bank-accounts/v1/account-check`

const bankValidationResults: Record<string, Promise<string | boolean>> = {}
const validateBankDetailsRequest =
  (form: UseFormMethods<StepTwoForm>) =>
  (accountNumber: string, sortCode: string): Promise<string | boolean> => {
    const key = `${sortCode}/${accountNumber}`
    const invalidMessage = 'Account and/or sortcode invalid'
    const clearErrors = () => {
      form.clearErrors('bankAccountNumber')
      form.clearErrors('bankAccountSortCode')
    }
    const setErrors = () => {
      form.setError('bankAccountNumber', { message: invalidMessage })
      form.setError('bankAccountSortCode', { message: invalidMessage })
    }

    if (!accountNumber || !sortCode) {
      return Promise.resolve(true)
    }

    bankValidationResults[key] =
      bankValidationResults[key] ??
      fetch(URL, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          sortcode: cleanSortCode(sortCode),
          accountnumber: accountNumber,
        }),
      })
        .then(res => res.json())
        .then(res => {
          if (res.data.validaccount) {
            clearErrors()
            return true
          }

          setErrors()
          return invalidMessage
        })
        .catch(e => {
          if (e.message === 'Failed to fetch') {
            // some network error - delete from cache
            delete bankValidationResults[key]
            // nothing we can do now. Better just hope the user got it right 🤷‍♂️
            return true
          }

          setErrors()
          return invalidMessage
        })

    return bankValidationResults[key]
  }

const inputStyle = {
  position: 'absolute',
  zIndex: '-1',
  '+ label': {
    backgroundColor: '#e7eaea',
  },
  ':checked + label': {
    backgroundColor: 'rgb(153, 204, 51)',
  },
} as const

const boxStyle = {
  cursor: 'pointer',
  padding: '16px',
  border: '1px solid #999',
  borderRadius: '4px',
  color: '#484848',
  fontSize: '1.25rem',
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
} as const

const kwhStyle = {
  marginLeft: '12px',
  marginRight: '12px',
  paddingLeft: '12px',
  height: '2.5rem',
  width: '96px',
} as const

const fuelTypeStyle = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'baseline',
  marginTop: '16px',
} as const

const DirectDebitStepTwo = ({
  back,
  complete,
  defaultValues,
  paymentType,
  fuelType,
}: {
  back: () => void
  complete: (data: StepTwoForm) => void
  defaultValues?: Partial<StepTwoForm>
  paymentType: StepOneForm['paymentType']
  fuelType: StepOneForm['fuelType']
}): JSX.Element => {
  const formFns = useForm<StepTwoForm>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onBlur', // important! The validation for bank details does an ajax lookup
    defaultValues,
  })

  const { register, watch, handleSubmit, errors } = formFns

  const requiredField = (label: string) =>
    register({ required: `Please enter your ${label}` })

  const addressesMatch = Boolean(watch('addressesMatch'))
  const accountNumber = watch('bankAccountNumber')
  const sortCode = watch('bankAccountSortCode')
  const energyUsageKnown = (watch('energyUsageKnown') ?? '') as
    | StepTwoForm['energyUsageKnown']
    | ''

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const directDebitDayOptions = () => {
    const options = []
    for (let day = 1; day <= 28; day++) {
      options.push(
        <option key={day} value={day}>
          {day}
        </option>
      )
    }

    return options
  }

  const validateBankDetails = validateBankDetailsRequest(formFns)

  return (
    <form noValidate onSubmit={handleSubmit(complete)}>
      <Box sx={fieldset}>
        <Heading sx={heading}>Bank Details</Heading>
        <Text as="p" sx={heading}>
          {`Please give us the account details you'd like your Direct Debit
          payments to come from. Don't worry, this is a secure web page - your
          details are safe.`}
        </Text>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['bankAccountName'] as const}
          handleRef={requiredField}
          label="Bank Account Holder Name"
        />
        <Grid columns={[1, '1fr 1fr']} gap="10">
          <ValidatingInput
            {...formFns}
            deepName={['bankAccountNumber'] as const}
            handleRef={register({
              required: `Please enter your bank account number`,
              validate: () => validateBankDetails(accountNumber, sortCode),
            })}
            label="Bank Account Number"
          />
          <MaskedValidatingInput
            {...formFns}
            deepName={['bankAccountSortCode'] as const}
            handleRef={register({
              required: `Please enter your bank account sort code`,
              validate: () => validateBankDetails(accountNumber, sortCode),
              setValueAs: cleanSortCode, // transform input value but display nicely formatted sort code
            })}
            label="Sort Code"
            mask="99-99-99"
            maskPlaceholder={null}
            beforeMaskedStateChange={({ nextState }) => {
              // horrible mess to workaround unpredictable cursor position. always position at end of input
              const { value } = nextState
              const { length } = value
              const selection = { start: length, end: length }
              return {
                value,
                selection,
              }
            }}
          />
        </Grid>
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Getting your Direct Debit payments right</Heading>
        {paymentType === 'variable' ? (
          <Fragment>
            <Text as="p">
              {`Your monthly payments to Ecotricity will leave your account within
              3 days of the date quoted on your bill. Monthly payments may vary
              depending on the time of year and how much energy you use. To
              ensure your payments are accurate, you'll need to provide a meter
              reading each month. If we do not have your meter reading, we will
              take payment for an estimate of your consumption for that month.`}
            </Text>
          </Fragment>
        ) : (
          <Fragment>
            <Box sx={fieldset}>
              <Text as="p">
                {`We'll calculate your monthly payment using our current prices
                and the industry information we hold about your property; we'll
                use the information you've provided too if we haven't received
                any industry data yet. And we will also include the current
                balance on your account.`}
              </Text>
            </Box>
            <Box sx={fieldset}>
              <Heading sx={heading}>
                Do you know how much energy you use?
              </Heading>
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <div sx={{ position: 'relative', width: '49%' }}>
                  <input
                    id="energyUsageKnownYes"
                    name="energyUsageKnown"
                    type="radio"
                    value="Yes"
                    ref={register({
                      validate: value =>
                        Boolean(value) || 'Please choose an option',
                    })}
                    sx={inputStyle}
                  />
                  <Label htmlFor="energyUsageKnownYes" sx={boxStyle}>
                    Yes
                  </Label>
                </div>
                <div sx={{ position: 'relative', width: '49%' }}>
                  <input
                    id="energyUsageKnownNo"
                    name="energyUsageKnown"
                    type="radio"
                    value="No"
                    ref={register({
                      validate: value =>
                        value ? true : 'Please choose an option',
                    })}
                    sx={inputStyle}
                  />
                  <Label htmlFor="energyUsageKnownNo" sx={boxStyle}>
                    No
                  </Label>
                </div>
              </Box>
              <Text as="p" variant="errorLabel">
                {errors.energyUsageKnown?.message}
              </Text>
            </Box>
            {energyUsageKnown === 'Yes' ? (
              <Box
                sx={{ ...fieldset, display: 'flex', flexFlow: 'column nowrap' }}
              >
                <Heading sx={heading}>How much energy do you use?</Heading>
                {fuelType === 'elec' || fuelType === 'both' ? (
                  <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
                    <Box
                      sx={{
                        ...fuelTypeStyle,
                        marginBottom: '16px',
                      }}
                    >
                      <Heading sx={{ ...heading, width: '12rem' }}>
                        Electricity
                      </Heading>{' '}
                      I use{' '}
                      <input
                        name="electricityKwh"
                        type="number"
                        sx={kwhStyle}
                        ref={register({
                          validate: (value: string) =>
                            value.length > 0 ||
                            'Please provide your electricity kWh usage per year',
                        })}
                      />{' '}
                      kWh per year
                    </Box>
                    <Text as="p" variant="errorLabel">
                      {errors.electricityKwh?.message}
                    </Text>
                  </Box>
                ) : undefined}
                {fuelType === 'gas' || fuelType === 'both' ? (
                  <Box sx={{ display: 'flex', flexFlow: 'column nowrap' }}>
                    <Box sx={fuelTypeStyle}>
                      <Heading sx={{ ...heading, width: '12rem' }}>Gas</Heading>{' '}
                      I use{' '}
                      <input
                        name="gasKwh"
                        type="number"
                        sx={kwhStyle}
                        ref={register({
                          validate: (value: string) =>
                            value.length > 0 ||
                            'Please provide your gas kWh usage per year',
                        })}
                      />{' '}
                      kWh per year
                    </Box>
                    <Text as="p" variant="errorLabel">
                      {errors.gasKwh?.message}
                    </Text>
                  </Box>
                ) : undefined}
              </Box>
            ) : energyUsageKnown === 'No' ? (
              <Box sx={fieldset}>
                <Heading sx={heading}>Tell us about your property</Heading>
                How many bedrooms has it got?
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row wrap',
                    justifyContent: 'space-between',
                    marginBottom: '1rem',
                  }}
                >
                  {['1', '2', '3', '4', '5', '5+'].map((num, index) => (
                    <Box
                      sx={{
                        position: 'relative',
                        width: '33%',
                      }}
                      key={`dd-energy-use-box-${index}`}
                    >
                      <input
                        id={`numberofBedrooms${num}`}
                        name="numberofBedrooms"
                        type="radio"
                        value={num}
                        ref={register({
                          validate: value =>
                            Boolean(value) ||
                            'Please select the number of bedrooms',
                        })}
                        sx={inputStyle}
                      />
                      <Label htmlFor={`numberofBedrooms${num}`} sx={boxStyle}>
                        {`${num} bedroom${num === '1' ? '' : 's'}`}
                      </Label>
                    </Box>
                  ))}
                  <Text as="p" variant="errorLabel">
                    {errors.numberofBedrooms?.message}
                  </Text>
                </Box>
                How do you heat your property?
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ position: 'relative', width: '49%' }}>
                    <input
                      id="heatingSourceGas"
                      name="heatingSource"
                      type="radio"
                      value="gas"
                      ref={register({
                        validate: value =>
                          Boolean(value) || 'Please select a heating type',
                      })}
                      sx={inputStyle}
                    />
                    <Label htmlFor="heatingSourceGas" sx={boxStyle}>
                      Gas
                    </Label>
                  </Box>
                  <Box sx={{ position: 'relative', width: '49%' }}>
                    <input
                      id="heatingSourceElectric"
                      name="heatingSource"
                      type="radio"
                      value="electric"
                      ref={register({
                        validate: value =>
                          Boolean(value) || 'Please select a heating type',
                      })}
                      sx={inputStyle}
                    />
                    <Label htmlFor="heatingSourceElectric" sx={boxStyle}>
                      Electric
                    </Label>
                  </Box>
                </Box>
                <Text as="p" variant="errorLabel">
                  {errors.heatingSource?.message}
                </Text>
              </Box>
            ) : undefined}
            <Label htmlFor="directDebitDay">
              Please choose your payment date
            </Label>
            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'baseline',
              }}
            >
              Day{' '}
              <select
                id="directDebitDay"
                name="directDebitDay"
                defaultValue="28"
                ref={register}
                sx={{
                  marginLeft: '6px',
                  marginRight: '6px',
                  paddingLeft: '6px',
                  width: '4rem',
                  height: '2.5rem',
                  textAlign: 'center',
                }}
              >
                {directDebitDayOptions()}
              </select>{' '}
              of the month
            </Box>
            <Text as="p" variant="errorLabel">
              {errors.directDebitDay?.message}
            </Text>
          </Fragment>
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Payment name and address</Heading>
        <Text as="p">
          This should be the name and address this bank account is registered
          to.
        </Text>
      </Box>
      <Box sx={fieldset}>
        <Salutation {...formFns} />
        <Grid columns={[1, '1fr 1fr']} gap="10">
          <SymmetricValidatingInput
            {...formFns}
            deepName={['firstName'] as const}
            handleRef={requiredField}
            label="First name"
          />
          <SymmetricValidatingInput
            {...formFns}
            deepName={['lastName'] as const}
            handleRef={requiredField}
            label="Last name"
          />
        </Grid>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['emailAddress'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validEmail: validEmail(label),
              },
            })
          }
          label="Email address"
          type="email"
        />
        <AddressPicker addressName="billingAddress" {...formFns} />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Supply address</Heading>
        <Checkbox
          name="addressesMatch"
          label="Same as main address"
          {...formFns}
        />
        {/* not the soundest thing in the world, but will make TS stop complaining for now */}
        {addressesMatch ? null : (
          <AddressPicker
            addressName="supplyAddress"
            {...(formFns as UseFormMethods<
              StepTwoForm & Required<Pick<StepTwoForm, 'supplyAddress'>>
            >)}
          />
        )}
      </Box>
      <Flex
        sx={{
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault()
            back()
          }}
        >
          Back
        </Button>

        <Button>Continue</Button>
      </Flex>
    </form>
  )
}

export default DirectDebitStepTwo
