import React, { useMemo } from 'react'
import { Box, Flex, Text, Heading, Image, ThemeUIStyleObject } from 'theme-ui'
import { MillLocation } from '../templates/green-electricity'
import NavLink from './nav-link'
import { TileLayer, MapContainer, CircleMarker, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import L, { LeafletEvent, LatLng, MarkerCluster } from 'leaflet'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { fluidScale } from '../utils/mixins'
import { useEffect, useState } from 'react'
import ClusterGroup from 'react-leaflet-markercluster'
import greenDot from '../images/green-dot.svg'
import blueDot from '../images/blue-dot.svg'
import pinkDot from '../images/pink-dot.svg'
import { graphql, useStaticQuery } from 'gatsby'
import { Stack } from 'raam'
import { renderRichText } from '../rich-text'

interface Props {
  millLocations: Array<MillLocation>
}

interface BreakpointMapCenter {
  latLng: L.LatLng
  zoom: number
}

const MillsMap: React.FC<Props> = ({ millLocations }) => {
  const data = useStaticQuery<GatsbyTypes.MillsMapContentQuery>(graphql`
    query MillsMapContent {
      intro: contentfulItem(contentful_id: { eq: "2xN3mvlsOgaUFVaRN4Bv0U" }) {
        title
        body {
          raw
        }
      }
      wind: contentfulItem(contentful_id: { eq: "4ACFRWE82s8keWoUZSKfDI" }) {
        title
        body {
          raw
        }
      }
      sun: contentfulItem(contentful_id: { eq: "2KuGxtkqylBsm6nobQ0Mgo" }) {
        title
        body {
          raw
        }
      }
    }
  `)

  const mapContainer: ThemeUIStyleObject = {
    height: ['40rem', '70rem', '70rem'],
    zIndex: 0,
  }

  const mapContainerHeader: ThemeUIStyleObject = {
    textAlign: 'center',
    paddingTop: ['2rem', '3rem', '6rem'],
    paddingBottom: ['1.3rem', '1.5rem', '2rem'],
    paddingX: '2rem',
    fontSize: ['1.6rem', '2.4rem', '2.8rem', '3rem'],
  }

  const mapStyle: ThemeUIStyleObject = {
    paddingBottom: ['0', '0', '2.5rem'],
    width: ['100%', '100%', '95%', '95%'],
    '.leaflet-tooltip': {
      position: 'absolute',
      margin: 0,
      lineHeight: 0,
      background: 'transparent',
      boxShadow: 'none',
      border: 'none',
      fontFamily: '"Big Shoulders Display", sans-serif',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    '.leaflet-tooltip-left:before': {
      borderLeftColor: 'transparent',
    },
    '.leaflet-tooltip-right:before': {
      borderRightColor: 'transparent',
    },
    '.leaflet-control-attribution': {
      display: 'none',
    },
    '.leaflet-tile-pane': {
      WebkitFilter:
        'invert(75%) grayscale(65%) hue-rotate(90deg)  brightness(2.67)',
      filter: 'invert(75%) grayscale(65%) hue-rotate(90deg)  brightness(2.67)',
    },
    'leaflet-container': {
      zIndex: 0,
    },
    '.cluster-marker': {
      margin: 'auto',
      lineHeight: 0,
      padding: '1.15rem 1.5rem 1.15rem 0.8rem',
      borderRadius: '2rem',
      fontSize: '1.3rem',
      color: 'text',
      backgroundColor: 'contrast',
      opacity: 0.7,
      fontWeight: 'black',
      fontFamily: '"Big Shoulders Display", sans-serif',
      '&:hover': {
        opacity: 1,
      },
    },
  }

  const navSize: ThemeUIStyleObject = {
    width: 100 / 2 + '%',
    paddingX: 7,
    paddingY: fluidScale(10, 0.8, 1),
  }

  const mapLegend: ThemeUIStyleObject = {
    zIndex: [2, 1, 1],
    position: 'absolute',
    width: ['100%', '30rem', '33rem', '33rem'],
    paddingTop: '1.5rem',
    paddingX: '2rem',
  }

  const mapLegendIntro: ThemeUIStyleObject = {
    display: ['none', 'flex', 'flex'],
    flexDirection: 'column',
    marginTop: 9,
    marginBottom: 12,
    fontSize: 'min(max(71%,0.83vw),111.5%)',
  }

  const mapLegendStats: ThemeUIStyleObject = {
    display: ['none', 'none', 'flex'],
    flexDirection: 'column',
    boxSizing: 'border-box',
    marginTop: '1.5rem',
    minWidth: 0,
    width: '22rem',
    backgroundColor: 'white',
    height: 'auto',
    padding: 11,
    fontSize: 'min(max(71%,0.83vw),111.5%)',
  }

  const mapKey: ThemeUIStyleObject = {
    zIndex: 1,
    position: 'absolute',
    flexDirection: ['column', 'column', 'column', 'row'],
    justifyContent: 'space-between',
    marginLeft: ['1rem', '34rem', '42rem', '34rem'],
    paddingTop: ['1.5rem', '3rem', '3rem'],
    fontWeight: 'body',
  }

  const mapKeyItem: ThemeUIStyleObject = {
    alignItems: 'center',
    marginBottom: '1rem',
  }

  const mapKeyItemText: ThemeUIStyleObject = {
    flexDirection: 'row',
    marginRight: '4rem',
    fontSize: ['1rem', '1.3rem', '1.3rem'],
    fontWeight: 'body',
  }

  const millParkModal: ThemeUIStyleObject = {
    boxSizing: 'border-box',
    marginY: ['none', '2.5rem', '3rem'],
    minWidth: 0,
    boxShadow: '0 0 1rem 0.8rem rgba(0,0,0,0.1)',
    backgroundColor: 'background',
    borderRadius: '0.5rem',
  }

  const millParkModalBanner: ThemeUIStyleObject = {
    display: ['none', 'block', 'block'],
    width: '100%',
    height: '10rem',
    backgroundColor: '#ddd',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    borderRadius: '0.5rem 0.5rem 0',
  }

  const millParkModalBannerText: ThemeUIStyleObject = {
    backgroundColor: 'white',
    textTransform: 'uppercase',
    width: ['80%', '50%', '50%', '50%'],
    position: 'relative',
    display: 'block',
    top: '8.2rem',
    padding: '0.3rem 0.3rem 0.2rem 2.6rem',
    fontFamily: '"Open Sans",sans-serif',
    fontWeight: 'bold',
    fontSize: '0.9rem',
    color: 'primary',
    letterSpacing: '0.4rem',
  }

  const millParkModalContent: ThemeUIStyleObject = {
    flexDirection: 'column',
    height: 'auto',
    padding: [
      '0.2rem 2rem 2rem 1.5rem',
      '0.5rem 2.5rem 0.5rem 2.5rem',
      '0.5rem 2.5rem 0.5rem 2.5rem',
    ],
  }

  const millParkModalContentHeader: ThemeUIStyleObject = {
    fontFamily: '"Open Sans",sans-serif',
    fontWeight: 'bold',
    fontSize: ['1.2rem', '1.4rem', '1.4rem'],
    letterSpacing: '0.1rem',
  }

  const millParkModalContentText: ThemeUIStyleObject = {
    marginTop: '1.2rem',
    fontFamily: '"Open Sans",sans-serif',
    lineHeight: '1.6',
  }

  const millParkModalClose: ThemeUIStyleObject = {
    zIndex: [3, 1, 1],
    position: 'absolute',
    left: ['', '25.5rem', '28.5rem'],
    right: ['2.5rem', '', ''],
    top: ['2.2rem', '22rem', '33.2rem'],
    color: 'disabled',
    backgroundColor: 'background',
    border: '0.1rem solid',
    borderColor: 'disabled',
    borderRadius: '2rem',
    paddingX: '0.6rem',
    paddingY: '0.1rem',
    cursor: 'pointer',
  }

  const millParkModalArrow: ThemeUIStyleObject = {
    width: '0px',
    height: '0px',
    borderTop: '1.4rem solid transparent',
    borderBottom: '1.4rem solid transparent',
    borderLeft: '1.4rem solid white',
    position: 'absolute',
    left: ['', '28rem', '30.9rem'],
    top: ['', '33rem', '45rem'],
  }

  const [millParks, setMillParks] = useState(
    millLocations.filter(location => location.type === 'Wind')
  )
  const [activeTab, setActiveTab] = useState('Wind')
  const [selectedMillPark, setSelectedMillPark] = useState({} as MillLocation)

  const millTotals = activeTab === 'Wind' ? data.wind : data.sun

  const breakpoint = useBreakpointIndex()

  const [mapInstance, setMapInstance] = useState<L.Map | null>(null)

  const centerMap: Record<number, BreakpointMapCenter> = useMemo(() => {
    return {
      0: { latLng: new LatLng(55.8, -3.8), zoom: 5 },
      1: { latLng: new LatLng(55.2, -4.3), zoom: 6 },
      2: { latLng: new LatLng(55.2, -5.2), zoom: 6 },
      3: { latLng: new LatLng(55.2, -6.8), zoom: 6 },
      4: { latLng: new LatLng(55.2, -6.8), zoom: 6.25 },
    }
  }, [])

  const initialCenter = centerMap[breakpoint]

  const [lastBreakpoint, setLastBreakpoint] = useState<number>(breakpoint)

  useEffect(() => {
    const center = centerMap[breakpoint]
    if (mapInstance !== null && lastBreakpoint !== breakpoint) {
      mapInstance.invalidateSize()
      mapInstance.setView(center.latLng, center.zoom)
    }
    setLastBreakpoint(breakpoint)
  }, [breakpoint, centerMap, lastBreakpoint, mapInstance])

  useEffect(() => {
    if (mapInstance !== null) {
      L.control
        .zoom({
          position: 'bottomright',
        })
        .addTo(mapInstance)
    }
  }, [mapInstance])

  const MarkerClusterGroup: ClusterGroup = ClusterGroup

  const forceSetMapInstance = (map: L.Map) => {
    const center = centerMap[breakpoint]
    setMapInstance(map)
    map.invalidateSize()
    map.setView(center.latLng, center.zoom)
  }

  const isTabActive = (tab: string) => {
    return tab === activeTab
  }

  const setMillParkFilter = (tab: string) => {
    const millParks = millLocations.filter(
      mill => mill.type === (tab === 'Wind' ? 'Wind' : 'Sun')
    )
    setActiveTab(tab)
    setMillParks(millParks)
  }

  const createClusterCustomIcon = (cluster: MarkerCluster) => {
    const count = cluster.getChildCount()
    return L.divIcon({
      html: `${count}`,
      className: 'cluster-marker',
    })
  }

  const onMarkerClicked = (event: LeafletEvent, millPark: MillLocation) => {
    setSelectedMillPark(millPark)
  }

  const closeMillModal = () => {
    setSelectedMillPark({} as MillLocation)
  }

  const renderParagraph = (para: string) => {
    return para
      .split('\n')
      .filter(Boolean)
      .map((paragraph, i) => (
        <Text key={i} as="p" className="generatedParagraph" variant="paragraph">
          {paragraph}
        </Text>
      ))
  }

  return (
    <Box>
      <Flex sx={{ justifyContent: 'center' }}>
        <Box sx={mapStyle}>
          <Heading as="h2" variant="lrg" sx={mapContainerHeader}>
            {data.intro?.title || 'Our wind and sun mills'}
          </Heading>
          <Flex as="nav">
            <NavLink
              to={'#Wind'}
              onClick={() => setMillParkFilter('Wind')}
              sx={navSize}
              variant="tab"
              className={isTabActive('Wind') ? 'active' : ''}
              style={
                isTabActive('Wind')
                  ? { backgroundColor: '' }
                  : { backgroundColor: '#d4d7d7' }
              }
            >
              Wind parks
            </NavLink>
            <NavLink
              to={'#Sun'}
              onClick={() => setMillParkFilter('Sun')}
              sx={navSize}
              variant="tab"
              className={isTabActive('Sun') ? 'active' : ''}
              style={
                isTabActive('Sun')
                  ? { backgroundColor: '' }
                  : { backgroundColor: '#d4d7d7' }
              }
            >
              Sun parks
            </NavLink>
          </Flex>
          <Box style={{ zIndex: 1, backgroundColor: '#d0d0d0' }}>
            <Flex
              style={{ flexDirection: 'row', justifyContent: 'space-between' }}
            >
              <Box sx={mapLegend}>
                <Flex sx={mapLegendIntro}>
                  {data.intro?.body && (
                    <Stack gap={11}>{renderRichText(data.intro.body)}</Stack>
                  )}
                </Flex>
                <Flex sx={mapLegendStats}>
                  {millTotals?.title && millTotals?.body && (
                    <Stack gap={11}>
                      <Heading as="h2" variant="sml">
                        {millTotals.title}
                      </Heading>
                      {renderRichText(millTotals.body)}
                    </Stack>
                  )}
                </Flex>
                {selectedMillPark && Object.keys(selectedMillPark).length > 0 && (
                  <Flex sx={millParkModal}>
                    <Box
                      sx={millParkModalClose}
                      onClick={() => closeMillModal()}
                    >
                      X
                    </Box>
                    <Box sx={millParkModalArrow} />
                    <Box style={{ display: 'inline' }}>
                      <Box
                        sx={millParkModalBanner}
                        style={{
                          backgroundImage: `url(${selectedMillPark.image})`,
                        }}
                      >
                        <Text sx={millParkModalBannerText}>
                          {activeTab} PARK
                        </Text>
                      </Box>
                      <Flex sx={millParkModalContent}>
                        <Heading
                          as="h3"
                          style={{ display: 'flex', marginTop: '0.8rem' }}
                        >
                          <Text
                            sx={millParkModalContentHeader}
                            style={{ color: '#9ad43a' }}
                          >
                            {selectedMillPark?.markerId}.&nbsp;
                          </Text>
                          <Text sx={millParkModalContentHeader}>
                            {selectedMillPark?.name}
                          </Text>
                        </Heading>
                        <Text sx={millParkModalContentText}>
                          {renderParagraph(selectedMillPark.description)}
                        </Text>
                        <Text
                          style={{ marginBottom: '2rem' }}
                          sx={millParkModalContentText}
                        >
                          {renderRichText(selectedMillPark?.body)}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                )}
              </Box>
              <Flex sx={mapKey}>
                <Flex sx={mapKeyItem}>
                  <Image
                    src={greenDot}
                    width="20rem"
                    style={{ marginRight: '1rem' }}
                  />
                  <Text variant="heading" sx={mapKeyItemText}>
                    Operating
                  </Text>
                </Flex>
                <Flex sx={mapKeyItem}>
                  <Image
                    src={blueDot}
                    width="20rem"
                    style={{ marginRight: '1rem' }}
                  />
                  <Text variant="heading" sx={mapKeyItemText}>
                    Approved
                  </Text>
                </Flex>
                <Flex sx={mapKeyItem}>
                  <Image
                    src={pinkDot}
                    width="20rem"
                    style={{ marginRight: '1rem' }}
                  />
                  <Text variant="heading" sx={mapKeyItemText}>
                    In planning
                  </Text>
                </Flex>
              </Flex>
            </Flex>
            <MapContainer
              center={initialCenter.latLng}
              zoomControl={false}
              doubleClickZoom={false}
              touchZoom={false}
              dragging={true}
              sx={mapContainer}
              zoom={initialCenter.zoom}
              maxZoom={12}
              scrollWheelZoom={false}
              whenCreated={forceSetMapInstance}
            >
              <MarkerClusterGroup
                maxClusterRadius={20}
                showCoverageOnHover={false}
                iconCreateFunction={createClusterCustomIcon}
                spiderLegPolylineOptions={{
                  weight: 0,
                  opacity: 0,
                }}
              >
                {millParks.map((millPark, i) => {
                  return (
                    <CircleMarker
                      center={[millPark.location.lat, millPark.location.lon]}
                      eventHandlers={{
                        click: event => {
                          onMarkerClicked(event, millPark)
                        },
                        mouseover: event => {
                          event.target.setStyle({
                            radius: 12,
                            opacity: 0.5,
                            weight: 8,
                          })
                        },
                        mouseout: event => {
                          event.target.setStyle({
                            radius: 10,
                            opacity: 0.1,
                            weight: 2,
                          })
                        },
                      }}
                      radius={12}
                      color="#FFFFFF"
                      weight={2}
                      opacity={0.1}
                      sx={{ fill: `${millPark.colour}` }}
                      fillOpacity={1}
                      key={`${millPark.status}-${i}`}
                    >
                      <Tooltip permanent direction="center">
                        {millPark.markerId.toString()}
                      </Tooltip>
                    </CircleMarker>
                  )
                })}
              </MarkerClusterGroup>
              <TileLayer
                attribution=""
                className="mapTilePoint"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
              />
            </MapContainer>
          </Box>
        </Box>
      </Flex>
      <Text sx={{ mb: 12, fontSize: 0, textAlign: 'center' }} variant="intro">
        {`*2,700 kWh as per Ofgem's Medium typical domestic consumption value for
        2023.`}
        <br />
        **Based on 186g/kWh (DUKES 2023 (2022 All Fuels Value Table 5.14))
      </Text>
    </Box>
  )
}

export default MillsMap
