import React from 'react'
import { Flex, Heading, Image, Text, ThemeUIStyleObject } from 'theme-ui'
import energyImage from '../../images/green-battery.png'
import transportImage from '../../images/green-plane.png'
import foodImage from '../../images/green-food.png'

interface Props {
  category?: string
  questionNumber?: string | number
  totalQuestions?: string | number
}

const QuestionCategory: React.FC<Props> = ({
  category,
  questionNumber,
  totalQuestions,
}) => {
  const questionNumberSx: ThemeUIStyleObject = {
    color: 'primary',
    textTransform: 'uppercase',
    letterSpacing: '0.5rem',
    marginBottom: '3.33rem',
    marginTop: '0.42rem',
    position: 'relative',
    '&::before': {
      content: '""',
      display: ['none', 'block'],
      position: 'absolute',
      backgroundColor: 'black',
      width: '8.17rem',
      height: '0.17rem',
      left: '-9rem',
      bottom: '0.41rem',
    },
  }

  const headingSx: ThemeUIStyleObject = {
    alignSelf: 'flex-end',
  }

  const imageSx: ThemeUIStyleObject = {
    marginRight: '1.25rem',
  }

  let image = energyImage
  switch (category.toLocaleLowerCase()) {
    case 'transport':
      image = transportImage
      break
    case 'food':
      image = foodImage
      break
  }

  return (
    <>
      <Flex>
        <Image sx={imageSx} src={image} />
        <Heading sx={headingSx} variant="med">
          {category}
        </Heading>
      </Flex>
      <Flex>
        <Text sx={questionNumberSx} as="p">
          <b>Question {questionNumber}</b> of {totalQuestions}
        </Text>
      </Flex>
    </>
  )
}

export default QuestionCategory
