import React from 'react'
import { Box, Image, ThemeUIStyleObject, Text, Link } from 'theme-ui'
import flagSlice from '../../images/flag-slice.png'

const CarbonCalculatorFooter: React.FC = () => {
  const flagSliceFooterSx: ThemeUIStyleObject = {
    width: '100%',
  }

  const wrapperSx: ThemeUIStyleObject = {
    position: 'relative',
  }

  const textSx: ThemeUIStyleObject = {
    position: 'absolute',
    top: '-2.5rem',
    left: '1.66rem',
  }

  const linkSx: ThemeUIStyleObject = {
    marginX: '1.25rem',
    textDecoration: 'none',
    color: '#333',
    fontWeight: '400',
    opacity: 0.6,
  }

  return (
    <Box sx={wrapperSx}>
      <Text sx={textSx} as="p">
        <Link sx={linkSx} href="/the-legal-stuff/privacy-policy">
          Privacy Policy
        </Link>{' '}
        <Link sx={linkSx} href="/the-legal-stuff/website-terms-and-conditions">
          Terms &amp; Conditions
        </Link>
      </Text>
      <Image sx={flagSliceFooterSx} src={flagSlice} />
    </Box>
  )
}

export default CarbonCalculatorFooter
