import React, { useState } from 'react'
import { ThemeUIStyleObject, Text, IconButton, Grid } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'

import Modal from './modal'
import InfoIcon from '../assets/info-icon.svg'
import { renderRichText, Document } from '../rich-text'

/**
 * Card component containing live generation visualisations.
 */

interface ImpactCardsProps {
  items: Queries.ContentfulGeneralContent[]
}

type ImpactCardProps = Queries.ContentfulGeneralContent

const cardStyles: ThemeUIStyleObject = {
  background:
    'linear-gradient(113deg, rgba(154, 212, 58, 0.05) 0%, rgba(154, 212, 58, 0.25) 100.01%)',
  borderRadius: '28px',
  boxShadow: '0 4px 4px rgba(0, 0, 0, 0.25)',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  color: 'rgba(21, 21, 21, 1)',
  textAlign: 'center',
  justifyContent: 'flex-start',
  padding: '63px 16px',
  margin: '24px auto',
  alignItems: 'center',
  maxWidth: ['440px', null, null, '500px'],
}

const imageStyles: ThemeUIStyleObject = {
  height: '100px',
  width: '160px',
  marginBottom: '16px',
  objectFit: 'contain',
  objectPosition: 'bottom',
  svg: {
    height: '100%',
    width: '100%',
  },
}

const ImpactCard = ({
  title,
  description,
  context,
  assets,
}: ImpactCardProps) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <div key={title} sx={cardStyles}>
      {context && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
          onClick={() => setShowModal(true)}
        >
          <InfoIcon />
        </IconButton>
      )}
      {assets[0]?.svg ? (
        <div
          sx={imageStyles}
          dangerouslySetInnerHTML={{ __html: assets[0].svg.content }}
        />
      ) : (
        <GatsbyImage
          sx={imageStyles}
          imgStyle={imageStyles}
          image={assets[0]?.gatsbyImageData}
          alt={assets[0]?.description}
        />
      )}
      <Text
        sx={{ marginBottom: '16px', fontSize: '32px' }}
        as="h3"
        variant="med"
      >
        {title}
      </Text>
      <div>{description ? renderRichText(description as Document) : null}</div>
      {context && (
        <Modal onClose={() => setShowModal(false)} isOpen={showModal}>
          {renderRichText(context)}
        </Modal>
      )}
    </div>
  )
}

const ImpactCards: React.FC<ImpactCardsProps> = ({
  items,
}: ImpactCardsProps) => {
  return (
    <Grid columns={[1, null, 2]}>
      {items.map(item => (
        <ImpactCard key={item.title} {...item} />
      ))}
    </Grid>
  )
}

export default ImpactCards
