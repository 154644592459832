import React from 'react'
import { ThemeUIStyleObject, useThemeUI } from 'theme-ui'
import { Inline, Stack } from 'raam'
import { useStaticQuery, graphql } from 'gatsby'
import { formatContentfulIdxToLink, formatPageLink } from '../utils/helpers'
import NavLink from './nav-link'
import { ReactNodeArray } from 'react'
import { fluidScale } from '../utils/mixins'
import { ContentfulIdx, Topic } from '../utils/types'

interface Props {
  vertical?: boolean
}

const HeaderNav: React.FC<Props> = ({ vertical, ...other }) => {
  const { theme } = useThemeUI()

  const data = useStaticQuery<GatsbyTypes.PrimaryNavigationQuery>(graphql`
    query PrimaryNavigation {
      contentfulPageCollection(group: { eq: "Primary" }) {
        pages {
          ...PageLink
        }
      }
    }
  `)

  const primary: ContentfulIdx[] = data.contentfulPageCollection?.pages

  const link: ThemeUIStyleObject = {
    '&:hover': {
      color: ['background', null, 'contrast'],
    },
  }

  const links: ReactNodeArray | undefined = primary?.map((page, i) => {
    const formattedPageLink = formatContentfulIdxToLink(page)
    return (
      <NavLink key={i} sx={link} to={formattedPageLink}>
        {page?.title}
      </NavLink>
    )
  })

  const List = vertical ? Stack : Inline

  const flexGap = vertical
    ? 15
    : fluidScale(14, 0.2, 2.4)(theme) + ' !important'

  const style = {
    overflow: 'hidden',
  }

  const styleFallback = {
    li: { marginLeft: vertical ? 0 : 11 },
    'li:first-of-type': { marginLeft: 0 },
  }

  return (
    <List as="ul" sx={{ ...styleFallback, ...style }} gap={flexGap} {...other}>
      {links}
    </List>
  )
}

export default HeaderNav
