import React from 'react'
import { Box, Heading, Grid, Text, Link } from 'theme-ui'
import { useForm } from 'react-hook-form'
import { SymmetricValidatingInput } from './inputs/validating-input'
import Checkbox from './inputs/checkbox'
import {
  nonEmptyString,
  validEmail,
  validPhoneNumber,
} from './utils/validators'
import AddressPicker, { AddressPickerFormData } from './inputs/address-picker'
import { BusinessEnergyForm } from './utils/form-types'
import WebForm from './webform'
import { heading, fieldset, paragraph } from './utils/styles'

const completed = (): JSX.Element => (
  <Box sx={fieldset}>
    <Heading sx={heading}>Thank you</Heading>
    <Text as="p" sx={paragraph}>
      {`We've received your request for a quote and we'll be in touch with some
      advice about getting started with green energy.`}
    </Text>
    <Text as="p" sx={paragraph}>
      {`We'll aim to have your quote ready within the next two working days.`}
    </Text>
    <Text as="p" sx={paragraph}>
      If you need to get in touch with us in the meantime you can call us on{' '}
      <Link href="tel:03456001994">0345 600 1994</Link> or email us at{' '}
      <Link href="mailto:business.sales@ecotricity.co.uk">
        business.sales@ecotricity.co.uk
      </Link>
      .
    </Text>
    <Text as="p" sx={{ fontWeight: 'bold' }}>
      Best wishes,
    </Text>
    <Text as="p" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
      The Ecotricity Business Team
    </Text>
    <Text as="p">
      {`We're open from 8.30am - 5.00pm Monday to Thursday and 8:30 - 4:00pm
      Fridays (excluding bank holidays).`}
    </Text>
  </Box>
)

type BusinessFormData = {
  readonly firstName: string
  readonly lastName: string
  readonly businessName: string
  readonly email: string
  readonly contactNumber: string
  readonly keepUp: string
  readonly address: AddressPickerFormData
}

const reformatBusinessQuote = ({
  firstName,
  lastName,
  businessName,
  contactNumber,
  email,
  keepUp,
  address: { line1, line2, line3, postcode, town, county, udprn },
}: BusinessFormData): BusinessEnergyForm => ({
  webform: {
    type: 'quoteBusiness',
    formData: {
      firstNames: firstName,
      lastNames: lastName,
      businessName,
      address: {
        address1: line1,
        address2: line2,
        address3: line3,
        town,
        county,
        postcode,
        udprn,
      },
      emailAddress: email,
      phoneNumber: contactNumber,
      marketingOptIn: Boolean(keepUp),
    },
  },
})

const BusinessEnergy = (): JSX.Element => {
  const formFns = useForm<BusinessFormData>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { register } = formFns

  const requiredField = (label: string) =>
    register({ required: `Please enter your ${label.toLowerCase()}` })

  return (
    <WebForm<BusinessEnergyForm, BusinessFormData>
      reformat={reformatBusinessQuote}
      completeText={completed()}
      {...formFns}
    >
      <Grid columns={[1, '1fr 1fr']} gap="10">
        <SymmetricValidatingInput
          {...formFns}
          deepName={['firstName'] as const}
          handleRef={requiredField}
          label="First name"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['lastName'] as const}
          handleRef={requiredField}
          label="Last name"
        />
      </Grid>
      <SymmetricValidatingInput
        {...formFns}
        deepName={['businessName'] as const}
        handleRef={requiredField}
        label="Business name"
      />
      <AddressPicker {...formFns} addressName="address" />
      <SymmetricValidatingInput
        {...formFns}
        deepName={['email'] as const}
        handleRef={(label: string) =>
          register({
            validate: {
              nonEmptyString: nonEmptyString(label),
              validEmail: validEmail(label),
            },
          })
        }
        label="Email address"
        type="email"
      />
      <SymmetricValidatingInput
        {...formFns}
        deepName={['contactNumber'] as const}
        handleRef={(label: string) =>
          register({
            validate: {
              nonEmptyString: nonEmptyString(label),
              validPhoneNumber: validPhoneNumber(label),
            },
          })
        }
        label="Contact phone number"
        type="tel"
      />
      <Heading sx={heading}>Keep updated</Heading>
      <Checkbox
        {...formFns}
        label={`Please tick this box if you'd like to be kept informed about what
      we are doing at Ecotricity Group. Don't worry we won't ever
      pass your details to anyone else, unless required to do so by law`}
        name="keepUp"
      />
    </WebForm>
  )
}

export default BusinessEnergy
