import React from 'react'
import { Box, Text, ThemeUIStyleObject } from 'theme-ui'
import QuoteWidget from './quote-widget'

interface Props {
  heading?: string
}

const JoinUs: React.FC<Props> = ({ heading }) => {
  const quote: ThemeUIStyleObject = {
    fontSize: 5,
    fontWeight: 'bold',
    fontStyle: 'italic',
    lineHeight: 'heading',
    textAlign: 'center',
    maxWidth: '60rem',
    marginX: 'auto',
    marginY: 16,
  }

  const widget: ThemeUIStyleObject = {
    maxWidth: '35rem',
    marginX: 'auto',
    textAlign: 'center',
  }

  return (
    <Box>
      <Text as="blockquote" sx={quote}>
        {heading}
      </Text>
      <QuoteWidget sx={widget} />
    </Box>
  )
}

export default JoinUs
