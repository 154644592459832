import React, { ReactElement } from 'react'
import { Box } from '@theme-ui/components'

const SVG = ({ size = 24, ...props }: { size: number }): ReactElement => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    width={size + ''}
    height={size + ''}
    viewBox="0 0 30 30"
    fill="currentcolor"
    {...props}
  />
)

SVG.displayName = 'SVG'

export default SVG
