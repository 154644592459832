import React from 'react'
import { ThemeUIStyleObject, Image, Text, Flex, Heading } from 'theme-ui'
import PageLink from './page-link'

interface Props {
  title?: string
  maxLinkCount?: number
  links?: Array<{
    slug?: string
    name?: string
    icon?: string
  }>
}

const button: ThemeUIStyleObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: ['10rem', '11.5rem'],
  height: '7.5rem',
  padding: '1.2rem',
  margin: '0.5rem',
}

const QuickLinks: React.FC<Props> = ({
  title,
  maxLinkCount = null,
  links = [],
}) => {
  maxLinkCount = maxLinkCount ?? links.length
  links = links.slice(0, maxLinkCount)
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        // TODO: remove when search is implemented
        // alignItems: links.length > 3 ? 'center' : 'left',
        // maxWidth: links.length > 3 ? '' : '40rem',
      }}
    >
      <Heading
        sx={{
          textAlign: 'center',
          paddingBottom: links.length > 3 ? '2rem' : '1rem',
        }}
        as="h3"
        variant={links.length > 3 ? 'med' : 'sml'}
      >
        {title}
      </Heading>
      <Flex
        sx={{
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
          maxWidth: '50rem',
        }}
      >
        {links.map((link, i) => (
          <PageLink
            to={`/support/${link.slug}`}
            variant="buttonSecondary"
            sx={button}
            key={i}
          >
            <Image src={link.icon} sx={{ width: '5.5rem', height: '3rem' }} />
            <Text variant="caption">{link.name}</Text>
          </PageLink>
        ))}
      </Flex>
    </Flex>
  )
}

export default QuickLinks
