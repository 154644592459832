import React, { useState } from 'react'

import { ThemeUIStyleObject } from 'theme-ui'
import { Flex, Heading, Link, Box } from '@theme-ui/components'

import QuestionCategory from './question-category'
import RadioButtonList from './radio-button-list'
import MultiChoiceList from './multi-choice-list'

import Category from '../../components/carbon-calculator/categories'
import HalfPageQuote from '../../components/half-page-quote'
import Layout from '../../components/layout'
import Section from '../../components/section'
import SEO from '../../components/seo'

import {
  getCategoryProgressData,
  getRedirectUrl,
  getStoredCategory,
  getStoredData,
  getStoredValue,
} from '../../utils/carbon-calculator'

import arrowLeft from '../../images/arrow-left.svg'
import arrowRight from '../../images/arrow-right.svg'
import CarbonCalculatorCurlSpray from '../../images/carbon-calculator-curl-spray.png'
import CarbonCalculatorSpray from '../../images/carbon-calculator-spray.png'
import CarbonCalculatorSprayGrey from '../../images/carbon-calculator-spray-grey.png'

import { StepType, ValidationType } from '../../utils/carbon-calculator-types'

type PagePropsType = {
  pageContext: {
    category: string
    step: StepType
    validation: ValidationType
    stepValidation: ValidationType
    nextStep: boolean | string
    previousStep: boolean | string
    currentStep: number
    isLastStep: boolean
    totalSteps: number
  }
}

const wrapperSx: ThemeUIStyleObject = {
  flexWrap: 'wrap',
  '&:after': {
    content: ['""', '""', `url(${CarbonCalculatorCurlSpray})`],
    position: 'absolute',
    bottom: '2.5%',
    left: 0,
    mixBlendMode: 'multiply',
  },
}

const defaultHalfPageSectionSx: ThemeUIStyleObject = {
  padding: ['4.16rem 12%', '4.16rem 12%', '8.33rem 5% 22rem 17%'],
  '&:before': {
    content: '""',
    backgroundImage: ['', '', `url(${CarbonCalculatorSpray})`],
    width: '18.33rem',
    height: '26.67rem',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '15%',
    left: 0,
    mixBlendMode: 'multiply',
    zIndex: '-1',
  },
  '&:after': {
    content: '""',
    backgroundImage: ['', '', `url(${CarbonCalculatorSprayGrey})`],
    width: '22.92rem',
    height: '29.17rem',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: '5%',
    right: 0,
    mixBlendMode: 'multiply',
    zIndex: '-1',
  },
}

const greenHeading: ThemeUIStyleObject = {
  color: 'contrast',
}

const buttonSx: ThemeUIStyleObject = {
  display: 'inline-block',
  width: ['100%', '100%', '80%'],
  position: 'relative',
  cursor: 'pointer',
}

const prevSx: ThemeUIStyleObject = {
  ...buttonSx,
  '&:before': {
    content: `url(${arrowLeft})`,
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '1.67rem',
  },
  marginTop: '0.83rem',
}

const nextSx: ThemeUIStyleObject = {
  ...buttonSx,
  '&:after': {
    content: `url(${arrowRight})`,
    display: 'inline-block',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '1.67rem',
  },
}

const formSx: ThemeUIStyleObject = {
  minHeight: '25rem',
}

const CarbonCalculatorQuestionPage: React.FC<PagePropsType> = ({
  pageContext: {
    category = '',
    currentStep,
    isLastStep = false,
    nextStep = false,
    previousStep = false,
    step,
    validation,
    stepValidation,
    totalSteps,
  },
}) => {
  const ccData = getStoredData()
  const redirectUrl = getRedirectUrl(stepValidation, ccData)
  const ccDataCategory = getStoredCategory(ccData, category)
  const storedValue = getStoredValue(ccDataCategory, step.question.value)

  const categoryProgressData = getCategoryProgressData(validation, ccData)

  const [state, setState] = useState({
    ccData,
    value: storedValue,
  })

  if (redirectUrl && typeof window !== 'undefined') {
    window.location.href = redirectUrl
    return <></>
  }

  const nextButtonText = isLastStep
    ? 'Find out your footprint'
    : 'Next question'

  const halfPageSectionSx =
    step.rhsCaption || step.rhsText
      ? {
          ...defaultHalfPageSectionSx,
          boxShadow: ['none', '-0.33rem 0 0.33rem rgba(0, 0, 0, 0.25)'],
          position: 'relative',
        }
      : {
          ...defaultHalfPageSectionSx,
          marginLeft: 0,
        }

  const isValid = (value: string | null, step: StepType) =>
    step.question.multipleChoice ||
    !!step.question.answers.find(
      answer => value !== null && `${value}` === `${answer.value}`
    )

  const onChange = (event: MouseEvent, multiChoice = false) => {
    let value

    if (multiChoice) {
      const existingValues = {
        ...(state.value || ccDataCategory.values[step.question.value] || {}),
      }

      Object.assign(existingValues, {
        [event.target.value]: event.target.checked,
      })

      value = existingValues
      ccDataCategory.values[step.question.value] = existingValues
    } else {
      value = event.target.value
      ccDataCategory.values[step.question.value] = value
    }

    setState({
      ccData,
      value,
    })
  }

  const onSubmit = (event: Event, step) => {
    if (
      step.question.multipleChoice &&
      ccDataCategory.values[step.question.value] === null
    ) {
      ccDataCategory.values[step.question.value] = {}
      setState({
        ccData,
        value: {},
      })
    }

    if (isValid(state.value, step)) {
      localStorage.setItem('ccData', JSON.stringify(state.ccData))
    }

    window.location.href = nextStep
    event.preventDefault()
  }

  return (
    <Layout simple={true}>
      <SEO
        title={`Carbon Footprint Calculator | ${
          category.charAt(0).toUpperCase() + category.slice(1)
        } | ${step.metadata.title}`}
        description={step.metadata.description}
        titleTemplate={'%s'}
      />
      <Category categories={categoryProgressData} />
      <Flex sx={wrapperSx}>
        <Section style={halfPageSectionSx} variant="halfPage">
          <QuestionCategory
            category={category}
            questionNumber={currentStep}
            totalQuestions={totalSteps}
          />
          <Heading variant="lrg">{step.question.title}</Heading>
          <Heading sx={greenHeading} variant="lrg">
            {step.question.hint}
          </Heading>
          <Box as="form" sx={formSx}>
            {state && state.ccData ? (
              <>
                {step.question.multipleChoice ? (
                  <MultiChoiceList
                    name={step.question.value}
                    options={step.question.answers}
                    onChange={onChange}
                    value={state.value}
                  />
                ) : (
                  <RadioButtonList
                    name={step.question.value}
                    columns={step.question.columns}
                    options={step.question.answers}
                    onChange={onChange}
                    value={state.value}
                  />
                )}
                <Link
                  variant={
                    step.question.multipleChoice || state.value
                      ? 'button'
                      : 'buttonDisabled'
                  }
                  sx={nextSx}
                  onClick={
                    step.question.multipleChoice || state.value
                      ? event => onSubmit(event, step)
                      : undefined
                  }
                >
                  {nextButtonText}
                </Link>
                {previousStep ? (
                  <Link
                    variant="buttonSecondary"
                    sx={prevSx}
                    href={previousStep}
                  >
                    Previous question
                  </Link>
                ) : null}
              </>
            ) : null}
          </Box>
        </Section>
        {step.rhsCaption || step.rhsText ? (
          <HalfPageQuote quoteTitle={step.rhsCaption} quote={step.rhsText} />
        ) : null}
      </Flex>
    </Layout>
  )
}

export default CarbonCalculatorQuestionPage
