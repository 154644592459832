import React, { useState } from 'react'
import {
  Text,
  Box,
  Flex,
  IconButton,
  ThemeUIStyleObject,
  Button,
} from 'theme-ui'
import { useResponsiveValue } from '@theme-ui/match-media'
import NavLink from './nav-link'
import Modal from '../components/modal'
import HorizontalBarChart from '../components/horizontal-bar-chart'
import InfoIcon from '../assets/info-icon.svg'
import { renderRichText, Document } from '../rich-text'

enum DataVizId {
  WindmillProgress = 'windmill-progress',
  SpendChart = 'spend-data',
}

interface TableData {
  title: string
  tableData: Array<string[]>
  footer: string[]
}

interface Props {
  title: string
  context?: Document
  vizId?: DataVizId
  extraProps?: { [key: string]: any }
  tableData?: TableData
}

const GenerationChart: React.FC<Props> = ({ title, tableData, context }) => {
  const [showModal, setShowModal] = useState(false)
  const tickIncrement = useResponsiveValue([50, 25], { defaultIndex: 1 })

  const spendChartData = tableData?.data?.tableData
    .slice(1)
    .map(([year, value]) => ({
      label: year,
      value: Math.round(Number(value) / 1000),
    }))

  const words = title.split(' ')
  const firstWords = words.slice(0, -1).join(' ')
  const lastWord = words.pop()

  return (
    <>
      <Box sx={{ textAlign: 'center', mt: 5, px: 3, width: '80%' }}>
        <Text
          as="h3"
          sx={{
            mb: '32px',
            fontSize: 2,
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {firstWords}{' '}
          <span
            sx={{
              whitespace: 'nowrap',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {lastWord}
            {context && (
              <IconButton
                onClick={() => setShowModal(true)}
                sx={{
                  marginLeft: '4px',
                }}
              >
                <InfoIcon />
              </IconButton>
            )}
          </span>
        </Text>

        <Box>
          <HorizontalBarChart
            data={spendChartData}
            max={150}
            tickIncrement={tickIncrement}
            units="GWh"
          />
        </Box>
      </Box>
      {tableData?.footer && (
        <>
          <hr
            sx={{
              my: 12,
              color: '#FFFFFF',
              width: '80%',
              border: '2px solid #FFFFFF',
              opacity: 1,
            }}
          />
          <Text
            as="p"
            sx={{
              fontSize: 2,
              fontWeight: 'bold',
              fontFamily: 'heading',
              my: 8,
              px: 8,
              textAlign: 'center',
            }}
          >
            {tableData.footer}
          </Text>
        </>
      )}
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        {context ? renderRichText(context) : null}
      </Modal>
    </>
  )
}
const progressBar: ThemeUIStyleObject = {
  justifyContent: 'center',
  border: '4px solid #000000',
  borderRadius: '10px',
  padding: '4px',
  width: ['287px', '548px', '548px'],
  flex: 1,
  backgroundColor: '#FFFFFF',
}
const progressBlocks = (
  index: number,
  filledBlocks: number,
  totalBlocks: number
): ThemeUIStyleObject => ({
  width: ['9.75px', '22.8px', '22.8px'],
  height: '41px',
  backgroundColor: index < filledBlocks ? 'primary' : '#FFFFFF',
  borderRadius: '2px',
  marginRight: index < totalBlocks - 1 ? '4px' : 0,
})

const WindmillProgress: React.FC<Props> = ({ title, extraProps, context }) => {
  const [showModal, setShowModal] = useState(false)
  const currentProgress = JSON.parse(extraProps?.internal.content).progress
  const totalBlocks = 20
  const filledBlocks = Math.round((40 / 100) * totalBlocks)

  const [windmillsNeeded, ...titleString] = title.split(' ')
  const middle = titleString.slice(0, -1).join(' ')
  const lastWord = titleString.pop()

  return (
    <Flex
      sx={{
        padding: '64px',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 4,
        flexDirection: 'column',
        background: 'none',
      }}
    >
      <Text
        sx={{
          fontWeight: 'bold',
          fontSize: 2.5,
          background: 'none',
          textAlign: 'center',
        }}
      >
        <span sx={{ fontSize: 4, fontWeight: 'bold' }}>{windmillsNeeded}</span>{' '}
        {middle}{' '}
        <span
          sx={{
            whitespace: 'nowrap',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {lastWord}
          {context && (
            <IconButton
              onClick={() => setShowModal(true)}
              sx={{
                marginLeft: '4px',
              }}
            >
              <InfoIcon />
            </IconButton>
          )}
        </span>
      </Text>

      <Box
        sx={{
          mt: 8,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Text
          sx={{
            fontWeight: 'bold',
            fontSize: 1.5,
            flex: 1,
            textAlign: 'left',
            lineHeight: 1.5,
            background: 'none',
          }}
        >
          {currentProgress}% renewable energy...
        </Text>
        <Flex sx={progressBar}>
          {[...Array(totalBlocks)].map((_, index) => (
            <Box
              key={index}
              sx={progressBlocks(index, filledBlocks, totalBlocks)}
            />
          ))}
        </Flex>{' '}
      </Box>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        {context ? renderRichText(context) : null}
      </Modal>
    </Flex>
  )
}

const BillsToMills: React.FC<Queries.ContentfulGeneralContent> = ({
  items,
  ctaUrl,
  ctaText,
}) => {
  return (
    <>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <GenerationChart {...items[0]} />
        <hr
          sx={{
            my: 12,
            color: '#FFFFFF',
            width: '80%',
            border: '2px solid #FFFFFF',
            opacity: 1,
          }}
        />
        <WindmillProgress {...items[1]} />
        <NavLink
          to={ctaUrl}
          variant="button"
          sx={{ mt: 12, width: ['80%', '25%'] }}
        >
          {ctaText}
        </NavLink>
      </div>
    </>
  )
}

export default BillsToMills
