const heatMatrix = {
  //heating carbon neutralised?
  yes: 0,
  no: {
    //Heating fuel
    gas: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 1932,
          semiDetached: 1419,
          endTerraced: 1317,
          midTerraced: 1200,
          bungalow: 1943,
          convertedFlat: 1677,
          purposeBuiltFlat: 1043,
        },
        '2': {
          //property types
          detached: 2636,
          semiDetached: 2123,
          endTerraced: 2021,
          midTerraced: 1904,
          bungalow: 2647,
          convertedFlat: 2381,
          purposeBuiltFlat: 1747,
        },
        '3': {
          //property types
          detached: 3340,
          semiDetached: 2827,
          endTerraced: 2725,
          midTerraced: 2608,
          bungalow: 3350,
          convertedFlat: 3084,
          purposeBuiltFlat: 2451,
        },
        '4': {
          //property types
          detached: 4044,
          semiDetached: 3531,
          endTerraced: 3429,
          midTerraced: 3312,
          bungalow: 4054,
          convertedFlat: 3788,
          purposeBuiltFlat: 3155,
        },
        '>4': {
          //property types
          detached: 5451,
          semiDetached: 4939,
          endTerraced: 4837,
          midTerraced: 4720,
          bungalow: 5462,
          convertedFlat: 5196,
          purposeBuiltFlat: 4563,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 966,
          semiDetached: 710,
          endTerraced: 659,
          midTerraced: 600,
          bungalow: 971,
          convertedFlat: 838,
          purposeBuiltFlat: 522,
        },
        '2': {
          //property types
          detached: 1318,
          semiDetached: 1061,
          endTerraced: 1011,
          midTerraced: 952,
          bungalow: 1323,
          convertedFlat: 1190,
          purposeBuiltFlat: 874,
        },
        '3': {
          //property types
          detached: 1670,
          semiDetached: 1413,
          endTerraced: 1363,
          midTerraced: 1304,
          bungalow: 1675,
          convertedFlat: 1542,
          purposeBuiltFlat: 1226,
        },
        '4': {
          //property types
          detached: 2022,
          semiDetached: 1765,
          endTerraced: 1715,
          midTerraced: 1656,
          bungalow: 2027,
          convertedFlat: 1894,
          purposeBuiltFlat: 1578,
        },
        '>4': {
          //property types
          detached: 2726,
          semiDetached: 2469,
          endTerraced: 2418,
          midTerraced: 2360,
          bungalow: 2731,
          convertedFlat: 2598,
          purposeBuiltFlat: 2281,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 644,
          semiDetached: 473,
          endTerraced: 439,
          midTerraced: 400,
          bungalow: 648,
          convertedFlat: 559,
          purposeBuiltFlat: 348,
        },
        '2': {
          //property types
          detached: 879,
          semiDetached: 708,
          endTerraced: 674,
          midTerraced: 635,
          bungalow: 882,
          convertedFlat: 794,
          purposeBuiltFlat: 582,
        },
        '3': {
          //property types
          detached: 1113,
          semiDetached: 942,
          endTerraced: 908,
          midTerraced: 869,
          bungalow: 1117,
          convertedFlat: 1028,
          purposeBuiltFlat: 817,
        },
        '4': {
          //property types
          detached: 1348,
          semiDetached: 1177,
          endTerraced: 1143,
          midTerraced: 1104,
          bungalow: 1351,
          convertedFlat: 1263,
          purposeBuiltFlat: 1052,
        },
        '>4': {
          //property types
          detached: 1817,
          semiDetached: 1646,
          endTerraced: 1612,
          midTerraced: 1573,
          bungalow: 1821,
          convertedFlat: 1732,
          purposeBuiltFlat: 1521,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 483,
          semiDetached: 355,
          endTerraced: 329,
          midTerraced: 300,
          bungalow: 486,
          convertedFlat: 419,
          purposeBuiltFlat: 261,
        },
        '2': {
          //property types
          detached: 659,
          semiDetached: 531,
          endTerraced: 505,
          midTerraced: 476,
          bungalow: 662,
          convertedFlat: 595,
          purposeBuiltFlat: 437,
        },
        '3': {
          //property types
          detached: 835,
          semiDetached: 707,
          endTerraced: 681,
          midTerraced: 652,
          bungalow: 838,
          convertedFlat: 771,
          purposeBuiltFlat: 613,
        },
        '4': {
          //property types
          detached: 1011,
          semiDetached: 883,
          endTerraced: 857,
          midTerraced: 828,
          bungalow: 1014,
          convertedFlat: 947,
          purposeBuiltFlat: 789,
        },
        '>4': {
          //property types
          detached: 1363,
          semiDetached: 1235,
          endTerraced: 1209,
          midTerraced: 1180,
          bungalow: 1366,
          convertedFlat: 1299,
          purposeBuiltFlat: 1141,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 386,
          semiDetached: 284,
          endTerraced: 263,
          midTerraced: 240,
          bungalow: 389,
          convertedFlat: 335,
          purposeBuiltFlat: 209,
        },
        '2': {
          //property types
          detached: 527,
          semiDetached: 425,
          endTerraced: 404,
          midTerraced: 381,
          bungalow: 529,
          convertedFlat: 476,
          purposeBuiltFlat: 439,
        },
        '3': {
          //property types
          detached: 668,
          semiDetached: 565,
          endTerraced: 545,
          midTerraced: 522,
          bungalow: 670,
          convertedFlat: 617,
          purposeBuiltFlat: 490,
        },
        '4': {
          //property types
          detached: 809,
          semiDetached: 706,
          endTerraced: 686,
          midTerraced: 662,
          bungalow: 811,
          convertedFlat: 758,
          purposeBuiltFlat: 631,
        },
        '>4': {
          //property types
          detached: 1090,
          semiDetached: 988,
          endTerraced: 967,
          midTerraced: 944,
          bungalow: 1092,
          convertedFlat: 1039,
          purposeBuiltFlat: 913,
        },
      },
    },
    wood: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 152,
          semiDetached: 112,
          endTerraced: 104,
          midTerraced: 95,
          bungalow: 153,
          convertedFlat: 132,
          purposeBuiltFlat: 82,
        },
        '2': {
          //property types
          detached: 208,
          semiDetached: 167,
          endTerraced: 159,
          midTerraced: 150,
          bungalow: 209,
          convertedFlat: 188,
          purposeBuiltFlat: 138,
        },
        '3': {
          //property types
          detached: 263,
          semiDetached: 223,
          endTerraced: 215,
          midTerraced: 206,
          bungalow: 264,
          convertedFlat: 243,
          purposeBuiltFlat: 193,
        },
        '4': {
          //property types
          detached: 319,
          semiDetached: 278,
          endTerraced: 270,
          midTerraced: 261,
          bungalow: 320,
          convertedFlat: 299,
          purposeBuiltFlat: 249,
        },
        '>4': {
          //property types
          detached: 430,
          semiDetached: 389,
          endTerraced: 381,
          midTerraced: 372,
          bungalow: 431,
          convertedFlat: 410,
          purposeBuiltFlat: 360,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 76,
          semiDetached: 56,
          endTerraced: 52,
          midTerraced: 47,
          bungalow: 77,
          convertedFlat: 66,
          purposeBuiltFlat: 41,
        },
        '2': {
          //property types
          detached: 104,
          semiDetached: 84,
          endTerraced: 80,
          midTerraced: 75,
          bungalow: 104,
          convertedFlat: 94,
          purposeBuiltFlat: 69,
        },
        '3': {
          //property types
          detached: 132,
          semiDetached: 111,
          endTerraced: 107,
          midTerraced: 103,
          bungalow: 132,
          convertedFlat: 122,
          purposeBuiltFlat: 97,
        },
        '4': {
          //property types
          detached: 159,
          semiDetached: 139,
          endTerraced: 135,
          midTerraced: 131,
          bungalow: 160,
          convertedFlat: 149,
          purposeBuiltFlat: 124,
        },
        '>4': {
          //property types
          detached: 215,
          semiDetached: 195,
          endTerraced: 191,
          midTerraced: 186,
          bungalow: 215,
          convertedFlat: 205,
          purposeBuiltFlat: 180,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 51,
          semiDetached: 37,
          endTerraced: 35,
          midTerraced: 32,
          bungalow: 51,
          convertedFlat: 44,
          purposeBuiltFlat: 27,
        },
        '2': {
          //property types
          detached: 69,
          semiDetached: 56,
          endTerraced: 53,
          midTerraced: 50,
          bungalow: 70,
          convertedFlat: 63,
          purposeBuiltFlat: 46,
        },
        '3': {
          //property types
          detached: 88,
          semiDetached: 74,
          endTerraced: 72,
          midTerraced: 69,
          bungalow: 88,
          convertedFlat: 81,
          purposeBuiltFlat: 64,
        },
        '4': {
          //property types
          detached: 106,
          semiDetached: 93,
          endTerraced: 90,
          midTerraced: 87,
          bungalow: 107,
          convertedFlat: 100,
          purposeBuiltFlat: 83,
        },
        '>4': {
          //property types
          detached: 143,
          semiDetached: 130,
          endTerraced: 127,
          midTerraced: 124,
          bungalow: 144,
          convertedFlat: 137,
          purposeBuiltFlat: 120,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 38,
          semiDetached: 28,
          endTerraced: 26,
          midTerraced: 24,
          bungalow: 38,
          convertedFlat: 33,
          purposeBuiltFlat: 21,
        },
        '2': {
          //property types
          detached: 52,
          semiDetached: 42,
          endTerraced: 40,
          midTerraced: 38,
          bungalow: 52,
          convertedFlat: 47,
          purposeBuiltFlat: 34,
        },
        '3': {
          //property types
          detached: 66,
          semiDetached: 56,
          endTerraced: 54,
          midTerraced: 51,
          bungalow: 66,
          convertedFlat: 61,
          purposeBuiltFlat: 48,
        },
        '4': {
          //property types
          detached: 80,
          semiDetached: 70,
          endTerraced: 68,
          midTerraced: 65,
          bungalow: 80,
          convertedFlat: 75,
          purposeBuiltFlat: 62,
        },
        '>4': {
          //property types
          detached: 107,
          semiDetached: 97,
          endTerraced: 95,
          midTerraced: 93,
          bungalow: 108,
          convertedFlat: 102,
          purposeBuiltFlat: 90,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 30,
          semiDetached: 22,
          endTerraced: 21,
          midTerraced: 19,
          bungalow: 31,
          convertedFlat: 26,
          purposeBuiltFlat: 16,
        },
        '2': {
          //property types
          detached: 42,
          semiDetached: 33,
          endTerraced: 32,
          midTerraced: 30,
          bungalow: 42,
          convertedFlat: 38,
          purposeBuiltFlat: 28,
        },
        '3': {
          //property types
          detached: 53,
          semiDetached: 45,
          endTerraced: 43,
          midTerraced: 41,
          bungalow: 53,
          convertedFlat: 49,
          purposeBuiltFlat: 39,
        },
        '4': {
          //property types
          detached: 64,
          semiDetached: 56,
          endTerraced: 54,
          midTerraced: 52,
          bungalow: 64,
          convertedFlat: 60,
          purposeBuiltFlat: 50,
        },
        '>4': {
          //property types
          detached: 86,
          semiDetached: 78,
          endTerraced: 76,
          midTerraced: 74,
          bungalow: 86,
          convertedFlat: 82,
          purposeBuiltFlat: 72,
        },
      },
    },
    oil: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 2342,
          semiDetached: 1720,
          endTerraced: 1597,
          midTerraced: 1455,
          bungalow: 2355,
          convertedFlat: 2032,
          purposeBuiltFlat: 1265,
        },
        '2': {
          //property types
          detached: 3195,
          semiDetached: 2573,
          endTerraced: 2450,
          midTerraced: 2308,
          bungalow: 3208,
          convertedFlat: 2886,
          purposeBuiltFlat: 2118,
        },
        '3': {
          //property types
          detached: 4048,
          semiDetached: 3427,
          endTerraced: 3303,
          midTerraced: 3161,
          bungalow: 4061,
          convertedFlat: 3739,
          purposeBuiltFlat: 2971,
        },
        '4': {
          //property types
          detached: 4902,
          semiDetached: 4280,
          endTerraced: 4157,
          midTerraced: 4015,
          bungalow: 4915,
          convertedFlat: 4592,
          purposeBuiltFlat: 3825,
        },
        '>4': {
          //property types
          detached: 6608,
          semiDetached: 5987,
          endTerraced: 5863,
          midTerraced: 5721,
          bungalow: 6621,
          convertedFlat: 6299,
          purposeBuiltFlat: 5531,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 1171,
          semiDetached: 860,
          endTerraced: 798,
          midTerraced: 727,
          bungalow: 1177,
          convertedFlat: 1016,
          purposeBuiltFlat: 632,
        },
        '2': {
          //property types
          detached: 1598,
          semiDetached: 1287,
          endTerraced: 1225,
          midTerraced: 1154,
          bungalow: 1604,
          convertedFlat: 1443,
          purposeBuiltFlat: 1059,
        },
        '3': {
          //property types
          detached: 2024,
          semiDetached: 1713,
          endTerraced: 1652,
          midTerraced: 1581,
          bungalow: 2031,
          convertedFlat: 1869,
          purposeBuiltFlat: 1486,
        },
        '4': {
          //property types
          detached: 2451,
          semiDetached: 2140,
          endTerraced: 2078,
          midTerraced: 2007,
          bungalow: 2457,
          convertedFlat: 2296,
          purposeBuiltFlat: 1912,
        },
        '>4': {
          //property types
          detached: 3304,
          semiDetached: 2993,
          endTerraced: 2932,
          midTerraced: 2861,
          bungalow: 3311,
          convertedFlat: 3149,
          purposeBuiltFlat: 2766,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 781,
          semiDetached: 573,
          endTerraced: 532,
          midTerraced: 485,
          bungalow: 785,
          convertedFlat: 677,
          purposeBuiltFlat: 422,
        },
        '2': {
          //property types
          detached: 1065,
          semiDetached: 858,
          endTerraced: 817,
          midTerraced: 769,
          bungalow: 1069,
          convertedFlat: 962,
          purposeBuiltFlat: 706,
        },
        '3': {
          //property types
          detached: 1349,
          semiDetached: 1142,
          endTerraced: 1101,
          midTerraced: 1054,
          bungalow: 1354,
          convertedFlat: 1246,
          purposeBuiltFlat: 990,
        },
        '4': {
          //property types
          detached: 1634,
          semiDetached: 1427,
          endTerraced: 1386,
          midTerraced: 1338,
          bungalow: 1638,
          convertedFlat: 1531,
          purposeBuiltFlat: 1275,
        },
        '>4': {
          //property types
          detached: 2203,
          semiDetached: 1996,
          endTerraced: 1954,
          midTerraced: 1907,
          bungalow: 2207,
          convertedFlat: 2100,
          purposeBuiltFlat: 1844,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 585,
          semiDetached: 430,
          endTerraced: 399,
          midTerraced: 364,
          bungalow: 589,
          convertedFlat: 508,
          purposeBuiltFlat: 316,
        },
        '2': {
          //property types
          detached: 799,
          semiDetached: 643,
          endTerraced: 613,
          midTerraced: 577,
          bungalow: 802,
          convertedFlat: 721,
          purposeBuiltFlat: 529,
        },
        '3': {
          //property types
          detached: 1012,
          semiDetached: 857,
          endTerraced: 826,
          midTerraced: 790,
          bungalow: 1015,
          convertedFlat: 935,
          purposeBuiltFlat: 743,
        },
        '4': {
          //property types
          detached: 1225,
          semiDetached: 1070,
          endTerraced: 1039,
          midTerraced: 1004,
          bungalow: 1229,
          convertedFlat: 1148,
          purposeBuiltFlat: 956,
        },
        '>4': {
          //property types
          detached: 1652,
          semiDetached: 1497,
          endTerraced: 1466,
          midTerraced: 1430,
          bungalow: 1655,
          convertedFlat: 1575,
          purposeBuiltFlat: 1383,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 468,
          semiDetached: 344,
          endTerraced: 319,
          midTerraced: 291,
          bungalow: 471,
          convertedFlat: 406,
          purposeBuiltFlat: 253,
        },
        '2': {
          //property types
          detached: 639,
          semiDetached: 515,
          endTerraced: 490,
          midTerraced: 462,
          bungalow: 642,
          convertedFlat: 577,
          purposeBuiltFlat: 424,
        },
        '3': {
          //property types
          detached: 810,
          semiDetached: 685,
          endTerraced: 661,
          midTerraced: 632,
          bungalow: 812,
          convertedFlat: 748,
          purposeBuiltFlat: 594,
        },
        '4': {
          //property types
          detached: 980,
          semiDetached: 856,
          endTerraced: 831,
          midTerraced: 803,
          bungalow: 983,
          convertedFlat: 918,
          purposeBuiltFlat: 765,
        },
        '>4': {
          //property types
          detached: 1322,
          semiDetached: 1197,
          endTerraced: 1173,
          midTerraced: 1144,
          bungalow: 1324,
          convertedFlat: 1260,
          purposeBuiltFlat: 1106,
        },
      },
    },
    electric: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 1724,
          semiDetached: 1267,
          endTerraced: 1176,
          midTerraced: 1071,
          bungalow: 1734,
          convertedFlat: 1496,
          purposeBuiltFlat: 931,
        },
        '2': {
          //property types
          detached: 2353,
          semiDetached: 1895,
          endTerraced: 1804,
          midTerraced: 1699,
          bungalow: 2362,
          convertedFlat: 2125,
          purposeBuiltFlat: 1559,
        },
        '3': {
          //property types
          detached: 2981,
          semiDetached: 2523,
          endTerraced: 2432,
          midTerraced: 2328,
          bungalow: 2990,
          convertedFlat: 2753,
          purposeBuiltFlat: 2188,
        },
        '4': {
          //property types
          detached: 3609,
          semiDetached: 3151,
          endTerraced: 3061,
          midTerraced: 2956,
          bungalow: 3619,
          convertedFlat: 3381,
          purposeBuiltFlat: 2816,
        },
        '>4': {
          //property types
          detached: 4866,
          semiDetached: 4408,
          endTerraced: 4317,
          midTerraced: 4213,
          bungalow: 4875,
          convertedFlat: 4638,
          purposeBuiltFlat: 4073,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 862,
          semiDetached: 633,
          endTerraced: 588,
          midTerraced: 536,
          bungalow: 867,
          convertedFlat: 748,
          purposeBuiltFlat: 466,
        },
        '2': {
          //property types
          detached: 1176,
          semiDetached: 947,
          endTerraced: 902,
          midTerraced: 850,
          bungalow: 1181,
          convertedFlat: 1062,
          purposeBuiltFlat: 780,
        },
        '3': {
          //property types
          detached: 1490,
          semiDetached: 1262,
          endTerraced: 1216,
          midTerraced: 1164,
          bungalow: 1495,
          convertedFlat: 1377,
          purposeBuiltFlat: 1094,
        },
        '4': {
          //property types
          detached: 1805,
          semiDetached: 1576,
          endTerraced: 1530,
          midTerraced: 1478,
          bungalow: 1809,
          convertedFlat: 1691,
          purposeBuiltFlat: 1408,
        },
        '>4': {
          //property types
          detached: 2433,
          semiDetached: 2204,
          endTerraced: 2159,
          midTerraced: 2106,
          bungalow: 2438,
          convertedFlat: 2319,
          purposeBuiltFlat: 2036,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 575,
          semiDetached: 422,
          endTerraced: 392,
          midTerraced: 357,
          bungalow: 578,
          convertedFlat: 499,
          purposeBuiltFlat: 310,
        },
        '2': {
          //property types
          detached: 784,
          semiDetached: 632,
          endTerraced: 601,
          midTerraced: 566,
          bungalow: 787,
          convertedFlat: 708,
          purposeBuiltFlat: 520,
        },
        '3': {
          //property types
          detached: 994,
          semiDetached: 841,
          endTerraced: 811,
          midTerraced: 776,
          bungalow: 997,
          convertedFlat: 918,
          purposeBuiltFlat: 729,
        },
        '4': {
          //property types
          detached: 1203,
          semiDetached: 1050,
          endTerraced: 1020,
          midTerraced: 985,
          bungalow: 1206,
          convertedFlat: 1127,
          purposeBuiltFlat: 939,
        },
        '>4': {
          //property types
          detached: 1622,
          semiDetached: 1469,
          endTerraced: 1439,
          midTerraced: 1404,
          bungalow: 1625,
          convertedFlat: 1546,
          purposeBuiltFlat: 1358,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 431,
          semiDetached: 317,
          endTerraced: 294,
          midTerraced: 268,
          bungalow: 433,
          convertedFlat: 374,
          purposeBuiltFlat: 233,
        },
        '2': {
          //property types
          detached: 588,
          semiDetached: 474,
          endTerraced: 451,
          midTerraced: 425,
          bungalow: 591,
          convertedFlat: 531,
          purposeBuiltFlat: 390,
        },
        '3': {
          //property types
          detached: 745,
          semiDetached: 631,
          endTerraced: 608,
          midTerraced: 582,
          bungalow: 748,
          convertedFlat: 688,
          purposeBuiltFlat: 547,
        },
        '4': {
          //property types
          detached: 902,
          semiDetached: 788,
          endTerraced: 765,
          midTerraced: 739,
          bungalow: 905,
          convertedFlat: 845,
          purposeBuiltFlat: 704,
        },
        '>4': {
          //property types
          detached: 1216,
          semiDetached: 1102,
          endTerraced: 1079,
          midTerraced: 1053,
          bungalow: 1219,
          convertedFlat: 1159,
          purposeBuiltFlat: 1018,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 345,
          semiDetached: 253,
          endTerraced: 235,
          midTerraced: 214,
          bungalow: 347,
          convertedFlat: 299,
          purposeBuiltFlat: 186,
        },
        '2': {
          //property types
          detached: 471,
          semiDetached: 379,
          endTerraced: 361,
          midTerraced: 340,
          bungalow: 472,
          convertedFlat: 425,
          purposeBuiltFlat: 312,
        },
        '3': {
          //property types
          detached: 596,
          semiDetached: 505,
          endTerraced: 486,
          midTerraced: 466,
          bungalow: 598,
          convertedFlat: 551,
          purposeBuiltFlat: 438,
        },
        '4': {
          //property types
          detached: 722,
          semiDetached: 630,
          endTerraced: 612,
          midTerraced: 591,
          bungalow: 724,
          convertedFlat: 676,
          purposeBuiltFlat: 563,
        },
        '>4': {
          //property types
          detached: 973,
          semiDetached: 882,
          endTerraced: 863,
          midTerraced: 843,
          bungalow: 975,
          convertedFlat: 928,
          purposeBuiltFlat: 815,
        },
      },
    },
  },
}

export default heatMatrix
