import { useEffect, useState } from 'react'

/* https://usehooks.com/useOnScreen/ */

const useOnScreen = (
  ref: React.RefObject<HTMLElement>,
  rootMargin = '0px'
): boolean => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    const element = ref.current
    if (element) {
      observer.observe(element)
    }
    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [ref, rootMargin])

  return isIntersecting
}

export default useOnScreen
