import React from 'react'
import { Flex, Box, Text, Link, Image, ThemeUIStyleObject } from 'theme-ui'
import PdfIcon from '../images/pdf-icon.svg'
import carbonCalculatorReportPdf from '../files/carbon-calculator-report.pdf'

const InfoBox: React.FC = () => {
  const wrapperSx: ThemeUIStyleObject = {
    position: ['relative', 'relative', 'absolute'],
    top: ['auto', 'auto', '7.66rem'],
    right: ['auto', 'auto', '10%'],
    padding: '4.16rem',
    boxShadow: '0 0 12px rgba(0,0,0, 0.25)',
    maxWidth: ['80%', '80%', '20%'],
    margin: ['3rem auto 6rem', '3rem auto 6rem', '0'],
    borderRadius: '0.66rem',
  }

  const pSx: ThemeUIStyleObject = {
    fontSize: ['1.2rem', '1.2rem', '1rem'],
  }

  const pdfWrapperSx: ThemeUIStyleObject = {
    display: 'flex',
    textDecoration: 'none',
    marginTop: '1.5rem',
  }

  const pdfTextSx: ThemeUIStyleObject = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
    fontSize: '.9rem',
    textDecoration: 'none',
  }

  return (
    <Flex sx={wrapperSx}>
      <Box>
        <Text sx={pSx} as="p">
          Full details of our methodology, which we created with independent
          researchers from Imperial College London Consultants are in this
          report
        </Text>
        <Link sx={pdfWrapperSx} href={carbonCalculatorReportPdf} download>
          <Image src={PdfIcon} alt="PDF Icon" />
          <Box sx={pdfTextSx}>
            <Text>
              <strong>ICL Carbon Footprint Report</strong> (PDF)
            </Text>
          </Box>
        </Link>
      </Box>
    </Flex>
  )
}

export default InfoBox
