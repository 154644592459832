import React from 'react'
import { Heading, Input, Button, Box, ThemeUIStyleObject } from 'theme-ui'

import usePartnerPromo from '../hooks/use-partner-promo'
import { formatJoinLink } from '../utils/helpers'

interface Props {
  alt?: boolean
  inputSx?: ThemeUIStyleObject
  buttonSx?: ThemeUIStyleObject
}

const QuoteWidget: React.FC<Props> = ({
  alt,
  inputSx,
  buttonSx,
  children,
  ...other
}) => {
  const partner = usePartnerPromo()
  const url = formatJoinLink()

  const form: ThemeUIStyleObject = {
    display: ['block', 'block', 'flex', 'flex'],
    marginTop: 11,
  }

  const input: ThemeUIStyleObject = {
    backgroundColor: alt ? 'background' : 'muted',
    flexGrow: [0, 1],
    width: ['16rem', '16rem', '16rem', 'auto'],
    marginRight: ['auto', 'auto', 'auto', 5],
    marginLeft: ['auto', 'auto', 'auto', 0],
    textAlign: ['center', 'center', 'center', 'left'],
    ...inputSx,
  }

  const button: ThemeUIStyleObject = {
    display: 'block',
    width: ['16rem', '16rem', '16rem', '12rem'],
    marginTop: [5, 5, 5, 0],
    marginX: ['auto', 'auto', 'auto', 0],
    ...buttonSx,
  }

  function PostcodeForm() {
    return (
      <form sx={form} action={url}>
        <Input sx={input} name="postcode" placeholder="Enter your postcode" />
        {partner?.code && (
          <Input type="hidden" name="partner" value={partner.code} />
        )}
        <Button sx={button} type="submit" variant="primary">
          Get a quote
        </Button>
      </form>
    )
  }

  return (
    <Box {...other}>
      <PostcodeForm />
      {/* {children || (
          <Heading as="h3" variant="med">
            Switch to Ecotricity today
          </Heading>
        )} */}
    </Box>
  )
}

export default QuoteWidget
