import useSWR from 'swr'

export enum FuelType {
  Solar = 'solar',
  Wind = 'wind',
  Hydro = 'hydro',
  Gas = 'gas',
  Coal = 'coal',
  Other = 'other',
  Imports = 'imports',
  Nuclear = 'nuclear',
  Biomass = 'biomass',
  Combined = 'combined',
}

export type Volumes = { [key in FuelType]?: number }

export type LiveGenData = {
  ourPeakOutput: Volumes
  ourMixNow: Volumes
  ourLast28: Volumes[]
  gridMixNow: Volumes
  fetchedAt: Date
}

type LiveGenResponse = {
  data: LiveGenData
  isLoading: boolean
  isError: boolean
  isNetworkError: boolean
}

const fetcher = async () => {
  const query = `
    query {
        ourPeakOutput {
          combined
        }
        ourMixNow {
          solar
          wind
        }
        ourLast28 {
          wind
          solar
        }
        gridMixNow {
            wind
            solar
            hydro
            gas
            coal
            biomass
            nuclear
            imports
            other
        }
    }
  `

  let res
  try {
    res = await fetch(
      `https://${process.env.GATSBY_API_HOST as string}/data/graphql`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query,
        }),
      }
    )
  } catch (e) {
    throw new Error('Failed to fetch')
  }
  const { data, errors } = await res.json()

  if (errors) throw new Error(data.errors[0].message)
  return {
    ...data,
    fetchedAt: new Date(),
    gridMixNow: {
      ...data.gridMixNow,
      other: data.gridMixNow.imports + data.gridMixNow.other,
      imports: 0,
    },
  }
}

const useLiveGenData = (): LiveGenResponse => {
  const { data, error, isLoading } = useSWR('livegen', fetcher, {
    refreshInterval: 60000 * 10,
  })

  return {
    data,
    isLoading,
    isError: error,
    isNetworkError: error && error.message === 'Failed to fetch',
  }
}

export default useLiveGenData
