import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import Article from '../components/article'
import Cluster from '../components/cluster'
import Hero from '../components/hero'
import GeneralContent from '../components/general-content'

export const query = graphql`
  query Page(
    $pageId: String!
    $relatedPages: Boolean!
    $relatedFilter: ContentfulPageFilterInput
    $relatedSort: ContentfulPageSortInput
    $relatedLimit: Int
  ) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      slug
      description
      topic {
        name
        slug
        context {
          name
          slug
          banner {
            gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
          }
        }
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
        hideMasthead
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      divisions
      partnerPromotion
      hideHeaderAndFooter
      hideFromSearchEngines
      sections {
        __typename
        ... on Node {
          ... on ContentfulHero {
            image {
              title
              gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
            }
            video {
              title
              url
            }
          }
          ... on ContentfulArticle {
            title
            body {
              raw
              references {
                ...ArticleReferences
              }
            }
            aside {
              raw
              references {
                ...ArticleReferences
              }
            }
          }
          ... on ContentfulPartner {
            body {
              raw
              references {
                ...ArticleReferences
              }
            }
            aside {
              raw
              references {
                ...ArticleReferences
              }
            }
          }
          ... on ContentfulRelatedPages {
            __typename
            title
            pages {
              ...RelatedPage
            }
          }
          ... on ContentfulGeneralContent {
            title
            type
            generalItems: items {
              ... on Node {
                ... on ContentfulEvent {
                  title
                  date(formatString: "YYYY")
                  image {
                    ...ArticleImage
                  }
                  body {
                    raw
                  }
                }
                ... on ContentfulItem {
                  itemTitle: title
                  image {
                    gatsbyImageData(
                      quality: 60
                      layout: FULL_WIDTH
                      formats: [WEBP]
                    )
                  }
                  video {
                    title
                    url
                  }
                  body {
                    raw
                    references {
                      ...References
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    related: allContentfulPage(
      filter: $relatedFilter
      sort: $relatedSort
      limit: $relatedLimit
    ) @include(if: $relatedPages) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const Page: React.FC<PageProps<GatsbyTypes.PageQuery>> = ({
  data: { page = {}, related = {} },
}) => {
  const header = (
    <Section as="div" variant={page.sections ? 'header' : 'page'}>
      <PageHeader
        topic={page.topic?.heading || page.topic?.name}
        heading={page.heading || page.title}
        subheading={page.subheading}
        standfirst={page.standfirst?.standfirst}
      />
    </Section>
  )

  const isYourEnergy = page.topic?.slug === 'your-green-energy'

  const sections = page.sections
    ?.map((section, i) => {
      switch (section?.__typename) {
        case 'ContentfulHero':
          return <Hero image={section.image} video={section.video} />

        case 'ContentfulArticle':
          return (
            <Article
              title={i && page.divisions ? section.title : undefined}
              body={section.body}
              aside={section.aside}
              variant={isYourEnergy ? 'texture' : 'default'}
            />
          )

        case 'ContentfulPartner':
          return <Article body={section.body} aside={section.aside} />
        case 'ContentfulGeneralContent': {
          const title =
            section.type[0] === 'Timeline'
              ? i && page.divisions
                ? section.title
                : undefined
              : section.title

          const params =
            section.type[0] === 'Timeline' ? { variant: 'wide' } : {}

          return (
            <GeneralContent
              title={title}
              items={section.generalItems}
              type={section.type}
              params={params}
            />
          )
        }
        case 'ContentfulRelatedPages': {
          const pinned = section.pages || []
          const nodes = related.nodes || []
          return (
            <Cluster
              title={section.title}
              items={[...pinned, ...nodes].map(page => ({
                title: page?.title,
                image: page?.image,
                text: page?.description,
                page: page,
              }))}
            />
          )
        }
        default:
          return null
      }
    })
    .map((section, i) =>
      section ? (
        <Section
          key={i}
          pos={i}
          count={page.sections?.length}
          variant={page.divisions ? 'rule' : 'page'}
        >
          {section}
        </Section>
      ) : null
    )

  const masthead = page.topic?.hideMasthead ? 'breadcrumb' : 'default'
  const meta = page.hideFromSearchEngines
    ? { name: 'robots', content: 'noindex' }
    : undefined

  return (
    <Layout
      page={page}
      meta={meta}
      subnav={true}
      masthead={masthead}
      isolate={page.hideHeaderAndFooter}
    >
      {header}
      {sections}
    </Layout>
  )
}

export default Page
