// ENERGY
const darkGreenElectricityTariffStep = {
  url: 'home-electricity-supply',
  question: {
    title: `Is your home electricity on a dark green electricity tariff?`,
    hint: `It's a yes if you are with Ecotricity.`,
    value: 'darkGreenElectricityTariff',
    answers: [
      { name: 'Yes', value: 'yes' },
      { name: 'No', value: 'no' },
    ],
  },
  metadata: {
    title: 'Electricity Tariff',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by knowing what type of electricity tariff you are on.`,
  },
  rhsCaption: 'A <strong>Manifesto</strong> for the planet',
  rhsText: `<strong>"We can make all the electricity we need using the wind, the sun and the sea.</strong> We could reach 100% green electricity in ten years. And we don't need to stop using gas for heating either. We can make green gas from grass that is not just part of the answer for the energy sector, it also offers a transition for farming, as we change our diet."`,
}

const carbonNeutralisedStep = {
  url: 'carbon-neutralised-gas',
  question: {
    title: `Is your heating carbon neutralised?`,
    hint: `It's a yes if you are with Ecotricity.`,
    value: 'carbonNeutralised',
    answers: [
      { name: 'Yes', value: 'yes' },
      { name: 'No', value: 'no' },
    ],
  },
  metadata: {
    title: 'Carbon neutralised gas',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by finding out if your home gas is carbon neutralised.`,
  },
}

const fuelTypeStep = {
  url: 'type-of-home-fuel',
  question: {
    title: 'What type of fuel do you use to heat your home?',
    value: 'fuelType',
    answers: [
      { name: 'Gas', value: 'gas' },
      { name: 'Wood', value: 'wood' },
      { name: 'Oil', value: 'oil' },
      { name: 'Electric', value: 'electric' },
    ],
  },
  metadata: {
    title: 'Fuel Type',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by understanding how you heat your home.`,
  },
}

const propertyTypeStep = {
  url: 'type-of-property',
  question: {
    title: 'What type of property do you live in?',
    value: 'propertyType',
    columns: true,
    answers: [
      { name: 'Detached', value: 'detached' },
      { name: 'Semi detached', value: 'semiDetached' },
      { name: 'End terraced', value: 'endTerraced' },
      { name: 'Mid terraced', value: 'midTerraced' },
      { name: 'Bungalow', value: 'bungalow' },
      { name: 'Converted flat', value: 'convertedFlat' },
      { name: 'Purpose built flat', value: 'purposeBuiltFlat' },
    ],
  },
  metadata: {
    title: 'Property Type',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by identifying the type of property you live in.`,
  },
}

const bedroomsStep = {
  url: 'number-of-bedrooms',
  question: {
    title: 'How many bedrooms do you have in your home?',
    value: 'bedrooms',
    columns: true,
    answers: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '>4', value: '>4' },
    ],
  },
  metadata: {
    title: 'Bedrooms Number',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by identifying the number of bedrooms in your home.`,
  },
}

const adultsStep = {
  url: 'number-of-adults',
  question: {
    title:
      'How many people do you live with who are over 18 years old (including you)?',
    value: 'adults',
    columns: true,
    answers: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '5', value: '>4' },
    ],
  },
  metadata: {
    title: 'Occupying Adults',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by knowing the number of adults in your home.`,
  },
}

// TO BE REFACTORED TO USE CHECK BOXES
const energySavingMeasuresStep = {
  url: 'energy-saving-measures',
  question: {
    title: 'What energy saving measures do you have installed?',
    value: 'energySavingMeasures',
    multipleChoice: true,
    answers: [
      { name: 'Condensing boiler', value: 'condensingBoiler' },
      { name: 'Cavity wall insulation', value: 'cavityWallInsulation' },
      { name: 'Loft insulation', value: 'loftInsulation' },
      { name: 'Solid wall insulation', value: 'solidWallInsulation' },
      { name: 'Solar PV', value: 'solarPv' },
    ],
  },
  metadata: {
    title: 'Energy Saving',
    description: `Ecotricity's carbon footprint calculator can help you work out your average household carbon footprint by identifying what energy saving measures you have.`,
  },
}

// TRANSPORT
const travelCarMotorcycleHoursStep = {
  url: 'travel-by-car-or-motorcycle',
  question: {
    title:
      'How many hours do you usually spend travelling by car/motorcycle each week?',
    value: 'travelCarMotorcycleHours',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 2 hours', value: '<2' },
      { name: '2-4 hours', value: '2-4' },
      { name: '4-6 hours', value: '4-6' },
      { name: '6-8 hours', value: '6-8' },
      { name: '8-10 hours', value: '8-10' },
      { name: 'Over 10 hours', value: '10+' },
    ],
  },
  metadata: {
    title: 'Car travel',
    description: `Ecotricity's carbon footprint calculator can work out your carbon footprint for transport by knowing how many hours you spend travelling by car or motorcycle.`,
  },
  rhsCaption: 'A <strong>Manifesto</strong> for the planet',
  rhsText: `<strong>"In transport, we need to electrify everything.</strong> Cars, trucks, buses, trains, planes, you name it... even ships. And it's all possible. We should rethink cities and towns, deprioritise the car and reprioritise pedestrians and cyclists – let's reclaim the streets. And rethink work itself. Blended working, from home and office, could be one of the big takeaways from the virus crisis – reducing travel significantly and increasing inner city air quality."`,
}

const travelCarMotorcycleTypeStep = {
  url: 'type-of-vehicle',
  question: {
    title: 'What type of vehicle do you use for the majority of these hours?',
    value: 'travelCarMotorcycleType',
    columns: true,
    answers: [
      { name: 'NA', value: 'NA' },
      { name: 'Motorcycle', value: 'motorcycle' },
      { name: 'Car - Petrol up to 1.4 litre engine', value: 'car-petrol-1.4l' },
      {
        name: 'Car - Petrol from 1.4 litre to 2.0 litre engine',
        value: 'car-petrol-1.4l-2.0l',
      },
      {
        name: 'Car - Petrol above 2.0 litre engine',
        value: 'car-petrol-2.0l+',
      },
      {
        name: 'Car - Diesel  up to 1.7 litre engine',
        value: 'car-diesel-1.7l',
      },
      {
        name: 'Car - Diesel from 1.7 litre to 2.0 litre engine',
        value: 'car-diesel-1.7l-2.0l',
      },
      {
        name: 'Car - Diesel above 2.0 litre engine',
        value: 'car-diesel-2.0l+',
      },
      {
        name: 'Car - Hybrid - Small (e.g. Toyota Yaris)',
        value: 'car-hybrid-small',
      },
      {
        name: 'Car - Hybrid - Medium (e.g. Ford Focus)',
        value: 'car-hybrid-medium',
      },
      {
        name: 'Car - Hybrid - Large (e.g. Lexus IS300h)',
        value: 'car-hybrid-large',
      },
      {
        name: 'Car - Plug-in Hybrid - Small (e.g. BMW i3)',
        value: 'car-plug-in-hybrid-small',
      },
      {
        name: 'Car - Plug-in Hybrid - Medium (e.g. BMW 3-series)',
        value: 'car-plug-in-hybrid-medium',
      },
      {
        name: 'Car - Plug-in Hybrid - Large (Mercedes E-class)',
        value: 'car-plug-in-hybrid-large',
      },
      {
        name: 'Car - Battery Electric Vehicle - Small (e.g. Renault Zoe)',
        value: 'car-battery-electric-vehicle-small',
      },
      {
        name: 'Car - Battery Electric Vehicle - Medium (e.g. VW e-Golf)',
        value: 'car-battery-electric-vehicle-medium',
      },
      {
        name: 'Car - Battery Electric Vehicle - Large (e.g. Audi e-Tron)',
        value: 'car-battery-electric-vehicle-large',
      },
    ],
  },
  metadata: {
    title: 'Vehicle type',
    description: `Ecotricity's carbon footprint calculator can work out your carbon footprint for transport by identifying the type of vehicle you use most of the time.`,
  },
}

const travelAdultsStep = {
  url: 'number-of-travelling-adults',
  question: {
    title:
      'How many adults do you normally travel with (including you) in a car/motorcycle?',
    value: 'travelAdults',
    columns: true,
    answers: [
      { name: '1', value: '1' },
      { name: '2', value: '2' },
      { name: '3', value: '3' },
      { name: '4', value: '4' },
      { name: '>4', value: '>4' },
    ],
  },
  metadata: {
    title: 'Travelling adults',
    description: `Ecotricity's carbon footprint calculator can work out your carbon footprint for transport by being aware of the number of adults you usually travel with.`,
  },
}

const travelHoursBusStep = {
  url: 'hours-travelling-by-bus',
  question: {
    title: 'How many hours do you spend travelling by bus each week?',
    value: 'travelHoursBus',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 2 hours', value: '<2' },
      { name: '2-4 hours', value: '2-4' },
      { name: '4-6 hours', value: '4-6' },
      { name: '6-8 hours', value: '6-8' },
      { name: '8-10 hours', value: '8-10' },
      { name: 'Over 10 hours', value: '10+' },
    ],
  },
  metadata: {
    title: 'Hours travelling',
    description: `Ecotricity's carbon footprint calculator can work out your carbon footprint for transport by considering how many hours a week you travel by bus.`,
  },
}

const travelHoursTrainStep = {
  url: 'hours-travelling-by-train',
  question: {
    title: 'How many hours do you usually spend travelling by train each week?',
    value: 'travelHoursTrain',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 2 hours', value: '<2' },
      { name: '2-4 hours', value: '2-4' },
      { name: '4-6 hours', value: '4-6' },
      { name: '6-8 hours', value: '6-8' },
      { name: '8-10 hours', value: '8-10' },
      { name: 'Over 10 hours', value: '10+' },
    ],
  },
  metadata: {
    title: 'Train travel',
    description: `Ecotricity's carbon footprint calculator can work out your carbon footprint for transport by understanding how often you travel by train.`,
  },
}

const travelHoursFlyingDomesticStep = {
  url: 'hours-flying-domestic',
  question: {
    title: 'How many hours do you usually spend flying domestically each year?',
    value: 'travelHoursFlyingDomestic',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 5 hours', value: '0-5' },
      { name: '5-10 hours', value: '5-10' },
      { name: '10-20 hours', value: '10-20' },
      { name: '20-30 hours', value: '20-30' },
      { name: '30-40 hours', value: '30-40' },
      { name: 'Over 50 hours', value: '50+' },
    ],
  },
  metadata: {
    title: 'Flying domestic',
    description: `Ecotricity's carbon footprint calculator can calculate your carbon footprint for transportation by knowing how many hours you spend flying within the U.K.`,
  },
}

const travelHoursFlyingShortHaulStep = {
  url: 'hours-flying-short-haul',
  question: {
    title:
      'How many hours do you usually spend flying short-haul (<5 hour journey) each year?',
    value: 'travelHoursFlyingShortHaul',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 5 hours', value: '0-5' },
      { name: '5-10 hours', value: '5-10' },
      { name: '10-20 hours', value: '10-20' },
      { name: '20-30 hours', value: '20-30' },
      { name: '30-40 hours', value: '30-40' },
      { name: 'Over 50 hours', value: '50+' },
    ],
  },
  metadata: {
    title: 'Flying short-haul',
    description: `Ecotricity's carbon footprint calculator can calculate your carbon footprint for transportation by identifying how many hours you fly short-haul each year.`,
  },
}

const travelFlyingShortHaulTypeStep = {
  url: 'short-haul-ticket-type',
  question: {
    title: 'Do you normally fly Economy or Business for short-haul flights?',
    value: 'travelFlyingShortHaulType',
    answers: [
      { name: 'Do not fly short-haul', value: 'none' },
      { name: 'Economy', value: 'economy' },
      { name: 'Business', value: 'business' },
    ],
  },
  metadata: {
    title: 'Short-haul ticket',
    description: `Ecotricity's carbon footprint calculator can calculate your carbon footprint for transport by considering if you fly economy or business on short-haul flights.`,
  },
}

const travelHoursFlyingLongHaulStep = {
  url: 'hours-flying-long-haul',
  question: {
    title:
      'How many hours do you usually spend flying long-haul (>5 hour journey) each year?',
    value: 'travelHoursFlyingLongHaul',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: 'Up to 5 hours', value: '0-5' },
      { name: '5-10 hours', value: '5-10' },
      { name: '10-20 hours', value: '10-20' },
      { name: '20-30 hours', value: '20-30' },
      { name: '30-40 hours', value: '30-40' },
      { name: 'Over 50 hours', value: '50+' },
    ],
  },
  metadata: {
    title: 'Flying long-haul',
    description: `Ecotricity's carbon footprint calculator can calculate your carbon footprint for transportation by understanding how often you fly long-haul each year.`,
  },
}

const internationalFlightTypeStep = {
  url: 'type-of-international-flight-ticket',
  question: {
    title:
      'What type of ticket do you usually buy when you fly internationally?',
    value: 'internationalFlightType',
    columns: true,
    answers: [
      { name: 'Do not fly', value: 'none' },
      { name: 'Economy', value: 'economy' },
      { name: 'Premium Economy', value: 'premiumEconomy' },
      { name: 'Business', value: 'business' },
      { name: 'First', value: 'first' },
    ],
  },
  metadata: {
    title: 'Flying worldwide',
    description: `Ecotricity's carbon footprint calculator can calculate your carbon footprint for transportation by knowing what type of ticket you buy when flying abroad.`,
  },
}

// FOOD
const dietStep = {
  url: 'type-of-diet',
  question: {
    title: 'How do you describe your diet?',
    value: 'diet',
    answers: [
      { name: 'Regular', value: 'regular' },
      { name: 'Pescatarian', value: 'pescatarian' },
      { name: 'Vegan', value: 'vegan' },
      { name: 'Vegetarian', value: 'vegetarian' },
    ],
  },
  metadata: {
    title: 'Diet type',
    description: `Ecotricity's food carbon footprint calculator can determine your meat carbon footprint and environmental impact by learning about your type of diet.`,
  },
  rhsCaption: 'A <strong>Manifesto</strong> for the planet',
  rhsText: `<strong>"In food we just need to replace animals in our diet with plants. What we need here is behaviour change.</strong> The shift has begun but we need to push it. We need to make people aware of what they are eating, how it's made and what it's doing to them."`,
}

const dietRedMeatStep = {
  url: 'amount-of-red-meat',
  question: {
    title: 'How many times a week do you eat red meat?',
    value: 'dietRedMeat',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: '1 Day a week', value: '1' },
      { name: '2 Days a week', value: '2' },
      { name: '3 Days a week', value: '3' },
      { name: '4 Days a week', value: '4' },
      { name: '5 Days a week', value: '5' },
      { name: '6 Days a week', value: '6' },
      { name: '7 Days a week', value: '7' },
    ],
  },
  metadata: {
    title: 'Red meat',
    description: `Ecotricity's food carbon footprint calculator can determine your meat carbon footprint and food waste by considering how much red meat you eat each week.`,
  },
}

const dietWhiteMeatStep = {
  url: 'amount-of-white-meat',
  question: {
    title: 'How many times a week do you eat white meat?',
    value: 'dietWhiteMeat',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: '1 Day a week', value: '1' },
      { name: '2 Days a week', value: '2' },
      { name: '3 Days a week', value: '3' },
      { name: '4 Days a week', value: '4' },
      { name: '5 Days a week', value: '5' },
      { name: '6 Days a week', value: '6' },
      { name: '7 Days a week', value: '7' },
    ],
  },
  metadata: {
    title: 'White meat',
    description: `Ecotricity's food carbon footprint calculator can determine your meat carbon footprint and food waste by identifying how often you eat white meat.`,
  },
}

const dietSeafoodOrFishStep = {
  url: 'amount-of-seafood-or-fish',
  question: {
    title: 'How many times a week do you eat seafood/fish?',
    value: 'dietSeafoodOrFish',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: '1 Day a week', value: '1' },
      { name: '2 Days a week', value: '2' },
      { name: '3 Days a week', value: '3' },
      { name: '4 Days a week', value: '4' },
      { name: '5 Days a week', value: '5' },
      { name: '6 Days a week', value: '6' },
      { name: '7 Days a week', value: '7' },
    ],
  },
  metadata: {
    title: 'Seafood or fish',
    description: `Ecotricity's food carbon footprint calculator can determine your meat carbon footprint and food waste by knowing how much seafood and fish you eat each week.`,
  },
}

const dietEggsStep = {
  url: 'amount-of-eggs',
  question: {
    title: 'How many times a week do you eat eggs?',
    value: 'dietEggs',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: '1 Day a week', value: '1' },
      { name: '2 Days a week', value: '2' },
      { name: '3 Days a week', value: '3' },
      { name: '4 Days a week', value: '4' },
      { name: '5 Days a week', value: '5' },
      { name: '6 Days a week', value: '6' },
      { name: '7 Days a week', value: '7' },
    ],
  },
  metadata: {
    title: 'Eggs eaten',
    description: `Ecotricity's food carbon footprint calculator can determine your dairy carbon footprint and food waste by calculating how often you eat eggs.`,
  },
}

const dietDairyStep = {
  url: 'amount-of-dairy',
  question: {
    title: 'How many times a week do you eat dairy products?',
    value: 'dietDairy',
    columns: true,
    answers: [
      { name: 'None', value: 'none' },
      { name: '1 Day a week', value: '1' },
      { name: '2 Days a week', value: '2' },
      { name: '3 Days a week', value: '3' },
      { name: '4 Days a week', value: '4' },
      { name: '5 Days a week', value: '5' },
      { name: '6 Days a week', value: '6' },
      { name: '7 Days a week', value: '7' },
    ],
  },
  metadata: {
    title: 'Dairy products eaten',
    description: `Ecotricity's food carbon footprint calculator can determine your dairy carbon footprint and food waste by considering how much of your diet is dairy products.`,
  },
}

const foodWasteStep = {
  url: 'amount-of-food-wasted',
  question: {
    title: 'How much food is wasted a week?',
    value: 'foodWaste',
    columns: true,
    answers: [
      { name: '50%', value: '50%' },
      { name: '40%', value: '40%' },
      { name: '30%', value: '30%' },
      { name: '20%', value: '20%' },
      { name: '10%', value: '10%' },
    ],
  },
  metadata: {
    title: 'Food wasted',
    description: `Ecotricity's carbon footprint calculator can determine how your food carbon footprint and environmental impact is affected by how much of your food is wasted.`,
  },
}

const foodWasteDisposalStep = {
  url: 'food-waste-disposal',
  question: {
    title: 'How do you manage your food waste?',
    value: 'foodWasteDisposal',
    answers: [
      { name: 'General waste', value: 'generalWaste' },
      { name: 'Compost', value: 'compost' },
    ],
  },
  metadata: {
    title: 'Food waste disposal',
    description: `Ecotricity's carbon footprint calculator can calculate how much of your food carbon footprint is determined by how you manage your food waste.`,
  },
}

export {
  darkGreenElectricityTariffStep,
  travelHoursFlyingLongHaulStep,
  dietRedMeatStep,
}

export default {
  categories: [
    {
      name: 'Energy',
      url: 'energy',
      steps: [
        darkGreenElectricityTariffStep,
        carbonNeutralisedStep,
        fuelTypeStep,
        propertyTypeStep,
        bedroomsStep,
        adultsStep,
        energySavingMeasuresStep,
      ],
    },
    {
      name: 'Transport',
      url: 'transport',
      steps: [
        travelCarMotorcycleHoursStep,
        travelCarMotorcycleTypeStep,
        travelAdultsStep,
        travelHoursBusStep,
        travelHoursTrainStep,
        travelHoursFlyingDomesticStep,
        travelHoursFlyingShortHaulStep,
        travelFlyingShortHaulTypeStep,
        travelHoursFlyingLongHaulStep,
        internationalFlightTypeStep,
      ],
    },
    {
      name: 'Food',
      url: 'food',
      steps: [
        dietStep,
        dietRedMeatStep,
        dietWhiteMeatStep,
        dietSeafoodOrFishStep,
        dietEggsStep,
        dietDairyStep,
        foodWasteStep,
        foodWasteDisposalStep,
      ],
    },
  ],
}
