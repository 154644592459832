import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { Page } from '../utils/types'
import home from '../images/home-icon.svg'
import PageLink from './page-link'
import { formatPageLink } from '../utils/helpers'
import { Fragment } from 'react'

interface Props {
  page?: Page
  variant?: string
  parent?: GatsbyTypes.SupportPageQuery
}

const Breadcrumb: React.FC<Props> = ({ page, variant, parent, ...other }) => {
  let parts: Array<string | number | undefined> = []
  if (variant !== 'subpage') {
    parts = [
      page?.topic?.context?.name,
      page?.topic?.name,
      page?.createdAtTimestamp &&
        new Date(page.createdAtTimestamp).getFullYear(),
      page?.title,
    ]
  } else {
    parts = [parent?.topic?.name, parent?.title, page?.title]
  }

  parts = parts.filter(Boolean)

  const icon: ThemeUIStyleObject = {
    width: '1rem',
    verticalAlign: 'middle',
    marginTop: '-0.1rem',
  }

  const text: ThemeUIStyleObject = {
    marginLeft: 6,
    fontSize: -0.4,
    fontFamily: 'body',
    fontWeight: 'light',
  }

  const link: ThemeUIStyleObject = {
    ...text,
    '&:hover': {
      textDecoration: 'underline',
    },
  }

  let pageLinkPage = page

  if (variant === 'subpage') {
    pageLinkPage = {
      topic: {
        context: { slug: parent?.topic?.slug },
        slug: parent?.slug,
      },
      slug: page?.slug,
    }
  }

  return (
    <Box {...other}>
      <PageLink to="/">
        <img sx={icon} src={home} alt="" />
      </PageLink>
      {parts.map((part, i) => (
        <Fragment key={i}>
          <span sx={text}>/</span>
          <PageLink
            sx={link}
            to={formatPageLink(pageLinkPage, i + 1)}
            variant="plain"
          >
            {part}
          </PageLink>
        </Fragment>
      ))}
    </Box>
  )
}

export default Breadcrumb
