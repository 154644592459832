import React, { createContext, useEffect, useState } from 'react'
import useScript from '../hooks/use-script'

interface YouTubeStore {
  status: string
  init: () => void
  Player?: typeof YT.Player
}

const initStore = {
  status: '',
  init: () => null,
  Player: undefined,
}

const api = 'https://www.youtube.com/iframe_api'

const YouTubeContext = createContext<YouTubeStore>(initStore)

const YouTubeProvider: React.FC = ({ children }) => {
  const [active, setActive] = useState(false)
  const [ready, setReady] = useState(false)
  const status = useScript(active ? api : undefined, true)

  useEffect(() => {
    if (status === 'ready' && window.YT) {
      window.YT.ready(() => setReady(true))
    }
  })

  const store = {
    status,
    init: () => setActive(true),
    Player: ready ? window.YT.Player : undefined,
  }

  return (
    <YouTubeContext.Provider value={store}>{children}</YouTubeContext.Provider>
  )
}

export { YouTubeProvider, YouTubeContext }
