import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Heading, Box, Text, Image } from 'theme-ui'

import windmillSpinner from '../images/windmillspinner.gif'
import Chevron from '../assets/chevron-down.svg'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import useLiveGenData from '../hooks/use-live-gen-data'
import TabbedCards from '../components/tabbed-cards'
import ImpactCards from '../components/impact-cards'
import BillsToMills from '../components/bills-to-mills'

export const query = graphql`
  query LiveGeneration($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      heading
      subheading
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      standfirst {
        standfirst
      }
      sections {
        ... on Node {
          ... on ContentfulGeneralContent {
            __typename
            title
            type
            fill
            ctaText
            ctaUrl
            items {
              ... on Node {
                ... on ContentfulGeneralContent {
                  __typename
                  title
                  description {
                    raw
                  }
                  type
                  context {
                    raw
                  }
                  ctaText
                  ctaUrl
                  items {
                    ... on Node {
                      ... on ContentfulDataViz {
                        __typename
                        title
                        showLiveIcon
                        vizId
                        extraProps {
                          internal {
                            content
                          }
                        }
                        context {
                          raw
                        }
                      }
                    }
                  }
                  assets {
                    ... on Node {
                      ... on ContentfulAsset {
                        description
                        gatsbyImageData(quality: 60, layout: FULL_WIDTH)
                        svg {
                          content
                        }
                      }
                    }
                  }
                }
                ... on ContentfulDataViz {
                  __typename
                  title
                  showLiveIcon
                  vizId
                  extraProps {
                    internal {
                      content
                    }
                  }
                  context {
                    raw
                  }
                  tableData {
                    ... on Node {
                      ... on ContentfulTable {
                        __typename
                        title
                        data {
                          tableData
                        }
                        footer
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const componentMap = {
  'Tabbed Cards': TabbedCards,
  'Impact Cards': ImpactCards,
  'Bills Into Mills': BillsToMills,
}

type LoadingErrorProps = {
  page: Queries.LiveGenerationQuery
  isNetworkError?: boolean
}

const LoadingScreen: React.FC<LoadingErrorProps> = ({ page }) => (
  <Layout page={page} subnav masthead="default">
    <Section
      variant="liveGen"
      fill="greenFadeHalf"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        img: {
          marginY: '32px',
          marginX: 'auto',
        },
        p: {
          textAlign: 'center',
          fontSize: ['18px', '24px'],
          fontWeight: 700,
          span: {
            color: 'primary',
          },
        },
      }}
    >
      <PageHeader heading={page.heading || page.title} />
      <div sx={{ display: 'flex', justifyContent: 'center' }}>
        <Image src={windmillSpinner} alt="Loading..." />
      </div>
      <Text as="p">We&apos;re getting everything ready for you.</Text>
      <Text as="p">
        Please don&apos;t click away - you&apos;re helping us build a{' '}
        <span>greener </span>
        Britain.
      </Text>
    </Section>
  </Layout>
)

const ErrorScreen: React.FC<LoadingErrorProps> = ({ page, isNetworkError }) => (
  <Layout page={page} subnav masthead="default">
    <Section
      variant="liveGen"
      fill="greenFadeHalf"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: {
          textAlign: 'center',
          fontSize: ['18px', '24px'],
          fontWeight: 700,
        },
      }}
    >
      <Heading
        as="h1"
        sx={{
          fontSize: ['24px', null, null, '32px'],
          marginBottom: ['24px', null, null, '32px'],
        }}
      >
        Something has gone wrong
      </Heading>
      <Text as="p">
        {isNetworkError
          ? 'It looks like we’re having trouble loading the live generation data at the moment. Please check your internet connection.'
          : "Sorry, we're having trouble fetching the live generation data right now."}
      </Text>
      <Text as="p">
        We&apos;ll keep re-trying for you while you&apos;re here but if the
        problem persists feel free to check back a little later.
      </Text>
    </Section>
  </Layout>
)

const pageHeaderStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
  h1: {
    fontSize: ['36px', '48px'],
  },
  h2: {
    margin: '32px 0',
    fontFamily: 'Open sans',
    fontSize: ['16px', '32px'],
    fontWeight: [700, 400],
    lineHeight: 'normal',
  },
}

const LiveGeneration: React.FC<PageProps<Queries.LiveGenerationQuery>> = ({
  data: { page },
}) => {
  const {
    data: liveGenData,
    isLoading,
    isError,
    isNetworkError,
  } = useLiveGenData()
  if (isError)
    return <ErrorScreen page={page} isNetworkError={isNetworkError} />
  if (isLoading && !liveGenData) return <LoadingScreen page={page} />
  return (
    <Layout page={page} subnav masthead="default">
      <Section variant="liveGen" fill="greenFadeHalf" style={pageHeaderStyles}>
        <Heading as="h1">{page.heading || page.title}</Heading>
        <Heading as="h2">{page.subheading}</Heading>
        <Chevron sx={{ display: ['none', 'inline-flex'] }} />
      </Section>
      {page.sections?.map((section, i) => {
        const Component = componentMap[section.type[0]]
        return (
          <Section key={i} pos={i} fill={section.fill} variant="liveGen">
            <Box sx={{ maxWidth: ['90%', '1200px'], mx: 'auto' }}>
              {section.title && <Heading as="h1">{section.title}</Heading>}
              <Component {...section} data={liveGenData} />
            </Box>
          </Section>
        )
      })}
    </Layout>
  )
}

export default LiveGeneration
