import React, { useRef, useState } from 'react'
import { Heading, ThemeUIStyleObject, IconButton, Text } from 'theme-ui'
import {
  EcoGenMixPie,
  EcoGenGridMapProgress,
  EcoGenWindMill,
} from '@ecotricity/react-components'

import Modal from '../components/modal'
import LiveIcon from '../assets/live-icon.svg'
import InfoIcon from '../assets/info-icon.svg'
import { LiveGenData } from '../hooks/use-live-gen-data'
import useConfetti from '../hooks/use-confetti'
import useOnScreen from '../hooks/use-on-screen'
import { renderRichText, Document } from '../rich-text'

enum DataVizId {
  PieGridNow = 'pie-grid-now',
  WindEcoNow = 'wind-eco-now',
  PieEcoLast28 = 'pie-eco-last28',
  MapGridNow = 'gbmap-grid-now',
}

interface Props {
  title: string
  context?: Document
  data: LiveGenData
  showLiveIcon: boolean
  liveIconColour: string
  vizId: DataVizId
  extraProps?: { [key: string]: any }
  primary: boolean
}

const vizMap = {
  [DataVizId.PieGridNow]: {
    Viz: EcoGenMixPie,
    props: (data: LiveGenData) => ({
      units: 'W',
      showLegend: 'dynamic',
      showPercentages: 'hover',
      showValue: true,
      showTitle: true,
      showCategories: true,
      defaultTitle: '',
      volumes: data.gridMixNow,
    }),
  },
  [DataVizId.WindEcoNow]: {
    Viz: EcoGenWindMill,
    props: (data: LiveGenData) => ({
      power: data.ourMixNow.wind + data.ourMixNow.solar,
      peak: data.ourPeakOutput.combined,
    }),
  },
  [DataVizId.PieEcoLast28]: {
    Viz: EcoGenMixPie,
    props: (data: LiveGenData) => ({
      units: 'Wh',
      showLegend: 'dynamic',
      showPercentages: 'always',
      showValue: true,
      showTitle: true,
      showCategories: true,
      defaultTitle: '',
      volumes: data.ourLast28,
    }),
  },
  [DataVizId.MapGridNow]: {
    Viz: EcoGenGridMapProgress,
    props: (data: LiveGenData) => {
      const [renewables, total] = Object.entries(data.gridMixNow).reduce(
        (total, [fuel, value]) => {
          if (['solar', 'hydro', 'wind', 'biomass'].includes(fuel))
            total[0] += value
          total[1] += value
          return total
        },
        [0, 0]
      )

      return {
        percentage: Math.round((renewables / total) * 100),
      }
    },
  },
}

/**
 * Component for presenting a small data viz block with heading, context and data vizualisation
 */
const DataViz: React.FC<Props> = ({
  title,
  vizId,
  context,
  showLiveIcon,
  liveIconColour = 'contrast',
  data,
  extraProps,
}: Props) => {
  const [celebrated, setCelebrated] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const elemRef = useRef<HTMLDivElement>(null)
  const extra = extraProps ? JSON.parse(extraProps.internal.content) : {}

  const container: ThemeUIStyleObject = {
    padding: ['16px', '32px'],
    paddingBottom: '0 !important',
  }

  const { Viz, props } = vizMap[vizId]

  const confetti = useConfetti()
  const isOnScreen = useOnScreen(elemRef)

  const vizProps = props(data)

  if (
    !celebrated &&
    vizId === DataVizId.MapGridNow &&
    isOnScreen &&
    vizProps.percentage === 100
  ) {
    confetti.addConfetti()
    confetti.addConfetti({
      emojis: ['🌱', '🌿', '🌞', '🌏', '♻️', '💚'],
      emojiSize: 40,
    })
    setCelebrated(true)
  }

  return (
    <div sx={container} ref={elemRef}>
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid black',
          marginBottom: '4px',
          height: '40px',
        }}
      >
        <div sx={{ width: '40px', color: liveIconColour }}>
          {showLiveIcon && <LiveIcon />}
        </div>
        <Heading
          as="h4"
          sx={{
            flex: 1,
            textAlign: 'center',
            lineHeight: 2.2,
            fontSize: '16px',
          }}
        >
          {title}
        </Heading>
        <div sx={{ width: '40px', textAlign: 'end' }}>
          {context && (
            <IconButton onClick={() => setShowModal(true)}>
              <InfoIcon />
            </IconButton>
          )}
        </div>
      </div>

      {showLiveIcon && (
        <Text
          sx={{ marginBottom: '16px', fontSize: '12px', textAlign: 'center' }}
          as="p"
          variant="intro"
        >
          Last updated at{' '}
          {data.fetchedAt.toTimeString().split(':').slice(0, 2).join(':')}
        </Text>
      )}

      <div sx={{ height: ['300px', null, null, '360px'] }}>
        <Viz {...vizProps} {...extra} />
      </div>

      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        {context ? renderRichText(context) : null}
      </Modal>
    </div>
  )
}

export default DataViz
