import React from 'react'
import { Grid, Text, ThemeUIStyleObject, Heading } from 'theme-ui'

interface Props {
  topic?: string
  heading?: string
}

/**
 * Presents the topic, title, subtitle and standfirst on every standard
 * article page
 */
const ClientHeader: React.FC<Props> = ({ topic, heading }) => {
  const grid: ThemeUIStyleObject = {
    gridTemplateColumns: [
      '[start] repeat(7, 1fr) [end] 1fr repeat(4, 1fr)',
      null,
      '1fr [start] repeat(6, 1fr) [end] 1fr repeat(4, 1fr)',
    ],
    rowGap: 10,

    // horizontal rule
    '::before': {
      content: topic && '""',
      display: ['none', null, 'block'],
      borderTop: 'double',
      position: 'relative',
      top: '50%',
    },
  }

  const topicStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    letterSpacing: '0.4em',
  }

  const titleStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    fontSize: [4, 5],
    fontWeight: 300,
  }

  const headingStyle: ThemeUIStyleObject = {
    gridColumn: 'start / end',
    fontSize: [4, 5],
    marginTop: -11,
  }

  return (
    <Grid sx={grid} variant="default">
      <Text sx={topicStyle} variant="primary">
        {topic}
      </Text>
      <Heading as="h2" sx={titleStyle} variant="lrg">
        Case Study
      </Heading>
      {heading && (
        <Heading as="h1" sx={headingStyle} variant="lrg">
          {heading}
        </Heading>
      )}
    </Grid>
  )
}

export default ClientHeader
