import React from 'react'
import { Box, Flex, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import footprintImage from '../../images/footprint-large.svg'
import InfoBox from '../info-box'

interface Props {
  footprint?: number
}

const circleSx: ThemeUIStyleObject = {
  width: '2.4rem',
  minWidth: '2.4rem',
  height: '2.4rem',
  borderRadius: '100%',
  marginRight: '1rem',
  marginTop: '-0.5rem',
  border: '1px solid',
}

type CircleProps = {
  variant: string
}

const Circle = ({ variant = '' }: CircleProps) => {
  let colourVariantSx: ThemeUIStyleObject = {}

  if (variant === 'red') {
    colourVariantSx = {
      background: '#EB5757',
      borderColor: '#CE4E4E',
    }
  } else if (variant === 'orange') {
    colourVariantSx = {
      background: '#F2994A',
      borderColor: '#CE8340',
    }
  } else if (variant === 'green') {
    colourVariantSx = {
      background: '#9AD43A',
      borderColor: '#7CA933',
    }
  }

  return <Box sx={{ ...circleSx, ...colourVariantSx }} />
}

const carbonTotalInfoSx: ThemeUIStyleObject = {
  flexDirection: 'column',
  marginBottom: [undefined, undefined, '5rem'],
  background: '#F8F8F8',
  border: '1px solid #F2F2F2',
  padding: '2.92rem 2.92rem 1.42rem',
}

const carbonTotalInfoBlockSx: ThemeUIStyleObject = {
  marginBottom: '1.5rem',
}

const carbonTotalInfoTextSx: ThemeUIStyleObject = {
  fontSize: '1.1rem',
}

const CarbonFootprint: React.FC<Props> = ({ footprint }) => {
  const wrapperSx: ThemeUIStyleObject = {
    flexWrap: 'wrap',
    flexBasis: '100%',
    paddingLeft: ['10%', '10%', '10%'],
    paddingRight: ['10%', '10%', '40%'],
    paddingTop: '7.66rem',
  }

  const allItemsSx: ThemeUIStyleObject = {
    flexBasis: '100%',
  }

  const openTextSx: ThemeUIStyleObject = {
    flexBasis: ['60%', '100%'],
    marginBottom: '1.25rem',
    position: 'relative',
    fontSize: ['2.5rem', '2.5rem', '2rem'],
    marginLeft: [undefined, undefined, '10rem'],
    '&::before': {
      content: '""',
      display: ['none', 'none', 'block'],
      position: 'absolute',
      backgroundColor: 'black',
      width: '8.16rem',
      height: '0.16rem',
      left: '-9rem',
      top: '50%',
    },
  }

  const footprintTextSx: ThemeUIStyleObject = {
    ...allItemsSx,
    position: 'relative',
    paddingTop: '1rem',
    paddingBottom: ['1rem', '1rem', '2rem'],
    marginLeft: [undefined, undefined, '10rem'],
    display: 'flex',
    alignItems: 'flex-end',
    fontSize: ['5rem', '5rem', '7rem'],
    '&::before': {
      content: '""',
      backgroundImage: `url(${footprintImage})`,
      backgroundSize: '136%',
      backgroundPosition: 'center',
      height: ['8rem', '8rem', '8.33rem'],
      width: ['8rem', '8rem', '8.33rem'],
      display: 'block',
      position: 'absolute',
      left: ['auto', 'auto', '-8.33rem'],
      right: ['0', '0', 'auto'],
      top: ['-8.33rem', '-5.33rem', '-1rem'],
    },
  }

  return (
    <>
      <Flex sx={wrapperSx}>
        <Heading sx={openTextSx} variant="med">
          Your carbon footprint is
        </Heading>
        <Heading sx={footprintTextSx} variant="xl">
          {Number((footprint / 1000).toFixed(2))} tonnes
        </Heading>
        <Flex sx={carbonTotalInfoSx}>
          <Flex sx={carbonTotalInfoBlockSx}>
            <Circle variant="red" />
            <Text sx={carbonTotalInfoTextSx}>
              <strong>{'>'} 5 tonnes:</strong> your carbon footprint is high. At
              home, take a look at insulating your home, switching to
              renewables, using less water and include some meat free days every
              week. Small every day steps make a difference.
            </Text>
          </Flex>
          <Flex sx={carbonTotalInfoBlockSx}>
            <Circle variant="orange" />
            <Text sx={carbonTotalInfoTextSx}>
              <strong>Between 2 and 5 tonnes:</strong> your carbon footprint is
              moving in the right direction but we all need to aim for zero. How
              about taking one less international flight a year or removing red
              meat from your diet?
            </Text>
          </Flex>
          <Flex sx={carbonTotalInfoBlockSx}>
            <Circle variant="green" />
            <Text sx={carbonTotalInfoTextSx}>
              <strong>{'<'} 2 tonnes:</strong>
              {` you have a low carbon footprint.
              If you went car free (which is one of the biggest ways to reduce
              your carbon footprint) you would be close to zero tonnes.'`}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex>
        <InfoBox />
      </Flex>
    </>
  )
}

export default CarbonFootprint
