import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { isFixed } from '../utils/helpers'
import { MaybeGatsbyImageProps } from '../utils/types'

interface Props {
  description?: string
  lang?: string
  meta?: [
    {
      name: string
      content: string
    }
  ]
  image?: MaybeGatsbyImageProps | string
  title?: string
  titleTemplate?: string
}

const SEO: React.FC<Props> = ({
  description = '',
  lang = 'en',
  meta = [],
  image,
  title = '',
  titleTemplate = '',
}) => {
  const { site, file, gitBranch, gitCommit } = useStaticQuery(
    graphql`
      query {
        gitCommit(latest: { eq: true }) {
          hash
        }
        gitBranch(current: { eq: true }) {
          name
        }
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        file(relativePath: { eq: "images/our-mission-flag.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  let ogImage = null

  if (typeof image === 'string') {
    ogImage = {
      width: 1200,
      height: 686,
      src: image,
    }
  } else {
    ogImage =
      (image && isFixed(image) && image.gatsbyImageData) ||
      file.childImageSharp.gatsbyImageData
  }

  const ogImageUrl = ogImage?.src || ogImage?.images?.fallback?.src

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate || `%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: ogImageUrl,
        },
        {
          property: 'og:image:width',
          content: ogImage.width,
        },
        {
          property: 'og:image:height',
          content: ogImage.height,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `git:branch`,
          content: gitBranch.name,
        },
        {
          name: `git:revision`,
          content: gitCommit.hash,
        },
      ].concat(meta)}
    >
      <script
        async
        src={`https://www.googleoptimize.com/optimize.js?id=${process.env.GATSBY_GOOGLE_OPTIMIZE_ID}`}
      />

      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
    </Helmet>
  )
}

export default SEO
