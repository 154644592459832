import React from 'react'
import { Stack } from 'raam'
import { Box, Heading, Text, ThemeUIStyleObject } from 'theme-ui'

import dots from '../images/black-dots.svg'

interface Props {
  heading?: string
  items?: Array<{
    title?: string
    body?: string
    link?: string
  }>
}

const ComplaintSteps: React.FC<Props> = ({ heading, items }) => {
  const wrapper: ThemeUIStyleObject = {
    textAlign: 'center',
    maxWidth: '50rem',
    marginX: 'auto',
  }

  const sectionHeading: ThemeUIStyleObject = {
    maxWidth: '50rem',
    marginTop: '1.5rem',
    marginBottom: '5rem',
    textAlign: 'center',
    fontWeight: '700',
  }

  const group: ThemeUIStyleObject = {
    textAlign: 'center',
    paddingX: [13, 15, 16],
    paddingY: [12, 14, 15],
    borderRadius: '0.5rem',
    backgroundColor: 'primary',
    marginX: 'auto',
  }

  const divider: ThemeUIStyleObject = {
    padding: [14.5, 15.5, 15.5],
    backgroundImage: `url(${dots})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: ['1rem', '1.2rem', '1.2rem'],
  }

  const description: ThemeUIStyleObject = {
    marginY: 12,
  }

  return (
    <Stack sx={wrapper}>
      <Heading sx={sectionHeading} as="h2" variant="med">
        {heading}
      </Heading>
      {items?.map((item, i) => (
        <Stack key={`stack-${i}`}>
          <Box sx={group}>
            <Heading as="h3" variant="sml">
              {item.title}
            </Heading>
            <Text as="div" variant="intro" sx={description}>
              {item.body}
            </Text>
          </Box>
          {item === items[2] ? '' : <Box sx={divider} />}
        </Stack>
      ))}
    </Stack>
  )
}

export default ComplaintSteps
