/**
 * These functions are used to transform a paragraph containing a single link
 * into a block link, presented as a button.
 *
 * The renderInlineLink function can be overriden if a component needs to
 * render additional link types.
 */

import React, { ReactNode } from 'react'
import { Box, Link } from 'theme-ui'
import { CommonNode as Node } from '@contentful/rich-text-react-renderer'
import { Block as BlockNode } from '@contentful/rich-text-types'
import { isInlineBlock, toString } from './helpers'
import { formatPageLink } from '../utils/helpers'
import PageLink from '../components/page-link'
import AnchorLink from '../components/anchor-link'
import AssetLink from '../components/asset-link'
import JoinLink from '../components/join-link'

export const renderInlineLink = (node: Node): ReactNode => {
  const text = toString(node)

  switch (node.nodeType) {
    case 'hyperlink':
      if (node.data.uri.startsWith('#')) {
        return <AnchorLink href={node.data.uri}>{text}</AnchorLink>
      } else if (node.data.uri.includes('my.ecotricity.co.uk')) {
        return (
          <JoinLink link={node.data.uri} variant="button">
            {text}
          </JoinLink>
        )
      } else {
        return (
          <Link href={node.data.uri} variant="button">
            {text}
          </Link>
        )
      }

    case 'entry-hyperlink': {
      const to = formatPageLink(node.data.target)
      return (
        <PageLink to={to} variant="button">
          {text}
        </PageLink>
      )
    }

    case 'asset-hyperlink':
      return (
        <AssetLink
          href={node.data.target.file?.url}
          title={text}
          size={node.data.target.file?.details.size}
          mimeType={node.data.target.file?.contentType}
        />
      )
  }
}

export const renderBlockLink = (
  node: Node,
  render = renderInlineLink
): ReactNode => {
  if (isInlineBlock(node)) {
    const link = render((node as BlockNode).content[1])
    return link && <Box>{link}</Box>
  }
}
