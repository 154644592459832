import { Theme, get } from 'theme-ui'

/* https://github.com/modularscale/modularscale-js */
export const modularScale = (base: number, ratio: number, units = 'px'): [] => {
  return new Proxy([], {
    get: (_, i: number) => {
      return isNaN(i) || (base < 1 && i < 1)
        ? i
        : Math.pow(ratio, i) * base + units
    },
  })
}

/* https://css-tricks.com/simplified-fluid-typography/ */
export const fluidTypography = (
  base: number,
  ratio: number[],
  gradient: number,
  units = 'px'
): [] => {
  return new Proxy([], {
    get: (_, i: number) => {
      const min = modularScale(base, ratio[0], units)
      const max = modularScale(base, ratio[1], units)
      const factor = 2 * gradient * i
      return isNaN(i) ? i : `min(max(${min[i]}, ${factor}vw), ${max[i]})`
    },
  })
}

/* use the above method to scale any CSS length according to the screen width */
export const fluidScale =
  (value: string | number, factor: number, gradient: number) =>
  (theme: Theme): string => {
    // get theme value if a number was provided
    const v = isNaN(value as number) ? value : get(theme, `space.${value}`)

    // isolate the numeric value from the unit
    const [num, unit] = v ? v.match(/[\d.]+|\D+/g) : []

    const product = num * factor
    const suffix = unit || ''
    const min = Math.min(num, product) + suffix
    const max = Math.max(num, product) + suffix

    return `min(max(${min}, ${gradient}vw), ${max})`
  }

/* find total width of grid columns, including gaps */
export const spanWidth =
  (
    span: number,
    cols: number,
    gap: number | string,
    trailingGap = true
  ): ((theme: Theme) => string) =>
  (theme: Theme) => {
    const gapWidth = get(theme, `space.${gap}`)
    const totalGapWidth = `${cols - 1} * ${gapWidth}`
    const totalColWidth = `100% - ${totalGapWidth}`
    const spanGapWidth = `${gapWidth} * ${span - (trailingGap ? 0 : 1)}`
    const spanColWidth = `(${totalColWidth}) / ${cols} * ${span}`
    return `calc(${spanColWidth} + ${spanGapWidth})`
  }

export const gridTemplateAreas = (
  template: Array<Array<string | null> | undefined>
): string => {
  const rows = template.map(row => {
    if (Array.isArray(row)) {
      const cols = row.map(col => col || '.')
      return `"${cols.join(' ')}"`
    }
    return false
  })
  return rows.filter(Boolean).join('\n')
}

export const repeatGridArea = (count: number, area: string | null): string =>
  Array(count)
    .fill(area || '.')
    .join(' ')
