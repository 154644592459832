import React, { useEffect, useRef } from 'react'
import { IconButton } from 'theme-ui'

import CloseIcon from '../assets/close-icon.svg'

interface Props {
  isOpen: boolean
  children: React.ReactNode
  onClose: () => void
}

const Modal: React.FC<Props> = ({ isOpen, onClose, children }) => {
  const ref = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    ref.current?.addEventListener('close', onClose)
    return () => ref.current?.removeEventListener('close', onClose)
  }, [onClose])

  useEffect(() => {
    if (isOpen) {
      ref.current?.showModal()
    } else {
      ref.current?.close()
    }
  }, [isOpen])

  return (
    <dialog
      ref={ref}
      aria-modal="true"
      onClick={() => onClose()}
      sx={{
        background: 'transparent',
        border: 'none',
        '::backdrop': {
          background: 'rgba(1,1,1,0.3)',
        },
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
        }}
      >
        <IconButton
          sx={{ color: 'white', margin: '8px' }}
          onClick={() => onClose()}
        >
          <CloseIcon />
        </IconButton>
        <div
          sx={{
            border: 'none',
            borderRadius: '10px',
            padding: '30px',
            background: 'white',
            marginBottom: '20px',
          }}
        >
          {children}
        </div>
      </div>
    </dialog>
  )
}

export default Modal
