import React from 'react'
import { Box, Flex, Image, Text } from 'theme-ui'
import { Wrap } from 'raam'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { removeDuplicates, trimRight } from '../utils/helpers'
import PageLink from './page-link'
import filterIcon from '../images/filter-icon.svg'

interface Props {
  path?: string
  tags?: Array<{
    name?: string
    slug?: string
  }>
}

const NewsFilters: React.FC<Props> = ({ path, tags }) => {
  const data = useStaticQuery<GatsbyTypes.NewsFiltersQuery>(graphql`
    query NewsFilters {
      pages: allContentfulPage(
        filter: { topic: { slug: { eq: "our-news" } } }
      ) {
        edges {
          node {
            tags {
              slug
              name
            }
            createdAtTimestamp
          }
        }
      }
    }
  `)

  const topic = '/our-news'

  const allTags = data.pages.edges.flatMap(
    edge => edge.node.tags || []
  ) as Props['tags']

  const uniqueTags = allTags?.filter(
    (t1, index) => allTags.findIndex(t2 => t1.slug === t2.slug) === index
  )

  const targetTags = tags || uniqueTags

  const allYears = removeDuplicates(
    data.pages.edges.flatMap(edge =>
      edge.node.createdAtTimestamp
        ? new Date(edge.node.createdAtTimestamp).getFullYear()
        : undefined
    )
  )

  return (
    <Flex
      sx={{
        flexDirection: ['column', 'row'],
        justifyContent: 'space-between',
        mt: [0, null, tags ? -9 : -14],
        mb: 17,
      }}
    >
      <Box sx={{ display: ['block', null, 'none'], mr: 15, mb: [11, 0] }}>
        <Flex sx={{ mb: 8, alignContent: 'center' }}>
          {!tags && <Image sx={{ width: '1rem', mr: 7 }} src={filterIcon} />}
          <Text>
            {tags ? (tags.length ? 'Article tags' : '') : 'Filter articles'}
          </Text>
        </Flex>
        <select
          sx={{ variant: 'forms.smallSelect' }}
          onChange={e => navigate(`/our-news/${e.target.value}`)}
          defaultValue={path?.split('/').filter(Boolean).slice(-1).toString()}
        >
          <option value="">Select a tag</option>
          {targetTags?.map((tag, i) => (
            <option key={i} value={tag.slug}>
              {tag.name}
            </option>
          ))}
        </select>
      </Box>
      <Box sx={{ display: ['none', null, 'block'], mr: 15 }}>
        <Flex sx={{ mb: 8, alignContent: 'center' }}>
          {!tags && <Image sx={{ width: '1rem', mr: 7 }} src={filterIcon} />}
          <Text>
            {tags ? (tags.length ? 'Article tags' : '') : 'Filter articles'}
          </Text>
        </Flex>
        <Wrap as="ul" gap={8}>
          {!tags && (
            <PageLink
              to={topic}
              className={trimRight(path, '/') === topic ? 'active' : ''}
              variant="filter"
            >
              all
            </PageLink>
          )}
          {targetTags?.map((filter, i) => {
            const link = `${topic}/${filter.slug}`
            return (
              <PageLink
                key={i}
                to={link === trimRight(path, '/') ? topic : link}
                activeClassName="active"
                className={tags ? 'active' : ''}
                partiallyActive={true}
                variant="filter"
              >
                {filter.name}
              </PageLink>
            )
          })}
        </Wrap>
      </Box>
      <Box sx={{ flexShrink: 0 }}>
        <Text sx={{ mb: 8 }}>Browse archives</Text>
        <select
          sx={{ variant: 'forms.smallSelect' }}
          onChange={e => navigate(`/our-news/${e.target.value}`)}
          defaultValue={path?.split('/').filter(Boolean).slice(-1).toString()}
        >
          <option value="">Select a year</option>
          {allYears
            .sort()
            .reverse()
            .map((year, i) => (
              <option key={i} value={year}>
                {year}
              </option>
            ))}
        </select>
      </Box>
    </Flex>
  )
}

export default NewsFilters
