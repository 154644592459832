import React, { ReactNode } from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import Carousel from '../components/carousel'
import PartnersList from '../components/partners-list'
import Article from '../components/article'
import LogoList from '../components/logo-list'

export const query = graphql`
  query PartnersArchive($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      partnerPromotion
      sections {
        __typename
        ... on ContentfulArticle {
          title
          body {
            raw
            references {
              ...ArticleReferences
            }
          }
          aside {
            raw
            references {
              ...ArticleReferences
            }
          }
        }
      }
    }
    partners: allContentfulPartner(
      filter: { contentful_id: { nin: ["4jm18UIqZTpdIZHHscAb0N"] } }
    ) {
      nodes {
        name
        page {
          slug
          topic {
            slug
            context {
              slug
            }
          }
        }
      }
    }
    featured: allContentfulPartner(
      filter: {
        contentful_id: {
          in: [
            "6PFRfpUwX2hM7FiKrrlI2m"
            "2uKvBLsdLquM4uX0uErMXX"
            "wJS49rSxb6d1itUYuUgL3"
            "59ndTffhaZtovV9HLp3v0x"
            "11YdA58DYOomXMMW04EgNr"
          ]
        }
      }
    ) {
      nodes {
        name
        logo {
          gatsbyImageData(
            width: 120
            quality: 60
            layout: FIXED
            formats: [WEBP]
          )
        }
        page {
          slug
          topic {
            slug
            context {
              slug
            }
          }
        }
      }
    }
  }
`

const PartnersArchive: React.FC<PageProps<GatsbyTypes.PartnersArchiveQuery>> =
  ({ data: { page = {}, partners = {}, featured = {} } }) => {
    const alphabet = [...'ABCDEFGHIJKLMNOPQRSTUVWXYZ']

    const pages = alphabet.reduce<Record<string, ReactNode>>(
      (items, letter) => {
        items[letter] = (
          <PartnersList
            partners={partners.nodes
              ?.filter(partner => partner.name && partner.name[0] === letter)
              .map(partner => ({
                name: partner.name,
                page:
                  partner.page && partner.page[0]
                    ? {
                        slug: partner.page[0].slug,
                        topic: partner.page[0].topic,
                      }
                    : undefined,
              }))}
          />
        )
        return items
      },
      {}
    )

    const article = page.sections?.find(
      section => section?.__typename === 'ContentfulArticle'
    ) as GatsbyTypes.ContentfulArticle | undefined

    const logos = featured.nodes?.map(partner => ({
      title: partner.name,
      logo: partner.logo,
      page: partner.page && partner.page[0],
    }))

    return (
      <Layout page={page} subnav={true} masthead="default">
        <Section as="div" variant="header">
          <PageHeader
            topic={page.topic?.heading || page.topic?.name}
            heading={page.heading || page.title}
            subheading={page.subheading}
            standfirst={page.standfirst?.standfirst}
          />
        </Section>
        <Section pos={0} count={3} variant="page">
          <Carousel items={pages} />
        </Section>
        <Section pos={1} count={3} variant="page">
          <Article body={article?.body} aside={article?.aside} />
        </Section>
        <Section pos={2} count={3} variant="page">
          <LogoList items={logos} variant="tight" />
        </Section>
      </Layout>
    )
  }

export default PartnersArchive
