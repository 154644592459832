import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, ThemeUIStyleObject, Link } from 'theme-ui'

import PhoneIcon from '../assets/phone.svg'

import { fluidScale } from '../utils/mixins'
import Logo from './logo'

const HeaderLogo: React.FC = props => {
  const {
    site: {
      siteMetadata: { phoneNumber, phoneNumberHref },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            phoneNumber
            phoneNumberHref
          }
        }
      }
    `
  )

  const wrapper: ThemeUIStyleObject = {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: ['column', 'column', 'column', 'row'],
    display: 'flex',
    alignItems: ['center', 'center', 'flex-start', 'center'],
  }

  const logo: ThemeUIStyleObject = {
    width: fluidScale('18.6rem', 0.7, 14),
  }

  const link: ThemeUIStyleObject = {
    textDecoration: 'none',
    fontWeight: 'bold',
    '&:hover': {
      color: 'contrast',
    },
    marginLeft: [null, null, null, 10],
  }

  return (
    <Box sx={wrapper} {...props}>
      <Logo sx={logo} />
      <Link sx={link} href={phoneNumberHref}>
        <PhoneIcon />
        {` ${phoneNumber}`}
      </Link>
    </Box>
  )
}

export default HeaderLogo
