import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import Logo from './logo'
import { fluidScale } from '../utils/mixins'

const FooterLogo: React.FC = () => {
  const style: ThemeUIStyleObject = {
    width: fluidScale('14rem', 0.8, 16),
    mx: 'auto',
  }

  const fallback = {
    'width ': '14rem',
  }

  return (
    <Logo
      sx={{
        ...fallback,
        ...style,
      }}
    />
  )
}

export default FooterLogo
