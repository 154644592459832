import React from 'react'
import { Heading, Box, Link, ThemeUIStyleObject, Text } from 'theme-ui'
import { Props as VideoProps } from './video'
import { MaybeGatsbyImageProps } from '../utils/types'
import {
  Document,
  BLOCKS,
  Node,
  renderRichText,
  renderBlockLink,
} from '../rich-text'

type Props = {
  title?: string
  type?: string
  items?: Array<{
    title?: string
    body?: Document
    image?: MaybeGatsbyImageProps
    video?: VideoProps
  }>
  description?: Document
  ctaText?: string
  ctaUrl?: string
  ctaOpenInNewTab?: boolean
}

const blockRenderers = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) =>
      renderBlockLink(node) || (
        <Heading sx={descriptionStyle} as="h2">
          {children}
        </Heading>
      ),
  },
}

const imgStyle: ThemeUIStyleObject = {
  minHeight: '5.83rem',
  maxHeight: '5.83rem',
}

const imgWrapperStyle: ThemeUIStyleObject = {
  textAlign: 'center',
  marginBottom: '1rem',
}

const titleStyle: ThemeUIStyleObject = {
  textAlign: 'center',
  marginBottom: '3.5rem',
  fontSize: '2.5rem',
}

const button: ThemeUIStyleObject = {
  display: 'block',
  width: '18rem',
  marginTop: '2rem',
  marginX: 'auto',
  fontSize: '1.25rem',
}

const descriptionStyle: ThemeUIStyleObject = {
  textAlign: 'center',
  fontSize: '1.7rem',
  fontWeight: 'normal',
  fontFamily: 'body',
  margin: ['auto', 'initial'],
  maxWidth: ['17rem', '100%'],
}

const ThreePointBanner: React.FC<Props> = ({
  title = '',
  type = '',
  items = [],
  description,
  ctaText = '',
  ctaUrl = '',
  ctaOpenInNewTab = false,
}) => {
  const isWide = type === 'Three Point Banner Wide'

  const itemsWrapper: ThemeUIStyleObject = {
    display: 'flex',
    flexWrap: 'wrap',
    marginX: 'auto',
    justifyContent: ['center', 'space-between'],
    maxWidth: isWide ? '72.66rem' : '49.66rem',
  }

  const itemWrapper: ThemeUIStyleObject = {
    width: [
      '100%',
      isWide ? '100%' : '13.33rem',
      isWide ? '20.33rem' : '13.33rem',
    ],
    marginBottom: '3rem',
  }

  const textStyle: ThemeUIStyleObject = {
    textAlign: 'center',
    fontSize: '1.27rem',
    fontWeight: '600',
    maxWidth: [
      isWide ? '20.33rem' : '13.33rem',
      isWide ? '20.33rem' : 'initial',
      'initial',
    ],
    marginX: ['auto', isWide ? 'auto' : 'initial', 'initial'],
  }

  const itemRenderers = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) =>
        renderBlockLink(node) || (
          <Text sx={textStyle} as="p" variant="intro">
            {children}
          </Text>
        ),
    },
  }

  const linkParams = {
    href: ctaUrl,
    sx: button,
    variant: 'button',
  }

  if (ctaOpenInNewTab) {
    linkParams.target = '_blank'
  }

  //This fixes "image" being a redudant phrase in the alt text
  const newItems = []
  items.map(({ body, image }) => {
    newItems.push({
      body: body,
      file: {
        url: image?.file?.url,
        altText: image?.title,
      },
    })
    return null
  })

  return (
    <Box>
      <Heading sx={titleStyle} as="h2" variant="med">
        {title}
      </Heading>
      <Box sx={itemsWrapper}>
        {newItems.map(({ body, file }, index) => (
          <Box sx={itemWrapper} key={`box-${index}`}>
            <Box sx={imgWrapperStyle}>
              <img sx={imgStyle} src={file?.url} alt={file?.altText} />
            </Box>
            {body && renderRichText(body, itemRenderers)}
          </Box>
        ))}
      </Box>
      {description && ctaText && ctaUrl ? (
        <Box>
          {description && renderRichText(description, blockRenderers)}
          <Link {...linkParams}>{ctaText}</Link>
        </Box>
      ) : null}
    </Box>
  )
}

export default ThreePointBanner
