import carbonFootprintElectricityMatrix from '../../carbon-footprint-electricity-matrix'
import carbonFootprintHeatMatrix from '../../carbon-footprint-heat-matrix'
import carbonFootprintTransportMatrix from '../../carbon-footprint-transport-matrix'
import carbonFootprintFoodMatrix from '../../carbon-footprint-food-matrix'
import {
  ValidationType,
  ValidationCategory,
  ValidationStep,
  StoredData,
  StoredCategory,
  StoredValues,
} from './carbon-calculator-types'

type CategoryProgressType = Array<{
  name?: string
  completed?: boolean
}>

export const getStoredData = (): void | StoredData => {
  // if running server-side localstorage is not defined
  if (typeof window == 'undefined') {
    return
  }

  let ccData = localStorage.getItem('ccData')

  if (!ccData) {
    ccData = JSON.stringify({
      categories: [],
    })

    localStorage.setItem('ccData', ccData)
  }

  return JSON.parse(ccData)
}

export const getStoredCategory = (
  ccData: StoredData,
  category: string
): void | StoredCategory => {
  if (!ccData?.categories) {
    return
  }

  let ccDataCategory = ccData.categories.find(
    ccDataCategory => ccDataCategory.name === category.toLocaleLowerCase()
  )

  if (!ccDataCategory) {
    ccDataCategory = {
      name: category.toLocaleLowerCase(),
      values: {},
    }
    ccData.categories.push(ccDataCategory)
  }
  return ccDataCategory
}

export const getStoredValue = (
  ccDataCategory: StoredCategory,
  value: string
): string | boolean | void => {
  if (!ccDataCategory?.values) {
    return
  }

  const storedValue = ccDataCategory.values[value]
  if (!storedValue) {
    ccDataCategory.values[value] = null
  }

  return storedValue
}

export const getRedirectUrl = (
  stepValidation: ValidationType,
  ccData: StoredData
): boolean | string => {
  let redirectUrl: string | boolean = false

  stepValidation.categories.forEach(
    (validationCategory: ValidationCategory) => {
      if (redirectUrl) return false

      validationCategory.steps.forEach(
        (validationStep: ValidationStep): boolean | string => {
          if (redirectUrl) return false

          const storedCategory: undefined | StoredCategory =
            ccData?.categories.find(
              (storedCategory: StoredCategory) =>
                storedCategory.name === validationCategory.name
            )

          if (
            validationStep.multipleChoice &&
            storedCategory.values[validationStep.value] != null
          ) {
            return false
          }

          if (
            !storedCategory ||
            (storedCategory && !storedCategory.values[validationStep.value]) ||
            (storedCategory &&
              storedCategory.values[validationStep.value] &&
              !validationStep.values.find(
                validValue =>
                  validValue.value ===
                  storedCategory.values[validationStep.value]
              ))
          ) {
            redirectUrl = validationStep.url
          }
        }
      )
    }
  )

  return redirectUrl
}

export const getCategoryProgressData = (
  validation: ValidationType,
  ccData: StoredData
): CategoryProgressType | void => {
  if (!ccData?.categories) {
    return
  }

  const categoryProgress: CategoryProgressType = []

  validation.categories.forEach(validationCategory => {
    const ccDataCategory = ccData.categories.find(
      category => category.name === validationCategory.name
    )
    let isCategoryValid = true
    validationCategory.steps.forEach(validationStep => {
      if (!isCategoryValid) return

      const validCcValue = validationStep.multipleChoice
        ? ccDataCategory.values[validationStep.value] !== null
        : validationStep.values.find(
            validValue =>
              ccDataCategory &&
              ccDataCategory.values[validationStep.value] === validValue.value
          )

      isCategoryValid = !!validCcValue
    })

    categoryProgress.push({
      name: validationCategory.name,
      completed: isCategoryValid,
    })
  })

  return categoryProgress
}

export const getCarbonFootprint = (overrideValues: StoredValues): number => {
  const carbonData = getStoredData()

  if (!carbonData) {
    return 0
  }

  let totalCarbonfootprint = 0

  carbonData.categories.forEach(({ name, values }: StoredCategory): void => {
    if (overrideValues) {
      values = {
        ...values,
        ...overrideValues[name],
      }
    }

    switch (name) {
      case 'energy': {
        const energyFootprint = getElectricityCarbonFootprint(values)
        const heatFootprint = getHeatCarbonFootprint(values, carbonData)

        totalCarbonfootprint =
          totalCarbonfootprint + energyFootprint + heatFootprint
        break
      }
      case 'transport': {
        const transportFootprint = getTransportCarbonFootprint(values)

        totalCarbonfootprint = totalCarbonfootprint + transportFootprint
        break
      }
      case 'food': {
        const foodFootprint = getFoodCarbonFootprint(values)

        totalCarbonfootprint = totalCarbonfootprint + foodFootprint
        break
      }
    }
  })

  return totalCarbonfootprint
}

/**
 * Retrieve value from accessing electricity matrix object with energy values
 *
 * @param values energy question answers
 * @returns {number}
 */
const getElectricityCarbonFootprint = (values: StoredValues): number => {
  const darkGreenElectricityTariff =
    carbonFootprintElectricityMatrix[values.darkGreenElectricityTariff]

  if (Number.isInteger(darkGreenElectricityTariff)) {
    return darkGreenElectricityTariff
  }

  const solarPv = values?.energySavingMeasures?.solarPv ? 'yes' : 'no'

  let energyFootprint =
    darkGreenElectricityTariff[solarPv][values.adults][values.bedrooms][
      values.propertyType
    ]

  const energySavingMeasures = values.energySavingMeasures
  if (energySavingMeasures.solarPv) {
    energyFootprint = energyFootprint * 0.9
  }

  return energyFootprint
}

/**
 * Retrieve value from accessing heat matrix object with energy values
 *
 * @param values energy question answers
 * @returns {number}
 */
const getHeatCarbonFootprint = (
  values: StoredValues,
  carbonData: StoredData
): number => {
  const carbonNeutralised = carbonFootprintHeatMatrix[values.carbonNeutralised]

  if (Number.isInteger(carbonNeutralised)) {
    return carbonNeutralised
  }

  let heatEnergyfootprint =
    carbonNeutralised[values.fuelType][values.adults][values.bedrooms][
      values.propertyType
    ]

  const energyCategoryValues = getStoredCategory(carbonData, 'energy')
  const energySavingMeasures = energyCategoryValues.values.energySavingMeasures

  if (!energySavingMeasures) {
    return heatEnergyfootprint
  }

  if (energySavingMeasures.condensingBoiler) {
    heatEnergyfootprint = heatEnergyfootprint * 0.962
  }

  if (energySavingMeasures.cavityWallInsulation) {
    heatEnergyfootprint = heatEnergyfootprint * 0.919
  }

  if (energySavingMeasures.loftInsulation) {
    heatEnergyfootprint = heatEnergyfootprint * 0.974
  }

  if (energySavingMeasures.solidWallInsulation) {
    heatEnergyfootprint = heatEnergyfootprint * 0.83
  }

  return heatEnergyfootprint
}

/**
 * Retrieve value from accessing transport matrix object with transport values
 *
 * @param values transport question answers
 * @returns {number}
 */
const getTransportCarbonFootprint = (values: StoredValues): number => {
  let transportFootprintValue = 0

  if (values.travelCarMotorcycleType !== 'NA') {
    transportFootprintValue =
      transportFootprintValue +
      carbonFootprintTransportMatrix['car'][values.travelCarMotorcycleType][
        values.travelAdults
      ][values.travelCarMotorcycleHours]
  }

  transportFootprintValue =
    transportFootprintValue +
    carbonFootprintTransportMatrix['bus'][values.travelHoursBus] +
    carbonFootprintTransportMatrix['train'][values.travelHoursTrain] +
    carbonFootprintTransportMatrix['domestic-flight'][
      values.travelHoursFlyingDomestic
    ]

  const shortHaulFlight =
    carbonFootprintTransportMatrix['short-haul-flight'][
      values.travelFlyingShortHaulType
    ]

  if (!Number.isInteger(shortHaulFlight)) {
    transportFootprintValue =
      transportFootprintValue +
      shortHaulFlight[values.travelHoursFlyingShortHaul]
  }

  const longHaulFlight =
    carbonFootprintTransportMatrix['long-haul-flight'][
      values.internationalFlightType
    ]

  if (!Number.isInteger(longHaulFlight)) {
    transportFootprintValue =
      transportFootprintValue + longHaulFlight[values.travelHoursFlyingLongHaul]
  }

  return transportFootprintValue
}

/**
 * Retrieve value from accessing food matrix object with food values
 *
 * @param values food question answers
 * @returns {number}
 */
const getFoodCarbonFootprint = (values: StoredValues): number => {
  let foodFootprintValue = 0

  const redMeat =
    carbonFootprintFoodMatrix['red-meat'][values.foodWasteDisposal][
      values.foodWaste
    ][values.dietRedMeat]
  if (!Number.isInteger(redMeat)) {
    foodFootprintValue = foodFootprintValue + redMeat[values.diet]
  }

  const whiteMeat =
    carbonFootprintFoodMatrix['white-meat'][values.foodWasteDisposal][
      values.foodWaste
    ][values.dietWhiteMeat]
  if (!Number.isInteger(whiteMeat)) {
    foodFootprintValue = foodFootprintValue + whiteMeat[values.diet]
  }

  const fish =
    carbonFootprintFoodMatrix['fish'][values.foodWasteDisposal][
      values.foodWaste
    ][values.dietSeafoodOrFish]
  if (!Number.isInteger(fish)) {
    foodFootprintValue = foodFootprintValue + fish[values.diet]
  }

  const eggs =
    carbonFootprintFoodMatrix['eggs'][values.foodWasteDisposal][
      values.foodWaste
    ][values.dietEggs]
  if (!Number.isInteger(eggs)) {
    foodFootprintValue = foodFootprintValue + eggs[values.diet]
  }

  const dairy =
    carbonFootprintFoodMatrix['dairy'][values.foodWasteDisposal][
      values.foodWaste
    ][values.dietDairy]
  if (!Number.isInteger(dairy)) {
    foodFootprintValue = foodFootprintValue + dairy[values.diet]
  }

  foodFootprintValue =
    foodFootprintValue +
    carbonFootprintFoodMatrix['other'][values.foodWasteDisposal][
      values.foodWaste
    ][values.diet]

  return foodFootprintValue
}
