import React from 'react'
import { Box, Heading, ThemeUIStyleObject } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ReactNodeArray } from 'react'
import { Wrap } from 'raam'
import { fluidScale } from '../utils/mixins'
import PageLink from './page-link'
import { Page, MaybeGatsbyImageProps } from '../utils/types'
import { formatPageLink, isFixed } from '../utils/helpers'

interface Props {
  title?: string
  items?: Array<{
    title?: string
    logo?: MaybeGatsbyImageProps
    page?: Page
    url?: string
  }>
  variant?: 'tight' | 'default'
}

const LogoList: React.FC<Props> = ({
  title,
  items,
  children,
  variant = 'default',
}) => {
  const heading: ThemeUIStyleObject = {
    marginBottom: 12,
    textAlign: 'center',
  }

  const wrap: ThemeUIStyleObject = {
    marginTop: 11,
    justifyContent: 'center',
  }

  const logo = {
    tight: {
      display: 'block',
      width: '150px',
    },
    default: {
      display: 'block',
      marginX: 9,
      width: fluidScale('170px', 0.8, 10),
    },
  }

  const logos: ReactNodeArray | undefined = items?.map((item, i) => {
    const image = item?.logo && isFixed(item.logo) && (
      <GatsbyImage
        style={{ width: '100%' }}
        imgStyle={{ objectFit: 'contain' }}
        image={item.logo.gatsbyImageData}
        alt={`${item.title} logo`}
      />
    )

    let params = {}

    if (item?.url) {
      params = { target: '_blank' }
    }

    return item?.url || item?.page ? (
      <PageLink
        key={i}
        sx={logo[variant]}
        to={item?.url || formatPageLink(item.page)}
        {...params}
      >
        {image}
      </PageLink>
    ) : (
      <Box key={i} sx={logo[variant]}>
        {image}
      </Box>
    )
  })

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      {children}
      <Wrap sx={wrap} gap={variant === 'tight' ? 4 : 14}>
        {logos}
      </Wrap>
    </Box>
  )
}

export default LogoList
