import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'

import shape1 from '../images/spray-shape-1.webp'
import shape2 from '../images/spray-shape-2.webp'
import shape3 from '../images/spray-shape-3.webp'
import shape4 from '../images/spray-shape-4.webp'
import shape5 from '../images/spray-shape-5.webp'

const shapeImages = [shape1, shape2, shape3, shape4, shape5]

interface Props {
  muted?: boolean
  blankBackground?: boolean
}

const Main: React.FC<Props> = ({
  children,
  muted,
  blankBackground = false,
}) => {
  /*
   * Define CSS rules for the background shapes in the site margins
   */
  const backgroundShape = (side: 'left' | 'right') => {
    /*
     * Randomly define the parameters for each shape
     */
    const randomise = (multi = false, pos = 0) => {
      // choose an image
      const image = shapeImages[Math.floor(Math.random() * shapeImages.length)]
      // choose a size between 50% and 100%
      const size = 50 + Math.ceil(Math.random() * 50)
      // calculate the X offset as a quadratic function of size
      // (CSS background position offset is weird)
      const x = 0.5 * (1.07 ^ size) + 10
      // choose a Y offset between 0% and 80%
      // or for multiple shapes, between 0% and 40% / 60% and 100%
      const y =
        Math.floor(Math.random() * (multi ? 40 : 80)) + (multi ? pos * 60 : 0)

      return { image, size, x, y }
    }

    // randomly choose 1 or 2 shapes and call randomise() for each
    const oneOrTwo = Math.ceil(Math.random() * 2)
    const iterator = new Array(oneOrTwo).fill(undefined)
    const shapes = iterator.map((_, j) => randomise(oneOrTwo === 2, j))

    return {
      [`&::${side === 'left' ? 'before' : 'after'}`]: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: side === 'left' ? 0 : 'auto',
        right: side === 'right' ? 0 : 'auto',
        width: '12%',
        height: '100%',
        backgroundImage: shapes.map(shape => `url(${shape.image})`).join(','),
        backgroundRepeat: 'no-repeat',
        backgroundSize: shapes.map(shape => `${shape.size}%`).join(','),
        backgroundPosition: shapes
          .map(shape => `left -${shape.x}% top ${shape.y}%`)
          .join(','),
        transform: side === 'right' ? 'scaleX(-1)' : 'none',
        zIndex: -1,
      },
    }
  }

  let style: ThemeUIStyleObject = {
    position: 'relative',
    overflow: muted ? 'hidden' : 'visible',
    backgroundColor: muted ? 'muted' : 'inherit',
  }

  if (!blankBackground) {
    style = {
      ...style,
      ...backgroundShape('left'),
      ...backgroundShape('right'),
    }
  }

  return (
    <Box as="main" sx={style}>
      {children}
    </Box>
  )
}

export default Main
