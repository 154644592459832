import { useEffect } from 'react'
import useLocalStorage from './use-local-storage'
import fetchPromo, {
  PartnerDetails,
  OldPartnerDetails,
  PartnerResult,
} from './fetch-promo'

export function isOldPartnerDetails(
  object: PartnerDetails | OldPartnerDetails
): object is OldPartnerDetails {
  return 'terms_and_conditions' in object
}

const sanitiseId = (id: string | null): string =>
  id ? id.replace(/\W/g, '').substr(0, 32) : ''

const validatePromo = (result: PartnerResult): boolean => {
  const data = result.data || {}
  const validStatus = result.status === 'success'
  if (data.invalid) {
    return true
  }

  let validStartDate = false
  let validEndDate = false

  if (!isOldPartnerDetails(data)) {
    validStartDate =
      !data.offerStartDate ||
      new Date(data.offerStartDate.replace(/ /g, 'T')).valueOf() <= Date.now()

    validEndDate =
      !data.offerEndDate ||
      new Date(data.offerEndDate.replace(/ /g, 'T')).valueOf() >= Date.now()
  } else if (isOldPartnerDetails(data)) {
    validStartDate =
      !data.offer_start_date ||
      new Date(data.offer_start_date.replace(/ /g, 'T')).valueOf() <= Date.now()

    validEndDate =
      !data.offer_end_date ||
      new Date(data.offer_end_date.replace(/ /g, 'T')).valueOf() >= Date.now()
  }

  return validStatus && validStartDate && validEndDate
}

const usePartnerPromo = (): PartnerDetails | undefined => {
  const [partnerPromo, setPartnerPromo] = useLocalStorage(
    'partner-promo',
    undefined,
    86400
  )

  useEffect(() => {
    const params = new URL(document.location.href).searchParams
    const id = sanitiseId(params.get('partner'))

    if (
      (id && partnerPromo === undefined) ||
      (id && partnerPromo !== undefined && id !== partnerPromo['code'])
    ) {
      fetchPromo
        .get(id)
        .then(
          response => validatePromo(response) && setPartnerPromo(response.data)
        )
        .catch(err => console.error(err))
    }
  }, [setPartnerPromo])

  return partnerPromo
}

export default usePartnerPromo
