import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import NavLink from './nav-link'
import LiveIcon from '../assets/live-icon.svg'
import NewIcon from '../assets/new-icon.svg'

interface Props {
  to: string
  title: string
}
const LiveGenLink: React.FC<Props> = ({ to, title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '190px',
        width: '80%',
        maxWidth: '933px',
        mx: 'auto',
      }}
    >
      <NavLink
        to={to}
        variant="button"
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          fontSize: [1, 2],
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        }}
      >
        <LiveIcon />
        {title}
        <NewIcon />
      </NavLink>
    </Box>
  )
}

export default LiveGenLink
