import React, { useState } from 'react'
import { Flex, ThemeUIStyleObject, SxStyleProp, useThemeUI } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { fluidScale } from '../utils/mixins'
import bgWebp from '../images/header-bg.webp'
import bgJpg from '../images/header-bg.jpg'
import HeaderLogo from './header-logo'
import HeaderMenu from './header-menu'
import HeaderIcons from './header-icons'
import useWebpImage from '../hooks/use-webp-image'

interface Props {
  sx?: SxStyleProp
  logoOnly?: boolean
  isBusiness?: boolean
}

const Header: React.FC<Props> = props => {
  const { theme } = useThemeUI()
  const bgHeight = fluidScale('0.8rem', 0.8, 1)(theme)
  const bg = useWebpImage(bgWebp, bgJpg)

  const breakpoint = useBreakpointIndex({ defaultIndex: 2 })
  const isMobile = breakpoint <= 1
  const isBusiness = props.isBusiness

  const [isOpen, setOpen] = useState(false)

  const toggleMenu = () => {
    if (!isOpen) {
      window.scrollTo({
        top: 0,
        left: 0,
      })
    }
    setOpen(!isOpen)
  }

  const layout = {
    alignItems: 'center',
    paddingLeft: [0, 0, 12],
    paddingTop: bgHeight,
  }

  const background = {
    backgroundColor: 'background',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    'backgroundSize ': '100% 0.8rem',
    backgroundSize: `100% ${bgHeight}`,
  }

  const logo: ThemeUIStyleObject = {
    textAlign: ['center', null, 'left'],
  }

  const mobileLogin: ThemeUIStyleObject = {
    display: ['flex', null, 'none'],
  }

  const menuMobile = { display: [isOpen ? 'flex' : 'none', null, 'none'] }
  const menuDesktop = { display: ['none', null, 'flex'] }

  const menuIcon = { display: ['flex', null, 'none'] }
  const menuIconVariant = isOpen ? 'close' : 'menu'

  if (props.logoOnly) {
    return (
      <React.Fragment>
        <Flex as="header" sx={{ ...layout, ...background }} {...props}>
          <HeaderLogo sx={logo} />
        </Flex>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Flex as="header" sx={{ ...layout, ...background }} {...props}>
        <HeaderIcons
          sx={menuIcon}
          variant={menuIconVariant}
          onClick={toggleMenu}
        />
        <HeaderLogo sx={logo} />
        {!isMobile && <HeaderMenu sx={menuDesktop} isBusiness={isBusiness} />}
        <HeaderIcons sx={mobileLogin} variant="mobileLogin" />
      </Flex>
      {isMobile && <HeaderMenu sx={menuMobile} variant="mobile" />}
    </React.Fragment>
  )
}

export default Header
