import React from 'react'

import {
  get,
  Theme,
  ThemeUIStyleObject,
  Box,
  Text,
  Link,
  Button,
} from 'theme-ui'

import useWebpImage from '../hooks/use-webp-image'
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image'
import contourBackground from '../images/contour-bg.gif'
import sprayEdgeNarrowWebp from '../images/spray-edge-2.webp'
import sprayEdgeNarrowPng from '../images/spray-edge-2.png'

interface Props {
  fluid: IGatsbyImageData
  alt: string
  description: string
  link?: string
  variant?: 'pair' | 'texture' | 'default'
}

/**
 * An image for a standard article page, with optional captions and image credit
 *
 * Uses Gatsby Image for responsive, optimised images.
 */
const ArticleImage: React.FC<Props> = ({
  fluid,
  alt,
  description,
  link,
  variant = 'default',
  ...other
}) => {
  const wrapper: ThemeUIStyleObject = {
    position: 'relative',
  }

  const sprayEdgeNarrow = useWebpImage(sprayEdgeNarrowWebp, sprayEdgeNarrowPng)

  const horizontalText: ThemeUIStyleObject = {
    marginTop: variant === 'default' ? 8 : 0,
    marginBottom: variant === 'pair' ? 8 : 0,
    paddingY: variant === 'texture' ? 11 : 0,
    paddingLeft: variant === 'texture' ? 13 : 0,
    paddingRight: variant === 'texture' ? 18 : 0,
    width: variant === 'texture' ? '90%' : 'auto',
    backgroundImage:
      variant === 'texture' ? `url(${contourBackground})` : 'none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',

    '&::after': {
      content: '""',
      display: variant === 'texture' ? 'block' : 'none',
      position: 'absolute',
      height: 0,
      right: 0,
      bottom: 0,
      width: '75%',
      paddingBottom: 'calc(45% * 252 / 1055)',
      backgroundImage: `url(${sprayEdgeNarrow})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      transform: 'translateY(40%)',
    },
  }

  const verticalText: ThemeUIStyleObject = {
    position: 'absolute',
    top: '4.6rem',
    left: '100%',
    right: '-100%',
    transform: 'rotate(90deg) translateY(-100%)',
    transformOrigin: 'top left',
  }

  const horizontalRule: ThemeUIStyleObject = {
    '::before': {
      content: '""',
      position: 'absolute',
      width: '3.6rem',
      left: '-4.6rem',
      top: (theme: Theme) =>
        `calc(50% - ${get(theme, 'borderWidths.single')} / 2)`,
      borderTop: 'double',
    },
  }

  const imageWrapper: ThemeUIStyleObject = {
    position: 'relative',
    button: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translateX(-50%) translateY(-50%)',
      variant: 'links.filter',
      display: 'none',
    },
    '&:hover button': {
      display: 'block',
      color: 'text',
    },
  }

  const lines: string[] = description.split('\n')

  // extract image credit from text containing 'Credit: blah blah'
  const credit: string = lines.reduce((match, line) => {
    const matches = line.match(/^Credit:\s?(.*)/)
    return match || (matches && matches[1]) || ''
  }, '')

  // extract caption from text containing 'Caption: blah blah'
  const caption: string =
    lines.reduce((match, line) => {
      const matches = line.match(/^Caption:\s?(.*)/)
      return match || (matches && matches[1]) || ''
    }, '') || description

  const image = (
    <Box key="image" sx={imageWrapper}>
      <GatsbyImage image={fluid} alt={alt} />
      {link && <Button className="active">Find out more</Button>}
    </Box>
  )

  const text = (
    <Text key="text" sx={horizontalText} variant="caption">
      {caption}
    </Text>
  )

  return (
    <Link
      as={link ? 'a' : 'div'}
      href={link || undefined}
      sx={wrapper}
      variant="plain"
      {...other}
    >
      {variant === 'pair' ? [text, image] : [image, text]}
      {credit && (
        <Text
          sx={{ mx: 8, ...verticalText, ...horizontalRule }}
          variant="caption"
        >
          {credit}
        </Text>
      )}
    </Link>
  )
}

export default ArticleImage
