import React from 'react'
import { Box, Flex, Text, Link, ThemeUIStyleObject } from 'theme-ui'
import { Inline } from 'raam'

import {
  BLOCKS,
  CustomRenderer,
  Document,
  isInlineBlock,
  isParagraph,
  renderRichText,
} from '../rich-text'

import arrow from '../images/small-arrow.svg'

interface Props {
  servicesHeading: string
  servicesLinks?: Array<{
    title?: string
    body?: Document
  }>
  actionsHeading: string
  actionsLinks?: Array<{
    title?: string
    body?: Document
  }>
}

const BusinessHero: React.FC<Props> = ({
  servicesHeading,
  servicesLinks,
  actionsHeading,
  actionsLinks,
}) => {
  const wrapper: ThemeUIStyleObject = {
    flexDirection: ['column', null, 'row'],
    paddingY: 14,
  }

  const group: ThemeUIStyleObject = {
    marginTop: [0, null, '-9rem'],
    marginBottom: [0, 14],
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
  }

  const division: ThemeUIStyleObject = {
    display: ['none', 'block'],
    marginLeft: 12,
    borderLeft: 'muted',
    borderWidth: '0.5rem',
    paddingLeft: 12,
  }

  const heading: ThemeUIStyleObject = {
    paddingY: 5,
    paddingX: 9,
    marginBottom: 9,
    borderRadius: '0.5rem',
    fontWeight: 'bold',
    backgroundColor: 'text',
    color: 'background',
    minHeight: '6rem',
  }

  const inline = {
    sx: {
      overflow: 'visible',
      alignItems: 'stretch',
    },
    gap: 11,
    flexGrow: 1,
    flexBasis: ['10rem', 0],
    flexWrap: ['wrap', 'nowrap'],
  }

  const link: ThemeUIStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    fontSize: 1,
    textAlign: 'center',
    padding: [12, 10, 10, 12],
    paddingBottom: ['6rem', '6rem', '6rem', '6rem', '6rem'],
    backgroundColor: 'background',
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom 2.5rem',
    backgroundSize: '2rem',
    borderRadius: '0.5rem',
    boxShadow: 'card',
  }

  const text: ThemeUIStyleObject = {
    display: ['none', 'block'],
    flexBasis: 0,
    marginTop: 13,
    paddingX: 9,
    textAlign: 'center',
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        isParagraph(node) && (
          <Text as="p" variant="intro">
            {children}
          </Text>
        ),
    },
  }

  const serviceLinks = servicesLinks?.map((service, i) => {
    const body = service.body?.raw && JSON.parse(service.body.raw)
    const node = body.content && body.content.find(isInlineBlock)
    const uri = node.content[1].data.uri

    return (
      <Link key={i} sx={link} href={uri} variant="label">
        {service.title}
      </Link>
    )
  })

  const serviceTexts = servicesLinks?.map((service, i) => (
    <Box key={i} sx={text}>
      {service.body && renderRichText(service.body, renderers)}
    </Box>
  ))

  const actionLinks = actionsLinks?.map((action, i) => {
    const body = action.body?.raw && JSON.parse(action.body.raw)
    const node = body.content && body.content.find(isInlineBlock)
    const uri = node?.content[1].data.uri

    return (
      <Link key={i} sx={link} href={uri} variant="label">
        {action.title}
      </Link>
    )
  })

  const actionTexts = actionsLinks?.map((action, i) => (
    <Box key={i} sx={text}>
      {action.body && renderRichText(action.body, renderers)}
    </Box>
  ))

  return (
    <Flex sx={wrapper}>
      <Box sx={group}>
        <Text sx={heading} variant="head">
          {servicesHeading}
        </Text>
        <Inline {...inline}>{serviceLinks}</Inline>
        <Inline {...inline}>{serviceTexts}</Inline>
      </Box>
      <Box sx={division} />
      <Box sx={group}>
        <Text sx={heading} variant="head">
          {actionsHeading}
        </Text>
        <Inline {...inline}>{actionLinks}</Inline>
        <Inline {...inline}>{actionTexts}</Inline>
      </Box>
    </Flex>
  )
}

export default BusinessHero
