import React, { useState } from 'react'
import JoinLink from './join-link'
import { ThemeUIStyleObject, Box } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import { useEffect, useRef } from 'react'

type Props = {
  homeHeroRef: React.RefObject<Element>
}

const StickyQuoteBar: React.FC<Props> = ({ homeHeroRef }) => {
  const breakpoint = useBreakpointIndex({ defaultIndex: 2 })
  const isMobile = breakpoint < 2
  const ref = useRef()
  const [visible, setVisible] = useState(false)

  const wrapper: ThemeUIStyleObject = {
    background: 'white',
    padding: 8,
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    zIndex: '9999',
    textAlign: 'center',
    display: visible ? 'block' : 'none',
    boxShadow: '0px -2px 6px -2px #D4D7D7',
  }

  const wrapperFallback: ThemeUIStyleObject = {
    'z-index ': '9999',
    'text-align ': 'center',
  }

  const buttonStyle: ThemeUIStyleObject = {
    paddingY: 10,
    paddingX: 14.75,
    fontSize: 1,
  }

  const handleScroll = () => {
    if (ref.current !== null && homeHeroRef.current !== null) {
      const boundingClientRect = homeHeroRef?.current?.getClientRects()[0]
      const headerHeight = 75

      const visibleVerticalBreakpoint =
        window?.scrollY +
        boundingClientRect?.top +
        boundingClientRect?.height -
        headerHeight

      const showStickyQuoteBar = window.scrollY >= visibleVerticalBreakpoint
      setVisible(showStickyQuoteBar)
    }
  }

  useEffect(() => window.addEventListener('scroll', handleScroll))

  function getElementsByXPath(xpath, parent) {
    const results = []
    const query = document.evaluate(
      xpath,
      parent || document,
      null,
      XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
      null
    )

    for (let i = 0, length = query.snapshotLength; i < length; ++i) {
      results.push(query.snapshotItem(i))
    }

    return results
  }

  function toggleQuoteButtons(buttons, shouldHide) {
    //If the bar hasn't rendered yet, ignore.
    if (typeof ref.current === 'undefined' || ref.current === null) return

    //Only hide the buttons if the parentElement (variant) is visible
    if (
      shouldHide &&
      typeof ref.current !== 'undefined' &&
      ref.current !== null &&
      window.getComputedStyle(ref.current.parentElement).display === 'none'
    ) {
      shouldHide = false
    }

    buttons.forEach(element => {
      element.style.display = shouldHide ? 'none' : ''
    })
  }

  if (isMobile) {
    const xPathQuery = `
      //a[contains(@class, "QuoteWidget")] |
      //a[contains(normalize-space(), "Get a quote") and not(contains(@id, "StickyQuoteBar"))]
    `

    toggleQuoteButtons(getElementsByXPath(xPathQuery, document), visible)

    return (
      <Box ref={ref} sx={{ ...wrapperFallback, ...wrapper }}>
        <JoinLink
          sx={{ ...buttonStyle }}
          variant={'button'}
          id="StickyQuoteBar"
        >
          Get a quote
        </JoinLink>
      </Box>
    )
  }

  return null
}

export default StickyQuoteBar
