import React, { ReactElement } from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from '../components/layout'
import Section from '../components/section'
import SupportSubheader from '../components/support-subheader'
import AppHelp from '../components/app-help'
import LoginAppCTA from '../components/login-app-cta'
import Spread from '../components/spread'
import GeneralContent from '../components/general-content'
import { Document } from '../rich-text/index'
import { MaybeGatsbyImageProps } from '../utils/types'
import { Props as VideoProps } from '../components/video'

export const query = graphql`
  query SupportPage($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      slug
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      divisions
      sections {
        __typename
        ... on Node {
          ... on ContentfulAppHelp {
            title
            description
            buttonText
            buttonUrl
            videos {
              title
              url
            }
          }
          ... on ContentfulItem {
            ...Item
          }
          ... on ContentfulItemlet {
            ...Itemlet
          }
          ... on ContentfulSupportCta {
            heading {
              raw
            }
          }
          ... on ContentfulGeneralContent {
            title
            type
            items {
              ... on Node {
                ... on ContentfulFaq {
                  id
                  question
                  __typename
                  anchor
                  answer {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulGuide {
                  id
                  heading
                  anchor
                  body {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulItem {
                  itemTitle: title
                  image {
                    gatsbyImageData(
                      quality: 60
                      layout: FULL_WIDTH
                      formats: [WEBP]
                    )
                  }
                  video {
                    title
                    url
                  }
                  body {
                    raw
                    references {
                      ...References
                    }
                  }
                }
                ... on ContentfulSupportCategory {
                  slug
                  name
                  icon {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

type Location = {
  pathname?: string
  search?: string
  state?: unknown
  hash?: string
  key?: string
}

type SectionType = {
  __typename: string
  links: Array<SectionLink>
  title: string
  pages: Array<SectionPage>
  body: Document
  image: unknown
  video: unknown
  type: Array<string>
  heading: Document
  items: Array<{
    title: string
    body: Document
    image: MaybeGatsbyImageProps
    video: VideoProps
  }>
}

type SectionPage = {
  question: string
  heading: string
  anchor: string
  body: Document
  answer: Document
  __typename: string
}

type SectionLink = {
  slug: string
  name: string
  icon: {
    file: {
      url: string
    }
  }
}

export const getSections = (
  page: GatsbyTypes.SupportPageQuery,
  location: Location
): ReactElement =>
  page.sections
    ?.map((section: SectionType, i: number) => {
      switch (section?.__typename) {
        case 'ContentfulItem':
          return (
            <Spread
              title={section.title}
              items={[
                {
                  body: section.body,
                  image: section.image,
                  video: section.video,
                },
              ]}
              flip={Boolean(i & 1)}
              centered={true}
              variant="index"
            />
          )

        case 'ContentfulGeneralContent': {
          const params =
            section.type[0] === 'Cluster'
              ? {
                  cols: Math.min(Math.max(section.items.length, 2), 4) as
                    | 2
                    | 3
                    | 4,
                  variant: 'support',
                }
              : section.type[0] === 'Quick Links'
              ? { maxLinkCount: i === 0 ? 3 : undefined }
              : section.type[0] === 'Support'
              ? { location }
              : {}

          return <GeneralContent {...section} params={params} />
        }
        case 'ContentfulItemlet':
          return (
            <Spread
              subtitle={section.title}
              items={[
                {
                  body: section.body,
                  image: section.image,
                  video: section.video,
                },
              ]}
              flip={Boolean(i & 1)}
              centered={true}
              variant="index"
            />
          )
        case 'ContentfulSupportCta':
          return <LoginAppCTA heading={section.heading} title={page.title} />
        case 'ContentfulAppHelp':
          return <AppHelp {...section} />
      }
    })
    .map((section, i, sections) => (
      <Section as="div" key={i} pos={i} count={sections.length} variant="loose">
        {section}
      </Section>
    ))

const Page: React.FC<PageProps<GatsbyTypes.SupportPageQuery>> = ({
  location,
  data: { page = {} },
}) => {
  const sections = getSections(page, location)

  const masthead = location.pathname === '/support' ? 'supportIndex' : 'support'
  return (
    <Layout page={page} masthead={masthead}>
      <SupportSubheader />
      {sections}
    </Layout>
  )
}

export default Page
