import React from 'react'
import { Box, Text, ThemeUIStyleObject } from 'theme-ui'
import tickImage from '../../images/black-tick-white-bg.svg'

interface Props {
  finished?: boolean
  active?: boolean
  name?: string
  categoriesCount: number
}

const Category: React.FC<Props> = ({
  finished = false,
  active = false,
  name,
  categoriesCount,
}) => {
  const circleSx: ThemeUIStyleObject = {
    content: '""',
    display: 'block',
    height: '3.08rem',
    width: '3.08rem',
    backgroundColor: 'muted',
    borderRadius: '50%',
    border: 'mutedBg',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: ['1.67rem', 'auto'],
    right: ['auto', '1.67rem'],
  }

  let wrapperSx: ThemeUIStyleObject = {
    display: ['none', 'initial'],
    flexBasis: ['100%', `${100 / categoriesCount}%`],
    textAlign: 'center',
    paddingY: '1.25rem',
    position: 'relative',
    borderStyle: 'solid',
    borderWidth: '0.42rem 0.08rem 0.08rem 0.08rem',
    borderColor: '#E0E0E0',
    '&::after': {
      ...circleSx,
    },
  }

  if (active) {
    wrapperSx = {
      ...wrapperSx,
      display: 'initial',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '70%',
        height: '0.33rem',
        backgroundColor: 'primary',
      },
    }
  }

  if (finished) {
    wrapperSx = {
      ...wrapperSx,
      backgroundColor: 'primary',
      borderColor: '#6A9C27',
      borderTopColor: '#80C12A',
      '&::after': {
        ...circleSx,
        backgroundImage: `url(${tickImage})`,
        backgroundSize: '100%',
        backgroundPosition: 'center',
        border: 0,
      },
    }
  }

  const textSx: ThemeUIStyleObject = {
    fontWeight: 700,
    textTransform: 'capitalize',
  }

  return (
    <Box sx={wrapperSx}>
      <Text sx={textSx} as="p">
        {name}
      </Text>
    </Box>
  )
}

export default Category
