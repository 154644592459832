import React from 'react'
import { AddressPickerFormData } from './inputs/address-picker'
import { DirectDebitForm } from './utils/form-types'
import DirectDebitStepZero from './direct-debit-step-zero'
import DirectDebitStepOne from './direct-debit-step-one'
import DirectDebitStepTwo from './direct-debit-step-two'
import DirectDebitStepThree from './direct-debit-step-three'
import Stepper from './stepper'
import { submitForm } from './utils/submit'
import { cleanSortCode } from '../utils/helpers'

import { useState, UseState } from './utils/use-state'

export type StepZeroForm = {
  readonly declaration: string
}

export type StepOneForm = {
  readonly accountNumber: string
  readonly fuelType: 'gas' | 'elec' | 'both'
  readonly paymentType: 'variable' | 'fixed'
}

export type StepTwoForm = {
  readonly bankAccountName: string
  readonly bankAccountNumber: string
  readonly bankAccountSortCode: string
  readonly directDebitDay: string
  readonly salutation: string
  readonly firstName: string
  readonly lastName: string
  readonly emailAddress: string
  readonly addressesMatch: string
  readonly billingAddress: AddressPickerFormData
  readonly supplyAddress?: AddressPickerFormData
  readonly energyUsageKnown: 'Yes' | 'No'
  readonly electricityKwh?: string
  readonly gasKwh?: string
  readonly numberofBedrooms: string
  readonly heatingSource: 'gas' | 'electric'
}

export type WholeForm = StepZeroForm & StepOneForm & StepTwoForm

const reformatDirectDebitForm = ({
  salutation,
  firstName,
  lastName,
  emailAddress,
  declaration,
  accountNumber,
  fuelType,
  paymentType,
  bankAccountName,
  bankAccountNumber,
  bankAccountSortCode,
  energyUsageKnown,
  electricityKwh = '',
  gasKwh = '',
  numberofBedrooms,
  heatingSource,
  directDebitDay,
  billingAddress,
  supplyAddress,
  addressesMatch,
}: WholeForm): DirectDebitForm => {
  const billingAddressData = {
    address1: billingAddress.line1,
    address2: billingAddress.line2,
    address3: billingAddress.line3,
    town: billingAddress.town,
    county: billingAddress.county,
    postcode: billingAddress.postcode,
    udprn: billingAddress.udprn,
  }
  return {
    webform: {
      type: 'directDebit',
      formData: {
        declaration: Boolean(declaration),
        accountNumber,
        hasGas: fuelType === 'gas' || fuelType === 'both',
        hasElectricity: fuelType === 'elec' || fuelType === 'both',
        paymentType,
        bankDetails: {
          accountName: bankAccountName,
          accountNumber: bankAccountNumber,
          accountSortCode: cleanSortCode(bankAccountSortCode),
        },
        energyUsageKnown: energyUsageKnown === 'Yes',
        electricityKwh,
        gasKwh,
        numberofBedrooms,
        heatingSource,
        directDebitDay,
        salutation,
        firstNames: firstName,
        lastNames: lastName,
        emailAddress,
        billingAddress: billingAddressData,
        supplyAddress: addressesMatch
          ? billingAddressData
          : {
              address1: supplyAddress?.line1 ?? '',
              address2: supplyAddress?.line2 ?? '',
              address3: supplyAddress?.line3 ?? '',
              town: supplyAddress?.town ?? '',
              county: supplyAddress?.county ?? '',
              postcode: supplyAddress?.postcode ?? '',
              udprn: supplyAddress?.udprn ?? '',
            },
      },
    },
  }
}

type StepState =
  | { step: 0; data: Partial<WholeForm> }
  | { step: 1; data: StepZeroForm & Partial<StepOneForm & StepTwoForm> }
  | { step: 2; data: StepZeroForm & StepOneForm & Partial<StepTwoForm> }
  | { step: 3; data: WholeForm }

export const _DirectDebit = ({
  useSteppedState,
  useSubmissionState,
}: {
  readonly useSteppedState: UseState<StepState>
  readonly useSubmissionState: UseState<{
    readonly submissionError: string | null
    readonly inFlight: boolean
    readonly submitted: boolean
  }>
}) =>
  function DirectDebit(): JSX.Element {
    const [formState, setFormState] = useSteppedState({
      step: 0,
      data: { addressesMatch: 'selected' },
    })

    const [submissionState, setSubmissionState] = useSubmissionState({
      submissionError: null,
      inFlight: false,
      submitted: false,
    })

    const confirm = (finalForm: WholeForm) => {
      if (submissionState.inFlight) {
        return
      }

      setSubmissionState({
        ...submissionState,
        submissionError: null,
        inFlight: true,
      })

      submitForm(reformatDirectDebitForm(finalForm))
        .then(() =>
          setSubmissionState({
            ...submissionState,
            inFlight: false,
            submitted: true,
          })
        )
        .catch(() =>
          setSubmissionState({
            ...submissionState,
            submissionError: 'Submission failed!',
            inFlight: false,
          })
        )
    }

    return (
      <div>
        {formState.step > 0 && !submissionState.submitted && (
          <Stepper step={formState.step}></Stepper>
        )}
        {formState.step === 0 && (
          <DirectDebitStepZero
            complete={(input: StepZeroForm) =>
              setFormState({
                step: 1,
                data: {
                  ...formState.data,
                  ...input,
                },
              })
            }
            defaultValues={formState.data}
          />
        )}

        {formState.step === 1 && (
          <DirectDebitStepOne
            complete={(input: StepOneForm) =>
              setFormState({
                step: 2,
                data: {
                  ...formState.data,
                  ...input,
                },
              })
            }
            back={() => setFormState({ ...formState, step: 0 })}
            defaultValues={formState.data}
          />
        )}

        {formState.step === 2 && (
          <DirectDebitStepTwo
            complete={(input: StepTwoForm) =>
              setFormState({
                step: 3,
                data: {
                  ...formState.data,
                  ...input,
                },
              })
            }
            back={() => setFormState({ ...formState, step: 1 })}
            paymentType={formState.data.paymentType}
            fuelType={formState.data.fuelType}
            defaultValues={formState.data}
          />
        )}

        {formState.step === 3 && (
          <DirectDebitStepThree
            submissionState={submissionState}
            values={formState.data}
            back={() => setFormState({ ...formState, step: 2 })}
            complete={confirm}
          />
        )}
      </div>
    )
  }

const DirectDebit = _DirectDebit({
  useSteppedState: useState,
  useSubmissionState: useState,
})

export default DirectDebit
