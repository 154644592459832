const foodMatrix = {
  'red-meat': {
    //Waste route
    compost: {
      //Food waste
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 299,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 598,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 897,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1196,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1494,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 1793,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2092,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 336,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 673,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1009,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1345,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1681,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2018,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2354,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 384,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 769,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1153,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1537,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1922,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2306,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 448,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 897,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1345,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1794,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 2242,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 3139,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 538,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 1076,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1614,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 2152,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 3228,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 3767,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
    generalWaste: {
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 299,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 598,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 897,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1196,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1494,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 1793,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2092,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 336,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 673,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1009,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1345,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1681,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2018,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2354,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 384,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 769,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1153,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1537,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 1922,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2306,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 448,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 897,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1345,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 1794,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 2242,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 3139,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 538,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 1076,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 1614,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 2152,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 2690,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 3229,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 3767,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
  },
  'white-meat': {
    //Waste route
    compost: {
      //Food waste
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 73,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 147,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 220,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 293,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 367,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 440,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 513,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 83,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 165,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 248,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 330,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 413,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 495,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 578,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 94,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 189,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 283,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 377,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 472,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 566,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 110,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 220,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 330,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 440,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 551,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 771,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 132,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 264,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 396,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 529,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 793,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 925,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
    generalWaste: {
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 73,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 147,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 220,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 293,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 367,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 440,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 513,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 83,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 165,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 248,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 330,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 413,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 495,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 578,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 94,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 189,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 283,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 377,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 472,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 566,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 110,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 220,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 330,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 440,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 551,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 771,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 132,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 264,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 396,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 529,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 661,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 793,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 925,
          pescatarian: 0,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
  },
  fish: {
    //Waste route
    compost: {
      //Food waste
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 66,
          pescatarian: 64,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 131,
          pescatarian: 128,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 197,
          pescatarian: 191,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 263,
          pescatarian: 255,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 328,
          pescatarian: 319,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 394,
          pescatarian: 383,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 460,
          pescatarian: 446,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 74,
          pescatarian: 72,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 148,
          pescatarian: 144,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 222,
          pescatarian: 215,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 296,
          pescatarian: 287,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 370,
          pescatarian: 359,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 443,
          pescatarian: 431,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 517,
          pescatarian: 502,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 84,
          pescatarian: 82,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 169,
          pescatarian: 164,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 253,
          pescatarian: 246,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 338,
          pescatarian: 328,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 422,
          pescatarian: 410,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 507,
          pescatarian: 492,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 591,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 99,
          pescatarian: 96,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 197,
          pescatarian: 191,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 296,
          pescatarian: 287,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 394,
          pescatarian: 383,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 493,
          pescatarian: 479,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 592,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 690,
          pescatarian: 670,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 118,
          pescatarian: 115,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 237,
          pescatarian: 230,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 355,
          pescatarian: 345,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 473,
          pescatarian: 459,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 592,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 710,
          pescatarian: 689,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 828,
          pescatarian: 804,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
    generalWaste: {
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 66,
          pescatarian: 64,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 131,
          pescatarian: 128,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 197,
          pescatarian: 191,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 263,
          pescatarian: 255,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 328,
          pescatarian: 319,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 394,
          pescatarian: 383,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 460,
          pescatarian: 446,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 74,
          pescatarian: 72,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 148,
          pescatarian: 144,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 222,
          pescatarian: 215,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 296,
          pescatarian: 287,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 370,
          pescatarian: 359,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 443,
          pescatarian: 431,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 517,
          pescatarian: 502,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 84,
          pescatarian: 82,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 169,
          pescatarian: 164,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 253,
          pescatarian: 246,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 338,
          pescatarian: 328,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 422,
          pescatarian: 410,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 507,
          pescatarian: 492,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 591,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 99,
          pescatarian: 96,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 197,
          pescatarian: 191,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 296,
          pescatarian: 287,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 394,
          pescatarian: 383,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 493,
          pescatarian: 479,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 592,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 690,
          pescatarian: 670,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 118,
          pescatarian: 115,
          vegan: 0,
          vegetarian: 0,
        },
        '2': {
          //Diet type
          regular: 237,
          pescatarian: 230,
          vegan: 0,
          vegetarian: 0,
        },
        '3': {
          //Diet type
          regular: 355,
          pescatarian: 345,
          vegan: 0,
          vegetarian: 0,
        },
        '4': {
          //Diet type
          regular: 473,
          pescatarian: 460,
          vegan: 0,
          vegetarian: 0,
        },
        '5': {
          //Diet type
          regular: 592,
          pescatarian: 574,
          vegan: 0,
          vegetarian: 0,
        },
        '6': {
          //Diet type
          regular: 710,
          pescatarian: 689,
          vegan: 0,
          vegetarian: 0,
        },
        '7': {
          //Diet type
          regular: 828,
          pescatarian: 804,
          vegan: 0,
          vegetarian: 0,
        },
        none: 0,
      },
    },
  },
  eggs: {
    //Waste route
    compost: {
      //Food waste
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 14,
          pescatarian: 6,
          vegan: 0,
          vegetarian: 6,
        },
        '2': {
          //Diet type
          regular: 27,
          pescatarian: 13,
          vegan: 0,
          vegetarian: 13,
        },
        '3': {
          //Diet type
          regular: 41,
          pescatarian: 19,
          vegan: 0,
          vegetarian: 19,
        },
        '4': {
          //Diet type
          regular: 54,
          pescatarian: 25,
          vegan: 0,
          vegetarian: 25,
        },
        '5': {
          //Diet type
          regular: 68,
          pescatarian: 32,
          vegan: 0,
          vegetarian: 32,
        },
        '6': {
          //Diet type
          regular: 82,
          pescatarian: 38,
          vegan: 0,
          vegetarian: 38,
        },
        '7': {
          //Diet type
          regular: 95,
          pescatarian: 45,
          vegan: 0,
          vegetarian: 45,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 15,
          pescatarian: 7,
          vegan: 0,
          vegetarian: 7,
        },
        '2': {
          //Diet type
          regular: 31,
          pescatarian: 14,
          vegan: 0,
          vegetarian: 14,
        },
        '3': {
          //Diet type
          regular: 46,
          pescatarian: 22,
          vegan: 0,
          vegetarian: 22,
        },
        '4': {
          //Diet type
          regular: 61,
          pescatarian: 29,
          vegan: 0,
          vegetarian: 29,
        },
        '5': {
          //Diet type
          regular: 77,
          pescatarian: 36,
          vegan: 0,
          vegetarian: 36,
        },
        '6': {
          //Diet type
          regular: 92,
          pescatarian: 43,
          vegan: 0,
          vegetarian: 43,
        },
        '7': {
          //Diet type
          regular: 107,
          pescatarian: 50,
          vegan: 0,
          vegetarian: 50,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 18,
          pescatarian: 8,
          vegan: 0,
          vegetarian: 8,
        },
        '2': {
          //Diet type
          regular: 35,
          pescatarian: 16,
          vegan: 0,
          vegetarian: 16,
        },
        '3': {
          //Diet type
          regular: 53,
          pescatarian: 25,
          vegan: 0,
          vegetarian: 25,
        },
        '4': {
          //Diet type
          regular: 70,
          pescatarian: 33,
          vegan: 0,
          vegetarian: 33,
        },
        '5': {
          //Diet type
          regular: 88,
          pescatarian: 41,
          vegan: 0,
          vegetarian: 41,
        },
        '6': {
          //Diet type
          regular: 105,
          pescatarian: 49,
          vegan: 0,
          vegetarian: 49,
        },
        '7': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 20,
          pescatarian: 10,
          vegan: 0,
          vegetarian: 10,
        },
        '2': {
          //Diet type
          regular: 41,
          pescatarian: 19,
          vegan: 0,
          vegetarian: 19,
        },
        '3': {
          //Diet type
          regular: 61,
          pescatarian: 29,
          vegan: 0,
          vegetarian: 29,
        },
        '4': {
          //Diet type
          regular: 82,
          pescatarian: 38,
          vegan: 0,
          vegetarian: 38,
        },
        '5': {
          //Diet type
          regular: 102,
          pescatarian: 48,
          vegan: 0,
          vegetarian: 48,
        },
        '6': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        '7': {
          //Diet type
          regular: 143,
          pescatarian: 67,
          vegan: 0,
          vegetarian: 67,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 25,
          pescatarian: 11,
          vegan: 0,
          vegetarian: 11,
        },
        '2': {
          //Diet type
          regular: 49,
          pescatarian: 23,
          vegan: 0,
          vegetarian: 23,
        },
        '3': {
          //Diet type
          regular: 74,
          pescatarian: 34,
          vegan: 0,
          vegetarian: 34,
        },
        '4': {
          //Diet type
          regular: 98,
          pescatarian: 46,
          vegan: 0,
          vegetarian: 46,
        },
        '5': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        '6': {
          //Diet type
          regular: 147,
          pescatarian: 69,
          vegan: 0,
          vegetarian: 69,
        },
        '7': {
          //Diet type
          regular: 172,
          pescatarian: 80,
          vegan: 0,
          vegetarian: 80,
        },
        none: 0,
      },
    },
    generalWaste: {
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 14,
          pescatarian: 6,
          vegan: 0,
          vegetarian: 6,
        },
        '2': {
          //Diet type
          regular: 27,
          pescatarian: 13,
          vegan: 0,
          vegetarian: 13,
        },
        '3': {
          //Diet type
          regular: 41,
          pescatarian: 19,
          vegan: 0,
          vegetarian: 19,
        },
        '4': {
          //Diet type
          regular: 54,
          pescatarian: 25,
          vegan: 0,
          vegetarian: 25,
        },
        '5': {
          //Diet type
          regular: 68,
          pescatarian: 32,
          vegan: 0,
          vegetarian: 32,
        },
        '6': {
          //Diet type
          regular: 82,
          pescatarian: 38,
          vegan: 0,
          vegetarian: 38,
        },
        '7': {
          //Diet type
          regular: 95,
          pescatarian: 45,
          vegan: 0,
          vegetarian: 45,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 15,
          pescatarian: 7,
          vegan: 0,
          vegetarian: 7,
        },
        '2': {
          //Diet type
          regular: 31,
          pescatarian: 14,
          vegan: 0,
          vegetarian: 14,
        },
        '3': {
          //Diet type
          regular: 46,
          pescatarian: 22,
          vegan: 0,
          vegetarian: 22,
        },
        '4': {
          //Diet type
          regular: 61,
          pescatarian: 29,
          vegan: 0,
          vegetarian: 29,
        },
        '5': {
          //Diet type
          regular: 77,
          pescatarian: 36,
          vegan: 0,
          vegetarian: 36,
        },
        '6': {
          //Diet type
          regular: 92,
          pescatarian: 43,
          vegan: 0,
          vegetarian: 43,
        },
        '7': {
          //Diet type
          regular: 107,
          pescatarian: 50,
          vegan: 0,
          vegetarian: 50,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 18,
          pescatarian: 8,
          vegan: 0,
          vegetarian: 8,
        },
        '2': {
          //Diet type
          regular: 35,
          pescatarian: 16,
          vegan: 0,
          vegetarian: 16,
        },
        '3': {
          //Diet type
          regular: 53,
          pescatarian: 25,
          vegan: 0,
          vegetarian: 25,
        },
        '4': {
          //Diet type
          regular: 70,
          pescatarian: 33,
          vegan: 0,
          vegetarian: 33,
        },
        '5': {
          //Diet type
          regular: 88,
          pescatarian: 41,
          vegan: 0,
          vegetarian: 41,
        },
        '6': {
          //Diet type
          regular: 105,
          pescatarian: 49,
          vegan: 0,
          vegetarian: 49,
        },
        '7': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 20,
          pescatarian: 10,
          vegan: 0,
          vegetarian: 10,
        },
        '2': {
          //Diet type
          regular: 41,
          pescatarian: 19,
          vegan: 0,
          vegetarian: 19,
        },
        '3': {
          //Diet type
          regular: 61,
          pescatarian: 29,
          vegan: 0,
          vegetarian: 29,
        },
        '4': {
          //Diet type
          regular: 82,
          pescatarian: 38,
          vegan: 0,
          vegetarian: 38,
        },
        '5': {
          //Diet type
          regular: 102,
          pescatarian: 48,
          vegan: 0,
          vegetarian: 48,
        },
        '6': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        '7': {
          //Diet type
          regular: 143,
          pescatarian: 67,
          vegan: 0,
          vegetarian: 67,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 25,
          pescatarian: 11,
          vegan: 0,
          vegetarian: 11,
        },
        '2': {
          //Diet type
          regular: 49,
          pescatarian: 23,
          vegan: 0,
          vegetarian: 23,
        },
        '3': {
          //Diet type
          regular: 74,
          pescatarian: 34,
          vegan: 0,
          vegetarian: 34,
        },
        '4': {
          //Diet type
          regular: 98,
          pescatarian: 46,
          vegan: 0,
          vegetarian: 46,
        },
        '5': {
          //Diet type
          regular: 123,
          pescatarian: 57,
          vegan: 0,
          vegetarian: 57,
        },
        '6': {
          //Diet type
          regular: 147,
          pescatarian: 69,
          vegan: 0,
          vegetarian: 69,
        },
        '7': {
          //Diet type
          regular: 172,
          pescatarian: 80,
          vegan: 0,
          vegetarian: 80,
        },
        none: 0,
      },
    },
  },
  dairy: {
    //Waste route
    compost: {
      //Food waste
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 83,
          pescatarian: 83,
          vegan: 0,
          vegetarian: 83,
        },
        '2': {
          //Diet type
          regular: 167,
          pescatarian: 167,
          vegan: 0,
          vegetarian: 167,
        },
        '3': {
          //Diet type
          regular: 250,
          pescatarian: 250,
          vegan: 0,
          vegetarian: 250,
        },
        '4': {
          //Diet type
          regular: 333,
          pescatarian: 333,
          vegan: 0,
          vegetarian: 333,
        },
        '5': {
          //Diet type
          regular: 417,
          pescatarian: 417,
          vegan: 0,
          vegetarian: 417,
        },
        '6': {
          //Diet type
          regular: 500,
          pescatarian: 500,
          vegan: 0,
          vegetarian: 500,
        },
        '7': {
          //Diet type
          regular: 584,
          pescatarian: 584,
          vegan: 0,
          vegetarian: 584,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 94,
          pescatarian: 94,
          vegan: 0,
          vegetarian: 94,
        },
        '2': {
          //Diet type
          regular: 188,
          pescatarian: 188,
          vegan: 0,
          vegetarian: 188,
        },
        '3': {
          //Diet type
          regular: 282,
          pescatarian: 282,
          vegan: 0,
          vegetarian: 282,
        },
        '4': {
          //Diet type
          regular: 376,
          pescatarian: 376,
          vegan: 0,
          vegetarian: 376,
        },
        '5': {
          //Diet type
          regular: 470,
          pescatarian: 470,
          vegan: 0,
          vegetarian: 470,
        },
        '6': {
          //Diet type
          regular: 564,
          pescatarian: 564,
          vegan: 0,
          vegetarian: 564,
        },
        '7': {
          //Diet type
          regular: 658,
          pescatarian: 658,
          vegan: 0,
          vegetarian: 658,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 107,
          pescatarian: 107,
          vegan: 0,
          vegetarian: 107,
        },
        '2': {
          //Diet type
          regular: 215,
          pescatarian: 215,
          vegan: 0,
          vegetarian: 215,
        },
        '3': {
          //Diet type
          regular: 322,
          pescatarian: 322,
          vegan: 0,
          vegetarian: 322,
        },
        '4': {
          //Diet type
          regular: 430,
          pescatarian: 430,
          vegan: 0,
          vegetarian: 430,
        },
        '5': {
          //Diet type
          regular: 537,
          pescatarian: 537,
          vegan: 0,
          vegetarian: 537,
        },
        '6': {
          //Diet type
          regular: 645,
          pescatarian: 645,
          vegan: 0,
          vegetarian: 645,
        },
        '7': {
          //Diet type
          regular: 752,
          pescatarian: 752,
          vegan: 0,
          vegetarian: 752,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 125,
          pescatarian: 125,
          vegan: 0,
          vegetarian: 125,
        },
        '2': {
          //Diet type
          regular: 251,
          pescatarian: 251,
          vegan: 0,
          vegetarian: 251,
        },
        '3': {
          //Diet type
          regular: 376,
          pescatarian: 376,
          vegan: 0,
          vegetarian: 376,
        },
        '4': {
          //Diet type
          regular: 502,
          pescatarian: 502,
          vegan: 0,
          vegetarian: 502,
        },
        '5': {
          //Diet type
          regular: 627,
          pescatarian: 627,
          vegan: 0,
          vegetarian: 627,
        },
        '6': {
          //Diet type
          regular: 753,
          pescatarian: 753,
          vegan: 0,
          vegetarian: 753,
        },
        '7': {
          //Diet type
          regular: 878,
          pescatarian: 878,
          vegan: 0,
          vegetarian: 878,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 151,
          pescatarian: 151,
          vegan: 0,
          vegetarian: 151,
        },
        '2': {
          //Diet type
          regular: 301,
          pescatarian: 301,
          vegan: 0,
          vegetarian: 301,
        },
        '3': {
          //Diet type
          regular: 452,
          pescatarian: 452,
          vegan: 0,
          vegetarian: 452,
        },
        '4': {
          //Diet type
          regular: 602,
          pescatarian: 602,
          vegan: 0,
          vegetarian: 602,
        },
        '5': {
          //Diet type
          regular: 753,
          pescatarian: 753,
          vegan: 0,
          vegetarian: 753,
        },
        '6': {
          //Diet type
          regular: 904,
          pescatarian: 904,
          vegan: 0,
          vegetarian: 904,
        },
        '7': {
          //Diet type
          regular: 1054,
          pescatarian: 1054,
          vegan: 0,
          vegetarian: 1054,
        },
        none: 0,
      },
    },
    generalWaste: {
      '10%': {
        //Times per week
        '1': {
          //Diet type
          regular: 83,
          pescatarian: 83,
          vegan: 0,
          vegetarian: 83,
        },
        '2': {
          //Diet type
          regular: 167,
          pescatarian: 167,
          vegan: 0,
          vegetarian: 167,
        },
        '3': {
          //Diet type
          regular: 250,
          pescatarian: 250,
          vegan: 0,
          vegetarian: 250,
        },
        '4': {
          //Diet type
          regular: 333,
          pescatarian: 333,
          vegan: 0,
          vegetarian: 333,
        },
        '5': {
          //Diet type
          regular: 417,
          pescatarian: 417,
          vegan: 0,
          vegetarian: 417,
        },
        '6': {
          //Diet type
          regular: 500,
          pescatarian: 500,
          vegan: 0,
          vegetarian: 500,
        },
        '7': {
          //Diet type
          regular: 584,
          pescatarian: 584,
          vegan: 0,
          vegetarian: 584,
        },
        none: 0,
      },
      '20%': {
        //Times per week
        '1': {
          //Diet type
          regular: 94,
          pescatarian: 94,
          vegan: 0,
          vegetarian: 94,
        },
        '2': {
          //Diet type
          regular: 188,
          pescatarian: 188,
          vegan: 0,
          vegetarian: 188,
        },
        '3': {
          //Diet type
          regular: 282,
          pescatarian: 282,
          vegan: 0,
          vegetarian: 282,
        },
        '4': {
          //Diet type
          regular: 376,
          pescatarian: 376,
          vegan: 0,
          vegetarian: 376,
        },
        '5': {
          //Diet type
          regular: 470,
          pescatarian: 470,
          vegan: 0,
          vegetarian: 470,
        },
        '6': {
          //Diet type
          regular: 564,
          pescatarian: 564,
          vegan: 0,
          vegetarian: 564,
        },
        '7': {
          //Diet type
          regular: 658,
          pescatarian: 658,
          vegan: 0,
          vegetarian: 658,
        },
        none: 0,
      },
      '30%': {
        //Times per week
        '1': {
          //Diet type
          regular: 108,
          pescatarian: 108,
          vegan: 0,
          vegetarian: 108,
        },
        '2': {
          //Diet type
          regular: 215,
          pescatarian: 215,
          vegan: 0,
          vegetarian: 215,
        },
        '3': {
          //Diet type
          regular: 323,
          pescatarian: 323,
          vegan: 0,
          vegetarian: 323,
        },
        '4': {
          //Diet type
          regular: 430,
          pescatarian: 430,
          vegan: 0,
          vegetarian: 430,
        },
        '5': {
          //Diet type
          regular: 538,
          pescatarian: 538,
          vegan: 0,
          vegetarian: 538,
        },
        '6': {
          //Diet type
          regular: 645,
          pescatarian: 645,
          vegan: 0,
          vegetarian: 645,
        },
        '7': {
          //Diet type
          regular: 753,
          pescatarian: 753,
          vegan: 0,
          vegetarian: 753,
        },
        none: 0,
      },
      '40%': {
        //Times per week
        '1': {
          //Diet type
          regular: 126,
          pescatarian: 126,
          vegan: 0,
          vegetarian: 126,
        },
        '2': {
          //Diet type
          regular: 251,
          pescatarian: 251,
          vegan: 0,
          vegetarian: 251,
        },
        '3': {
          //Diet type
          regular: 377,
          pescatarian: 377,
          vegan: 0,
          vegetarian: 377,
        },
        '4': {
          //Diet type
          regular: 502,
          pescatarian: 502,
          vegan: 0,
          vegetarian: 502,
        },
        '5': {
          //Diet type
          regular: 628,
          pescatarian: 628,
          vegan: 0,
          vegetarian: 628,
        },
        '6': {
          //Diet type
          regular: 753,
          pescatarian: 753,
          vegan: 0,
          vegetarian: 753,
        },
        '7': {
          //Diet type
          regular: 879,
          pescatarian: 879,
          vegan: 0,
          vegetarian: 879,
        },
        none: 0,
      },
      '50%': {
        //Times per week
        '1': {
          //Diet type
          regular: 151,
          pescatarian: 151,
          vegan: 0,
          vegetarian: 151,
        },
        '2': {
          //Diet type
          regular: 301,
          pescatarian: 301,
          vegan: 0,
          vegetarian: 301,
        },
        '3': {
          //Diet type
          regular: 452,
          pescatarian: 452,
          vegan: 0,
          vegetarian: 452,
        },
        '4': {
          //Diet type
          regular: 603,
          pescatarian: 603,
          vegan: 0,
          vegetarian: 603,
        },
        '5': {
          //Diet type
          regular: 753,
          pescatarian: 753,
          vegan: 0,
          vegetarian: 753,
        },
        '6': {
          //Diet type
          regular: 904,
          pescatarian: 904,
          vegan: 0,
          vegetarian: 904,
        },
        '7': {
          //Diet type
          regular: 1055,
          pescatarian: 1055,
          vegan: 0,
          vegetarian: 1055,
        },
        none: 0,
      },
    },
  },
  other: {
    compost: {
      '10%': {
        //Diet type
        regular: 680,
        pescatarian: 768,
        vegan: 882,
        vegetarian: 791,
      },
      '20%': {
        //Diet type
        regular: 765,
        pescatarian: 864,
        vegan: 992,
        vegetarian: 890,
      },
      '30%': {
        //Diet type
        regular: 874,
        pescatarian: 988,
        vegan: 1134,
        vegetarian: 1018,
      },
      '40%': {
        //Diet type
        regular: 1020,
        pescatarian: 1152,
        vegan: 1323,
        vegetarian: 1187,
      },
      '50%': {
        //Diet type
        regular: 1224,
        pescatarian: 1383,
        vegan: 1587,
        vegetarian: 1425,
      },
    },
    generalWaste: {
      '10%': {
        //Diet type
        regular: 680,
        pescatarian: 768,
        vegan: 882,
        vegetarian: 791,
      },
      '20%': {
        //Diet type
        regular: 765,
        pescatarian: 864,
        vegan: 992,
        vegetarian: 890,
      },
      '30%': {
        //Diet type
        regular: 874,
        pescatarian: 988,
        vegan: 1134,
        vegetarian: 1018,
      },
      '40%': {
        //Diet type
        regular: 1020,
        pescatarian: 1152,
        vegan: 1323,
        vegetarian: 1187,
      },
      '50%': {
        //Diet type
        regular: 1224,
        pescatarian: 1383,
        vegan: 1587,
        vegetarian: 1425,
      },
    },
  },
}

export default foodMatrix
