import React from 'react'
import { Button, Heading, Flex, Label, Text } from 'theme-ui'
import { SymmetricValidatingInput } from './inputs/validating-input'
import { validAccountNumber } from './utils/validators'
import { StepOneForm } from './direct-debit'
import { useForm } from 'react-hook-form'
import { heading } from './utils/styles'
import { useEffect } from 'react'

const DirectDebitStepOne = ({
  back,
  complete,
  defaultValues,
}: {
  complete: (data: StepOneForm) => void
  back: () => void
  defaultValues?: Partial<StepOneForm>
}): JSX.Element => {
  const formFns = useForm<StepOneForm>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  })

  const { register, errors, handleSubmit } = formFns

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form noValidate onSubmit={handleSubmit(complete)}>
      <Heading style={heading}>Fuel type</Heading>
      <SymmetricValidatingInput
        {...formFns}
        deepName={['accountNumber'] as const}
        handleRef={(label: string) =>
          register({
            required: `Please enter your ${label}`,
            validate: {
              validAccountNumber: validAccountNumber,
            },
          })
        }
        label="Account number"
      />
      <Heading sx={heading}>About your energy</Heading>
      <Label htmlFor="fuelTypeBoth">
        <input
          id="fuelTypeBoth"
          name="fuelType"
          type="radio"
          value="both"
          ref={register({
            validate: value => (value ? true : 'You must choose a fuel type'),
          })}
        />
        Electricity and Gas
      </Label>
      <Label htmlFor="fuelTypeElec">
        <input
          id="fuelTypeElec"
          name="fuelType"
          type="radio"
          value="elec"
          ref={register({
            validate: value => (value ? true : 'You must choose a fuel type'),
          })}
        />
        Electricity
      </Label>
      <Label htmlFor="fuelTypeGas">
        <input
          id="fuelTypeGas"
          name="fuelType"
          type="radio"
          value="gas"
          ref={register({
            validate: value => (value ? true : 'You must choose a fuel type'),
          })}
        />
        Gas
      </Label>
      <Text as="p" variant="errorLabel">
        {errors.fuelType?.message}
      </Text>
      <Heading sx={heading}>Choose how to pay</Heading>
      <Label htmlFor="paymentTypeVariable">
        <input
          id="paymentTypeVariable"
          name="paymentType"
          type="radio"
          value="variable"
          ref={register({
            validate: value =>
              value ? true : 'You must choose a payment type',
          })}
        />
        Pay for the energy you use each month.
      </Label>
      <Label htmlFor="paymentTypeFixed">
        <input
          id="paymentTypeFixed"
          name="paymentType"
          type="radio"
          value="fixed"
          ref={register({
            validate: value =>
              value ? true : 'You must choose a payment type',
          })}
        />
        Budget with fixed monthly instalments to spread your payments equally
        across the year.
      </Label>
      <Text as="p" variant="errorLabel">
        {errors.paymentType?.message}
      </Text>
      <Flex
        sx={{
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault()
            back()
          }}
        >
          Back
        </Button>

        <Button>Continue</Button>
      </Flex>
    </form>
  )
}

export default DirectDebitStepOne
