import React from 'react'
import { Box, Text, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  quote: string
  citation: string
}

/**
 * Block quote with horizontal rule before optional citation.
 */
const PullQuote: React.FC<Props> = ({ quote, citation }) => {
  const hr: ThemeUIStyleObject = {
    '&::after': {
      content: '""',
      display: 'block',
      width: '26%',
      marginTop: '1em',
      borderBottom: 'double',
    },
  }

  return (
    <Box>
      <Text as="blockquote" sx={hr} variant="sml">
        {quote}
      </Text>
      <Text variant="intro">{citation}</Text>
    </Box>
  )
}

export default PullQuote
