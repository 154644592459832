import React, { useEffect, useState } from 'react'
import { Stack } from 'raam'
import { ThemeUIStyleObject, Heading, Text, Link } from 'theme-ui'

import {
  BLOCKS,
  CustomRenderer,
  Document,
  isParagraph,
  Node,
  renderBlockLink,
  renderInlineLink,
  renderRichText,
  toString,
} from '../rich-text'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { ReactNode } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { formatPageLink } from '../utils/helpers'
import PageLink from './page-link'
import Video from './video'
import Table from './table'

interface Props {
  title?: string
  faqs?: Array<{
    question?: string
    answer?: Document
    anchor?: string
  }>
  location?: Location
}

const FAQsAccordion: React.FC<Props> = ({ title, faqs, location }) => {
  const [changed, setChanged] = useState(false)
  const hash = location?.hash
  useEffect(() => setChanged(false), [hash])
  const handleChange = () => setChanged(true)

  const heading: ThemeUIStyleObject = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginX: 'auto',
    marginBottom: '1rem',
  }

  const accordion: ThemeUIStyleObject = {
    maxWidth: '95rem',
    marginX: 'auto',
  }

  const accordionItem: ThemeUIStyleObject = {
    border: 'mutedBg',
    marginY: '4px',
  }

  const accordionButton: ThemeUIStyleObject = {
    position: 'relative',
    backgroundColor: 'muted',
    color: 'text',
    cursor: 'pointer',
    paddingLeft: '2rem',
    paddingRight: '4.5rem',
    paddingY: '1.7rem',
    width: '100%',
    textAlign: 'left',
    border: 'none',
    '&::after': {
      position: 'absolute',
      content: '""',
      top: '50%',
      right: '2rem',
      width: '0.5rem',
      height: '0.5rem',
      borderRight: '0.3rem solid',
      borderBottom: '0.3rem solid',
      transform: 'translateY(-60%) rotate(45deg)',
    },
    '&[aria-expanded=true]': {
      backgroundColor: 'primary',
      fontWeight: 'bold',
      outline: 'none',
      '&::after': {
        transform: 'translateY(-20%) rotate(-135deg)',
      },
    },
  }

  const accordionPanel: ThemeUIStyleObject = {
    backgroundColor: 'darkMuted',
    paddingX: '2rem',
    paddingY: '1.5rem',
  }

  const renderAltLink = (node: Node): ReactNode => {
    switch (node.nodeType) {
      case 'hyperlink':
        return (
          <Link href={node.data.uri} variant="buttonAlt">
            {toString(node)}
          </Link>
        )

      case 'entry-hyperlink': {
        const to = formatPageLink(node.data.target)
        return (
          <PageLink to={to} variant="buttonAlt">
            {toString(node)}
          </PageLink>
        )
      }

      default:
        return renderInlineLink(node)
    }
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        renderBlockLink(node, renderAltLink) ||
        (isParagraph(node) && <Text as="p">{children}</Text>),

      [BLOCKS.EMBEDDED_ASSET]: node => {
        switch (node.data.target.file.contentType) {
          case 'image/png':
          case 'image/jpeg':
            return (
              <GatsbyImage
                sx={{ maxWidth: '50rem' }}
                image={node.data.target.gatsbyImageData}
                alt={node.data.target.title}
              />
            )
        }
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        switch (node.data.target.__typename) {
          case 'ContentfulVideo':
            return (
              <Video
                title={node.data.target.title}
                url={node.data.target.url}
              />
            )
          case 'ContentfulTable':
            return (
              <Table
                title={node.data.target.title}
                data={node.data.target.data.tableData}
              />
            )
        }
      },
    },
  }

  return (
    <Stack>
      <Heading sx={heading} as="h3" variant="med">
        {title}
      </Heading>
      <Accordion sx={accordion} onChange={handleChange} allowZeroExpanded>
        {faqs?.map((faq, i) => (
          <AccordionItem
            sx={accordionItem}
            key={i}
            {...(faq.anchor?.length && { id: faq.anchor })}
            {...(faq.anchor === hash?.substring(1) &&
              !changed && {
                dangerouslySetExpanded: true,
              })}
          >
            <AccordionItemHeading>
              <AccordionItemButton sx={accordionButton}>
                {faq.question}
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel sx={accordionPanel}>
              <Stack gap={12}>
                {faq.answer && renderRichText(faq.answer, renderers)}
              </Stack>
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Stack>
  )
}

export default FAQsAccordion
