import React from 'react'
import { Box, Label, Select, Text } from 'theme-ui'
import { UseFormMethods } from 'react-hook-form'

const Salutation = ({
  ...formFns
}: UseFormMethods<{ readonly salutation: string }>): JSX.Element => {
  const { errors, register } = formFns
  return (
    <Box sx={{ marginBottom: '2rem' }}>
      <Label htmlFor="salutation" id="label-salutation">
        Title
      </Label>
      <Select
        aria-describedby="label-salutation errors-salutation"
        name="salutation"
        ref={register({
          required: ' Please select your title',
        })}
      >
        <option value="">Please select</option>
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Ms">Ms</option>
        <option value="Mx">Mx</option>
        <option value="Dr">Dr</option>
        <option value="Reverend">Reverend</option>
        <option value="Professor">Professor</option>
      </Select>
      {errors.salutation ? (
        <Text as="p" id="errors-salutation" variant="errorLabel">
          {errors.salutation.message}
        </Text>
      ) : null}
    </Box>
  )
}

export default Salutation
