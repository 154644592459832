import React from 'react'
import { Box, Button, Flex, Grid, Heading, Text, Link } from 'theme-ui'
import { WholeForm } from './direct-debit'
import { AddressPickerFormData } from './inputs/address-picker'
import { heading, fieldset, paragraph } from './utils/styles'
import { useRef, useEffect } from 'react'

const filterAddress = ({
  line1,
  line2,
  line3,
  town,
  county,
  postcode,
}: AddressPickerFormData): string[] =>
  [line1, line2, line3, town, county, postcode].filter(part => part?.length > 0)

const successText: JSX.Element = (
  <Box sx={fieldset}>
    <Heading sx={heading}>Thanks for choosing Direct Debit</Heading>
    <Text as="p" sx={paragraph}>
      {`We've got everything we need from you to set up your Direct Debit
      payments. We'll write to you confirming all the details within three
      working days and no later than 10 working days before the first payment is
      collected from you.`}
    </Text>
    <Text as="p" sx={paragraph}>
      If you have any questions about your new Direct Debit payment feel free to
      call us on <Link href="tel:03456001994">0345 600 1994</Link>
    </Text>
    <Text as="p" sx={paragraph}>
      Your Direct Debit Guarantee is below for your reference:
    </Text>
    <Heading sx={heading}>The Direct Debit Guarantee</Heading>
    <Text as="p" sx={paragraph}>
      This Guarantee is offered by all banks and building societies that accept
      instructions to pay Direct Debits.
    </Text>
    <Text as="p" sx={paragraph}>
      {`If there are any changes to the amount, date or frequency of your
      Direct Debit The Renewable Energy Company will notify you ten (10) working
      days in advance of your account being debited or as otherwise agreed. If
      you request The Renewable Energy Company to collect a payment,
      confirmation of the amount and date will be given to you at the time of
      the request.`}
    </Text>
    <Text as="p" sx={paragraph}>
      If an error is made in the payment of your Direct Debit, by The Renewable
      Energy Company or your bank or building society you are entitled to a full
      and immediate refund of the amount paid from your bank or building
      society.
    </Text>
    <Text as="p" sx={paragraph}>
      If you receive a refund you are not entitled to, you must pay it back when
      The Renewable Energy Company asks you to.
    </Text>
    <Text as="p" sx={paragraph}>
      You can cancel a Direct Debit at any time by simply contacting your bank
      or building society. Written confirmation may be required. Please also
      notify us.
    </Text>
    <Text as="p" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
      The Ecotricity Team
    </Text>
  </Box>
)

const DirectDebitStepThree = ({
  back,
  complete,
  values,
  submissionState,
}: {
  back: () => void
  complete: (data: WholeForm) => void
  values: WholeForm
  submissionState: {
    readonly submissionError: string | null
    readonly inFlight: boolean
    readonly submitted: boolean
  }
}): JSX.Element => {
  const address = filterAddress(values.billingAddress)
  const success = useRef<HTMLDivElement>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [submissionState.submitted])

  return submissionState.submitted ? (
    <Box ref={success}>
      <Text>{successText}</Text>
    </Box>
  ) : (
    <Box>
      <Heading sx={heading}>Nearly there</Heading>
      <Text as="p" sx={fieldset}>
        {`A summary of the details you have given us are shown below. Please check
        them thoroughly and, when you're happy with everything, continue to the
        final step.`}
      </Text>
      <Heading
        sx={heading}
      >{`You're setting up Direct Debits for the following account:`}</Heading>
      <Grid columns={['auto', '1fr 1fr', '1fr 3fr']} gap={10} sx={fieldset}>
        <Text as="p" variant="label">
          Account number:
        </Text>{' '}
        <Text as="p">{values.accountNumber}</Text>
      </Grid>
      <Heading sx={heading}>Billing name and address</Heading>
      <Grid columns={['auto', '1fr 1fr', '1fr 3fr']} gap={10} sx={fieldset}>
        <Text as="p" variant="label">
          Name:
        </Text>
        <Text as="p">
          {values.salutation} {values.firstName} {values.lastName}
        </Text>
        <Text as="p" variant="label">
          {' '}
          Address:
        </Text>
        <Text as="p">
          {address.map((part, i) => (
            <span key={i}>
              {part}
              <br />
            </span>
          ))}
        </Text>
        <Text as="p" variant="label">
          E-mail:
        </Text>
        <Text as="p">{values.emailAddress}</Text>
      </Grid>
      <Heading sx={heading}>Bank account details </Heading>
      <Text as="p" sx={heading}>
        The name which will appear on your bank statement against the Direct
        Debit will be Ecotricity Ltd.
      </Text>
      <Grid columns={['auto', '1fr 1fr', '1fr 3fr']} gap={10} sx={fieldset}>
        <Text as="p" variant="label">
          Account name:
        </Text>
        <Text as="p">{values.bankAccountName}</Text>
        <Text as="p" variant="label">
          Account number:
        </Text>
        <Text as="p">{values.bankAccountNumber}</Text>
        <Text as="p" variant="label">
          Account sort code:
        </Text>
        <Text as="p">{values.bankAccountSortCode}</Text>
      </Grid>
      <Box sx={fieldset}>
        <Heading sx={heading}>Getting your Direct Debit payments right</Heading>
        <Text as="p" sx={heading}>
          {`Your monthly payments to Ecotricity will leave your account within 3
          days of the date quoted on your bill. Monthly payments may vary
          depending on the time of year and how much energy you use. To ensure
          your payments are accurate, you'll need to provide a meter reading
          each month. If we do not have your meter reading, we will take payment
          for an estimate of your consumption for that month.`}
        </Text>
        <Text as="p" sx={heading}>
          And we will also include the current balance on your account.
        </Text>
        <Grid columns={['auto', '1fr 1fr', '1fr 3fr']} gap={10} sx={fieldset}>
          <Text as="p" variant="label">
            Account name:
          </Text>
          <Text as="p">{values.bankAccountName}</Text>
          <Text as="p" variant="label">
            Account number:
          </Text>
          <Text as="p">{values.bankAccountNumber}</Text>
        </Grid>
        <Text as="p">
          {`We'll send you a letter confirming the amount we would like you to pay
          as soon as we process your details. You'll get this letter at least 10
          working days before we request any money from your account. If you
          have any queries regarding this, please call us on 0345 555 7 100.`}
        </Text>
      </Box>
      <Flex
        sx={{
          flexFlow: 'row wrap',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault()
            back()
          }}
        >
          Back
        </Button>
        <Button type="submit" onClick={() => complete(values)}>
          {submissionState.inFlight ? 'Submitting form...' : 'Submit form'}
        </Button>
        {submissionState.submissionError === null ? (
          ''
        ) : (
          <Text as="p" variant="errorLabel">
            {submissionState.submissionError}
          </Text>
        )}
      </Flex>
    </Box>
  )
}

export default DirectDebitStepThree
