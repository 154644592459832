import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { Heading, Text } from '@theme-ui/components'
import Section from './section'
import ReactHtmlParser from 'react-html-parser'

interface Props {
  variant?: string
  quoteTitle?: string
  quote?: string
  quoteSummary?: string
  style?: ThemeUIStyleObject
}

const HalfPageQuote: React.FC<Props> = ({
  variant,
  quoteTitle,
  quote,
  quoteSummary,
  style,
}) => {
  const sectionVariant = variant ?? 'halfPageRight'

  let quoteSx: ThemeUIStyleObject = {
    fontWeight: 400,
  }

  if ('contrastHalfPage' === variant) {
    quoteSx = {
      color: 'white',
      fontWeight: 700,
    }
  }

  return (
    <Section style={style} fill={sectionVariant} variant="halfPageRight">
      <Text as="p">{ReactHtmlParser(quoteTitle)}</Text>
      <Heading sx={quoteSx} variant="med">
        {ReactHtmlParser(quote)}
      </Heading>
      {sectionVariant === 'contrastHalfPage' && (
        <Text as="p">{ReactHtmlParser(quoteSummary)}</Text>
      )}
    </Section>
  )
}

export default HalfPageQuote
