import React from 'react'
import { Box, Button, Heading, Text, Image } from 'theme-ui'
import { useState } from 'react'
import Checkbox from './inputs/checkbox'
import { StepZeroForm } from './direct-debit'
import { useForm } from 'react-hook-form'
import { heading, fieldset } from './utils/styles'
import bacs from '../images/bacs-approved-bureau.png'
import { useEffect } from 'react'

const DirectDebitStepZero = ({
  complete,
  defaultValues,
}: {
  complete: (data: StepZeroForm) => void
  defaultValues?: Partial<StepZeroForm>
}): JSX.Element => {
  const formFns = useForm<StepZeroForm>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues,
  })

  const { handleSubmit } = formFns

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <form
      noValidate
      onSubmit={handleSubmit(formData => {
        if (!formData.declaration) {
          setError(
            'You must complete the declaration by checking the checkbox.'
          )
          return
        }
        setError(null)
        complete(formData)
      })}
    >
      <Box sx={fieldset}>
        <Heading sx={heading}>Declaration</Heading>
        <Text as="p" sx={{ fontWeight: 'bold' }}>
          Please note:
        </Text>
        <Text as="p">
          If you are not the account holder or your account requires more than
          one signature a paper Direct Debit Instruction will be required to be
          completed and posted to us.{' '}
          <a href="https://assets.ctfassets.net/620j9bwnh4b6/4aR32VrYLy313CrKfMjzmh/dd13d06917a0916711933af0687694d5/Ecotricity_Business_Direct_Debit_Mandate_Form.pdf">
            Download a printable Direct Debit Instruction form.
          </a>
        </Text>
      </Box>
      <Box sx={fieldset}>
        <Checkbox
          {...formFns}
          label="I wish to start a monthly Direct Debit"
          name="declaration"
        />
        <Text as="p" variant="errorLabel">
          {error}
        </Text>
        <Button>Continue</Button>
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Information</Heading>
        <Text as="p">
          All the normal Direct Debit safeguards and guarantees apply. No
          changes in the amount, date, frequency to be debited can be made
          without notifying you at least ten (10) working days in advance of
          your account being debited. In the event of any error, you are
          entitled to an immediate refund from your Bank or Building Society.
          You have the right to cancel a Direct Debit Instruction at any time
          simply by writing to your Bank or Building Society, with a copy to us.
        </Text>
      </Box>
      <Image sx={fieldset} src={bacs} alt="Bacs approved bureau logo" />
    </form>
  )
}

export default DirectDebitStepZero
