/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import { Box, Button, Input, Text, Label, Select, Grid } from 'theme-ui'
import { useCallback, useLayoutEffect } from 'react'

import { UseFormMethods, FieldError } from 'react-hook-form'

import injectDeps from '../utils/ap'
import { useState, UseState } from '../utils/use-state'
import { nonEmptyString, validPostcode } from '../utils/validators'
import { FetchAddressError } from '../utils/errors'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isFieldError = (x: any | undefined): x is FieldError =>
  typeof x?.message === 'string'

const URL = `https://${
  process.env.GATSBY_API_HOST as string
}/address-lookup/v1/public/address-lookup`

export type AddressPickerFormData = {
  readonly postcode: string
  readonly line1: string
  readonly line2: string
  readonly line3: string
  readonly town: string
  readonly county: string
  readonly udprn: string
}
const postcodeName = 'postcode'
const postcodeId = 'postcode'
const line1Name = 'line1'
const line1Id = 'line1'
const line2Name = 'line2'
const line3Name = 'line3'
const townName = 'town'
const townId = 'town'
const countyName = 'county'
const countyId = 'county'
const udprnName = 'udprn'
const udprnId = 'udprn'

export type FetchedAddress = {
  readonly addressline1: string
  readonly addressline2: string
  readonly buildingname: string
  readonly county: string
  readonly postcode: string
  readonly posttown: string
  readonly premise: string
  readonly street: string
  readonly summaryline: string
  readonly organisation?: string
  readonly subbuildingname?: string
  readonly uniquedeliverypointreferencenumber?: string
}

const emptyFetchedAddress: FetchedAddress = {
  addressline1: '',
  addressline2: '',
  buildingname: '',
  county: '',
  postcode: '',
  posttown: '',
  premise: '',
  street: '',
  summaryline: '',
  uniquedeliverypointreferencenumber: '',
}

const AddressDetails = <AddressName extends string>({
  manual,
  register,
  udprn,
  addressName,
}: {
  readonly manual: boolean
  readonly udprn: string | undefined
  readonly addressName: AddressName
} & UseFormMethods<{
  readonly [Key in AddressName]: AddressPickerFormData
}>) => {
  const inputMode = manual ? 'text' : 'hidden'
  const displayMode = { display: manual ? 'auto' : 'none' }

  return (
    <Box sx={{ padding: manual ? '2rem 0' : '2rem 0 0' }}>
      <Label htmlFor={line1Id} sx={displayMode}>
        Address
      </Label>
      <Input
        name={`${addressName}.${line1Name}`}
        ref={register({ validate: () => true })}
        type={inputMode}
        variant="complexFormInput"
      />
      <Input
        aria-label="Address line 2"
        name={`${addressName}.${line2Name}`}
        ref={register({ validate: () => true })}
        type={inputMode}
        variant="complexFormInput"
      />
      <Input
        aria-label="Address line 3"
        name={`${addressName}.${line3Name}`}
        ref={register({ validate: () => true })}
        type={inputMode}
        variant="complexFormInput"
      />
      <Label htmlFor={townId} sx={displayMode}>
        Town
      </Label>
      <Input
        id={townId}
        name={`${addressName}.${townName}`}
        ref={register({ validate: () => true })}
        type={inputMode}
        variant="complexFormInput"
      />
      <Label htmlFor={countyId} sx={displayMode}>
        County
      </Label>
      <Input
        id={countyId}
        name={`${addressName}.${countyName}`}
        ref={register({ validate: () => true })}
        type={inputMode}
        variant="complexFormInput"
      />
      <Input
        id={udprnId}
        name={`${addressName}.${udprnName}`}
        ref={register()}
        type="hidden"
        value={manual ? '' : udprn}
      />
    </Box>
  )
}

const safeIndex =
  (index: number) =>
  <V extends unknown>(array: ReadonlyArray<V>): V | undefined =>
    array[index]

const SelectOrSpecifyAddress = ({
  useManualState,
  useSelectedState,
}: {
  readonly useManualState: UseState<boolean>
  readonly useSelectedState: UseState<number>
}) =>
  function SelectOrSpecifyAddress<AddressName extends string>(
    props: {
      readonly addresses: ReadonlyArray<FetchedAddress>
      readonly addressName: AddressName
    } & UseFormMethods<{
      readonly [Key in AddressName]: AddressPickerFormData
    }>
  ) {
    const { setValue, addresses } = props
    const [manual, setManual] = useManualState(false)
    const [selected, setSelectedAddress] = useSelectedState(-1)

    const selectedAddress =
      selected >= 0 ? safeIndex(selected)(addresses) : emptyFetchedAddress

    useLayoutEffect(() => {
      if (selectedAddress !== undefined) {
        // @ts-expect-error hard to convince react-hook-form this is safe
        setValue(`${props.addressName}.${line1Name}`, selectedAddress.premise)
        // @ts-expect-error hard to convince react-hook-form this is safe
        setValue(`${props.addressName}.${line2Name}`, selectedAddress.street)
        // @ts-expect-error hard to convince react-hook-form this is safe
        setValue(`${props.addressName}.${townName}`, selectedAddress.posttown)
        // @ts-expect-error hard to convince react-hook-form this is safe
        setValue(`${props.addressName}.${countyName}`, selectedAddress.county)
      }
    }, [selectedAddress, props.addressName, setValue])

    return (
      <div>
        <Select
          aria-label="Select address"
          disabled={manual}
          name="select-address"
          onBlur={({ target: { options, selectedIndex } }) => {
            const selected = Number(options[selectedIndex].value)

            setSelectedAddress(selected)
          }}
          sx={{ marginBottom: '1rem' }}
        >
          <option value={-1}>Please select</option>
          {addresses.map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={address.summaryline + index} value={index}>
              {address.summaryline}
            </option>
          ))}
        </Select>
        <Label htmlFor="custom-address-checkbox">
          {manual ? 'Use Address Picker' : "Can't see your address?"}
        </Label>
        <input
          id="custom-address-checkbox"
          name="custom-address"
          onChange={({ target: { checked } }) => {
            setManual(checked)
          }}
          style={{ position: 'absolute', left: '-9999px' }}
          type="checkbox"
        />
        <AddressDetails
          {...props}
          addressName={props.addressName}
          manual={manual}
          udprn={selectedAddress?.uniquedeliverypointreferencenumber}
        />
      </div>
    )
  }

type PotentiallyFetchedAddresses =
  | ReadonlyArray<FetchedAddress>
  | Error
  | undefined

const validate = {
  valid: validPostcode,
  nonEmpty: nonEmptyString('postcode'),
}

export const formatPostcode = (postcode: string): string =>
  postcode.replace(/ /g, '')

export const _AddressPicker = injectDeps(SelectOrSpecifyAddress)(
  ({
      useFetchedAddresses,
    }: {
      readonly useFetchedAddresses: UseState<PotentiallyFetchedAddresses>
    }) =>
    SelectOrSpecifyAddress =>
      function AddressPicker<AddressName extends string>(
        props: {
          readonly addressName: AddressName
        } & UseFormMethods<{
          readonly [Key in AddressName]: AddressPickerFormData
        }>
      ): JSX.Element {
        const { register, watch, errors } = props

        const postcodeValue = watch(`${props.addressName}.${postcodeName}`) as
          | string
          | undefined

        const [fetchedAddresses, setFetchedAddresses] =
          useFetchedAddresses(undefined)

        const fetchAddress = useCallback(() => {
          const unavailableError = new FetchAddressError(
            "The postcode search isn't available right now, please enter your address below"
          )
          return Promise.resolve()
            .then(() => {
              if (!postcodeValue) {
                throw new FetchAddressError('Please enter a valid postcode')
              }
            })
            .then(() =>
              fetch(
                `${URL}/${formatPostcode(postcodeValue ?? '')}?addTags=UDPRN`
              )
            )
            .then(response => response.json())
            .then(
              (
                value: Record<string, unknown>
              ): Promise<PotentiallyFetchedAddresses> => {
                if (value.status === 'success') {
                  // @ts-expect-error no validation here - let's hope it works!
                  return value.data
                }

                throw unavailableError
              }
            )
            .then(setFetchedAddresses)
            .catch(error =>
              setFetchedAddresses(
                error instanceof FetchAddressError ? error : unavailableError
              )
            )
        }, [postcodeValue, setFetchedAddresses])

        const deepErrors = errors[props.addressName]

        const addressErrors = (
          deepErrors !== undefined &&
          !isFieldError(deepErrors) &&
          !Array.isArray(deepErrors)
            ? deepErrors
            : undefined
        ) as
          | Record<keyof AddressPickerFormData, FieldError | undefined>
          | undefined

        return (
          <Box>
            <Grid columns={[1, '1fr 4fr']} gap="10">
              <Box>
                <Label htmlFor={postcodeId}>Postcode</Label>
                <Input
                  id={postcodeId}
                  name={`${props.addressName}.${postcodeName}`}
                  ref={register({ validate })}
                  variant="complexFormInput"
                />
              </Box>
              <Box
                sx={{
                  padding: ['0 0 2rem', '2rem 0'],
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  alignItems: 'flex-end',
                }}
              >
                <Button
                  onClick={fetchAddress}
                  type="button"
                  variant="small"
                  sx={{ padding: '8px 16px', cursor: 'pointer' }}
                >
                  Get address
                </Button>
              </Box>
              <Text
                as="p"
                variant="errorLabel"
                sx={{
                  display:
                    addressErrors?.[postcodeName]?.message ||
                    fetchedAddresses instanceof Error
                      ? 'block'
                      : 'none',
                }}
              >
                {addressErrors?.[postcodeName]?.message ??
                  (fetchedAddresses instanceof Error &&
                    fetchedAddresses.message)}
              </Text>
            </Grid>
            {fetchedAddresses instanceof Error ? (
              <Box>
                <AddressDetails
                  {...props}
                  addressName={props.addressName}
                  manual={true}
                  udprn={undefined}
                />
              </Box>
            ) : fetchedAddresses !== undefined ? (
              <SelectOrSpecifyAddress {...props} addresses={fetchedAddresses} />
            ) : undefined}
          </Box>
        )
      }
)

const AddressPicker = _AddressPicker({
  useManualState: useState,
  useSelectedState: useState,
  useFetchedAddresses: useState,
})

export default AddressPicker
