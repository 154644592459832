import React from 'react'
import { Stack } from 'raam'
import { Grid, Heading, ThemeUIStyleObject } from 'theme-ui'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import ArticleImage from './article-image'
import { Document, renderRichText } from '../rich-text'
import {
  gridTemplateAreas as rows,
  repeatGridArea as cols,
} from '../utils/mixins'

interface Props {
  title?: string
  image?: {
    title?: string
    description?: string
    fluid?: IGatsbyImageData
  }
  body?: Document
}

const Event: React.FC<Props> = ({ title, image, body }) => {
  // prettier-ignore
  const grid: ThemeUIStyleObject = {
    gridTemplateAreas: [
      rows([
        [cols(12, 'title')],
        [cols(12, 'body')],
        [cols(12, 'image')],
      ]),
      rows([
        [cols(4, 'title'), cols(8, null)],
        [cols(4, 'body'), null, cols(7, 'image')],
      ]),
      rows([
        ['hr', cols(3, 'title'), cols(8, null)],
        [null, cols(3, 'body'), null, cols(7, 'image')],
      ]),
    ],
    marginTop: 17,

    '::before': {
      content: title && '""',
      display: ['none', null, 'block'],
      borderTop: 'active',
      borderWidth: '0.5rem',
      position: 'relative',
      top: 'calc(50% - 0.5rem)',
    },
  }

  const columns = {
    title: { gridArea: 'title' },
    body: { gridArea: 'body' },
    image: { gridArea: 'image' },
  }

  return (
    <Grid sx={grid} variant="equal">
      {title && (
        <Heading as="h3" sx={columns.title} variant="med">
          {title}
        </Heading>
      )}
      {body && (
        <Stack sx={columns.body} gap={12}>
          {renderRichText(body)}
        </Stack>
      )}
      {image?.gatsbyImageData && (
        <ArticleImage
          sx={columns.image}
          fluid={image.gatsbyImageData}
          alt={image.title || ''}
          description={image.description || ''}
        />
      )}
    </Grid>
  )
}

export default Event
