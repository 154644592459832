import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Video, { Props as VideoProps } from './video'
import { spanWidth } from '../utils/mixins'
import { ThemeUIStyleObject } from 'theme-ui'
import { MaybeGatsbyImageProps } from '../utils/types'
import { isFluid } from '../utils/helpers'

interface Props {
  image?: MaybeGatsbyImageProps
  video?: VideoProps
}

const Hero: React.FC<Props> = ({ image, video }) => {
  const style = {
    marginLeft: [0, null, spanWidth(1, 12, 11, true)],
  }

  return video ? (
    <Video
      sx={style as ThemeUIStyleObject}
      url={video.url}
      title={video.title}
    />
  ) : image && isFluid(image) ? (
    <GatsbyImage
      sx={style as ThemeUIStyleObject}
      image={image.gatsbyImageData}
      alt={image.title}
    />
  ) : null
}

export default Hero
