import React from 'react'
import { Stack } from 'raam'
import { Link, Text } from 'theme-ui'

interface Props {
  text?: string
  buttonText?: string
  buttonLink?: string
}

const CTA: React.FC<Props> = ({ text, buttonText, buttonLink }) => {
  return (
    <Stack sx={{ maxWidth: '30rem', mx: 'auto', textAlign: 'center' }} gap={13}>
      <Text as="p" variant="intro">
        {text}
      </Text>
      <Link href={buttonLink} variant="button">
        {buttonText}
      </Link>
    </Stack>
  )
}

export default CTA
