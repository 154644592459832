const FIT_ID_REGEX = /^(FIT)?00[0-9]{6}-[1-9]$/

export const validFitId = (str: string | undefined): true | string =>
  Boolean(str?.length) && str?.trim().match(FIT_ID_REGEX)
    ? true
    : 'Your FIT ID must be at least 9 numerical characters'

export const validMeterRead =
  (fieldName: string) =>
  (str: string | undefined): true | string =>
    str === '' ||
    str === undefined ||
    (Boolean(str.length) &&
      str.trim().match(/^[0-9]+$/) &&
      str.trim().match(/[1-9]/))
      ? true
      : `${fieldName} is not valid. It does not need to include numbers after the decimal place.`
