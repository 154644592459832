import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import Article from '../components/article'
import { Document } from '../rich-text'

// Lazy load mills map as it is only supposed to be rendered client side
import loadable from '@loadable/component'
const MillsMap = loadable(() => import('../components/mills-map'))

export interface LatLon {
  lat: number
  lon: number
}

export interface MillLocation {
  markerId: number
  name: string
  type: string
  status: string
  location: LatLon
  description: string
  body: Document
  image: string
}

export const query = graphql`
  query GreenElectricity($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      slug
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      partnerPromotion
      sections {
        __typename
        ... on ContentfulArticle {
          title
          body {
            raw
            references {
              ...ArticleReferences
            }
          }
          aside {
            raw
            references {
              ...ArticleReferences
            }
          }
        }
      }
    }
    millsMap: allContentfulMill {
      nodes {
        name
        type
        status
        image {
          file {
            url
          }
        }
        location {
          lat
          lon
        }
        keyInfo {
          keyInfo
        }
        body {
          raw
        }
      }
    }
  }
`

const GreenElectricity: React.FC<PageProps<GatsbyTypes.GreenElectricityQuery>> =
  ({ data: { page = {}, millsMap = {} } }) => {
    const articles = page.sections?.filter(
      section => section?.__typename === 'ContentfulArticle'
    ) as GatsbyTypes.ContentfulArticle | undefined

    const getFillColour = (millPark: MillLocation) => {
      if (millPark.status.toLowerCase() === 'operating') {
        return '#9ad43a'
      }
      if (millPark.status.toLowerCase() === 'approved') {
        return '#3ea0fd'
      }

      if (millPark.status.toLowerCase() === 'in planning') {
        return '#e54d9f'
      }
    }

    const mapData = millsMap?.nodes?.map(
      (source: MillLocation, index: number) =>
        ({
          markerId: index,
          name: source.name,
          type: source.type,
          status: source.status,
          colour: getFillColour(source),
          location: source?.location
            ? {
                lat: source.location.lat,
                lon: source.location.lon,
              }
            : null,
          description: source.keyInfo?.keyInfo as string,
          body: source.body,
          image: (source?.image?.file?.url
            ? source.image.file.url
            : null) as string,
        } as MillLocation)
    ) as Array<MillLocation>

    const sectionsCount = articles.length + 1

    return (
      <Layout page={page} subnav={true} masthead="default">
        <Section as="div" variant={page.sections ? 'header' : 'page'}>
          <PageHeader
            topic={page.topic?.heading || page.topic?.name}
            heading={page.heading || page.title}
            subheading={page.subheading}
            standfirst={page.standfirst?.standfirst}
          />
        </Section>
        {articles.map((article, index) => (
          <Section key={index} pos={index} count={sectionsCount} variant="page">
            <Article body={article?.body} aside={article?.aside} />
          </Section>
        ))}
        <Section
          pos={sectionsCount - 1}
          count={sectionsCount}
          fill="sprayNESW"
          variant="page"
        >
          <MillsMap millLocations={mapData || []} />
        </Section>
      </Layout>
    )
  }

export default GreenElectricity
