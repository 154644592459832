import React, { ReactElement, ReactNode } from 'react'

import Carousel from '../components/carousel'
import Cluster from '../components/cluster'
import ComplaintSteps from './complaint-steps'
import Event from '../components/event'
import FAQsAccordion from '../components/faqs-accordion'
import LogoList from '../components/logo-list'
import QuickLinks from './quick-links'
import Spread from '../components/spread'
import ThreePointBanner from './three-point-banner'
import TrustpilotPromo from '../components/trustpilot-promo'
import FindCarbonFootprint from '../components/find-carbon-footprint'

import { Document, renderRichText } from '../rich-text'
import { Client, Item, Link, FAQ, EventType } from '../utils/types'

type Props = {
  type: string
  title: string
  items: Array<Client | Item | Link | FAQ | EventType>
  description?: Document
  ctaText?: string
  ctaUrl?: string
  ctaOpenInNewTab?: boolean
  params?: {
    cols?: number
    plain?: boolean
    tabbed?: string
    variant?: string
    maxLinkCount?: number
    location?: {
      pathname?: string
      search?: string
      state?: unknown
      hash?: string
      key?: string
    }
  }
}

const GeneralContent: React.FC<Props> = ({
  type,
  title,
  description,
  items,
  ctaText,
  ctaUrl,
  ctaOpenInNewTab,
  params,
}): ReactElement | null => {
  type = type[0]
  switch (type) {
    case 'Client List':
      return (
        <LogoList
          title={title}
          items={items?.map((client: Client) => ({
            title: client?.name,
            logo: client?.logo,
            page: client?.page && client.page[0],
          }))}
        />
      )
    case 'Cluster':
      return (
        <Cluster
          items={items?.map((item: Item) => ({
            title: item?.title || item?.itemTitle,
            body: item?.body,
            image: item?.image,
            video: item?.video,
          }))}
          {...params}
        />
      )
    case 'Flow Chart':
      return (
        <ComplaintSteps
          heading="If something goes wrong"
          items={items?.map((item: Item) => ({
            title: item?.title || item.itemTitle,
            body: renderRichText(item?.body),
          }))}
        />
      )
    case 'Quick Links':
      return (
        <QuickLinks
          title={title}
          links={items?.map((link: Link) => ({
            slug: link?.slug,
            name: link?.name,
            icon: link?.icon?.file?.url,
          }))}
          {...params}
        />
      )
    case 'Spread':
      return (
        <Spread
          items={items?.map((item: Item) => ({
            title: item?.title || item?.itemTitle,
            body: item?.body,
            image: item?.image,
            video: item?.video,
          }))}
          {...params}
        />
      )
    case 'Support':
      return (
        <FAQsAccordion
          title={title}
          faqs={items?.map((page: FAQ) => {
            const isFaq = page.__typename === 'ContentfulFaq'
            return {
              question: isFaq ? page.question : page.heading,
              answer: isFaq ? page.answer : page.body,
              anchor: page.anchor,
            }
          })}
          {...params}
        />
      )
    case 'Timeline':
      return (
        <Carousel
          title={title}
          items={items?.reduce(
            (items: { [key: string]: ReactNode }, event: EventType) => {
              if (event?.date) {
                items[event.date] = (
                  <Event
                    title={event?.title}
                    image={event?.image}
                    body={event?.body}
                  />
                )
              }
              return items
            },
            {}
          )}
          {...params}
        />
      )
    case 'Three Point Banner':
      return (
        <ThreePointBanner
          title={title}
          type={type}
          items={items}
          description={description}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          ctaOpenInNewTab={ctaOpenInNewTab}
        />
      )
    case 'Three Point Banner Wide':
      return (
        <ThreePointBanner
          title={title}
          type={type}
          items={items}
          description={description}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          ctaOpenInNewTab={ctaOpenInNewTab}
        />
      )
    case 'Trustpilot Promo':
      return <TrustpilotPromo title={title} />
    case 'Find Carbon Footprint':
      return process.env.GATSBY_ENABLE_CARBON_CALCULATOR_V2 === 'true' ? (
        <FindCarbonFootprint
          title={title}
          description={description}
          ctaText={ctaText}
          ctaUrl={ctaUrl}
          ctaOpenInNewTab={ctaOpenInNewTab}
        />
      ) : null
    default:
      return null
  }
}

export default GeneralContent
