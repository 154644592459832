import React from 'react'
import { AnchorHTMLAttributes } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'

// fix for https://github.com/gatsbyjs/gatsby/issues/16682#issuecomment-523352028
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

// link interface as per https://paulie.dev/posts/2020/04/typescript-theme-ui-link/
export interface Props
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  innerRef?: React.RefObject<HTMLAnchorElement>
  variant?: string
}

// component replicates link variant theming for Gatsby Link
const PageLink: React.FC<Props> = ({
  to,
  innerRef,
  variant,
  children,
  ...other
}) => {
  const fallback = variant ? `links.${variant}` : 'styles.a'

  return (
    <Link innerRef={innerRef} to={to} sx={{ variant: fallback }} {...other}>
      {children}
    </Link>
  )
}

export default PageLink
