/**
 * These functions abstract the Contentful rich text handling into a decoupled
 * interface for the app to process rich-text. If the app needs to swtich CMS
 * then the rich-text handling only needs to be changed here.
 *
 * This implementation currently uses the WIP 'next' branch of
 * gatsby-source-contentful because of the big step forward in perforamnce
 * and DX. All the planned changes for the branch are complete except for
 * tests and docs and the API seems to be stable and worth using.
 *
 * See: https://github.com/gatsbyjs/gatsby/pull/25249
 */

import React from 'react'
import { Heading, Text, Link, ThemeUIStyleObject } from 'theme-ui'
import { ReactNode } from 'react'
import { formatPageLink } from '../utils/helpers'
import { Stack } from 'raam'
import { GatsbyImage } from 'gatsby-plugin-image'
import PageLink from '../components/page-link'

import { renderRichText as render } from 'gatsby-source-contentful/rich-text'

import {
  CommonNode as Node,
  RenderNode,
  RenderMark,
  RenderText,
  Options,
} from '@contentful/rich-text-react-renderer'

import {
  BLOCKS,
  INLINES,
  MARKS,
  Block as BlockNode,
  Inline as InlineNode,
  Text as TextNode,
} from '@contentful/rich-text-types'

import { renderGroups } from './groups'
import { renderBlockLink } from './links'
import { isParagraph, toString } from './helpers'

// the capitalised exports are ENUMs for use when specifying custom
// nodeRenderers, e.g. BLOCKS.PARAGRAPH, and the other exports are Typescript
// types for different kinds of rich-text node
export {
  BLOCKS,
  INLINES,
  MARKS,
  Node,
  BlockNode,
  InlineNode,
  TextNode,
  Options as CustomRenderer,
}

export * from './groups'
export * from './links'
export * from './helpers'

// gatsby-source-contentful v3.0 does not have an updated type for the new
// Document interface, so define it here
export interface Document {
  raw?: string
  references?: unknown // wait for official types
}

const defaultNodeRenderers: RenderNode = {
  [BLOCKS.DOCUMENT]: (node, children) =>
    renderGroups(node as BlockNode, children),

  [BLOCKS.PARAGRAPH]: (node, children) =>
    renderBlockLink(node) ||
    (isParagraph(node) && <Text as="p">{children}</Text>),

  [BLOCKS.HEADING_1]: () => null, // reserved for page heading

  [BLOCKS.HEADING_2]: () => null, // reserved for section titles

  [BLOCKS.HEADING_3]: (node, children) => (
    <Heading as="h3" variant="med">
      {children}
    </Heading>
  ),

  [BLOCKS.HEADING_4]: (node, children) => (
    <Heading as="h4" variant="sml">
      {children}
    </Heading>
  ),

  [BLOCKS.HEADING_5]: (node, children) => (
    <Heading as="h5" variant="xs">
      {children}
    </Heading>
  ),

  [BLOCKS.HEADING_6]: (node, children) => <Heading as="h6">{children}</Heading>,

  [BLOCKS.UL_LIST]: (node, children) => (
    <Stack as="ul" sx={{ pl: 0 }} gap={11}>
      {children}
    </Stack>
  ),

  [BLOCKS.OL_LIST]: (node, children) => (
    <Stack as="ol" sx={{ pl: 0 }} gap={11}>
      {children}
    </Stack>
  ),

  [BLOCKS.LIST_ITEM]: (node, children) => {
    const bullets: ThemeUIStyleObject = {
      display: 'flex',
      textAlign: 'left',

      '&::before': {
        content: '"•"',
        color: 'primary',
        fontSize: '3em',
        width: '0.7em',
        flexShrink: 0,
        lineHeight: 0.55,
      },
    }
    // LI element is added by the Stack component
    return <div sx={bullets}>{children}</div>
  },

  [BLOCKS.QUOTE]: node => (
    <Text as="blockquote" variant="xs">
      {toString(node)}
    </Text>
  ),

  [BLOCKS.HR]: () => null, // not used

  [BLOCKS.EMBEDDED_ASSET]: node => {
    switch (node.data.target.file.contentType) {
      case 'image/png':
      case 'image/jpeg':
        return (
          <GatsbyImage
            image={node.data.target.gatsbyImageData}
            alt={node.data.target.title}
          />
        )
    }
  },

  [BLOCKS.EMBEDDED_ENTRY]: () => null, // defined in specific components

  // external or anchor link (should not be used for internal links to other pages)
  [INLINES.HYPERLINK]: (node, children) => (
    <Link href={node.data.uri}>{children}</Link>
  ),

  // internal links (uses Gatsby Link for fast prefetching)
  [INLINES.ENTRY_HYPERLINK]: (node, children) => {
    const link = formatPageLink(node.data.target)
    return <PageLink to={link}>{children}</PageLink>
  },

  // document download links
  [INLINES.ASSET_HYPERLINK]: (node, children) => (
    <Link href={node.data.target.file?.url}>{children}</Link>
  ),

  [INLINES.EMBEDDED_ENTRY]: () => null, // not used
}

const defaultMarkRenderers: RenderMark = {
  [MARKS.BOLD]: text => <strong>{text}</strong>,
  [MARKS.ITALIC]: text => <em>{text}</em>,
  [MARKS.UNDERLINE]: text => text, // underline is reserved for links
  [MARKS.CODE]: text => text, // not used
}

const defaultTextRenderer: RenderText = text =>
  text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text])

export const renderRichText = (
  document: Document,
  options: Options = {}
): ReactNode => {
  return render(document, {
    renderNode: {
      ...defaultNodeRenderers,
      ...options.renderNode,
    },
    renderMark: {
      ...defaultMarkRenderers,
      ...options.renderMark,
    },
    renderText: options.renderText || defaultTextRenderer,
  })
}
