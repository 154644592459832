import { useState } from 'react'

/* https://usehooks.com/useLocalStorage/ */

const useLocalStorage = <T>(
  key: string,
  initialValue?: T,
  expiry?: number
): [T, (value: T | ((val: T) => T)) => void] => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get expiry from local storage
      const itemExpiry = window.localStorage.getItem(`${key}-expiry`)
      const isExpired =
        itemExpiry && Date.parse(JSON.parse(itemExpiry)) < Date.now()
      if (isExpired) {
        window.localStorage.removeItem(key)
        window.localStorage.removeItem(`${key}-expiry`)
      }

      // Get from local storage by key
      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
      // Save expiry to local storage
      if (expiry) {
        const date = new Date()
        date.setSeconds(date.getSeconds() + expiry)
        window.localStorage.setItem(`${key}-expiry`, JSON.stringify(date))
      }
    } catch (error) {
      return
    }
  }

  return [storedValue, setValue]
}

export default useLocalStorage
