import React from 'react'
import { Box, Button, Heading, Link, ThemeUIStyleObject } from 'theme-ui'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { Inline } from 'raam'
import arrow from '../images/small-arrow.svg'

interface Props {
  title?: string
  items?: {
    [key: string]: ReactNode
  }
  variant?: 'wide' | 'default'
}

const Carousel: React.FC<Props> = ({ title, items = {}, variant }) => {
  const [index, setIndex] = useState(0)
  const [start, setStart] = useState(true)
  const [end, setEnd] = useState(false)

  const wrapRef = useRef<HTMLDivElement>(null)
  const keyRef = useRef<HTMLUListElement>(null)

  const keys = Object.keys(items)
  const nodes = Object.values(items)

  useEffect(() => {
    const hash = window.location.hash.substr(1)
    if (hash) {
      const current = keys.findIndex(key => key === hash)
      if (current > 0 && current !== index) {
        setIndex(current)
        wrapRef.current?.scrollIntoView()
      }
    }
  }, [index, keys])

  const wrapper: ThemeUIStyleObject = {
    position: 'relative',
    marginX: '-15%',
  }

  const button: ThemeUIStyleObject = {
    position: 'absolute',
    right: '16%',
    backgroundColor: 'muted',
    backgroundImage: `url(${arrow})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '60%',
    transform: 'rotate(90deg) translateX(-4.4rem)',
  }

  const prev: ThemeUIStyleObject = {
    backgroundColor: start ? 'muted' : 'primary',
    transform: 'rotate(90deg) translateX(-4.4rem)',
  }

  const next: ThemeUIStyleObject = {
    backgroundColor: end ? 'muted' : 'primary',
    transform: 'rotate(-90deg) translateX(1.2rem)',
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: 11,
  }

  const list: ThemeUIStyleObject = {
    scrollbarWidth: 'none',
    height: '4rem',

    '&::-webkit-scrollbar': {
      width: '0px',
      background: 'transparent',
    },
  }

  const links: ThemeUIStyleObject = {
    position: 'relative',
    padding: '0.2rem',
    borderBottom: 'solid transparent',
    borderWidth: '0.5rem',

    '&.active': {
      borderColor: 'primary',
    },

    '&': keys.some(key => key.length === 1)
      ? { fontFamily: 'heading', fontWeight: 'bold', fontSize: 1 }
      : {},
  }

  const first: ThemeUIStyleObject = {
    marginLeft: ['12.3vw', null, variant === 'wide' ? null : '18.5vw'],
  }

  const last: ThemeUIStyleObject = {
    marginRight: '12.3vw',
  }

  const scroll = (forward: boolean) => {
    const amount = window.innerWidth / 4
    if (keyRef.current) {
      const left = keyRef.current.scrollLeft + (forward ? amount : -amount)
      keyRef.current.scrollTo({
        top: 0,
        left,
        behavior: 'smooth',
      })
      setStart(left <= 0)
      setEnd(left >= keyRef.current.scrollWidth - keyRef.current.clientWidth)
    }
  }

  const click = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    i: number,
    key: string
  ) => {
    e.preventDefault()
    window.history.pushState(null, '', `#${key}`)
    setIndex(i)
  }

  return (
    <Box ref={wrapRef} sx={wrapper}>
      {title && (
        <Heading as="h2" sx={{ ...heading, ...first, ...last }} variant="lrg">
          {title}
        </Heading>
      )}
      <Button
        sx={{ ...button, ...prev }}
        onClick={() => scroll(false)}
        variant="round"
      />
      <Inline ref={keyRef} as="ul" sx={list} gap={13}>
        {keys.map((key, i, keys) => (
          <Link
            key={i}
            href={`#${key}`}
            sx={{
              ...links,
              ...(i === 0 && first),
              ...(i === keys.length - 1 && last),
            }}
            className={i === index ? 'active' : ''}
            onClick={e => click(e, i, key)}
            variant="nav"
          >
            {key}
          </Link>
        ))}
      </Inline>
      <Button
        sx={{ ...button, ...next }}
        onClick={() => scroll(true)}
        variant="round"
      />
      <Box sx={{ ...first, ...last }}>{nodes[index]}</Box>
    </Box>
  )
}

export default Carousel
