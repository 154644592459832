import React from 'react'
import { Label } from 'theme-ui'

const tooltipColour = '#009cc8'
const TooltipIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill={tooltipColour}
    width="32px"
    height="32px"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
  </svg>
)

const Tooltip: React.FC<{
  readonly contents: React.FC
}> = ({ contents: Contents }) => (
  <Label
    sx={{
      position: 'relative',
      marginLeft: '32px',
      width: '32px',
      '> div': { display: 'none' },
      ':hover > div, :focus > div': {
        display: 'block',
      },
    }}
    role="tooltip"
  >
    <TooltipIcon />
    <div
      sx={{
        color: 'white',
        padding: '20px',
        borderRadius: '4px',
        position: 'absolute',
        width: '300px',
        backgroundColor: tooltipColour,
        bottom: '90px',
        left: '-150px',
      }}
    >
      <Contents />
    </div>
  </Label>
)
export default Tooltip
