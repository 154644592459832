import React, { FunctionComponent } from 'react'
import { graphql, PageProps } from 'gatsby'
import { Box } from 'theme-ui'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import MovingOutForm from '../forms/moving-out'
import BusinessEnergyQuoteForm from '../forms/business-energy-quote'
import BusinessMeterReadingForm from '../forms/business-meter-reading'
import MicrotricityMeterReadingForm from '../forms/microtricity-meter-reading'
import PriorityServicesRegisterForm from '../forms/priority-services-register'
import DirectDebitForm from '../forms/direct-debit'

const forms: Record<string, FunctionComponent> = {
  'Moving out': MovingOutForm,
  'Business energy quote': BusinessEnergyQuoteForm,
  'Business meter reading': BusinessMeterReadingForm,
  'Priority services register': PriorityServicesRegisterForm,
  'Direct debit': DirectDebitForm,
  'Microtricity meter reading': MicrotricityMeterReadingForm,
  default: () => <p>Form not found</p>,
}

export const query = graphql`
  query Form($pageId: String!) {
    form: contentfulForm(id: { eq: $pageId }) {
      title
      slug
      description
      heading
      body {
        raw
        references {
          ...References
        }
      }
      form
    }
  }
`

const Form: React.FC<PageProps<GatsbyTypes.FormQuery>> = ({
  data: { form = {} },
}) => {
  const FormComponent = forms[form?.form ?? 'default'] ?? forms.default

  return (
    <Layout page={form} muted={true}>
      <Section as="div" variant="header">
        <PageHeader heading={form?.heading || form?.title} text={form?.body} />
      </Section>
      <Section variant="card">
        <Box sx={{ padding: '2rem', background: 'white' }}>
          <FormComponent />
        </Box>
      </Section>
    </Layout>
  )
}
export default Form
