import {
  Grid,
  Heading,
  Link,
  Text,
  Box,
  ThemeUIStyleObject,
  Button,
} from 'theme-ui'

import {
  Document,
  BLOCKS,
  Node,
  renderRichText,
  renderBlockLink,
  renderInlineLink,
  toString,
  CustomRenderer,
  isParagraph,
} from '../rich-text'

import React, { ReactNode, ReactNodeArray, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Stack } from 'raam'
import { spanWidth } from '../utils/mixins'
import { formatPageLink, isFluid } from '../utils/helpers'
import PageLink from './page-link'
import { Props as VideoProps } from './video'
import { Page, MaybeGatsbyImageProps } from '../utils/types'

interface Props {
  title?: string
  items?: Array<{
    title?: string
    date?: Date
    tags?: Array<string | undefined>
    text?: string
    body?: Document
    image?: MaybeGatsbyImageProps
    video?: VideoProps
    page?: Page
    link?: string
  }>
  cols?: 2 | 3 | 4
  limit?: number
  more?: boolean
  plain?: boolean
  variant?: 'index' | 'page' | 'support' | 'wide' | 'full'
}

/**
 * Component for presenting a grid a small content item with optional images.
 *
 * A custom block link style is used for the read more buttons at the bottom
 * of each tile.
 */
const Cluster: React.FC<Props> = ({
  title,
  items,
  cols = 3,
  limit,
  more,
  plain = false,
  variant = 'page',
}) => {
  const [page, setPage] = useState(1)

  const loadMore = () => setPage(page + 1)

  type Grids = {
    [key: string]: Record<string, unknown>
  }

  const grids: Grids = {
    '4col-full': {
      gridTemplateColumns: [
        'auto',
        '1fr 1fr',
        '1fr 1fr 1fr',
        '1fr 1fr 1fr 1fr',
      ],
    },
    '4col-wide': {
      gridTemplateColumns: ['auto', '1fr 1fr', 'null', '1fr 1fr 1fr 1fr'],
      marginLeft: [0, null, spanWidth(1, 12, 11)],
    },
    '4col-page': {
      gridTemplateColumns: ['auto', '1fr 1fr', 'null', '1fr 1fr 1fr 1fr'],
      marginLeft: [0, null, spanWidth(1, 12, 11)],
      marginRight: [0, null, spanWidth(2, 12, 11)],
    },
    '3col-index-long': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginX: [0, null, spanWidth(1, 12, 11)],
    },
    '3col-index-short': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginX: [0, null, spanWidth(1.5, 12, 11)],
    },
    '3col-support-long': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginX: [0, null, spanWidth(1, 12, 11)],
    },
    '3col-support-short': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginX: [0, null, spanWidth(1.5, 12, 11)],
    },
    '3col-page-long': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginLeft: [0, null, spanWidth(1, 12, 11)],
    },
    '3col-page-short': {
      gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr'],
      marginLeft: [0, null, spanWidth(1, 12, 11)],
      marginRight: [0, null, spanWidth(2, 12, 11)],
    },
    '2col': {
      gridTemplateColumns: ['auto', '1fr 1fr'],
      marginX: [0, null, spanWidth(1, 12, 11)],
    },
  }

  const card: ThemeUIStyleObject = {
    boxShadow: plain ? 'none' : 'card',
    borderRadius: variant === 'full' ? '0.6rem' : 0,
    overflow: 'hidden',
  }

  const imageWrap: ThemeUIStyleObject = {
    position: 'relative',
  }

  const image: ThemeUIStyleObject = {
    borderBottom: 'muted',
  }

  const headline = {
    marginBottom: 12,
    marginLeft:
      variant === 'page' || variant === 'wide'
        ? [0, null, spanWidth(1, 12, 11)]
        : 0,
    textAlign: variant === 'index' ? 'center' : 'left',
  }

  const tags: ThemeUIStyleObject = {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'white',
    letterSpacing: '0.4em',
    paddingTop: 6,
    paddingLeft: 14,
    paddingRight: 9,
  }

  const date: ThemeUIStyleObject = {
    color: 'disabled',
    marginBottom: -6,
  }

  const heading: ThemeUIStyleObject = {
    fontFamily: items?.some(item => item.image) ? 'body' : 'heading',
    fontWeight: 'bold',
    lineHeight: 1.4,
    marginBottom: plain ? -11 : -4,
  }

  const text: ThemeUIStyleObject = {
    padding: plain
      ? 0
      : cols === 4 && variant !== 'full' && variant !== 'wide'
      ? 12
      : 14,
    paddingRight: plain ? 9 : '',
  }

  const button: ThemeUIStyleObject = {
    textAlign: 'center',
    marginY: 16,
  }

  const getLayout = (items: Props['items']) => {
    if (cols === 4) return grids[`4col-${variant}`]

    if (cols === 3) {
      const isLong = items?.some(item => {
        const json = item.body?.raw && JSON.parse(item.body.raw)
        return json && toString(json).length > 400
      })
      const length = isLong ? 'long' : 'short'
      return grids[`3col-${variant}-${length}`]
    }

    if (cols === 2) return grids['2col']
  }

  const buttonVariant = variant === 'support' ? 'button' : 'more'

  const renderMoreLink = (node: Node): ReactNode => {
    switch (node.nodeType) {
      case 'hyperlink':
        return (
          <Link href={node.data.uri} variant={buttonVariant}>
            {toString(node)}
          </Link>
        )

      case 'entry-hyperlink': {
        const to = formatPageLink(node.data.target)
        return (
          <PageLink to={to} variant={buttonVariant}>
            {toString(node)}
          </PageLink>
        )
      }

      default:
        return renderInlineLink(node)
    }
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) =>
        renderBlockLink(node, renderMoreLink) ||
        (isParagraph(node) && <Text as="p">{children}</Text>),
    },
  }

  const cards: ReactNodeArray | undefined = items
    ?.slice(0, limit ? limit * page : undefined)
    .map((item, i) => {
      const img = item.image && isFluid(item.image) && (
        <Box sx={imageWrap}>
          <GatsbyImage
            sx={image}
            image={item.image.gatsbyImageData}
            alt={item.image.title}
          />
          {item.tags?.length && (
            <Text as="p" sx={tags} variant="primary">
              {item.tags[0]}
            </Text>
          )}
        </Box>
      )
      return (
        <Box key={i} sx={card}>
          {item.page ? (
            <PageLink sx={imageWrap} to={formatPageLink(item.page)}>
              {img}
            </PageLink>
          ) : buttonVariant === 'more' && item.link ? (
            <Link sx={imageWrap} href={item.link}>
              {img}
            </Link>
          ) : (
            <Box sx={imageWrap}>{img}</Box>
          )}
          <Stack sx={text} gap={12}>
            {item.date && (
              <Text as="p" sx={date}>
                {item.date.toLocaleDateString('en-GB', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
              </Text>
            )}
            <Heading
              as="h3"
              sx={{
                ...heading,
                color: /^Level \d$/.test(item.title as string)
                  ? 'primary'
                  : 'text',
              }}
              variant="head"
            >
              {item.title}
            </Heading>
            {item.text && <Text as="p">{item.text}</Text>}
            {item.body && renderRichText(item.body, renderers)}
            {item.page && (
              <PageLink to={formatPageLink(item.page)} variant={buttonVariant}>
                More
              </PageLink>
            )}
            {buttonVariant === 'more'
              ? item.link && (
                  <Link href={item.link} variant="more">
                    More
                  </Link>
                )
              : ''}
          </Stack>
        </Box>
      )
    })

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={headline as ThemeUIStyleObject} variant="lrg">
          {title}
        </Heading>
      )}
      <Grid sx={getLayout(items) as ThemeUIStyleObject} variant="equal">
        {cards}
      </Grid>
      {more && (
        <Box sx={button}>
          <Button onClick={loadMore} variant="rect">
            Load more
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default Cluster
