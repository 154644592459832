import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Section from '../components/section'
import PageHeader from '../components/page-header'

export const query = graphql`
  query Index($pageId: String!) {
    index: contentfulIndex(id: { eq: $pageId }) {
      title
      description
      heading
      subheading
    }
  }
`

const Index: React.FC<PageProps<GatsbyTypes.IndexQuery>> = ({
  data: { index = {} },
}) => (
  <Layout page={index}>
    <SEO title={index.title} description={index.description} />
    <Section as="div" variant="page">
      <PageHeader
        heading={index.heading || index.title}
        subheading={index.subheading}
      />
    </Section>
  </Layout>
)

export default Index
