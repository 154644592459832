import React from 'react'
import manifestoImage from '../../images/manifesto-book.png'
import manifestoImageWide from '../../images/manifesto-book-wide.png'
import {
  Flex,
  Heading,
  Box,
  Image,
  Text,
  Link,
  ThemeUIStyleObject,
} from 'theme-ui'

import facebookImage from '../../images/social-facebook-black.svg'
import twitterImage from '../../images/social-twitter-black.svg'
import instagramImage from '../../images/social-instagram-black.svg'

const GetInspiredSection: React.FC = () => {
  const wrapperSx: ThemeUIStyleObject = {
    flexWrap: 'wrap',
    paddingX: '10%',
    paddingY: '4.16rem',
    marginBottom: ['5rem', '10rem'],
  }

  const sectionHeadingSx: ThemeUIStyleObject = {
    fontSize: '2.85rem',
    flexBasis: '100%',
    marginBottom: '2.5rem',
  }

  const boxSx: ThemeUIStyleObject = {
    flexBasis: ['100%', '100%', '45%'],
    marginLeft: ['0%', '5%'],
  }

  const imageBoxSx: ThemeUIStyleObject = {
    flexBasis: ['100%', '100%', '20%'],
    position: 'relative',
  }

  const leftBoxSx: ThemeUIStyleObject = {
    ...boxSx,
    display: ['block', 'block', 'flex'],
    left: ['0', '0', '-8.33rem'],
    flexBasis: ['100%', '100%', '30%'],
    marginLeft: '0',
  }

  const headingSx: ThemeUIStyleObject = {
    fontWeight: '400',
    marginTop: ['2rem', '2rem', '0'],
    fontSize: '2.75rem',
    marginBottom: '1.25rem',
  }

  const bookBoxSx: ThemeUIStyleObject = {
    marginLeft: ['0', '0', '1.25rem'],
  }

  const bottomRowSx: ThemeUIStyleObject = {
    marginTop: '2.5rem',
    display: ['block', 'block', 'flex'],
  }

  const shopLinkSx: ThemeUIStyleObject = {
    marginY: '2.5rem',
    display: 'inline-block',
    textAlign: 'center',
    width: ['100%', '100%', '80%'],
  }

  const carbonLinkSx: ThemeUIStyleObject = {
    marginRight: '0.83rem',
    marginBottom: ['1.25rem', '1.25rem', '0'],
    width: ['100%', '100%', 'auto'],
    padding: 0,
    height: '5rem',
    lineHeight: '5rem',
    minWidth: ['auto', 'auto', '18.25rem'],
  }

  const socialSx: ThemeUIStyleObject = {
    marginX: '0.41rem',
    height: ['auto', 'auto', '5rem'],
  }

  const imageDesktopSx: ThemeUIStyleObject = {
    display: ['none', 'none', 'block'],
    position: 'absolute',
    right: '0',
    maxWidth: '35rem',
  }

  const imageMobileSx: ThemeUIStyleObject = {
    display: ['block', 'block', 'none'],
  }

  const textSx: ThemeUIStyleObject = {
    fontWeight: 300,
    marginBottom: '0.83rem',
    fontSize: '1.4rem',
    fontFamily: '"Open Sans",sans-serif',
  }

  const captionTextSx: ThemeUIStyleObject = {
    ...textSx,
    fontSize: '1rem',
  }

  const flexCenterSx: ThemeUIStyleObject = {
    justifyContent: ['center', 'center', 'initial'],
  }

  return (
    <Flex sx={wrapperSx}>
      <Heading sx={sectionHeadingSx} variant="lrg">
        Get inspired
      </Heading>
      <Box sx={imageBoxSx}>
        <Image sx={imageDesktopSx} src={manifestoImage} />
        <Image sx={imageMobileSx} src={manifestoImageWide} />
      </Box>
      <Box sx={leftBoxSx}>
        <Box sx={bookBoxSx}>
          <Heading sx={headingSx} variant="med">
            Buy the book
          </Heading>
          <Text sx={textSx} as="p">
            &lsquo;Dale’s first book <strong>Manifesto</strong> is part
            biography, part business &amp; part mission... It is MadMax meets
            Laurie Lee&rsquo;
          </Text>
          <Text sx={captionTextSx} as="p">
            <strong>Andrew Merrell</strong> Business Editor, So Glos.
          </Text>
          <Link
            sx={shopLinkSx}
            variant="buttonSecondary"
            href="https://shop.ecotricity.co.uk/products/manifesto-how-a-maverick-entrepreneur-took-on-british-energy-and-won"
          >
            Shop
          </Link>
        </Box>
      </Box>
      <Box sx={boxSx}>
        <Heading sx={headingSx} variant="med">
          Follow Dale
        </Heading>
        <Text sx={textSx} as="p">
          Daily inspiration delivered to your inbox or social feeds on how we
          can all live more sustainably, with green entrepreneur and climate
          campaigner <b>Dale Vince</b>. Being a zerocarbonista isn’t about
          giving up the things you love, it’s about making everyday changes that
          have a positive impact on the planet.
        </Text>
        <Box sx={bottomRowSx}>
          <Link
            sx={carbonLinkSx}
            href="https://zerocarbonista.com/"
            variant="buttonSecondary"
          >
            Zerocarbonista
          </Link>
          <Flex sx={flexCenterSx}>
            <Link href="https://www.facebook.com/dalevince">
              <Image sx={socialSx} src={facebookImage} />
            </Link>
            <Link href="https://twitter.com/dalevince">
              <Image sx={socialSx} src={twitterImage} />
            </Link>
            <Link href="https://www.instagram.com/zerocarbonista/">
              <Image sx={socialSx} src={instagramImage} />
            </Link>
          </Flex>
        </Box>
      </Box>
    </Flex>
  )
}

export default GetInspiredSection
