const URL = `https://${
  process.env.GATSBY_API_HOST as string
}/webforms/v1/business-meter-reading`

const URLMeterReads = `https://${
  process.env.GATSBY_API_HOST as string
}/microtricity/v1/public/meter-reads`

export const submitForm = async (formData: unknown): Promise<unknown> =>
  fetch(URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(formData),
  }).then(response => response.json())

export const submitMeterReadForm = async (
  formData: unknown
): Promise<unknown> =>
  fetch(URLMeterReads, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(formData),
  }).then(response => response.json())
