import React from 'react'
import { Flex, useThemeUI, get, ThemeUIStyleObject, Text, Link } from 'theme-ui'
import { IconButton } from 'theme-ui'
import { fluidScale } from '../utils/mixins'
import menu from '../images/menu-icon.svg'
import close from '../images/close-icon.svg'
import mobileLogin from '../images/mobile-login-icon.svg'

type Icons = 'menu' | 'close' | 'search' | 'mobileLogin'

interface Props extends React.ButtonHTMLAttributes<HTMLDivElement> {
  variant: Icons | Icons[]
}

const HeaderIcons: React.FC<Props> = ({ variant, ...other }) => {
  const { theme } = useThemeUI()

  const wrapper: ThemeUIStyleObject = {
    height: '100%',
    flexShrink: 0,
    paddingTop: 6,
  }

  const icon = {
    width: [null, null, fluidScale(get(theme, 'buttons.square.width'), 0.7, 5)],
  }

  const iconFallback = {
    width: [null, null, '5rem'],
    flexDirection: 'column',
  }

  const iconLabel = {
    px: 6,
    marginTop: 3,
    fontWeight: 'heading',
  }

  const loginLink = {
    textDecoration: 'none',
  }

  return (
    <Flex sx={wrapper} {...other}>
      {variant.includes('menu') && (
        <IconButton
          sx={{ ...iconFallback, ...icon } as ThemeUIStyleObject}
          variant="square"
        >
          <img src={menu} alt="Hamburger menu icon" />
          <Text sx={iconLabel}>Menu</Text>
        </IconButton>
      )}
      {variant.includes('close') && (
        <IconButton
          sx={{ ...iconFallback, ...icon, bg: 'primary' } as ThemeUIStyleObject}
          variant="square"
        >
          <img src={close} alt="Cross icon" />
        </IconButton>
      )}
      {variant.includes('mobileLogin') && (
        <IconButton
          sx={{ ...iconFallback, ...icon } as ThemeUIStyleObject}
          variant="square"
        >
          <Link sx={loginLink} href={process.env.GATSBY_ONLINE_ACCOUNT_URL}>
            <img src={mobileLogin} alt="Login" />
            <Text sx={iconLabel}>Log in</Text>
          </Link>
        </IconButton>
      )}
    </Flex>
  )
}

export default HeaderIcons
