import React from 'react'
import { ThemeUIStyleObject, IconButton, Link, Text, Image } from 'theme-ui'

import { Inline } from 'raam'

import Facebook from '../images/social-facebook.svg'
import Instagram from '../images/social-instagram.svg'
import X from '../images/social-x.svg'
import LinkedIn from '../images/social-linkedin.svg'
import { ReactNodeArray } from 'react'

interface Props {
  platforms: string[]
  labels?: boolean
}

type Platform = {
  label: string
  src: string
  alt: string
  url: string
  style: ThemeUIStyleObject
}

const data: { [name: string]: Platform } = {
  facebook: {
    label: 'Facebook',
    src: Facebook,
    alt: `Lowercase 'f' logo`,
    url: 'https://www.facebook.com/ecotricity/',
    style: { width: '100%' },
  },
  instagram: {
    label: 'Instagram',
    src: Instagram,
    alt: 'Camera logo',
    url: 'https://www.instagram.com/ecotricity/',
    style: { width: '85%' },
  },
  twitter: {
    label: 'Twitter/X',
    src: X,
    alt: 'X logo (twitter)',
    url: 'https://x.com/ecotricity/',
    style: { width: '70%' },
  },
  linkedin: {
    label: 'LinkedIn',
    src: LinkedIn,
    alt: `Lowercase 'in' logo`,
    url: 'https://uk.linkedin.com/company/ecotricity/',
    style: { width: '110%', marginLeft: '0.15rem' },
  },
}

const SocialLinks: React.FC<Props> = ({ platforms, labels, ...other }) => {
  const icons: ReactNodeArray = platforms.map((platform, i) => {
    const { label, src, alt, url, style } = data[platform]

    return (
      <Link key={i} variant="nav" href={url}>
        <IconButton
          sx={{ img: style, '&:hover': { filter: 'invert(95%)' } }}
          variant="round"
        >
          <Image src={src} alt={alt} />
        </IconButton>
        {labels && (
          <Text sx={{ mt: 5 }} variant="label">
            {label}
          </Text>
        )}
      </Link>
    )
  })

  return (
    <Inline sx={{ justifyContent: 'center' }} gap={11} {...other}>
      {icons}
    </Inline>
  )
}

export default SocialLinks
