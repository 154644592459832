import React from 'react'
import { Heading, ThemeUIStyleObject } from 'theme-ui'
import { Stack } from 'raam'
import { renderRichText, Document } from '../rich-text'

interface Props {
  title: string
  body?: Document
}

/**
 * Component for presenting a small info panel.
 */
const Boxout: React.FC<Props> = ({ title, body }) => {
  const panel: ThemeUIStyleObject = {
    padding: 11,
    backgroundColor: 'muted',
  }

  return (
    <Stack sx={panel} gap={11}>
      <Heading as="h4" variant="sml">
        {title}
      </Heading>
      {body && renderRichText(body)}
    </Stack>
  )
}

export default Boxout
