import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import ArticleImage from './article-image'
import contourBackground from '../images/contour-bg.gif'
import { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Props {
  primary: {
    fluid: IGatsbyImageData
    alt: string
    description: string
  }
  secondary: {
    fluid: IGatsbyImageData
    alt: string
    description: string
  }
}

const ArticleImagePair: React.FC<Props> = ({ primary, secondary }) => {
  const [logoName, setLogoName] = useState('')

  const data = useStaticQuery(graphql`
    query {
      britwind: file(relativePath: { eq: "images/britwind-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
      ecotalk: file(relativePath: { eq: "images/ecotalk-bee.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
    }
  `)

  useEffect(() => {
    const path = window.location.pathname.split('/').filter(Boolean)
    const slug = path.length && path[path.length - 1]

    if (slug === 'britwind' || slug === 'ecotalk') {
      setLogoName(slug)
    }
  }, [])

  const wrapper: ThemeUIStyleObject = {
    position: 'relative',
    backgroundImage: ['none', `url(${contourBackground})`],
    backgroundRepeat: 'no-repeat',
    backgroundSize: '90% 50%',
    backgroundPosition: 'right 35%',
  }

  const image = {
    primary: {
      width: ['100%', '88%'],
    },
    secondary: {
      width: ['100%', '66%'],
      ml: [0, '30%'],
      mt: '4%',
    },
  }

  const logo: ThemeUIStyleObject = {
    position: ['relative', 'absolute'],
    marginTop: [11, 0],
    width: logoName === 'britwind' ? ['33%', '18%'] : ['40%', '30%'],
    left: logoName === 'britwind' ? 0 : '-5%',
    top: logoName === 'britwind' ? '72%' : '70%',
  }

  return (
    <Box sx={wrapper}>
      <ArticleImage
        sx={image.primary}
        fluid={primary.gatsbyImageData}
        alt={primary.alt}
        description={primary.description}
        variant="pair"
      />
      <ArticleImage
        sx={image.secondary}
        fluid={secondary.gatsbyImageData}
        alt={secondary.alt}
        description={secondary.description}
      />
      {logoName && (
        <Box sx={logo}>
          <GatsbyImage
            image={data[logoName].childImageSharp.gatsbyImageData}
            alt={`${logoName} logo`}
          />
        </Box>
      )}
    </Box>
  )
}

export default ArticleImagePair
