import React from 'react'
import { Box, Label, ThemeUIStyleObject } from 'theme-ui'
import { Radio } from '../../forms/inputs/radio'

interface Props {
  name?: string
  options: Array<{
    name?: string
    value?: string
  }>
  columns?: boolean
  onChange: (event: unknown) => void
  value: string | null
}

const RadioButtonList: React.FC<Props> = ({
  name,
  options,
  onChange,
  columns,
  value,
}) => {
  const listSx: ThemeUIStyleObject = {
    marginTop: '3.33rem',
    marginBottom: '3.33rem',
    display: [undefined, undefined, columns ? 'flex' : undefined],
    flexDirection: 'column',
    flexWrap: 'wrap',
    maxHeight: [
      undefined,
      undefined,
      columns ? `${(options.length * 4.35) / 2}rem` : undefined,
    ],
    alignContent: [undefined, undefined, columns ? 'flex-start' : undefined],
  }

  const labelSx: ThemeUIStyleObject = {
    marginBottom: '0.83rem',
    alignItems: 'center',
    width: columns ? 'auto' : '100%',
    paddingRight: [undefined, undefined, columns ? '4rem' : undefined],
  }

  const buttons: Array<React.ReactElement> = []

  options.forEach((option, optionKey) => {
    buttons.push(
      <React.Fragment key={`${name}-${optionKey}`}>
        <Label sx={labelSx}>
          <Radio
            name={name}
            value={option?.value}
            variant="radioGrey"
            onChange={onChange}
            defaultChecked={value && value === option.value}
          />
          {option.name}
        </Label>
      </React.Fragment>
    )
  })

  return <Box sx={listSx}>{buttons}</Box>
}

export default RadioButtonList
