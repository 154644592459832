import React from 'react'
import { Heading, Link, Text } from 'theme-ui'
import Layout from '../components/layout'
import Section from '../components/section'

const NotFoundPage: React.FC = () => {
  return (
    <Layout page={{ title: '404: Not found' }}>
      <Section as="div" variant="header">
        <Heading sx={{ textAlign: 'center' }} variant="xl">
          {`Sorry, we can't find that page`}
        </Heading>
      </Section>
      <Section variant="page">
        <Text as="p" sx={{ textAlign: 'center' }}>
          {`This page could've moved or changed its name. Or you might have
          followed an old link. Please drop us an email at `}
          <Link href="mailto:home@ecotricity.co.uk">home@ecotricity.co.uk</Link>
          {` and we'll do everything we can to help.`}
        </Text>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
