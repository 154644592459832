import React, { ReactElement } from 'react'
import { Box } from '@theme-ui/components'
import SVG from './SVG'
import { ThemeUIStyleObject } from 'theme-ui'

interface Props {
  children: React.ReactNode
  size: number
}

const RadioChecked = (props: Props) => (
  <SVG {...props}>
    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#151515" />
    <circle cx="15" cy="15" r="9.5" fill="#9AD43A" stroke="#7CA933" />
  </SVG>
)

const RadioUnchecked = (props: Props) => (
  <SVG {...props}>
    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#151515" />
    <circle cx="15" cy="15" r="9.5" fill="#E7EAEA" stroke="#D4D7D7" />
  </SVG>
)

export const RadioIcon = (props: Props): ReactElement => (
  <React.Fragment>
    <RadioChecked
      {...props}
      __css={{
        display: 'none',
        'input:checked ~ &': {
          display: 'block',
        },
      }}
    />
    <RadioUnchecked
      {...props}
      __css={{
        display: 'block',
        'input:checked ~ &': {
          display: 'none',
        },
      }}
    />
  </React.Fragment>
)

type RadioProps = {
  className: string
  sx: ThemeUIStyleObject
  variant: string
}

export const Radio = React.forwardRef(function Radio(
  { className, sx, variant = 'radio', ...props }: RadioProps,
  ref
) {
  return (
    <Box sx={{ minWidth: 'min-content' }}>
      <Box
        ref={ref}
        as="input"
        type="radio"
        {...props}
        sx={{
          position: 'absolute',
          opacity: 0,
          zIndex: -1,
          width: 1,
          height: 1,
          overflow: 'hidden',
        }}
      />
      <Box
        as={RadioIcon}
        aria-hidden="true"
        __themeKey="forms"
        variant={variant}
        className={className}
        sx={sx}
        __css={{
          // todo: system props??
          mr: 2,
          borderRadius: 9999,
          color: 'gray',
          flexShrink: 0,
          'input:checked ~ &': {
            color: 'primary',
          },
          'input:focus ~ &': {
            bg: 'highlight',
          },
        }}
      />
    </Box>
  )
})
