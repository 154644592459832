import React from 'react'
import {
  Box,
  Grid,
  Heading,
  Input,
  Label,
  Select,
  Text,
  Textarea,
  Radio,
} from 'theme-ui'
import { useForm } from 'react-hook-form'
import {
  ValidatingInput,
  SymmetricValidatingInput,
} from './inputs/validating-input'
import Checkbox from './inputs/checkbox'
import { validEmail, validPhoneNumber } from './utils/validators'
import AddressPicker, { AddressPickerFormData } from './inputs/address-picker'
import { MovingOutForm } from './utils/form-types'
import DatePicker from './inputs/date-picker'
import Salutation from './inputs/salutation'
import WebForm from './webform'
import { heading, fieldset, paragraph } from './utils/styles'

const completed = (): JSX.Element => (
  <Box sx={fieldset}>
    <Heading
      sx={heading}
    >{`Thanks For Letting Us Know You're Moving Out`}</Heading>
    <Text as="p" sx={paragraph}>
      Thank you for providing us with details of your home move.
    </Text>
    <Text as="p" sx={paragraph}>
      {`We're processing your information now and will be in touch by email
      shortly to tell you what happens next.`}
    </Text>
    <Text as="p" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
      The Ecotricity Team
    </Text>
  </Box>
)

type MovingOutFormData = {
  readonly salutation: string
  readonly firstName: string
  readonly lastName: string
  readonly emailAddress: string
  readonly phoneNumber: string
  readonly movingFrom: AddressPickerFormData
  readonly movingTo: AddressPickerFormData
  readonly movingOutDate: string
  readonly currentAddressRenting: string
  readonly landlordName: string
  readonly landlordAddress: string
  readonly landlordPhoneNumber: string
  readonly finalReadingDate: string
  readonly electricityDayValue: string
  readonly electricityNightValue: string
  readonly gasValue: string
  readonly movingAbroad: string
  readonly abroadLine1: string
  readonly abroadLine2: string
  readonly abroadLine3: string
  readonly comments: string
  readonly keepEcotricity: string
}

const reformatMovingOut = ({
  salutation,
  firstName,
  lastName,
  emailAddress,
  phoneNumber,
  movingTo,
  movingFrom,
  movingOutDate,
  currentAddressRenting,
  landlordName,
  landlordAddress,
  landlordPhoneNumber,
  finalReadingDate,
  electricityDayValue,
  electricityNightValue,
  gasValue,
  movingAbroad,
  abroadLine1,
  abroadLine2,
  abroadLine3,
  comments,
  keepEcotricity,
}: MovingOutFormData): MovingOutForm => ({
  webform: {
    type: 'movingOutDomestic',
    formData: {
      salutation,
      firstNames: firstName,
      lastNames: lastName,
      emailAddress,
      phoneNumber,
      currentAddress: {
        address1: movingFrom.line1,
        address2: movingFrom.line2,
        address3: movingFrom.line3,
        town: movingFrom.town,
        county: movingFrom.county,
        postcode: movingFrom.postcode,
        udprn: movingFrom.udprn,
      },
      newAddress: {
        address1: movingAbroad ? abroadLine1 : movingTo.line1,
        address2: movingAbroad ? abroadLine2 : movingTo.line2,
        address3: movingAbroad ? abroadLine3 : movingTo.line3,
        town: movingAbroad ? '' : movingTo.town,
        county: movingAbroad ? '' : movingTo.county,
        postcode: movingAbroad ? '' : movingTo.postcode,
        udprn: movingAbroad ? '' : movingTo.udprn,
      },
      movingOutDate,
      currentAddressRenting: Boolean(currentAddressRenting),
      landlordRentalAgent: {
        name: landlordName,
        address: landlordAddress,
        phoneNumber: landlordPhoneNumber,
      },
      finalReadingDate,
      electricityDayValue,
      electricityNightValue,
      gasValue,
      movingAbroad: Boolean(movingAbroad),
      comments,
      keepEcotricity,
    },
  },
})

const MovingOut = (): JSX.Element => {
  const formFns = useForm<MovingOutFormData>({
    criteriaMode: 'all',
    mode: 'onBlur',
    reValidateMode: 'onChange',
  })

  const { register, errors, watch } = formFns

  const requiredField = (label: string) =>
    register({ required: `Please enter your ${label.toLowerCase()}` })

  const movingAbroad = Boolean(watch('movingAbroad'))
  const keepEcotricity = watch('keepEcotricity')

  const electricityDayValue = watch('electricityDayValue')
  const electricityNightValue = watch('electricityNightValue')
  const gasValue = watch('gasValue')
  const hasARead = () =>
    Boolean(electricityDayValue) ||
    Boolean(electricityNightValue) ||
    Boolean(gasValue)
      ? true
      : ''

  const isCurrentAddressRenting = Boolean(watch('currentAddressRenting'))

  const movingToPostcode = watch('movingTo.postcode')
  const shouldForwardToSwitchSite =
    ['yes', 'maybe'].includes(keepEcotricity) && !movingAbroad
  const postSubmitUrl = shouldForwardToSwitchSite
    ? `https://www.ecotricity.co.uk/for-your-home/quote-and-switch?partner=COT2&postcode=${movingToPostcode}`
    : undefined

  return (
    <WebForm<MovingOutForm, MovingOutFormData>
      reformat={reformatMovingOut}
      completeText={completed()}
      postSubmitUrl={postSubmitUrl}
      {...formFns}
    >
      <Box sx={fieldset}>
        <Heading sx={heading}>Your details</Heading>
        <Salutation {...formFns} />
        <Grid columns={[1, '1fr 1fr']} gap="10">
          <SymmetricValidatingInput
            {...formFns}
            deepName={['firstName'] as const}
            handleRef={requiredField}
            label="First name"
          />
          <SymmetricValidatingInput
            {...formFns}
            deepName={['lastName'] as const}
            handleRef={requiredField}
            label="Last name"
          />
        </Grid>
        <SymmetricValidatingInput
          {...formFns}
          deepName={['emailAddress'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validEmail: validEmail(label),
              },
            })
          }
          label="Email address"
          type="email"
        />
        <SymmetricValidatingInput
          {...formFns}
          deepName={['phoneNumber'] as const}
          handleRef={(label: string) =>
            register({
              required: `Please enter your ${label}`,
              validate: {
                validPhoneNumber: validPhoneNumber(label),
              },
            })
          }
          label="Phone number"
          type="tel"
        />
        <AddressPicker {...formFns} addressName="movingFrom" />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>About the move</Heading>
        <Label>Date you finished responsibility for the old property?</Label>
        <DatePicker
          errorMessage="Please enter a moving out date"
          name="movingOutDate"
          {...formFns}
          required={true}
        />
        <Label>Were you selling or renting the old property?</Label>
        <Label htmlFor="currentAddressRentingNo">
          <Radio
            id="currentAddressRentingNo"
            name="currentAddressRenting"
            value=""
            ref={register()}
          />
          Selling
        </Label>
        <Label htmlFor="currentAddressRentingYes">
          <Radio
            id="currentAddressRentingYes"
            name="currentAddressRenting"
            value="yes"
            ref={register()}
          />
          Renting
        </Label>
        {isCurrentAddressRenting && (
          <Box>
            <SymmetricValidatingInput
              {...formFns}
              deepName={['landlordName'] as const}
              handleRef={() => register({})}
              label="Landlord / rental agent name"
              type="text"
            />
            <SymmetricValidatingInput
              {...formFns}
              deepName={['landlordAddress'] as const}
              handleRef={() => register({})}
              label="Landlord / rental agent address"
              type="text"
            />
            <SymmetricValidatingInput
              {...formFns}
              deepName={['landlordPhoneNumber'] as const}
              handleRef={() => register({})}
              label="Landlord / rental agent telephone"
              type="text"
            />
          </Box>
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>For your final bill</Heading>
        <Text as="p" variant="intro" sx={heading}>
          Pay for only the energy you use - enter a meter reading for the day
          you ceased responsibility for your home.
        </Text>
        <DatePicker
          errorMessage="Please enter a date for the final reading"
          name="finalReadingDate"
          {...formFns}
          required={true}
        />
        <ValidatingInput
          {...formFns}
          deepName={['electricityDayValue'] as const}
          handleRef={register({ validate: hasARead })}
          label="Electricity Day Read"
        />
        <ValidatingInput
          {...formFns}
          deepName={['electricityNightValue'] as const}
          handleRef={register({})}
          label="Electricity Night Read"
        />
        <ValidatingInput
          {...formFns}
          deepName={['gasValue'] as const}
          handleRef={register({ validate: hasARead })}
          label="Gas Read"
        />
        {(errors.electricityDayValue ||
          errors.electricityNightValue ||
          errors.gasValue) && (
          <Text as="p" variant="errorLabel">
            Please enter at least one meter reading
          </Text>
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>New address</Heading>
        <Checkbox {...formFns} label="I am moving abroad" name="movingAbroad" />
        {movingAbroad ? (
          <Box>
            <Text as="p">Address</Text>
            <Input
              variant={errors.abroadLine1 ? 'error' : 'complexFormInput'}
              name="abroadLine1"
              ref={requiredField('address details')}
              type="text"
            />
            <Input
              variant={errors.abroadLine1 ? 'error' : 'complexFormInput'}
              name="abroadLine2"
              ref={register({})}
              type="text"
            />
            <Input
              variant={errors.abroadLine1 ? 'error' : 'complexFormInput'}
              name="abroadLine3"
              ref={register({})}
              type-="text"
            />
            {errors.abroadLine1 && (
              <Text as="p" variant="errorLabel">
                {errors.abroadLine1.message}
              </Text>
            )}
          </Box>
        ) : (
          <AddressPicker {...formFns} addressName="movingTo" />
        )}
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Your comments</Heading>
        <Label htmlFor="comments" id="label-comments">
          Tell us anything extra you think might be useful.
        </Label>
        <Textarea
          aria-describedby="label-comments"
          id="comments"
          name="comments"
          ref={register()}
        />
      </Box>
      <Box sx={fieldset}>
        <Heading sx={heading}>Taking us with you</Heading>
        <Text as="p" variant="intro" sx={heading}>
          {`It's easy to do, just select a payment method and leave the rest to
          us.`}
        </Text>
        <Label htmlFor="keepEcotricity" id="label-keepEcotricity">
          Would you like Ecotricity to supply your new home?
        </Label>
        <Select
          aria-describedby="label-keepEcotricity errors-keepEcotricity"
          name="keepEcotricity"
          ref={register({})}
        >
          <option value="yes">Yes please</option>
          <option value="maybe">Not sure</option>
          <option value="no">No thanks</option>
        </Select>
      </Box>
      <Box sx={fieldset}>
        {keepEcotricity === 'yes' && (
          <Text as="p">
            Thank you for choosing to take us with you to your new home. You
            will now be redirected to our online switch form which will guide
            you through all the relevant questions.
          </Text>
        )}
        {keepEcotricity === 'maybe' && (
          <Text as="p">
            You will now be redirected to our online switch form which will
            provide you with quotes and more information about our green energy
            tariffs.
          </Text>
        )}
        {keepEcotricity === 'no' && (
          <Text as="p">
            We’re really sorry to see you go and thank you for having been with
            us. We wish you all the best in your new home and would love to see
            you again in the future. Please feel free to come back to us
            anytime.
          </Text>
        )}
      </Box>
    </WebForm>
  )
}

export default MovingOut
