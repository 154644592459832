import React from 'react'
import { useEffect, useRef } from 'react'
import { Link } from 'theme-ui'
import usePartnerPromo from '../hooks/use-partner-promo'
import useWebgains from '../hooks/use-webgains'
import { formatJoinLink } from '../utils/helpers'

interface Props {
  link?: string
  variant?: string
  id?: string
  isBusiness?: boolean
}

const JoinLink: React.FC<Props> = ({
  link,
  variant = 'styles.a',
  isBusiness,
  children,
  ...other
}) => {
  const ref = useRef<HTMLAnchorElement>(null)
  const partner = usePartnerPromo()
  const webgain = useWebgains()
  const url = isBusiness
    ? process.env.GATSBY_BUSINESS_JOIN_URL
    : formatJoinLink(undefined, undefined, undefined, undefined, link)

  useEffect(() => {
    if (partner && ref.current) {
      ref.current.href = formatJoinLink(
        partner.code,
        undefined,
        undefined,
        undefined,
        link
      )
    }
    if (webgain && ref.current) {
      ref.current.href = formatJoinLink(
        webgain.partner,
        webgain.clickid,
        webgain.wgu,
        webgain.wgexpiry,
        link
      )
    }
  }, [webgain, partner, link])

  return (
    <Link ref={ref} href={url} variant={variant} {...other}>
      {children}
    </Link>
  )
}

export default JoinLink
