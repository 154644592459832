import React, { ReactElement } from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  id?: number
  name?: string
  children: ReactElement | ReactElement[]
  default?: boolean
}

const Variant: React.FC<Props> = ({
  id = 0,
  name = '',
  default: visible = false,
  children,
}) => {
  const boxStyle: ThemeUIStyleObject = {
    display: visible ? 'initial' : 'none',
  }

  return (
    <Box sx={{ ...boxStyle }} id={`ab-cro-${name}-${id + 1}`}>
      {children}
    </Box>
  )
}

export default Variant
