import React from 'react'
import Layout from '../../components/layout'
import {
  getStoredData,
  getRedirectUrl,
  getCarbonFootprint,
} from '../../utils/carbon-calculator'

import GetInspired from '../../components/carbon-calculator/get-inspired'
import ReduceCarbonFootPrint from '../../components/carbon-calculator/reduce-carbon-footprint'
import CarbonFootprint from '../../components/carbon-calculator/carbon-footprint'
import SEO from '../../components/seo'
import CarbonCalculatorSpray from '../../images/carbon-calculator-spray.png'
import CarbonCalculatorSprayGrey from '../../images/carbon-calculator-spray-grey.png'
import { ThemeUIStyleObject } from 'theme-ui'
import { Box } from '@theme-ui/components'
import { ValidationType } from '../../utils/carbon-calculator-types'

type PagePropsType = {
  pageContext: {
    stepValidation: ValidationType
  }
}

const resultsWrapperSx: ThemeUIStyleObject = {
  '&:before': {
    content: '""',
    backgroundImage: ['', '', `url(${CarbonCalculatorSpray})`],
    width: '18.33rem',
    height: '26.67rem',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '5%',
    left: 0,
    mixBlendMode: 'multiply',
    zIndex: '-1',
  },
  '&:after': {
    content: '""',
    backgroundImage: ['', '', `url(${CarbonCalculatorSprayGrey})`],
    width: '22.92rem',
    height: '29.17rem',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    top: '27.5%',
    right: 0,
    mixBlendMode: 'multiply',
  },
}

const CarbonCalculatorQuestionPage: React.FC<PagePropsType> = ({
  pageContext: { stepValidation },
}) => {
  const ccData = getStoredData()
  const redirectUrl = getRedirectUrl(stepValidation, ccData)

  if (redirectUrl && typeof window !== 'undefined') {
    window.location.href = redirectUrl
  }

  const footprint = getCarbonFootprint()

  return (
    <Layout simple={true}>
      <SEO
        title="Your Carbon Footprint Calculator Results"
        description={`Your carbon footprint results and how  your energy, transport and food  habits affect your carbon emissions. Learn how to reduce your carbon footprint.`}
      />
      <Box sx={resultsWrapperSx}>
        <CarbonFootprint footprint={footprint} />
        <ReduceCarbonFootPrint footprint={footprint} />
        <GetInspired />
      </Box>
    </Layout>
  )
}

export default CarbonCalculatorQuestionPage
