import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Sitemap from './sitemap'

/**
 * Sitemap navigation component, presented as set of page lists in the footer
 */
const FooterSitemap: React.FC = () => {
  const data = useStaticQuery<GatsbyTypes.SitemapNavigationQuery>(graphql`
    query SitemapNavigation {
      contentfulSitemap(pageSection: { eq: "page" }) {
        collections {
          heading
          pages {
            ...PageLink
          }
        }
      }
    }
  `)

  return <Sitemap query={data} />
}

export default FooterSitemap
