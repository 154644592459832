import React from 'react'
import { Box, Label, ThemeUIStyleObject } from 'theme-ui'
import { CcCheckbox } from '../../forms/inputs/cc-checkbox'

interface Props {
  name?: string
  options: Array<
    | string
    | {
        name?: string
        value?: string
      }
  >
  onChange: (event: unknown, multiChoice: boolean) => void
  value: string | null
}

const RadioButtonList: React.FC<Props> = ({
  name,
  options,
  onChange,
  value,
}) => {
  const listSx: ThemeUIStyleObject = {
    marginTop: '3.33rem',
    marginBottom: '3.33rem',
  }

  const labelSx: ThemeUIStyleObject = {
    marginBottom: '0.83rem',
    alignItems: 'center',
  }

  return (
    <Box sx={listSx}>
      {options.map((option, optionKey) => (
        <React.Fragment key={`${name}-${optionKey}`}>
          <Label sx={labelSx}>
            <CcCheckbox
              name={`${name}[]`}
              value={option?.value}
              variant="radioGrey"
              onChange={event => onChange(event, true)}
              defaultChecked={value && value[option?.value]}
            />
            {option.name}
          </Label>
        </React.Fragment>
      ))}
    </Box>
  )
}

export default RadioButtonList
