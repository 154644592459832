import React from 'react'
import { Grid, ThemeUIStyleObject, Box, Heading, Text } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import { ReactNode } from 'react'
import { Stack } from 'raam'

import {
  gridTemplateAreas as rows,
  repeatGridArea as cols,
  spanWidth,
} from '../utils/mixins'

import {
  Document,
  BLOCKS,
  Node,
  BlockNode,
  hasAssets,
  toString,
  renderRichText,
  renderGroups,
  getGroup,
  CustomRenderer,
} from '../rich-text'

import ArticleImage from './article-image'
import ArticleImagePair from './article-image-pair'
import AssetList from './asset-list'
import PullQuote from './pull-quote'
import Boxout from './boxout'
import Video from './video'
import Table from './table'
import TermsAgreement from './terms-agreement'

interface Props {
  title?: string
  header?: {
    topic?: string
    heading?: string
  }
  body?: Document
  aside?: Document
  promo?: ReactNode
  variant?: 'news' | 'client' | 'texture' | 'default'
}

/**
 * Main article component for standard content pages.
 *
 * Various grid layouts are determined algorithmically according to the
 * content supplied.
 */
const Article: React.FC<Props> = ({
  title,
  header,
  body,
  aside,
  promo,
  variant = 'default',
}) => {
  const data = useStaticQuery<GatsbyTypes.EmbeddedAssetsQuery>(graphql`
    query EmbeddedAssets {
      video: allContentfulVideo {
        edges {
          node {
            contentful_id
          }
        }
      }
      imagePair: allContentfulImagePair {
        edges {
          node {
            contentful_id
          }
        }
      }
    }
  `)

  // prettier-ignore
  const grids: ThemeUIStyleObject = {
    default: {
      gridTemplateAreas: [
        rows([
          body && [cols(12, 'body')],
          aside && [cols(12, 'aside')]
        ]),
        null,
        rows([
          body && [null, cols(10, 'body'), null],
          aside && [null, cols(10, 'aside'), null],
        ]),
      ],
    },
    sidebar: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(7, 'body'),
          null,
          cols(4, 'aside')]
        ]),
        rows([
          [null, cols(7, 'body'), null, cols(3, 'aside')]
        ]),
      ],
    },
    halves: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(6, 'body'), null, cols(5, 'aside')]
        ]),
        rows([
          [null, cols(5, 'body'), null, cols(5, 'aside')]
        ]),
      ],
    },
    header: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(5, 'body'), null, cols(6, 'aside')]
        ]),
        rows([
          [null, cols(4, 'body'), null, cols(6, 'aside')]
        ]),
      ],
    },
    media: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(5, 'body'), null, cols(6, 'aside')]
        ]),
        rows([
          [null, cols(3, 'body'), null, cols(7, 'aside')]
        ]),
      ],
    },
    thirds: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(7, 'body'), null, cols(4, 'aside')]
        ]),
        rows([
          [null, cols(6, 'body'), null, cols(3, 'aside'), null]
        ]),
      ],
    },
    news: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
        ]),
        null,
        rows([
          [null, cols(7, 'body'), null, cols(3, 'aside')],
        ]),
      ],
    },
    client: {
      gridTemplateAreas: [
        rows([
          [cols(12, 'body')],
          [cols(12, 'aside')]
        ]),
        rows([
          [cols(7, 'body'), null, cols(4, 'aside')],
        ]),
        rows([
          [null, cols(6, 'body'), null, cols(4, 'aside')],
        ]),
      ],
    },
  }

  const columns: ThemeUIStyleObject = {
    body: { gridArea: 'body' },
    aside: { gridArea: 'aside' },
  }

  const topic: ThemeUIStyleObject = {
    position: 'relative',
    marginBottom: 10,
    letterSpacing: '0.4em',

    // horizontal rule
    '::before': {
      content: '""',
      display: ['none', null, 'block'],
      borderTop: 'double',
      position: 'absolute',
      width: '25%',
      top: '50%',
      left: '-30%',
    },
  }

  const heading = {
    marginLeft: header ? 0 : [0, null, spanWidth(1, 12, 11)],
    marginBottom: header ? 11 : 14,
  }

  const getLayout = (body?: Document, aside?: Document) => {
    if (header) return grids.header
    if (variant === 'news') return grids.news
    if (!body || !aside) return grids.default
    if (variant === 'client') return grids.client

    const bodyJson = body.raw && JSON.parse(body.raw)
    const asideJson = aside.raw && JSON.parse(aside.raw)
    const assetIds = [...data.video.edges, ...data.imagePair.edges].map(
      edge => edge.node.contentful_id
    )

    if (hasAssets(bodyJson, assetIds)) return grids.sidebar

    if (hasAssets(asideJson, assetIds)) {
      if (toString(bodyJson).length > 1000) {
        return grids.halves
      } else {
        return grids.media
      }
    }

    return grids.thirds
  }

  const getEmbeddedGroup = (node: Node): string => {
    switch (node.nodeType) {
      case BLOCKS.EMBEDDED_ENTRY:
        switch (node.data.target.__typename) {
          case 'ContentfulAssetList':
            return 'assets'
          case 'ContentfulImagePair':
            return 'asset'
          case 'ContentfulPullQuote':
            return 'quote'
          case 'ContentfulBoxout':
            return 'info'
          case 'ContentfulVideo':
            return 'video'
        }
    }
    return getGroup(node)
  }

  const renderers: CustomRenderer = {
    renderNode: {
      [BLOCKS.DOCUMENT]: (node, children) =>
        renderGroups(node as BlockNode, children, getEmbeddedGroup),

      [BLOCKS.EMBEDDED_ASSET]: node => {
        switch (node.data.target.file?.contentType) {
          case 'image/png':
          case 'image/jpeg':
            return (
              <ArticleImage
                fluid={node.data.target.gatsbyImageData}
                alt={node.data.target.title}
                description={node.data.target.description}
                variant={variant === 'texture' ? variant : 'default'}
              />
            )
        }
      },

      [BLOCKS.EMBEDDED_ENTRY]: node => {
        switch (node.data.target.__typename) {
          case 'ContentfulAssetList':
            return (
              <AssetList
                title={node.data.target.title}
                assets={node.data.target.assets}
              />
            )
          case 'ContentfulPullQuote':
            return (
              <PullQuote
                quote={node.data.target.quote}
                citation={node.data.target.citation}
              />
            )
          case 'ContentfulBoxout':
            return (
              <Boxout
                title={node.data.target.title}
                body={node.data.target.body}
              />
            )
          case 'ContentfulVideo':
            return (
              <Video
                title={node.data.target.title}
                url={node.data.target.url}
              />
            )
          case 'ContentfulImagePair':
            return (
              <ArticleImagePair
                primary={node.data.target.primary}
                secondary={node.data.target.secondary}
              />
            )
          case 'ContentfulTable':
            return (
              <Table
                title={node.data.target.title}
                data={node.data.target.data.tableData}
              />
            )
          case 'ContentfulTermsAgreement':
            return (
              <TermsAgreement
                title={node.data.target.title}
                body={node.data.target.agreement}
                agree={node.data.target.agreeLink}
                disagree={node.data.target.disagreeLink}
              />
            )
        }
      },
    },
  }

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={heading as ThemeUIStyleObject} variant="lrg">
          {title}
        </Heading>
      )}
      <Grid sx={getLayout(body, aside) as ThemeUIStyleObject} variant="default">
        {body && (
          <Stack sx={columns.body as ThemeUIStyleObject} gap={12}>
            {header && (
              <React.Fragment>
                <Text sx={topic} variant="primary">
                  {header.topic}
                </Text>
                <Heading
                  as="h1"
                  sx={heading as ThemeUIStyleObject}
                  variant="lrg"
                >
                  {header.heading}
                </Heading>
              </React.Fragment>
            )}
            {renderRichText(body, renderers)}
          </Stack>
        )}
        {aside && (
          <Stack sx={columns.aside as ThemeUIStyleObject} gap={16}>
            {renderRichText(aside, renderers)}
          </Stack>
        )}
        {promo && <Box sx={columns.aside as ThemeUIStyleObject}>{promo}</Box>}
      </Grid>
    </Box>
  )
}

export default Article
