import React from 'react'
import { Heading, Box } from 'theme-ui'
import { Stack } from 'raam'
import AssetLink from './asset-link'

interface Props {
  title: string
  assets: Array<{
    file: {
      url: string
      contentType: 'application/pdf'
      details: {
        size: number
      }
    }
    title: string
  }>
}

/**
 * Collection of links for downloading document assets.
 */
const AssetList: React.FC<Props> = ({ title, assets }) => {
  const items: React.ReactNodeArray = assets.map((asset, i) => {
    return (
      <AssetLink
        key={i}
        href={asset.file.url}
        title={asset.title}
        size={asset.file.details.size}
        mimeType={asset.file.contentType}
      />
    )
  })

  return (
    <Box>
      <Heading as="h3" variant="med">
        {title}
      </Heading>
      <Stack as="ul" sx={{ mt: 8 }} gap={11}>
        {items}
      </Stack>
    </Box>
  )
}

export default AssetList
