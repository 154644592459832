import React from 'react'
import { Stack } from 'raam'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { formatPageLink } from '../utils/helpers'
import { Page } from '../utils/types'
import PageLink from './page-link'

interface Props {
  partners?: Array<{
    name?: string
    page?: Page
  }>
}

const PartnersList: React.FC<Props> = ({ partners = [] }) => {
  const list: ThemeUIStyleObject = {
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    maxHeight: '12rem',
    marginTop: 14,
  }

  const item: ThemeUIStyleObject = {
    paddingRight: 16,
    minWidth: '18rem',
    display: 'block',
  }

  const page = partners.map((partner, i) =>
    partner.page ? (
      <PageLink key={i} sx={item} to={formatPageLink(partner.page)}>
        {partner.name}
      </PageLink>
    ) : (
      <Box sx={item}>{partner.name}</Box>
    )
  )
  return (
    <Stack as="ul" sx={list}>
      {page}
    </Stack>
  )
}

export default PartnersList
