import React from 'react'
import SocialLinks from './social-links'

const FooterSocial: React.FC = props => (
  <SocialLinks
    platforms={['facebook', 'instagram', 'twitter', 'linkedin']}
    {...props}
  />
)

export default FooterSocial
