import React from 'react'
import { Box, Image, ThemeUIStyleObject, Text, Link } from 'theme-ui'
import flagSlice from '../../images/flag-slice.png'

const CarbonCalculatorFooter: React.FC = () => {
  const flagSliceFooterSx: ThemeUIStyleObject = {
    width: '100%',
  }

  const wrapperSx: ThemeUIStyleObject = {
    position: 'relative',
  }

  const textSx: ThemeUIStyleObject = {
    textAlign: ['center', 'initial', 'initial'],
    position: ['initial', 'absolute', 'absolute'],
    top: '-2.5rem',
    marginBottom: ['1rem', 0, 0],
  }

  const leftTextSx: ThemeUIStyleObject = {
    ...textSx,
    left: '1.66rem',
  }

  const rightTextSx: ThemeUIStyleObject = {
    ...textSx,
    right: '1.66rem',
  }

  const linkSx: ThemeUIStyleObject = {
    marginX: '1.25rem',
    textDecoration: 'none',
    color: '#333',
    fontWeight: '400',
    opacity: 0.6,
  }

  return (
    <Box sx={wrapperSx}>
      <Text sx={leftTextSx} as="p">
        <Link sx={linkSx} href="/the-legal-stuff/privacy-policy">
          Privacy Policy
        </Link>{' '}
        <Link sx={linkSx} href="/the-legal-stuff/website-terms-and-conditions">
          Terms &amp; Conditions
        </Link>
      </Text>
      <Text sx={rightTextSx} as="p">
        <Link sx={linkSx} href="/carbon-footprint-report">
          Your ICL Carbon Footprint Report (PDF)
        </Link>
      </Text>
      <Image sx={flagSliceFooterSx} src={flagSlice} />
    </Box>
  )
}

export default CarbonCalculatorFooter
