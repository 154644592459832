import React from 'react'
import { Flex, ThemeUIStyleObject } from 'theme-ui'
import Category from './category'

type CategoryType = {
  completed: boolean
  name: string
}

type Props = {
  categories: Array<CategoryType>
}

const Categories: React.FC<Props> = ({ categories = [] }) => {
  const wrapperSx: ThemeUIStyleObject = {
    flexWrap: 'wrap',
    minHeight: '4.58rem',
  }

  let oneInProgress = false

  return (
    <Flex sx={wrapperSx}>
      {categories.map((category, index) => {
        let active = false
        if (!category.completed && !oneInProgress) {
          oneInProgress = true
          active = true
        }

        return (
          <Category
            name={category.name}
            finished={category.completed}
            active={active}
            categoriesCount={categories.length}
            key={`${category.name}-${index}`}
          />
        )
      })}
    </Flex>
  )
}

export default Categories
