import React, { useState } from 'react'
import { Flex, Heading, Box, Text, Link, ThemeUIStyleObject } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import footprintImage from '../../images/footprint-large.svg'
import {
  darkGreenElectricityTariffStep,
  travelHoursFlyingLongHaulStep,
  dietRedMeatStep,
} from '../../../carbon-calculator-config'
import CarbonCalculatorCurlSprayGrey from '../../images/carbon-calculator-curl-spray-grey.png'
import {
  getStoredData,
  getStoredCategory,
  getStoredValue,
  getCarbonFootprint,
} from '../../utils/carbon-calculator'

import { Question, StoredValues } from '../../utils/carbon-calculator-types'

interface Props {
  footprint?: number
}

let questions = [
  {
    category: 'energy',
    ...darkGreenElectricityTariffStep,
  },
  {
    category: 'transport',
    ...travelHoursFlyingLongHaulStep,
    additionalOverrides: {
      internationalFlightType: 'economy',
    },
  },
  {
    category: 'food',
    ...dietRedMeatStep,
    additionalOverrides: {
      diet: 'regular',
    },
  },
]

const defaultInputRangeStyle: ThemeUIStyleObject = {
  width: '100%',
  height: '2.5rem',
  paddingX: '0.08rem',
  position: 'absolute',
  zIndex: 2,
  margin: 0,
  backgroundColor: 'transparent',
  WebkitAppearance: 'none',
  ':focus': {
    outline: 'none',
  },
  '::-webkit-slider-runnable-track': {
    background: '#333',
    border: 0,
    width: '100%',
    height: '0.17rem',
    margin: '1.16rem 0',
    cursor: 'pointer',
  },
  '::-webkit-slider-thumb': {
    marginTop: ' -1.17rem',
    width: '2.5rem',
    height: '2.5rem',
    background: '#9ad43a',
    border: '0.17rem solid #fff',
    borderRadius: '4.17rem',
    cursor: 'pointer',
    WebkitAppearance: 'none',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  ':focus::-webkit-slider-runnable-track': {
    background: '#404040',
  },
  '::-moz-range-track': {
    background: '#333',
    border: 0,
    width: '100%',
    height: '0.17rem',
    margin: '1.16rem 0',
    cursor: 'pointer',
  },
  '::-moz-range-thumb': {
    width: '2.5rem',
    height: '2.5rem',
    background: '#9ad43a',
    border: '0.17rem solid #fff',
    borderRadius: '4.17rem',
    cursor: 'pointer',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  '::-ms-track': {
    background: 'transparent',
    borderColor: 'transparent',
    borderWidth: '1.17rem 0',
    color: 'transparent',
    width: '100%',
    height: '0.17rem',
    margin: '1.16rem 0',
    cursor: 'pointer',
  },
  '::-ms-fill-lower': {
    background: '#262626',
    border: 0,
  },
  '::-ms-fill-upper': {
    background: '#333',
    border: 0,
  },
  '::-ms-thumb': {
    width: '2.5rem',
    height: '2.5rem',
    background: '#9ad43a',
    border: '0.17rem solid #fff',
    borderRadius: '4.17rem',
    cursor: 'pointer',
    marginTop: 0,
    boxSizing: 'border-box',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  ':focus::-ms-fill-lower': {
    background: '#333',
  },
  ':focus::-ms-fill-upper': {
    background: '#404040',
  },
}

const answersWrapper: ThemeUIStyleObject = {
  justifyContent: 'space-between',
}

type RangeInputProps = {
  question: Question
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    category: string,
    question: Question
  ) => void
  category: string
  defaultValue: string | boolean
  additionalOverrides: StoredValues
}

const RangeInput = ({
  question,
  onChange,
  category,
  defaultValue,
  additionalOverrides,
}: RangeInputProps) => {
  const moreThanTwoOptions = question.answers.length > 2
  const breakpoint = useBreakpointIndex({ defaultIndex: 2 })
  const isMobile = breakpoint === 0

  const rangeInputWrapper: ThemeUIStyleObject = moreThanTwoOptions
    ? {
        position: 'relative',
        marginTop: ['3rem', '3rem', '2.5rem'],
        paddingBottom: ['4.5rem', '2rem'],
      }
    : {
        position: 'relative',
        marginTop: ['2rem', '2rem', '1.75rem'],
      }

  const bottomAnswersWrapper: ThemeUIStyleObject = moreThanTwoOptions
    ? {
        ...answersWrapper,
        position: ['absolute', 'initial'],
        width: '100%',
        top: '2.5rem',
      }
    : answersWrapper

  const answerStyle: ThemeUIStyleObject = {
    width: moreThanTwoOptions ? `1.33rem` : 'initial',
    position: 'relative',
  }

  const topAnswerStyle: ThemeUIStyleObject = {
    ...answerStyle,
    '&::after': {
      content: '""',
      width: '1.17rem',
      height: '1.17rem',
      background: '#333',
      borderRadius: '100%',
      position: 'absolute',
      bottom: '-1.83rem',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 1,
    },
  }

  const bottomAnswerStyle: ThemeUIStyleObject = {
    ...answerStyle,
    paddingTop: '2.5rem',
    '&::after': {
      content: '""',
      width: '1.17rem',
      height: '1.17rem',
      background: '#333',
      borderRadius: '100%',
      position: 'absolute',
      top: '0.67rem',
      zIndex: 1,
      left: '0.08rem',
    },
  }

  const rangeInputStyle: ThemeUIStyleObject =
    isMobile && moreThanTwoOptions
      ? {
          ...defaultInputRangeStyle,
          top: `1.25rem`,
          transform: 'translateY(-50%)',
        }
      : {
          ...defaultInputRangeStyle,
        }

  const answerPStyle: ThemeUIStyleObject = moreThanTwoOptions
    ? {
        width: '400%',
        textAlign: 'center',
        marginLeft: '-140%',
        position: 'absolute',
        marginY: 0,
      }
    : { marginY: 0, textAlign: 'center' }

  const defaultIndex = question.answers.findIndex(
    answer => answer.value === defaultValue
  )

  return (
    <Box sx={rangeInputWrapper}>
      {isMobile && moreThanTwoOptions ? (
        <Flex sx={answersWrapper}>
          {question.answers.map((answer, index) => (
            <Box sx={topAnswerStyle} key={`question-top-labels-${index}`}>
              {!((index + 1) % 2) ? (
                <p sx={{ ...answerPStyle, bottom: 0 }}>{answer.name}</p>
              ) : null}
            </Box>
          ))}
        </Flex>
      ) : null}
      <input
        sx={rangeInputStyle}
        name={question.value}
        type="range"
        min="0"
        max={question.answers.length - 1}
        defaultValue={defaultIndex}
        step="1"
        onChange={event =>
          onChange(event, category, question, additionalOverrides)
        }
      />
      <Flex sx={bottomAnswersWrapper}>
        {question.answers.map((answer, index) =>
          !isMobile ||
          (isMobile && !moreThanTwoOptions) ||
          (isMobile && moreThanTwoOptions && (index + 1) % 2) ? (
            <Box
              sx={
                !isMobile || !moreThanTwoOptions
                  ? bottomAnswerStyle
                  : answerStyle
              }
              key={`question-bot-labels-${index}`}
            >
              <p sx={answerPStyle}>{answer.name}</p>
            </Box>
          ) : (
            <Box sx={answerStyle} key={`question-bot-labels-${index}`} />
          )
        )}
      </Flex>
    </Box>
  )
}

const wrapperSx: ThemeUIStyleObject = {
  flexWrap: 'wrap',
  flexBasis: '100%',
  paddingX: '10%',
  paddingY: '5rem',
  background: '#F2F2F2',
  display: ['block', 'block', 'flex'],
  justifyContent: ['initial', 'initial', 'space-between'],
  position: 'relative',
  '&:after': {
    content: ['""', '""', `url(${CarbonCalculatorCurlSprayGrey})`],
    position: 'absolute',
    top: '55%',
    left: 0,
    mixBlendMode: 'multiply',
  },
}

const sideWrapperSx: ThemeUIStyleObject = {
  flex: ['initial', 'initial', '47% 0 0'],
}

const headerStyle: ThemeUIStyleObject = {
  fontWeight: 600,
  fontSize: '2.75rem',
}

const textStyle: ThemeUIStyleObject = {
  marginTop: '0.5rem',
  fontSize: '1.25rem',
}

const questionHeadingStyle: ThemeUIStyleObject = {
  marginTop: '4rem',
  fontWeight: 400,
  b: {
    textTransform: 'capitalize',
  },
}

const newCarbonFootprintStyle: ThemeUIStyleObject = {
  marginRight: ['30%', '30%', 'auto'],
  position: 'relative',
  marginLeft: ['auto', 'auto', '20%'],
  marginTop: ['5rem', '5rem', 'auto'],
  '&::before': {
    content: [undefined, undefined, '""'],
    display: ['none', 'none', 'block'],
    position: 'absolute',
    backgroundColor: 'black',
    width: '25%',
    height: '0.16rem',
    left: '-30%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}

const tonnesStyle: ThemeUIStyleObject = {
  marginTop: '1rem',
  fontSize: ['3.5rem', '3.5rem', '4rem'],
  position: 'relative',
  paddingLeft: ['auto', 'auto', '20%'],
  '&::before': {
    content: [undefined, undefined, '""'],
    backgroundImage: `url(${footprintImage})`,
    backgroundSize: '136%',
    backgroundPosition: 'center',
    height: '6.33rem',
    width: '6.33rem',
    display: 'block',
    position: 'absolute',
    left: '-1rem',
    top: '-2rem',
  },
}

const whiteBreakStyle: ThemeUIStyleObject = {
  width: '100%',
  height: '2.17rem',
  background: '#fff',
  marginY: '4rem',
}

const switchHeaderStyle: ThemeUIStyleObject = {
  fontWeight: 400,
  fontSize: '2.5rem',
}

const joinButtonStyle: ThemeUIStyleObject = {
  marginTop: '2rem',
  marginBottom: ['2rem', '2rem', '5rem'],
  width: '100%',
  maxWidth: ['auto', 'auto', '21rem'],
}

const formStyle: ThemeUIStyleObject = {
  paddingBottom: ['initial', 'initial', '2.5rem'],
}

const CarbonFootprint: React.FC<Props> = ({ footprint }) => {
  const storedData = getStoredData()
  const overrideValues = {}

  questions = questions.map(
    ({ category, question, additionalOverrides = [] }) => {
      const storedCategory = getStoredCategory(storedData, category)
      const defaultValue = getStoredValue(storedCategory, question.value)

      if (!overrideValues[category]) {
        overrideValues[category] = {}
      }

      overrideValues[category][question.value] = defaultValue

      return {
        category,
        question,
        defaultValue,
        additionalOverrides,
      }
    }
  )

  const [state, setState] = useState<{
    footprint: number
    overrideValues: unknown
  }>({
    footprint: Number((footprint / 1000).toFixed(2)),
    overrideValues,
  })

  const onChange = (
    event,
    category,
    { value: questionValue, answers },
    additionalOverrides
  ) => {
    const overrideValues = JSON.parse(JSON.stringify(state.overrideValues))

    const newAnswer = answers[event.target.value].value

    overrideValues[category] = {
      ...overrideValues[category],
      ...additionalOverrides,
      [questionValue]: newAnswer,
    }

    if (newAnswer === 'none') {
      Object.entries(additionalOverrides).forEach(([key]) => {
        delete overrideValues[category][key]
      })
    }

    const newFootprint = getCarbonFootprint(overrideValues)

    setState({
      footprint: Number((newFootprint / 1000).toFixed(2)),
      overrideValues,
    })
  }

  return (
    <Box sx={wrapperSx}>
      <Box sx={sideWrapperSx}>
        <Heading variant="med" sx={headerStyle}>
          Reduce your carbon footprint
        </Heading>
        <Text as="p" sx={textStyle}>
          Make changes to your Energy, Transport and Food daily habits below and
          see how it changes your results.
        </Text>
        <Box sx={formStyle} as="form">
          {questions.map(
            (
              { category, question, defaultValue, additionalOverrides },
              index
            ) => (
              <Box key={`question-${index}`}>
                <Heading sx={questionHeadingStyle} variant="med">
                  <b>{category}</b> {question.title}
                </Heading>
                <RangeInput
                  question={question}
                  onChange={onChange}
                  category={category}
                  defaultValue={defaultValue}
                  additionalOverrides={additionalOverrides}
                />
              </Box>
            )
          )}
        </Box>
      </Box>
      <Box sx={sideWrapperSx}>
        <Heading sx={newCarbonFootprintStyle} variant="med">
          Your new carbon footprint would be
        </Heading>
        <Heading sx={tonnesStyle} variant="lrg">
          {state.footprint} tonnes
        </Heading>
        <Box sx={whiteBreakStyle} />
        <Heading variant="med" sx={switchHeaderStyle}>
          Take a small step for a greener Britain,
          <b> switch your SIM to Ecotalk</b> and save nature.
        </Heading>
        <Link
          sx={joinButtonStyle}
          href="https://www.ecotalk.co.uk/"
          variant="button"
        >
          Click and switch
        </Link>
      </Box>
    </Box>
  )
}

export default CarbonFootprint
