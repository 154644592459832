import React from 'react'
import { Box, Label, Input, Text } from 'theme-ui'
import { FieldError, UseFormMethods } from 'react-hook-form'
import Tooltip from '../tooltip'
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask'

type HandleRef = (el: HTMLInputElement | null) => void

type ValidatingInputProps<Name> = {
  readonly label: string
  readonly deepName: Name
  readonly type?: string
  readonly tooltip?: React.FC
}

type NarrowString<Name extends string> = Exclude<string, Name>

type NarrowStringOrNumber<Name extends unknown> =
  | NarrowString<Name extends string ? Name : string>
  | number

type DeepName<Name> = Name extends readonly [infer First]
  ? First extends NarrowStringOrNumber<First>
    ? { readonly [Key in First]: string }
    : never
  : Name extends readonly [infer First, infer Second]
  ? First extends NarrowStringOrNumber<First>
    ? Second extends NarrowStringOrNumber<Second>
      ? { readonly [Key in First]: { readonly [Key in Second]: string } }
      : never
    : never
  : Name extends readonly [infer First, infer Second, infer Third]
  ? First extends NarrowStringOrNumber<First>
    ? Second extends NarrowStringOrNumber<Second>
      ? Third extends NarrowStringOrNumber<Third>
        ? {
            readonly [Key in First]: {
              readonly [Key in Second]: { readonly [Key in Third]: string }
            }
          }
        : never
      : never
    : never
  : never

/**
 * deepName must be a tuple of narrow strings, e.g.
 * `['foo', 0, 'bar'] as const`
 * instead of
 * `['foo', 0, 'bar']`
 * the `as const` is the important bit!
 * why? if this is a regular array of strings `string[]` or tuple of widened string `[string, string]` then
 * it will wildcard match against your form data type in an unexpected and confusing way.
 */
export const ValidatingInput = <
  Name extends
    | readonly [string | number]
    | readonly [string | number, string | number]
    | readonly [string | number, string | number, string | number],
  Rest
>({
  deepName,
  label,
  handleRef,
  errors,
  type = 'text',
  tooltip,
}: UseFormMethods<DeepName<Name> & Rest> &
  ValidatingInputProps<Name> & {
    readonly handleRef: (el: HTMLInputElement | null) => void
  } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >): JSX.Element => {
  const name = deepName
    .map(x => (typeof x === 'number' ? `[${x}]` : x))
    .join('')
  const error = deepName.reduce(
    (_errors: typeof errors | undefined, name) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error hard to convince TS this is fine
      _errors ? (_errors[name] as typeof errors) : undefined,
    errors
  ) as FieldError | undefined

  const inputVariant = error ? 'error' : 'complexFormInput'

  return (
    <Box sx={{ width: '100%', marginBottom: '2rem' }}>
      <Label htmlFor={`input-${name}`} id={`label-${name}`}>
        {label}:
      </Label>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
        <Input
          aria-describedby={`label-${name} errors-${name}`}
          aria-invalid={Boolean(error?.message)}
          id={`input-${name}`}
          name={name}
          ref={handleRef}
          variant={inputVariant}
          type={type}
        />
        {tooltip && <Tooltip contents={tooltip} />}
      </Box>
      <Text as="p" variant="errorLabel" id={`errors-${name}`}>
        {error?.message}
      </Text>
    </Box>
  )
}

/**
 * deepName must be a tuple of narrow strings, e.g.
 * `['foo', 0, 'bar'] as const`
 * instead of
 * `['foo', 0, 'bar']`
 * the `as const` is the important bit!
 * why? if this is a regular array of strings `string[]` or tuple of widened string `[string, string]` then
 * it will wildcard match against your form data type in an unexpected and confusing way.
 */
export const SymmetricValidatingInput = <
  Name extends
    | readonly [string | number]
    | readonly [string | number, string | number]
    | readonly [string | number, string | number, string | number],
  Rest
>(
  props: UseFormMethods<DeepName<Name> & Rest> &
    ValidatingInputProps<Name> & {
      readonly handleRef: (name: string) => HandleRef
    }
): JSX.Element => {
  const { handleRef, label } = props

  return (
    <ValidatingInput<typeof props.deepName, Rest>
      {...props}
      handleRef={handleRef(label)}
    />
  )
}

export const MaskedValidatingInput = <
  Name extends
    | readonly [string | number]
    | readonly [string | number, string | number]
    | readonly [string | number, string | number, string | number],
  Rest
>({
  deepName,
  label,
  handleRef,
  errors,
  type = 'text',
  tooltip,
  mask,
  maskPlaceholder,
  beforeMaskedStateChange,
}: Pick<
  ReactInputMaskProps,
  'mask' | 'maskPlaceholder' | 'beforeMaskedStateChange'
> &
  UseFormMethods<DeepName<Name> & Rest> &
  ValidatingInputProps<Name> & {
    readonly handleRef: (el: HTMLInputElement | null) => void
  } & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >): JSX.Element => {
  const name = deepName
    .map(x => (typeof x === 'number' ? `[${x}]` : x))
    .join('')
  const error = deepName.reduce(
    (_errors: typeof errors | undefined, name) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error hard to convince TS this is fine
      _errors ? (_errors[name] as typeof errors) : undefined,
    errors
  ) as FieldError | undefined

  const inputVariant = error ? 'error' : 'complexFormInput'

  return (
    <Box sx={{ width: '100%', marginBottom: '2rem' }}>
      <Label htmlFor={`input-${name}`} id={`label-${name}`}>
        {label}:
      </Label>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap' }}>
        <ReactInputMask
          mask={mask}
          maskPlaceholder={maskPlaceholder}
          beforeMaskedStateChange={beforeMaskedStateChange}
        >
          <Input
            aria-describedby={`label-${name} errors-${name}`}
            aria-invalid={Boolean(error?.message)}
            id={`input-${name}`}
            name={name}
            ref={handleRef}
            variant={inputVariant}
            type={type}
          />
        </ReactInputMask>
        {tooltip && <Tooltip contents={tooltip} />}
      </Box>
      <Text as="p" variant="errorLabel" id={`errors-${name}`}>
        {error?.message}
      </Text>
    </Box>
  )
}
