import React, { useState } from 'react'
import {
  Button,
  Container,
  Text,
  ThemeUIStyleObject,
  Image,
  Switch,
  Divider,
} from 'theme-ui'
import windTurbineImage from '../../images/wind-turbine.png'

interface Props {
  ready: boolean
  settingsBanner: boolean
  onPrefsClicked: (marketing: boolean, analytics: boolean) => void
}

const CookieSettingsBanner: React.FC<Props> = Props => {
  const [analytics, setAnalytics] = useState(true)
  const [marketing, setMarketing] = useState(true)

  const { ready, settingsBanner, onPrefsClicked } = Props

  const overlay: ThemeUIStyleObject = {
    maxWidth: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(128, 128, 128, 0.5)',
    zIndex: 5,
  }

  const outer: ThemeUIStyleObject = {
    border: '0.625rem',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: ['100%', '54%', '54%'],
    backgroundColor: 'muted',
    borderRadius: '0.625rem',
    borderBottom: 'single',
    borderBottomColor: 'contrast',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25);',
    zIndex: 6,
  }

  const inner: ThemeUIStyleObject = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }

  const greenBand: ThemeUIStyleObject = {
    maxWidth: '100%',
    width: '100%',
    top: 0,
    height: '7.5rem',
    backgroundColor: '#9AD43A',
    position: 'absolute',
    zIndex: 6,
    borderRadius: '0.625rem 0.625rem 0 0',
  }

  const form: ThemeUIStyleObject = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    '& > div': {
      maxWidth: '88%',
    },
    '& > button': {
      minWidth: '9em',
      marginTop: ['0.688rem', 0],
      paddingY: '1.25rem',
      paddingX: '1.25rem',
    },
  }

  const preferences: ThemeUIStyleObject = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const windTurbine: ThemeUIStyleObject = {
    zIndex: 7,
  }

  const switches: ThemeUIStyleObject = {
    backgroundColor: '#D9D9D9',
    width: '11.5rem',
    height: '2.5rem',
    '& > div': {
      position: 'relative',
      width: '36%',
      height: '90%',
      top: '0.10rem',
      right: '-0.15rem',
    },
    'input:checked ~ &': {
      '& > div': {
        position: 'relative',
        right: '-1.38rem',
        top: '0.10rem',
      },
    },
  }

  const switchContainer: ThemeUIStyleObject = {
    marginTop: '0.5rem',
    width: '6rem',
  }

  const upperHorizontalDivider: ThemeUIStyleObject = {
    height: '0.125rem !important',
    color: 'black',
    width: '100%',
    marginTop: '1.25rem',
  }

  const lowerHorizontalDivider: ThemeUIStyleObject = {
    height: '0.125rem !important',
    color: 'black',
    width: '100%',
    marginBottom: '1.25rem',
  }

  const verticalDivider: ThemeUIStyleObject = {
    width: '0.125rem',
    height: '5rem !important',
    color: 'black',
  }

  const dividerContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '20%',
    margin: 0,
  }

  const innerPreferenceContainer: ThemeUIStyleObject = {
    marginRight: 0,
    height: '6.25rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: '40%',
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: '0.938rem',
    zIndex: '7',
  }

  const pText = {
    textAlign: 'center',
  }

  const button: ThemeUIStyleObject = {
    marginTop: '1.25rem',
    marginBottom: '2.0rem',
  }

  const handleAnalyticsChange = () => {
    setAnalytics(!analytics)
  }

  const handleMarketingChange = () => {
    setMarketing(!marketing)
  }

  return ready && settingsBanner ? (
    <Container sx={overlay}>
      <Container sx={outer} variant="full" data-testid="cookie-settings-banner">
        <Container sx={greenBand}></Container>
        <Container sx={inner} variant="wide">
          <Image src={windTurbineImage} sx={windTurbine}></Image>
          <Text as="h2" variant="heading" sx={heading}>
            Choose your cookies
          </Text>
          <Container sx={form} variant="full">
            <Container>
              <Text as="p" variant="intro" sx={pText}>
                Some cookies are essential to make the site work properly for
                you, and can&apos;t be turned off.
              </Text>
              <Divider sx={upperHorizontalDivider}></Divider>
              <Container sx={preferences}>
                <Container sx={innerPreferenceContainer}>
                  <Text as="p" sx={pText}>
                    Analytics & Customisation
                  </Text>
                  <Container sx={switchContainer}>
                    <Switch
                      name="analytics"
                      onChange={handleAnalyticsChange}
                      checked={analytics}
                      sx={switches}
                    />
                  </Container>
                </Container>
                <Container sx={dividerContainer}>
                  <Divider sx={verticalDivider}></Divider>
                </Container>
                <Container sx={innerPreferenceContainer}>
                  <Text as="p" sx={pText}>
                    Marketing & Social Media
                  </Text>
                  <Container sx={switchContainer}>
                    <Switch
                      name="marketing"
                      onChange={handleMarketingChange}
                      checked={marketing}
                      sx={switches}
                    />
                  </Container>
                </Container>
              </Container>
              <Divider sx={lowerHorizontalDivider}></Divider>
            </Container>
            <Button
              variant="alt"
              onClick={() => onPrefsClicked(marketing, analytics)}
              sx={button}
            >
              All done
            </Button>
          </Container>
        </Container>
      </Container>
    </Container>
  ) : null
}

export default CookieSettingsBanner
