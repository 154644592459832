import React from 'react'
import { Heading } from 'theme-ui'
import { Flex, Stack } from 'raam'
import { Document, renderRichText } from '../rich-text'
import { formatPageLink } from '../utils/helpers'
import { Page } from '../utils/types'
import PageLink from './page-link'

interface Props {
  title?: string
  body?: Document
  agree?: Page
  disagree?: Page
}

const TermsAgreement: React.FC<Props> = ({ title, body, agree, disagree }) => {
  return (
    <Stack sx={{ mt: 11 }} gap={11}>
      <Heading as="h4" variant="sml">
        {title}
      </Heading>
      {body && renderRichText(body)}
      <Flex sx={{ mt: 11 }} gap={11}>
        <PageLink to={formatPageLink(agree)} variant="button">
          Accept
        </PageLink>
        <PageLink to={formatPageLink(disagree)} variant="buttonSecondary">
          Decline
        </PageLink>
      </Flex>
    </Stack>
  )
}

export default TermsAgreement
