/**
 * Functions for working with rich-text nodes from Contentful.
 */

import { CommonNode as Node } from '@contentful/rich-text-react-renderer'

// node has embedded assets, e.g. images or video or docs?
export const hasAssets = (node: Node, assets: string[] = []): boolean =>
  'content' in node &&
  node.content.some(child => hasAssets(child, assets) || isAsset(child, assets))

export const isAsset = (node: Node, assets: string[] = []): boolean => {
  return (
    node.nodeType === 'embedded-asset-block' ||
    (node.nodeType === 'embedded-entry-block' &&
      assets.includes(node.data.target.sys.id))
  )
}

// block node contains a single inline node, e.g. a link?
export const isInlineBlock = (node: Node): boolean =>
  'content' in node &&
  node.content.length === 3 &&
  isEmptyNode(node.content[0]) &&
  isEmptyNode(node.content[2])

export const isParagraph = (node: Node): boolean =>
  node.nodeType === 'paragraph' &&
  'content' in node &&
  node.content.some(node => node.nodeType === 'text' && node.value !== '')

// node contains no text?
export const isEmptyNode = (node: Node): boolean =>
  ('content' in node && !node.content.length) ||
  ('value' in node && node.value === '')

// recursively get the text from all child nodes
export const toString = (node: Node): string => {
  if ('content' in node) {
    return (node.content as Node[]).reduce((copy: string, child: Node) => {
      const recurse = toString(child) || ''
      const value = 'value' in child ? child.value : ''
      return copy + recurse + value
    }, '')
  } else return ''
}
