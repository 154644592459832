import React from 'react'
import { Box, Heading, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  title?: string
}

const TrustpilotPromo: React.FC<Props> = ({ title }) => {
  const heading: ThemeUIStyleObject = {
    marginBottom: 12,
    textAlign: 'center',
  }

  const ref = React.useRef(null)
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [])

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      <div
        ref={ref}
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="548ad10600006400057c1c11"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-stars="5"
        data-review-languages="en"
      >
        <a
          href="https://uk.trustpilot.com/review/www.ecotricity.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          Trustpilot
        </a>
      </div>
    </Box>
  )
}

export default TrustpilotPromo
