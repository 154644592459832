import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const OurMissionFlag: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/our-mission-flag.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <GatsbyImage
      sx={{ my: -11 }}
      image={data.file.childImageSharp.gatsbyImageData}
      backgroundColor="ffffff"
      alt="Union Jack flag in Ecotricity colours"
    />
  )
}

export default OurMissionFlag
