import React, { useEffect } from 'react'
import { Box, Container, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import useScript from '../hooks/use-script'

const api = 'https://climateclock.world/widget-v2.js'

const FooterUtilities: React.FC = () => {
  const status = useScript(api)

  const textStyle: ThemeUIStyleObject = {
    textAlign: 'left',
    mt: 9,
    paddingX: [0, 0, 0, 21],
  }

  const climateClockStyle: ThemeUIStyleObject = {
    mt: 10,
  }

  useEffect(() => {
    // this is because there's no simple way to trigger the widget
    // to display correctly when navigating to different pages
    window.dispatchEvent(new Event('resize'))
  })

  return (
    <>
      <Container variant="container">
        <Heading as="h3" variant="lrg">
          Climate Clock
        </Heading>
        <Text sx={textStyle} variant="default">
          The Climate Clock is a version of the Doomsday clock that has been
          running since 1947 - this tracks the risk of global man-made disaster,
          through man made technology (like nuclear weapons) - displaying the
          minutes and seconds left before midnight, when disaster strikes. The
          climate crisis is a small part of the calculations made.
        </Text>
        <Text sx={textStyle} variant="default">
          The climate clock uses a similar approach, but, focuses only on the
          climate crisis - which is the biggest and most urgent existential
          threat we face.
        </Text>
        <Text sx={textStyle} variant="default">
          {`"The Climate Clock is a countdown to the biggest man-made disaster we
          face - but also a measure by which we can track our progress - moving
          from fossil to renewable energy. It shows we have no time to lose -
          the clock is ticking…" `}
          <strong>Dale Vince, OBE.</strong>
        </Text>
      </Container>
      {status === 'ready' && (
        <Box sx={climateClockStyle}>
          <climate-clock />
        </Box>
      )}
    </>
  )
}

export default FooterUtilities
