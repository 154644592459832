import React from 'react'
import { Flex, Box, Heading, Text, ThemeUIStyleObject, Link } from 'theme-ui'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import borderWebp from '../images/flag-border.webp'
import borderJpg from '../images/flag-border.jpg'
import useWebpImage from '../hooks/use-webp-image'

const FooterPromo: React.FC = props => {
  const border = useWebpImage(borderWebp, borderJpg)

  const data = useStaticQuery(graphql`
    query {
      blog: file(relativePath: { eq: "images/zerocarbonista.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
      book: file(
        relativePath: { eq: "images/NEW-Manifesto-book-paperback.png" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH)
        }
      }
    }
  `)

  const wrapper: ThemeUIStyleObject = {
    flexDirection: ['column', 'row'],
    flexWrap: 'wrap',
  }

  const bookImage: ThemeUIStyleObject = {
    width: ['100%', '50%', '27%'],
    marginLeft: [0, 0, '-5%'],
    flexShrink: 0,
  }

  const blogImage: ThemeUIStyleObject = {
    width: ['100%', '50%', '33%'],
    flexShrink: 0,
  }

  const bookText: ThemeUIStyleObject = {
    width: ['100%', '50%', '20%'],
    textAlign: ['center', 'left'],
    paddingX: [0, 14],
    paddingY: 14,
  }

  const blogText: ThemeUIStyleObject = {
    width: ['100%', '50%', '25%'],
    textAlign: ['center', 'left'],
    paddingX: [0, 14],
    paddingY: 14,
    backgroundImage: `url(${border})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: ['bottom center', 'bottom left'],
    backgroundSize: '60%',
  }

  return (
    <Flex sx={wrapper} {...props}>
      <GatsbyImage
        sx={bookImage}
        image={data.book.childImageSharp.gatsbyImageData}
        imgStyle={{
          objectFit: 'cover',
          objectPosition: 'left',
        }}
        alt="Manifesto book with long shadow"
      />
      <Box sx={bookText}>
        <Heading as="h3" variant="med">
          Get the book!
        </Heading>
        <Text as="p" variant="intro">
          <strong>Manifesto</strong> out now
        </Text>
        <Link
          sx={{ mt: 9 }}
          href="https://dalevince.com/manifesto/"
          variant="button"
        >
          Shop
        </Link>
      </Box>
      <GatsbyImage
        sx={blogImage}
        image={data.blog.childImageSharp.gatsbyImageData}
        imgStyle={{ objectFit: 'cover' }}
        alt="Dale Vince portrait with bandana"
      />
      <Box sx={blogText}>
        <Heading as="h3" variant="med">
          DaleVince.com
        </Heading>
        <Text as="p" variant="intro">
          <strong>Our founder Dale Vince</strong> shares his thoughts on the
          green revolution
        </Text>
        <Link sx={{ mt: 9 }} href="https://dalevince.com" variant="button">
          Explore the site
        </Link>
      </Box>
    </Flex>
  )
}

export default FooterPromo
