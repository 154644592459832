import React, { createContext, useEffect, useState } from 'react'

/* https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp */

const WebpContext = createContext<boolean>(true)

const WebpProvider: React.FC = ({ children }) => {
  const [webp, setWebp] = useState<boolean>(true)

  useEffect(() => {
    const img = new Image()
    img.onload = () => setWebp(img.width > 0 && img.height > 0)
    img.onerror = () => setWebp(false)
    img.src =
      'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA=='
  }, [])

  return <WebpContext.Provider value={webp}>{children}</WebpContext.Provider>
}

export { WebpProvider, WebpContext }
