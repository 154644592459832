const electricityMatrix = {
  //Is darkgreen energy tariff
  yes: 0,
  no: {
    //Solar energy
    yes: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 661,
          semiDetached: 516,
          endTerraced: 509,
          midTerraced: 468,
          bungalow: 611,
          convertedFlat: 719,
          purposeBuiltFlat: 601,
        },
        '2': {
          //property types
          detached: 832,
          semiDetached: 686,
          endTerraced: 679,
          midTerraced: 639,
          bungalow: 781,
          convertedFlat: 889,
          purposeBuiltFlat: 771,
        },
        '3': {
          //property types
          detached: 1002,
          semiDetached: 856,
          endTerraced: 849,
          midTerraced: 809,
          bungalow: 951,
          convertedFlat: 1059,
          purposeBuiltFlat: 941,
        },
        '4': {
          //property types
          detached: 1172,
          semiDetached: 1027,
          endTerraced: 1019,
          midTerraced: 979,
          bungalow: 1121,
          convertedFlat: 1129,
          purposeBuiltFlat: 1111,
        },
        '>4': {
          //property types
          detached: 1512,
          semiDetached: 1367,
          endTerraced: 1360,
          midTerraced: 1319,
          bungalow: 1462,
          convertedFlat: 1570,
          purposeBuiltFlat: 1452,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 331,
          semiDetached: 258,
          endTerraced: 254,
          midTerraced: 234,
          bungalow: 305,
          convertedFlat: 359,
          purposeBuiltFlat: 300,
        },
        '2': {
          //property types
          detached: 416,
          semiDetached: 343,
          endTerraced: 340,
          midTerraced: 319,
          bungalow: 391,
          convertedFlat: 445,
          purposeBuiltFlat: 385,
        },
        '3': {
          //property types
          detached: 501,
          semiDetached: 428,
          endTerraced: 425,
          midTerraced: 404,
          bungalow: 476,
          convertedFlat: 530,
          purposeBuiltFlat: 470,
        },
        '4': {
          //property types
          detached: 586,
          semiDetached: 513,
          endTerraced: 510,
          midTerraced: 489,
          bungalow: 561,
          convertedFlat: 615,
          purposeBuiltFlat: 556,
        },
        '>4': {
          //property types
          detached: 756,
          semiDetached: 684,
          endTerraced: 680,
          midTerraced: 660,
          bungalow: 731,
          convertedFlat: 785,
          purposeBuiltFlat: 726,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 220,
          semiDetached: 172,
          endTerraced: 170,
          midTerraced: 156,
          bungalow: 204,
          convertedFlat: 240,
          purposeBuiltFlat: 200,
        },
        '2': {
          //property types
          detached: 277,
          semiDetached: 229,
          endTerraced: 226,
          midTerraced: 213,
          bungalow: 260,
          convertedFlat: 296,
          purposeBuiltFlat: 257,
        },
        '3': {
          //property types
          detached: 334,
          semiDetached: 285,
          endTerraced: 283,
          midTerraced: 270,
          bungalow: 317,
          convertedFlat: 353,
          purposeBuiltFlat: 314,
        },
        '4': {
          //property types
          detached: 391,
          semiDetached: 342,
          endTerraced: 340,
          midTerraced: 326,
          bungalow: 374,
          convertedFlat: 410,
          purposeBuiltFlat: 370,
        },
        '>4': {
          //property types
          detached: 504,
          semiDetached: 456,
          endTerraced: 453,
          midTerraced: 440,
          bungalow: 487,
          convertedFlat: 523,
          purposeBuiltFlat: 484,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 165,
          semiDetached: 129,
          endTerraced: 127,
          midTerraced: 117,
          bungalow: 153,
          convertedFlat: 180,
          purposeBuiltFlat: 150,
        },
        '2': {
          //property types
          detached: 208,
          semiDetached: 172,
          endTerraced: 170,
          midTerraced: 160,
          bungalow: 195,
          convertedFlat: 222,
          purposeBuiltFlat: 193,
        },
        '3': {
          //property types
          detached: 250,
          semiDetached: 214,
          endTerraced: 212,
          midTerraced: 202,
          bungalow: 238,
          convertedFlat: 265,
          purposeBuiltFlat: 235,
        },
        '4': {
          //property types
          detached: 293,
          semiDetached: 257,
          endTerraced: 255,
          midTerraced: 245,
          bungalow: 280,
          convertedFlat: 307,
          purposeBuiltFlat: 278,
        },
        '>4': {
          //property types
          detached: 378,
          semiDetached: 342,
          endTerraced: 340,
          midTerraced: 330,
          bungalow: 365,
          convertedFlat: 392,
          purposeBuiltFlat: 363,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 132,
          semiDetached: 103,
          endTerraced: 102,
          midTerraced: 94,
          bungalow: 122,
          convertedFlat: 144,
          purposeBuiltFlat: 120,
        },
        '2': {
          //property types
          detached: 166,
          semiDetached: 137,
          endTerraced: 136,
          midTerraced: 128,
          bungalow: 156,
          convertedFlat: 178,
          purposeBuiltFlat: 154,
        },
        '3': {
          //property types
          detached: 200,
          semiDetached: 171,
          endTerraced: 170,
          midTerraced: 162,
          bungalow: 190,
          convertedFlat: 212,
          purposeBuiltFlat: 188,
        },
        '4': {
          //property types
          detached: 234,
          semiDetached: 205,
          endTerraced: 204,
          midTerraced: 196,
          bungalow: 224,
          convertedFlat: 246,
          purposeBuiltFlat: 222,
        },
        '>4': {
          //property types
          detached: 302,
          semiDetached: 273,
          endTerraced: 272,
          midTerraced: 264,
          bungalow: 292,
          convertedFlat: 314,
          purposeBuiltFlat: 290,
        },
      },
    },
    no: {
      //Adults in home
      '1': {
        //bedrooms
        '1': {
          //property types
          detached: 661,
          semiDetached: 516,
          endTerraced: 509,
          midTerraced: 468,
          bungalow: 611,
          convertedFlat: 719,
          purposeBuiltFlat: 601,
        },
        '2': {
          //property types
          detached: 832,
          semiDetached: 686,
          endTerraced: 679,
          midTerraced: 639,
          bungalow: 781,
          convertedFlat: 889,
          purposeBuiltFlat: 771,
        },
        '3': {
          //property types
          detached: 1002,
          semiDetached: 856,
          endTerraced: 849,
          midTerraced: 809,
          bungalow: 951,
          convertedFlat: 1059,
          purposeBuiltFlat: 941,
        },
        '4': {
          //property types
          detached: 1172,
          semiDetached: 1027,
          endTerraced: 1019,
          midTerraced: 979,
          bungalow: 1121,
          convertedFlat: 1229,
          purposeBuiltFlat: 1111,
        },
        '>4': {
          //property types
          detached: 1512,
          semiDetached: 1367,
          endTerraced: 1360,
          midTerraced: 1319,
          bungalow: 1462,
          convertedFlat: 1570,
          purposeBuiltFlat: 1452,
        },
      },
      '2': {
        //bedrooms
        '1': {
          //property types
          detached: 331,
          semiDetached: 258,
          endTerraced: 254,
          midTerraced: 234,
          bungalow: 305,
          convertedFlat: 359,
          purposeBuiltFlat: 300,
        },
        '2': {
          //property types
          detached: 416,
          semiDetached: 343,
          endTerraced: 340,
          midTerraced: 319,
          bungalow: 391,
          convertedFlat: 445,
          purposeBuiltFlat: 385,
        },
        '3': {
          //property types
          detached: 501,
          semiDetached: 428,
          endTerraced: 425,
          midTerraced: 404,
          bungalow: 476,
          convertedFlat: 530,
          purposeBuiltFlat: 470,
        },
        '4': {
          //property types
          detached: 586,
          semiDetached: 513,
          endTerraced: 510,
          midTerraced: 489,
          bungalow: 561,
          convertedFlat: 615,
          purposeBuiltFlat: 556,
        },
        '>4': {
          //property types
          detached: 756,
          semiDetached: 684,
          endTerraced: 680,
          midTerraced: 660,
          bungalow: 731,
          convertedFlat: 785,
          purposeBuiltFlat: 726,
        },
      },
      '3': {
        //bedrooms
        '1': {
          //property types
          detached: 220,
          semiDetached: 172,
          endTerraced: 170,
          midTerraced: 156,
          bungalow: 204,
          convertedFlat: 240,
          purposeBuiltFlat: 200,
        },
        '2': {
          //property types
          detached: 277,
          semiDetached: 229,
          endTerraced: 226,
          midTerraced: 213,
          bungalow: 260,
          convertedFlat: 296,
          purposeBuiltFlat: 257,
        },
        '3': {
          //property types
          detached: 334,
          semiDetached: 285,
          endTerraced: 283,
          midTerraced: 270,
          bungalow: 317,
          convertedFlat: 353,
          purposeBuiltFlat: 314,
        },
        '4': {
          //property types
          detached: 391,
          semiDetached: 342,
          endTerraced: 340,
          midTerraced: 326,
          bungalow: 374,
          convertedFlat: 410,
          purposeBuiltFlat: 370,
        },
        '>4': {
          //property types
          detached: 504,
          semiDetached: 456,
          endTerraced: 453,
          midTerraced: 440,
          bungalow: 487,
          convertedFlat: 523,
          purposeBuiltFlat: 484,
        },
      },
      '4': {
        //bedrooms
        '1': {
          //property types
          detached: 165,
          semiDetached: 129,
          endTerraced: 127,
          midTerraced: 117,
          bungalow: 153,
          convertedFlat: 180,
          purposeBuiltFlat: 150,
        },
        '2': {
          //property types
          detached: 208,
          semiDetached: 172,
          endTerraced: 170,
          midTerraced: 160,
          bungalow: 195,
          convertedFlat: 222,
          purposeBuiltFlat: 193,
        },
        '3': {
          //property types
          detached: 250,
          semiDetached: 214,
          endTerraced: 212,
          midTerraced: 202,
          bungalow: 238,
          convertedFlat: 265,
          purposeBuiltFlat: 235,
        },
        '4': {
          //property types
          detached: 293,
          semiDetached: 257,
          endTerraced: 255,
          midTerraced: 245,
          bungalow: 280,
          convertedFlat: 307,
          purposeBuiltFlat: 278,
        },
        '>4': {
          //property types
          detached: 378,
          semiDetached: 342,
          endTerraced: 340,
          midTerraced: 330,
          bungalow: 365,
          convertedFlat: 392,
          purposeBuiltFlat: 363,
        },
      },
      '>4': {
        //bedrooms
        '1': {
          //property types
          detached: 132,
          semiDetached: 103,
          endTerraced: 102,
          midTerraced: 94,
          bungalow: 122,
          convertedFlat: 144,
          purposeBuiltFlat: 120,
        },
        '2': {
          //property types
          detached: 166,
          semiDetached: 137,
          endTerraced: 136,
          midTerraced: 128,
          bungalow: 156,
          convertedFlat: 178,
          purposeBuiltFlat: 154,
        },
        '3': {
          //property types
          detached: 200,
          semiDetached: 171,
          endTerraced: 170,
          midTerraced: 162,
          bungalow: 190,
          convertedFlat: 212,
          purposeBuiltFlat: 188,
        },
        '4': {
          //property types
          detached: 234,
          semiDetached: 205,
          endTerraced: 204,
          midTerraced: 196,
          bungalow: 224,
          convertedFlat: 246,
          purposeBuiltFlat: 222,
        },
        '>4': {
          //property types
          detached: 302,
          semiDetached: 273,
          endTerraced: 272,
          midTerraced: 264,
          bungalow: 292,
          convertedFlat: 314,
          purposeBuiltFlat: 290,
        },
      },
    },
  },
}

export default electricityMatrix
