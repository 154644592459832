const URL = `https://${
  process.env.GATSBY_API_HOST as string
}/microtricity/v1/public/installation`

export enum ExportStatus {
  STANDARD = 'Standard Tariff',
}

export type Installation = {
  exportStatus: string
}

export const fetchInstallation = async (
  fitId: string,
  postcode: string
): Promise<Installation> => {
  const UrlWithParams = `${URL}/${fitId}?postcode=${postcode}`
  return fetch(UrlWithParams)
    .then(response => response.json())
    .then(response =>
      response.status === 'success' ? response.data : undefined
    )
}
