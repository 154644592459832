/**
 * ap for the function/reader applicative functor
 * very useful for dependency injection
 * takes two functions and applies a value to them both, then applies the result of the first to the result of the second
 * see: S combinator from SKI calculus
 */
const ap =
  <Q, A>(fa: (q: Q) => A) =>
  <R, B>(fab: (r: R) => (a: A) => B) =>
  (qr: Q & R): B =>
    fab(qr)(fa(qr))

export default ap
