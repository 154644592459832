import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import BusinessHero from '../components/business-hero'
import Spread from '../components/spread'
import Strapline from '../components/strapline'
import JoinUs from '../components/join-us'
import Cluster from '../components/cluster'
import GeneralContent from '../components/general-content'

export const query = graphql`
  query Business(
    $pageId: String!
    $pageSlug: String!
    $relatedPages: Boolean!
    $relatedFilter: ContentfulPageFilterInput
    $relatedSort: ContentfulPageSortInput
    $relatedLimit: Int
  ) {
    business: contentfulIndex(id: { eq: $pageId }) {
      title
      slug
      description
      sections {
        __typename
        ... on Node {
          ... on ContentfulBusinessHero {
            servicesHeading
            servicesLinks {
              title
              body {
                raw
              }
            }
            actionsHeading
            actionsLinks {
              title
              body {
                raw
              }
            }
          }
          ... on ContentfulStrapline {
            strapline
          }
          ... on ContentfulItem {
            ...Item
          }
          ... on ContentfulGeneralContent {
            title
            type
            items {
              ... on Node {
                ... on ContentfulItem {
                  itemTitle: title
                  image {
                    gatsbyImageData(
                      quality: 60
                      layout: FULL_WIDTH
                      formats: [WEBP]
                    )
                  }
                  video {
                    title
                    url
                  }
                  body {
                    raw
                    references {
                      ...References
                    }
                  }
                }
                ... on ContentfulClient {
                  name
                  logo {
                    gatsbyImageData(
                      width: 170
                      quality: 60
                      layout: FIXED
                      formats: [WEBP]
                    )
                  }
                  page {
                    slug
                    topic {
                      slug
                      context {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
          ... on ContentfulRelatedPages {
            __typename
            title
            pages {
              ...RelatedPage
            }
          }
          ... on ContentfulJoinUs {
            heading
            callToAction
          }
        }
      }
    }
    topic: contentfulTopic(slug: { eq: $pageSlug }) {
      name
      heading
      subheading
      banner {
        gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
      }
    }
    related: allContentfulPage(
      filter: $relatedFilter
      sort: $relatedSort
      limit: $relatedLimit
    ) @include(if: $relatedPages) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const Business: React.FC<PageProps<GatsbyTypes.BusinessQuery>> = ({
  data: { business = {}, topic = {}, related = {} },
}) => {
  const sections = business.sections
    ?.map((section, i) => {
      switch (section?.__typename) {
        case 'ContentfulBusinessHero':
          return (
            <BusinessHero
              servicesHeading={section.servicesHeading as string}
              servicesLinks={section.servicesLinks?.map(links => ({
                title: links?.title,
                body: links?.body,
              }))}
              actionsHeading={section.actionsHeading as string}
              actionsLinks={section.actionsLinks?.map(links => ({
                title: links?.title,
                body: links?.body,
              }))}
            />
          )

        case 'ContentfulStrapline':
          return <Strapline strapline={section.strapline as string} />

        case 'ContentfulItem':
          return (
            <Spread
              title={section.title}
              items={[
                {
                  body: section.body,
                  image: section.image,
                  video: section.video,
                },
              ]}
              flip={Boolean(i & 1)}
              variant="index"
            />
          )

        case 'ContentfulGeneralContent':
          return <GeneralContent {...section} />
        case 'ContentfulRelatedPages': {
          const pinned = section.pages || []
          const nodes = related.nodes || []
          return (
            <Cluster
              title={section.title}
              items={[...pinned, ...nodes]?.map(page => ({
                title: page?.title,
                image: page?.image,
                text: page?.description,
                page: page,
              }))}
              variant="index"
            />
          )
        }

        case 'ContentfulJoinUs':
          return (
            <JoinUs
              heading={section.heading}
              callToAction={section.callToAction}
            />
          )
        default:
          return null
      }
    })
    .map((section, i) => {
      switch (business.sections && business.sections[i]?.__typename) {
        case 'ContentfulBusinessHero':
          return (
            <Section as="div" key={i} pos={i} variant="wide">
              {section}
            </Section>
          )

        case 'ContentfulStrapline':
          return (
            <Section as="div" key={i} pos={i} fill="sprayNESW" variant="wide">
              {section}
            </Section>
          )

        case 'ContentfulGeneralContent':
          return (
            <Section key={i} pos={i} fill="cutNWSpraySE" variant="index">
              {section}
            </Section>
          )
        default:
          return (
            <Section key={i} pos={i} variant="index">
              {section}
            </Section>
          )
      }
    })

  return (
    <Layout page={{ ...business, topic }} masthead="business">
      {sections}
    </Layout>
  )
}

export default Business
