import React from 'react'
import { Stack } from 'raam'
import { ReactNode } from 'react'
import { Box, Grid, Heading, Text, ThemeUIStyleObject } from 'theme-ui'
import {
  BLOCKS,
  Document,
  Node,
  renderBlockLink,
  renderRichText,
} from '../rich-text'

interface Props {
  title?: string
  body?: Document
  items?: Array<ReactNode>
}

const Gallery: React.FC<Props> = ({ title, body, items }) => {
  const heading: ThemeUIStyleObject = {
    width: ['auto', null, '70%', '60%'],
    textAlign: 'center',
    marginX: 'auto',
    marginBottom: 11,
  }

  const stack: ThemeUIStyleObject = {
    width: ['auto', null, '75%', '65%'],
    marginX: 'auto',
  }

  const grid: ThemeUIStyleObject = {
    marginTop: 14,
    gridTemplateColumns: ['auto', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr 1fr'],
  }

  const renderers = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: ReactNode) =>
        renderBlockLink(node) || (
          <Text as="p" variant="intro">
            {children}
          </Text>
        ),
    },
  }

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      {body && (
        <Stack sx={stack} gap={11}>
          {body && renderRichText(body, renderers)}
        </Stack>
      )}
      {items && (
        <Grid sx={grid} variant="equal">
          {items.slice(0, 8)}
        </Grid>
      )}
    </Box>
  )
}

export default Gallery
