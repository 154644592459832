import React from 'react'
import { Fragment, useState } from 'react'
import { Box, Image, Link, Text, ThemeUIStyleObject } from 'theme-ui'
import { PartnerDetails } from '../hooks/fetch-promo'
import arrowhead from '../images/small-arrowhead.svg'

interface Props {
  partner: PartnerDetails
}

const joinUrl = process.env.GATSBY_JOIN_URL

const PartnerPromo: React.FC<Props> = ({ partner }) => {
  const [showTerms, setShowTerms] = useState(false)

  const clickTerms = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    setShowTerms(!showTerms)
  }

  const banner: ThemeUIStyleObject = {
    width: '100%',
  }

  const promo: ThemeUIStyleObject = {
    display: ['block', 'flex'],
    alignItems: 'center',
    width: ['76%', null, '70%'],
    marginTop: 16,
    marginX: 'auto',
    boxShadow: 'card',
  }

  const logo: ThemeUIStyleObject = {
    display: 'flex',
    flexShrink: 0,
    alignSelf: 'stretch',
    padding: 12,
    borderRight: 'muted',
    borderWidth: '0.15rem',
    img: {
      alignSelf: 'center',
      maxWidth: ['15rem', null, '20rem'],
    },
  }

  const offer: ThemeUIStyleObject = {
    paddingY: 12,
    paddingX: 14,
  }

  const offerText: ThemeUIStyleObject = {
    variant: ['text.intro', null, 'text.head'] as unknown as string,
    p: { margin: 0 },
  }

  const offerTerms: ThemeUIStyleObject = {
    display: 'inline-block',
    marginTop: 8,
    paddingRight: '1.8rem',
    color: 'text',
    fontWeight: 'bold',
    backgroundImage: `url(${arrowhead})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right center',
    backgroundSize: '1rem',
    cursor: 'pointer',
  }

  const offerTermsDetails: ThemeUIStyleObject = {
    display: showTerms ? 'block' : 'none',
    'ul, ol': { paddingLeft: '1.2rem' },
  }

  if (partner.invalid) {
    return null
  }

  return (
    <Fragment>
      {partner?.bannerImage?.imageUrl && (
        <Image sx={banner} src={partner.bannerImage.imageUrl} />
      )}
      {partner?.description && (
        <Link
          sx={promo}
          href={`${joinUrl}?partnercode=${partner?.code}`}
          variant="plain"
        >
          {partner.logo?.imageUrl && (
            <Box sx={logo}>
              <Image src={`${partner.logo.imageUrl}`} />
            </Box>
          )}
          <Box sx={offer}>
            <Text
              sx={offerText}
              dangerouslySetInnerHTML={{
                __html: partner.description?.toString(),
              }}
            />
            {partner.termsAndConditions && (
              <Fragment>
                <Text sx={offerTerms} onClick={clickTerms}>
                  Terms &amp; Conditions apply
                </Text>
                <Text
                  sx={offerTermsDetails}
                  dangerouslySetInnerHTML={{
                    __html: partner.termsAndConditions?.toString(),
                  }}
                />
              </Fragment>
            )}
          </Box>
        </Link>
      )}
    </Fragment>
  )
}

export default PartnerPromo
