import React from 'react'
import NavLink from './nav-link'
import logo from '../images/logotype.svg'

const Logo: React.FC = props => (
  <NavLink to="/" sx={{ display: 'inline-block' }} {...props}>
    <img
      sx={{ verticalAlign: 'middle' }}
      src={logo}
      alt="Ecotricity logotype"
    />
  </NavLink>
)

export default Logo
