import React from 'react'
import { Box, ThemeUIStyleObject } from 'theme-ui'
import { useRef, useContext, useEffect } from 'react'
import { YouTubeContext } from '../context/youtube'
import useOnScreen from '../hooks/use-on-screen'
import getId from 'get-youtube-id'

export interface Props {
  title?: string
  url?: string
}

const Video: React.FC<Props> = ({ url = '', ...other }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { init, Player } = useContext(YouTubeContext)
  const isOnScreen = useOnScreen(ref)

  useEffect(() => {
    if (isOnScreen) init()
  }, [init, isOnScreen])

  useEffect(() => {
    const id = getId(url)
    const el = ref.current

    let player: YT.Player

    if (id && el && Player) {
      player = new Player(el, {
        videoId: id,
        playerVars: {
          rel: 0,
          modestbranding: 1,
          controls: 0,
        },
      })
    }

    return () => {
      if (player) {
        player.destroy()
      }
    }
  }, [url, Player])

  const wrapper: ThemeUIStyleObject = {
    position: 'relative',
    pb: 9 / (16 / 100) + '%',
  }

  const video: ThemeUIStyleObject = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  }

  return (
    <Box sx={wrapper} {...other}>
      <div ref={ref} sx={video} />
    </Box>
  )
}

export default Video
