import React from 'react'
import { ThemeUIStyleObject, Heading, Box, Link, Image, Text } from 'theme-ui'
import {
  Document,
  BLOCKS,
  Node,
  renderBlockLink,
  renderRichText,
} from '../rich-text'

import carbonCalculatorSvg from '../images/carbon-calculator.svg'

type Props = {
  title?: string
  description?: Document
  ctaText?: string
  ctaUrl?: string
  ctaOpenInNewTab?: boolean
}

const buttonStyle: ThemeUIStyleObject = {
  display: 'block',
  width: '20rem',
  marginTop: '2rem',
  marginX: 'auto',
  fontSize: '1.25rem',
}

const titleStyle: ThemeUIStyleObject = {
  fontSize: '3rem',
  textAlign: 'center',
}

const descriptionStyle: ThemeUIStyleObject = {
  fontSize: '1.25rem',
  textAlign: 'center',
  marginTop: '0.5rem',
}

const imageStyle: ThemeUIStyleObject = {
  height: '18.5rem',
  display: ['block', 'block', 'inline-block'],
  margin: ['auto', 'auto', 'initial'],
}

const textContainer: ThemeUIStyleObject = {
  display: 'inline-flex',
  marginTop: ['2rem', '2rem', 'initial'],
  height: ['initial', 'initial', '18.5rem'],
  verticalAlign: 'top',
  textAlign: 'center',
  width: ['100%', '100%', 'initial'],
}

const containerStyle: ThemeUIStyleObject = {
  display: ['initial', 'initial', 'flex'],
  justifyContent: 'center',
}

const textWrapper: ThemeUIStyleObject = {
  alignSelf: 'center',
  width: '100%',
  marginLeft: ['initial', 'initial', '4rem'],
}

const blockRenderers = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) =>
      renderBlockLink(node) || <Text sx={descriptionStyle}>{children}</Text>,
  },
}

const FindCarbonFootprint: React.FC<Props> = ({
  title = '',
  description,
  ctaText = '',
  ctaUrl = '',
  ctaOpenInNewTab = false,
}) => {
  const linkParams = {
    href: ctaUrl,
    sx: buttonStyle,
    variant: 'button',
  }

  if (ctaOpenInNewTab) {
    linkParams.target = '_blank'
  }

  return (
    <Box sx={containerStyle}>
      <Image sx={imageStyle} src={carbonCalculatorSvg} />
      <Box sx={textContainer}>
        <Box sx={textWrapper}>
          <Heading sx={titleStyle}>{title}</Heading>
          {description && renderRichText(description, blockRenderers)}
          {ctaText && ctaUrl ? (
            <Box>
              <Link {...linkParams}>{ctaText}</Link>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  )
}

export default FindCarbonFootprint
