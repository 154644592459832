import React from 'react'
import { AnchorHTMLAttributes } from 'react'
import { Link as External, ThemeUIStyleObject } from 'theme-ui'
import { Link as Internal, GatsbyLinkProps } from 'gatsby'

// fix for https://github.com/gatsbyjs/gatsby/issues/16682#issuecomment-523352028
type Omit<T, K> = Pick<T, Exclude<keyof T, K>>

// link interface as per https://paulie.dev/posts/2020/04/typescript-theme-ui-link/
export interface Props
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  variant?: string
}

// component replicates link variant theming for Gatsby Link
const NavLink: React.FC<Props> = ({
  to,
  variant = 'nav',
  activeClassName = 'active',
  children,
  ...other
}) => {
  const link: ThemeUIStyleObject = {
    variant: `links.${variant}`,
  }

  return to.charAt(0) === '/' ? (
    <Internal
      to={to}
      sx={link}
      activeClassName={activeClassName}
      partiallyActive={true}
      {...(other as AnchorHTMLAttributes<HTMLAnchorElement>)}
    >
      {children}
    </Internal>
  ) : (
    <External href={to} sx={link} {...other}>
      {children}
    </External>
  )
}

export default NavLink
