import React from 'react'
import { Heading, Text, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  heading: string
  subheading?: string
}

const HomeHeroText: React.FC<Props> = ({ heading, subheading }) => {
  const headingStyle: ThemeUIStyleObject = {
    fontFamily: '"Big Shoulders Display", sans-serif',
    fontSize: [6, 8, 11],
    fontWeight: 900,
    textTransform: 'uppercase',
    lineHeight: 0.9,
  }

  const subheadingStyle: ThemeUIStyleObject = {
    marginTop: 8,
    lineHeight: 1.3,
  }

  return (
    <React.Fragment>
      <Heading as="h1" sx={headingStyle}>
        {heading}
      </Heading>
      {subheading && (
        <Text sx={subheadingStyle} variant="hero">
          {subheading}
        </Text>
      )}
    </React.Fragment>
  )
}

export default HomeHeroText
