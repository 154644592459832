export interface PartnerDetails {
  createdAt?: string
  updatedAt?: string
  name?: string
  code?: string
  group?: string
  description?: string
  offerStartDate?: string
  offerEndDate?: string
  termsAndConditions?: string
  confirmationMessage?: string
  logo?: {
    name: string
    caption: string
    imageUrl: string
    url: string
  }
  bannerImage?: {
    name: string
    caption: string
    imageUrl: string
    url: string
  }
  invalid?: boolean
}

export interface OldPartnerDetails {
  code: string
  name?: string
  banner_image?: { image: string }
  logo?: { image: string }
  description?: string
  terms_and_conditions?: string
  offer_start_date?: string
  offer_end_date?: string
  invalid?: boolean
}

export interface PartnerResult {
  status?: string
  data?: PartnerDetails | OldPartnerDetails
}

class FetchPromo {
  endpoint = `https://${
    process.env.GATSBY_API_HOST as string
  }/partners/v1/partners`

  promise: undefined | Promise<PartnerResult>

  async getPartner(id: string) {
    try {
      const response = await fetch(`${this.endpoint}/${id}`)
      const jsonResponse = await response.json()

      if (jsonResponse.status === 'fail') {
        return {
          status: jsonResponse.status,
          data: { code: id, invalid: true },
        }
      }

      return jsonResponse
    } catch (error) {
      return {}
    }
  }

  async get(id: string) {
    if (!this.promise) {
      this.promise = this.getPartner(id)
    }

    return await this.promise
  }
}

export default new FetchPromo()
