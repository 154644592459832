import React from 'react'
import { Link, Box, Text, ThemeUIStyleObject } from 'theme-ui'
import { useBreakpointIndex } from '@theme-ui/match-media'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../styles/slick-carousel.css'

const carousel: ThemeUIStyleObject = {
  maxWidth: '720px',
  margin: 'auto',
}

const link: ThemeUIStyleObject = { display: 'block' }

const iframe: ThemeUIStyleObject = {
  pointerEvents: 'none',
  width: '100%',
  height: '9rem',
}

const card: ThemeUIStyleObject = {
  margin: ['auto auto 1rem', '0 1rem 1rem'],
  maxWidth: ['18rem', 'initial'],
  boxShadow: '3px 3px 6px 1px rgb(0 0 0 / 7%)',
}

const cardBody: ThemeUIStyleObject = {
  minHeight: ['5.3rem', '5.3rem', '5.3rem', '5.8rem'],
  padding: ['0.7rem 1rem 1rem'],
  fontSize: '1rem',
}

const cardBodyText: ThemeUIStyleObject = {
  fontSize: ['1rem', '1rem', '1rem', '0.9rem'],
  fontWeight: 700,
}

const seeAllButton: ThemeUIStyleObject = {
  display: 'block',
  textAlign: 'center',
  width: '18.25rem',
  lineHeight: '5.5rem',
  paddingY: 0,
  background: '#9cd637',
  borderRadius: '4.5rem',
  margin: ['3.5rem auto auto', '1.5rem auto auto'],
  textDecoration: 'none',
}

interface Props {
  title?: string
  description?: string
  buttonText?: string
  buttonUrl?: string
  videos?: Array<Video>
}

interface IVideo {
  title?: string
  url?: string
}

const Video = ({ title, url }: IVideo) => {
  if (!url) {
    return <Box />
  }

  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  if (!match || match[2].length !== 11) {
    return null
  }

  return (
    <Box sx={card}>
      <Link sx={link} draggable="false" href={url} target="_blank">
        <iframe
          sx={iframe}
          src={`//www.youtube.com/embed/${match[2]}`}
          title={title}
          frameBorder="0"
        />
      </Link>
      <Box sx={cardBody}>
        <Text sx={cardBodyText}>{title}</Text>
      </Box>
    </Box>
  )
}

const Carousel: React.FC<Props> = ({
  buttonText,
  buttonUrl,
  videos = [],
}: Props) => {
  const defaultSettings = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
  }

  const breakpoint = useBreakpointIndex({ defaultIndex: 2 })
  const isMobile = breakpoint === 0
  let sliderSettings = { ...defaultSettings }

  if (isMobile) {
    sliderSettings = {
      ...defaultSettings,
      dots: true,
      slidesToShow: 1,
    }
  }

  return (
    <Box sx={carousel}>
      <Slider {...sliderSettings}>
        {videos.map((video, key) => (
          <Video key={key} {...video} />
        ))}
      </Slider>
      {buttonText ? (
        <Link
          sx={seeAllButton}
          variant="button"
          href={buttonUrl}
          target="_blank"
        >
          {buttonText}
        </Link>
      ) : null}
    </Box>
  )
}

export default Carousel
