import React from 'react'
import { ThemeUIStyleObject } from 'theme-ui'
import { fluidScale } from '../utils/mixins'
import QuoteWidget from './quote-widget'

interface Props {
  callToAction?: string
}

const HomeHeroWidget: React.FC<Props> = ({ callToAction }) => {
  const wrapper: ThemeUIStyleObject = {
    marginTop: [8, 12],
    marginX: 0,
    marginBottom: [11, 0],
    width: ['100%', '85%'],
  }

  const fields: ThemeUIStyleObject = {
    paddingY: fluidScale(11, 1, 1),
    paddingX: fluidScale(13, 1, 1),
  }

  const fieldsFallback: ThemeUIStyleObject = {
    'paddingLeft ': 11,
    'paddingRight ': 11,
    'paddingTop ': 13,
    'paddingBottom ': 13,
  }

  return (
    <QuoteWidget
      sx={wrapper}
      inputSx={{ ...fieldsFallback, ...fields }}
      buttonSx={{ ...fieldsFallback, ...fields }}
      alt={true}
    ></QuoteWidget>
  )
}

export default HomeHeroWidget
