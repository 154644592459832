import React from 'react'
import { Grid, Text, ThemeUIStyleObject, Heading, Box } from 'theme-ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Inline, Stack } from 'raam'
import { graphql, useStaticQuery } from 'gatsby'
import { renderRichText } from '../rich-text'
import { MaybeGatsbyImageProps } from '../utils/types'
import { isFixed } from '../utils/helpers'

interface Props {
  topic?: string
  heading?: string
  subheading?: string
  author?: {
    name?: string
    image?: MaybeGatsbyImageProps
  }
  date?: Date
}

/**
 * Presents the topic, title, subtitle and standfirst on every standard
 * article page
 */
const NewsHeader: React.FC<Props> = ({
  topic,
  heading,
  subheading,
  author,
  date,
}) => {
  const data = useStaticQuery<GatsbyTypes.NewsPromoQuery>(graphql`
    query NewsPromo {
      promo: contentfulItem(contentful_id: { eq: "4VyQ7tHiDZNfzX4S9NM90P" }) {
        title
        body {
          raw
        }
      }
    }
  `)

  const grid: ThemeUIStyleObject = {
    gridTemplateColumns: [
      '[start] repeat(12, 1fr) [end]',
      null,
      '1fr [start] repeat(7, 1fr) [end] 1fr [promo-start] repeat(3, 1fr) [promo-end]',
    ],
    rowGap: 10,
    position: 'relative',

    // horizontal rule
    '::before': {
      content: topic && '""',
      display: ['none', null, 'block'],
      borderTop: 'double',
      position: 'relative',
      top: '50%',
    },
  }

  const col: ThemeUIStyleObject = {
    gridColumn: 'start / end',
  }

  const category: ThemeUIStyleObject = {
    letterSpacing: '0.4em',
  }

  const title: ThemeUIStyleObject = {
    fontSize: [5, 6],
  }

  const byline: ThemeUIStyleObject = {
    alignItems: 'center',
    marginTop: 11,
    marginBottom: -11,
  }

  const avatar: ThemeUIStyleObject = {
    borderRadius: '50%',
    marginRight: 6,
  }

  const promoOuter: ThemeUIStyleObject = {
    display: ['none', null, 'block'],
    gridColumn: 'promo-start / promo-end',
    position: 'relative',
  }

  const promoInner: ThemeUIStyleObject = {
    position: 'absolute',
    width: '100%',
    backgroundColor: 'primary',
    borderRadius: '0.5rem',
    padding: 13,
  }

  return (
    <Grid sx={grid} variant="default">
      <Text sx={{ ...category, ...col }} variant="primary">
        {topic}
      </Text>
      {heading && (
        <Heading as="h1" sx={{ ...title, ...col }} variant="lrg">
          {heading}
        </Heading>
      )}
      {data.promo && (
        <Box sx={promoOuter}>
          <Stack sx={promoInner} gap={11}>
            <Heading as="h3" variant="sml">
              {data.promo.title}
            </Heading>
            {data.promo.body && renderRichText(data.promo.body)}
          </Stack>
        </Box>
      )}
      {subheading && (
        <Heading as="h2" sx={col} variant="intro">
          {subheading}
        </Heading>
      )}
      {(author || date) && (
        <Inline sx={{ ...byline, ...col }} gap={8}>
          {author?.image && isFixed(author.image) && (
            <GatsbyImage image={author.image.gatsbyImageData} sx={avatar} />
          )}
          {author?.name && <Text variant="label">By {author.name}</Text>}
          {date && (
            <Text>
              {date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </Text>
          )}
        </Inline>
      )}
    </Grid>
  )
}

export default NewsHeader
