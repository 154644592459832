import React from 'react'
import { Grid, Heading, Box, ThemeUIStyleObject, Text, Flex } from 'theme-ui'
import { useState, ReactNodeArray } from 'react'
import { Stack } from 'raam'
import { GatsbyImage } from 'gatsby-plugin-image'
import NavLink from './nav-link'
import Video, { Props as VideoProps } from './video'
import { MaybeGatsbyImageProps } from '../utils/types'
import {
  gridTemplateAreas as rows,
  repeatGridArea as cols,
  spanWidth,
} from '../utils/mixins'
import {
  Document,
  BLOCKS,
  Node,
  renderRichText,
  renderBlockLink,
  toString,
} from '../rich-text'
import { isFluid } from '../utils/helpers'

interface Props {
  title?: string
  subtitle?: string
  items?: Array<{
    title?: string
    body?: Document
    image?: MaybeGatsbyImageProps
    video?: VideoProps
  }>
  flip?: boolean
  tabbed?: boolean
  centered?: boolean
  variant?: 'index' | 'page'
}

/**
 * Component for presenting a media block, i.e. image alongside short text content.
 *
 * Multiple items are presented alternatively with the image on the left or right,
 * or optionally with a tabbed view.
 *
 * Grid layout controlled with variant options.
 */
const Spread: React.FC<Props> = ({
  title,
  subtitle,
  items,
  flip = false,
  tabbed = false,
  centered = false,
  variant = 'page',
}) => {
  const [active, setActive] = useState(0)

  // prettier-ignore
  const grids = {
    index: {
      odd: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'media')],
            [cols(12, 'body')],
          ]),
          rows([
            [cols(5, 'body'), null, cols(6, 'media')]
          ]),
          rows([
            [cols(4, 'body'), null, cols(7, 'media')]
          ])
        ],
        alignItems: 'center',
      },
      even: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'media')],
            [cols(12, 'body')],
          ]),
          rows([
            [cols(6, 'media'), null, cols(5, 'body')]
          ]),
          rows([
            [cols(7, 'media'), null, cols(4, 'body')]
          ])
        ],
        alignItems: 'center',
      },
      text: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'body')],
          ]),
          rows([
            [cols(3, null), cols(6, 'body'), cols(3, null)]
          ]),
        ],
      },
    },
    page: {
      odd: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'media')],
            [cols(12, 'body')],
          ]),
          rows([
            [cols(5, 'body'), null, cols(6, 'media')]
          ]),
          rows([
            [null, cols(4, 'body'), null, cols(5, 'media'), null]
          ])
        ],
        alignItems: 'center',
      },
      even: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'media')],
            [cols(12, 'body')],
          ]),
          rows([
            [cols(6, 'media'), null, cols(5, 'body')]
          ]),
          rows([
            [null, cols(5, 'media'), null, cols(4, 'body'), null]
          ])
        ],
        alignItems: 'center',
      },
      text: {
        gridTemplateAreas: [
          rows([
            [cols(12, 'body')],
          ]),
          null,
          rows([
            [null, cols(10, 'body'), null]
          ])
        ],
      },
    },
  }

  const columns = {
    media: {
      odd: {
        gridArea: 'media',
        alignSelf: 'flex-start',
        marginLeft: [0, -11],
      },
      even: {
        gridArea: 'media',
        alignSelf: 'flex-start',
        marginRight: [0, -11],
      },
      text: {},
    },
    body: {
      odd: {
        gridArea: 'body',
        textAlign: centered ? 'center' : 'left',
      },
      even: {
        gridArea: 'body',
        textAlign: centered ? 'center' : 'left',
      },
      text: {
        gridArea: 'body',
        textAlign: centered ? 'center' : 'left',
      },
    },
  }

  const heading: ThemeUIStyleObject = {
    marginBottom: 13,
    textAlign: 'center',
  }

  const flex = {
    marginBottom: 14,
    marginLeft: [0, null, spanWidth(1, 12, 11)],
    justifyContent: 'space-evenly',
  }

  const tabs: ReactNodeArray | undefined = items?.map((item, i) => {
    const size: ThemeUIStyleObject = {
      width: 100 / items.length + '%',
      paddingX: 7,
      paddingY: 10,
    }

    const border: ThemeUIStyleObject = {
      '&.active': { border: 'mutedBg' },
    }

    return (
      <NavLink
        key={i}
        to="#"
        sx={{ ...size, ...border }}
        variant="tab"
        className={i === active ? 'active' : ''}
        onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          e.preventDefault()
          setActive(i)
        }}
      >
        {item?.title}
      </NavLink>
    )
  })

  const renderers = {
    page: {},
    index: {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) =>
          renderBlockLink(node) || (
            <Text as="p" variant="intro">
              {children}
            </Text>
          ),

        [BLOCKS.QUOTE]: (node: Node) => (
          <Text as="blockquote" variant="sml">
            {toString(node)}
          </Text>
        ),
      },
    },
  }

  const stack = items?.map((item, i) => {
    const offset = i + (flip ? 1 : 0)
    const bitwise = offset & 1
    const parity = bitwise ? 'odd' : 'even'
    const layout = item.image || item.video ? parity : 'text'

    return (
      <Grid key={i} sx={grids[variant][layout]} variant="default">
        {item.image && isFluid(item.image) && (
          <GatsbyImage
            sx={columns.media[layout]}
            image={item.image.gatsbyImageData}
          />
        )}
        {item.video && (
          <Video
            sx={columns.media[layout]}
            url={item.video.url}
            title={item.video.title}
          />
        )}
        <Stack sx={columns.body[layout] as ThemeUIStyleObject} gap={10}>
          {!tabbed && item.title && (
            <Heading as="h3" variant="med">
              {item.title}
            </Heading>
          )}
          {item.body && renderRichText(item.body, renderers[variant])}
        </Stack>
      </Grid>
    )
  })

  return (
    <Box>
      {title && (
        <Heading as="h2" sx={heading} variant="lrg">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h4" sx={heading} variant="med">
          {subtitle}
        </Heading>
      )}
      {tabbed && (
        <Flex as="nav" sx={flex as ThemeUIStyleObject}>
          {tabs}
        </Flex>
      )}
      <Stack gap={12}>{stack && tabbed ? stack[active] : stack}</Stack>
    </Box>
  )
}

export default Spread
