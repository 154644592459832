import React from 'react'
import { ThemeUIStyleObject, Flex } from 'theme-ui'
import HeaderButtons from './header-buttons'
import HeaderNav from './header-nav'

interface Props {
  variant?: 'mobile' | 'desktop'
  isBusiness?: boolean
}

const HeaderMenu: React.FC<Props> = ({
  variant = 'desktop',
  isBusiness,
  ...other
}) => {
  const isMobile = variant === 'mobile'
  const mobile: ThemeUIStyleObject = {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    backgroundColor: 'primary',
    width: '100vw',
    minHeight: '100vh',
    padding: '12%',
    zIndex: 3,
  }

  const style = isMobile ? mobile : {}

  return (
    <Flex as="nav" sx={style} {...other}>
      <HeaderNav vertical={isMobile} />
      <HeaderButtons vertical={isMobile} isBusiness={isBusiness} />
    </Flex>
  )
}

export default HeaderMenu
