import { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { cookiePreferences } from '../../types'

const COOKIE_BANNER_NAME = 'eco-cookie-preferences-set'
const COOKIE_MAX_AGE = 31536000

const cookieDomain =
  process.env.NODE_ENV === 'development' ? 'localhost' : '.ecotricity.co.uk'

export const useCookieCompliance = () => {
  const [cookies, setCookie] = useCookies<string>([
    COOKIE_BANNER_NAME,
    'ad_storage',
    'analytics_storage',
  ])
  const [areUserCookiePrefsSet, setAreUserCookiePrefsSet] = useState('false')

  useEffect(() => {
    setAreUserCookiePrefsSet(cookies[COOKIE_BANNER_NAME])
  }, [setAreUserCookiePrefsSet])

  const setCookiePreferences = (
    userSelectedCookieCategories: cookiePreferences
  ) => {
    const { marketing, analytics } = userSelectedCookieCategories

    setCookie(COOKIE_BANNER_NAME, 'true', {
      path: '/',
      maxAge: COOKIE_MAX_AGE,
      domain: cookieDomain,
    })
    setCookie('ad_storage', marketing, {
      path: '/',
      maxAge: COOKIE_MAX_AGE,
      domain: cookieDomain,
    })
    setCookie('analytics_storage', analytics, {
      path: '/',
      maxAge: COOKIE_MAX_AGE,
      domain: cookieDomain,
    })
    window.dataLayer.push({ event: 'user_consent_set' })
  }

  return {
    cookies,
    areUserCookiePrefsSet,
    setCookiePreferences,
  }
}
