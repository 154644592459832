import React from 'react'
import { Box } from 'theme-ui'
import FooterLogo from './footer-logo'
import FooterNav from './footer-nav'
import FooterSocial from './footer-social'

const FooterUtilities: React.FC = () => (
  <Box>
    <FooterLogo />
    <FooterNav sx={{ mt: 11 }} />
    <FooterSocial sx={{ mt: 12 }} />
  </Box>
)

export default FooterUtilities
