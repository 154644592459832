import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import PageHeader from '../components/page-header'
import Article from '../components/article'
import OurMissionFlag from '../components/our-mission-flag'

export const query = graphql`
  query OurMission($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      image {
        gatsbyImageData(width: 1200, quality: 60, layout: FIXED)
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      partnerPromotion
      sections {
        __typename
        ... on ContentfulArticle {
          title
          body {
            raw
            references {
              ...ArticleReferences
            }
          }
          aside {
            raw
            references {
              ...ArticleReferences
            }
          }
        }
      }
    }
  }
`

const OurMission: React.FC<PageProps<GatsbyTypes.OurMissionQuery>> = ({
  data: { page = {} },
}) => {
  const article = page.sections?.find(
    section => section?.__typename === 'ContentfulArticle'
  ) as GatsbyTypes.ContentfulArticle | undefined

  return (
    <Layout page={page} subnav={true} masthead="default">
      <Section as="div" variant={page.sections ? 'header' : 'page'}>
        <PageHeader
          topic={page.topic?.heading || page.topic?.name}
          heading={page.heading || page.title}
          subheading={page.subheading}
          standfirst={page.standfirst?.standfirst}
        />
      </Section>
      <Section pos={0} count={2} variant="page">
        <OurMissionFlag />
      </Section>
      <Section pos={1} count={2} variant="page">
        <Article body={article?.body} aside={article?.aside} />
      </Section>
    </Layout>
  )
}

export default OurMission
