import React from 'react'
import { Text, ThemeUIStyleObject } from 'theme-ui'

interface Props {
  strapline: string
}

/**
 * Full-width large text with solid background.
 */
const Strapline: React.FC<Props> = ({ strapline }) => {
  const style: ThemeUIStyleObject = {
    position: 'relative',
    paddingY: 16,
    width: '72%',
    marginX: 'auto',
    textAlign: 'center',
  }

  return (
    <Text sx={style} variant="lrg">
      {strapline}
    </Text>
  )
}

export default Strapline
