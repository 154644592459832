import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import Section from '../components/section'
import Article from '../components/article'
import Cluster from '../components/cluster'
import Hero from '../components/hero'
import GeneralContent from '../components/general-content'

export const query = graphql`
  query BusinessPage(
    $pageId: String!
    $relatedPages: Boolean!
    $relatedFilter: ContentfulPageFilterInput
    $relatedSort: ContentfulPageSortInput
    $relatedLimit: Int
  ) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      slug
      description
      topic {
        name
        slug
        heading
        subheading
      }
      image {
        gatsbyImageData(
          width: 1200
          quality: 60
          layout: FIXED
          formats: [WEBP]
        )
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      divisions
      partnerPromotion
      sections {
        __typename
        ... on Node {
          ... on ContentfulHero {
            image {
              title
              gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
            }
            video {
              title
              url
            }
          }
          ... on ContentfulArticle {
            title
            body {
              raw
              references {
                ...ArticleReferences
              }
            }
            aside {
              raw
              references {
                ...ArticleReferences
              }
            }
          }
          ... on ContentfulRelatedPages {
            __typename
            title
            pages {
              ...RelatedPage
            }
          }
          ... on ContentfulGeneralContent {
            title
            type
            items {
              ... on Node {
                ... on ContentfulItem {
                  ...Item
                }
              }
            }
          }
        }
      }
    }
    related: allContentfulPage(
      filter: $relatedFilter
      sort: $relatedSort
      limit: $relatedLimit
    ) @include(if: $relatedPages) {
      nodes {
        ...RelatedPage
      }
    }
  }
`

const BusinessPage: React.FC<PageProps<GatsbyTypes.BusinessPageQuery>> = ({
  data: { page = {}, related = {} },
}) => {
  const header = {
    topic: page.topic?.heading || page.topic?.name,
    heading: page.heading || page.title,
  }

  const sections = page.sections
    ?.map((section, i) => {
      switch (section?.__typename) {
        case 'ContentfulHero':
          return <Hero image={section.image} video={section.video} />

        case 'ContentfulArticle':
          return (
            <Article
              title={i !== 0 && page.divisions ? section.title : undefined}
              header={i === 0 ? header : undefined}
              body={section.body}
              aside={section.aside}
            />
          )
        case 'ContentfulGeneralContent': {
          const params =
            section.type[0] === 'Cluster'
              ? { cols: 4, plain: true }
              : section.type[0] === 'Spread'
              ? { tabbed: true }
              : {}
          return <GeneralContent {...section} params={params} />
        }
        case 'ContentfulRelatedPages': {
          const pinned = section.pages || []
          const nodes = related.nodes || []
          return (
            <Cluster
              title={section.title}
              items={[...pinned, ...nodes].map(page => ({
                title: page?.title,
                image: page?.image,
                text: page?.description,
                page: page,
              }))}
              cols={4}
            />
          )
        }

        default:
          return null
      }
    })
    .map((section, i) => (
      <Section
        key={i}
        pos={i}
        count={page.sections?.length}
        variant={i === 0 ? 'default' : 'loose'}
      >
        {section}
      </Section>
    ))

  return (
    <Layout page={page} masthead="breadcrumb">
      {sections}
    </Layout>
  )
}

export default BusinessPage
