// yoinked from current website 🤷‍♀️
const EMAIL_REGEX =
  /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/
const PHONENUMBER_REGEX =
  /^(?:(?:\(?(?:0(?:0|1{2})\)?[\s-]?\(?|\+)4{2}\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?(?:[\s-]?\d{4}){2}))(?:[\s-]?(?:x|ext\.?|#)\d{3,4})?$/
const POSTCODE_REGEX = /^[A-Za-z]{1,2}(\d{1,2}|\d[A-Za-z])\s*\d[A-Za-z]{2}$/
const ACCOUNT_NUMBER_REGEX = /^([0-9]){8,9}$/g

export const nonEmptyString =
  (fieldName: string) =>
  (str: string | undefined): true | string =>
    str?.length ? true : `Please enter your ${fieldName}`

export const nonZeroOption =
  (fieldName: string) =>
  (option: string | undefined): true | string =>
    option ? true : `Please select your ${fieldName}`

export const validEmail =
  (fieldName: string) =>
  (str: string | undefined): true | string =>
    !str?.length || str?.match(EMAIL_REGEX)
      ? true
      : `${fieldName} is not valid.`

export const validPhoneNumber =
  (fieldName: string) =>
  (str: string | undefined): true | string =>
    !str?.length || str?.match(PHONENUMBER_REGEX)
      ? true
      : `${fieldName} is not valid.`

export const validPostcode = (postcode: string): boolean | string =>
  POSTCODE_REGEX.test(postcode) || 'Please enter a valid postcode'

export const validAccountNumber = (accountNumber: string): boolean | string =>
  accountNumber.match(ACCOUNT_NUMBER_REGEX)
    ? true
    : 'Account number is not valid.'
