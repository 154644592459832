import { CSSObject } from 'theme-ui'

export const global: CSSObject = {
  /* fluid typography  */
  html: {
    fontSize: 'min(max(75%, 0.83vw), 112.5%)',
    scrollBehavior: 'smooth',
  },
  /* scroll offset for anchor links */
  ':target': {
    scrollMarginTop: '20vh',
  },
  '#nprogress .bar': {
    height: '10px',
  },
  /* wrap long email addresses */
  'a[href^="mailto:"]': {
    wordBreak: 'break-word',
  },
  /* don't wrap telephone numbers */
  'a[href^="tel:"]': {
    whiteSpace: 'nowrap',
  },
}
