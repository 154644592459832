import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/layout'
import { getSections } from './support-page'

export const query = graphql`
  query SupportSubPage($pageId: String!) {
    page: contentfulPage(id: { eq: $pageId }) {
      title
      description
      slug
      topic {
        name
        slug
        heading
        subheading
        banner {
          gatsbyImageData(quality: 60, layout: FULL_WIDTH, formats: [WEBP])
        }
      }
      heading
      subheading
      standfirst {
        standfirst
      }
      divisions
      sections {
        __typename
        ... on Node {
          ... on ContentfulAppHelp {
            title
            description
            buttonText
            buttonUrl
            videos {
              title
              url
            }
          }
          ... on ContentfulItem {
            ...Item
          }
          ... on ContentfulItemlet {
            ...Itemlet
          }
          ... on ContentfulSupportCta {
            heading {
              raw
            }
          }
          ... on ContentfulGeneralContent {
            title
            type
            items {
              ... on Node {
                ... on ContentfulFaq {
                  id
                  question
                  anchor
                  __typename
                  answer {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulGuide {
                  id
                  heading
                  anchor
                  body {
                    raw
                    references {
                      ...ArticleReferences
                    }
                  }
                }
                ... on ContentfulItem {
                  itemTitle: title
                  image {
                    gatsbyImageData(
                      quality: 60
                      layout: FULL_WIDTH
                      formats: [WEBP]
                    )
                  }
                  video {
                    title
                    url
                  }
                  body {
                    raw
                    references {
                      ...References
                    }
                  }
                }
                ... on ContentfulSupportCategory {
                  slug
                  name
                  icon {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface PageContext {
  parent?: GatsbyTypes.SupportPageQuery
}

const Page: React.FC<PageProps<GatsbyTypes.SupportSubPageQuery, PageContext>> =
  ({ location, data: { page = {} }, pageContext: { parent } }) => {
    let parentPath = parent.slug
    if (parentPath[0] === '/') {
      parentPath = parentPath.slice(1)
    }

    parentPath = `${parent.topic.slug}/${parentPath}`

    const subPages = parent.subPages.map(
      ({ slug, ...subPage }: GatsbyTypes.SupportSubPageQuery) => ({
        slug: `${parentPath}/${slug}`,
        ...subPage,
      })
    )

    const sections = getSections(page, location)

    const subnav = {
      largeButtons: true,
    }

    return (
      <Layout
        page={page}
        parent={parent}
        subPages={subPages}
        masthead="subpage"
        subnav={subnav}
      >
        {sections}
      </Layout>
    )
  }

export default Page
